import React, { Component } from "react";
import moment from "moment";
import "../../assets/scss/calendarWidget.scss";
import ModalWindow from "../UI/ModalWindow";

export class ReminderDetails extends Component {
  state = {
    notifyViewModal: false,
    notifyData: [],
  };

  handleViewModal = (item) => {
    this.setState({
      notifyData: item,
      notifyViewModal: true,
    });
  };

  toggleViewModal = () => {
    this.setState({
      notifyViewModal: !this.state.notifyViewModal,
    });
  };

  render() {
    let notification = this.props.detailsNotifications;
    return (
      <>
        <div>
          <div className="row">
            <div className="col-6 mb-3">
              <span>
                <strong className="mr-2">Heading: </strong>
                {notification.heading}
              </span>
            </div>
            <div className="col-6 mb-3">
              <span>
                <strong className="mr-2">Notification Type:</strong>
                {notification.notificationName}
              </span>
            </div>
            <div className="col-6 mb-3">
              <span>
                <strong className="mr-2">Notify Date:</strong>
                {moment(notification.notifyDate).format("yyyy-MM-DD")}
              </span>
            </div>
            <div className="col-6 mb-3">
              <span>
                <strong className="mr-2">Status:</strong>
                {notification.isScheduled ? "Scheduled" : "Sent"}
              </span>
            </div>
            <div className="col-6 mb-3">
              <span>
                <strong className="mr-2">Start date:</strong>
                {notification.startDate !== null
                  ? moment(notification.startDate).format("yyyy-MM-DD")
                  : "-"}
              </span>
            </div>
            <div className="col-6 mb-3">
              <span>
                <strong className="mr-2">End date:</strong>
                {notification.endDate !== null
                  ? moment(notification.endDate).format("yyyy-MM-DD")
                  : "-"}
              </span>
            </div>

            {notification.groups !== null && notification.groups.length > 0 ? (
              <div className="col-6 mb-3">
                <span>
                  <strong className="mr-2">Notify groups:</strong>

                  <button
                    className="tt-button tt-button-primary ml-3"
                    onClick={() => this.handleViewModal(notification.groups)}
                  >
                    View groups
                  </button>
                </span>
              </div>
            ) : (
              <div className="col-6 mb-3">
                <span>
                  <strong className="mr-2">Notify peoples:</strong>
                  <button
                    className="tt-button tt-button-primary ml-3"
                    onClick={() => this.handleViewModal(notification.peoples)}
                  >
                    View peoples
                  </button>
                </span>
              </div>
            )}
          </div>
          <div className="row">
            <div class="col">
              <strong className="mr-2">Message: </strong>
              <p className="p-2 mt-2">{notification.message}</p>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.notifyViewModal}
          toggleModal={this.toggleViewModal}
          modalHeader="Notify To"
          modalBody={
            <>
              <div style={{ maxHeight: "200px" }}>
                <div className="tt-chipBox mt-2 mb-2">
                  {this.state.notifyData.length > 0 &&
                    this.state.notifyData?.map((nd) => (
                      <div className="tt-chipHolder mb-2">{nd.name}</div>
                    ))}
                </div>
              </div>
            </>
          }
          size="xl"
        ></ModalWindow>
      </>
    );
  }
}

export default ReminderDetails;

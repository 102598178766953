import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../../utils/Constants";

import AssignInventoryItemHeader from "./AssignInventoryItemHeader";
import AssignInventoryItemList from "./AssignInventoryItemList";
import { displayErrorAlert } from "../../../utils/Utils";
import ModalWindow from "../../UI/ModalWindow";
import GranularPermissionModal from "../../Permissions/GranularPermissionModal";

class AssignInventoryItems extends Component {
  state = {
    allStudents: true,
    assignedInventories: [
      {
        feeCategoryId: "",
        inventoryItems: [],
      },
    ],
    categories: [],
    classroom: "",
    classrooms: [],
    displayCategoruy: false,
    educationLevel: "",
    educationLevels: [],
    selectedIndividual: [],
    studentList: [],
    yearLevel: "",
    yearLevels: [],
    permissionModal: false,
  };

  addAssignedInventories = () => {
    this.setState({
      assignedInventories: [
        ...this.state.assignedInventories,
        {
          feeCategoryId: "",
          inventoryItems: [],
        },
      ],
    });
  };

  assignInventories = () => {
    let assignedInventories = this.state.assignedInventories,
      studentInventoryItems = [];
    let param = {
      educationLevelId: this.state.educationLevel,
      yearLevelId: this.state.yearLevel,
      classroomId: this.state.classroom,
      admissionIds: [],
      studentInventoryItems: [],
    };
    if (!this.state.allStudents) {
      let admissionIds = [];
      this.state.selectedIndividual.forEach((el) => {
        admissionIds.push(el.value);
      });
      param.admissionIds = admissionIds;
    }

    assignedInventories.forEach((el) => {
      if (el.inventoryItems.length > 0) {
        el.inventoryItems.forEach((sl) => {
          if (sl.isChecked) {
            let tempData = {};
            tempData.feeCategoryId = el.feeCategoryId;
            tempData.inventoryItemId = sl.id;
            tempData.quantity = sl.quantity;
            studentInventoryItems.push(tempData);
          }
        });
      }
    });
    param.studentInventoryItems = studentInventoryItems;
    axiosPost(
      URL.insertStudentInventory,
      param,
      (res) => {
        if (res.status === 200) {
          swal("Success", "Successfully assigned inventories");
          this.resetData();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  componentDidMount() {
    this.getEducationLevels();
    this.getCategories();
  }

  deleteAssignedInventories = (idx) => {
    let assignedInventories = [...this.state.assignedInventories];
    assignedInventories.splice(idx, 1);
    this.setState({ assignedInventories }, function () {
      this.handleCategoryValidation();
    });
  };

  getCategories = () => {
    axiosPost(
      URL.getAllFeeCategory,
      { isInventoryCategory: true },
      (response) => {
        if (response.status === 200) {
          let datum = response.data.data;
          datum.forEach((el) => {
            el.isDisabled = false;
          });
          this.setState({ categories: datum });
        }
      }
    );
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
          });
      }
    });
  };

  getStudentList = () => {
    let param = {
      yearLevelId: this.state.yearLevel,
      classroomId: this.state.classroom,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status == 200) {
        let list = response.data.data;
        let studentList = [];
        list.forEach((std) => {
          let data = {
            label: std?.people?.name,
            value: std?.admissionId,
          };
          studentList.push(data);
        });
        this.setState({ studentList });
      }
    });
  };

  handleAssignChange = (e, idx) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let assignedInventories = [...this.state.assignedInventories];
    assignedInventories[idx][name] = value;
    if (name === "feeCategoryId") {
      let param = {
        feeCategoryId: value,
      };
      axiosPost(URL.getInventories, param, (res) => {
        if (res.status === 200) {
          let datum = res.data.data;
          datum.forEach((el) => {
            el.isChecked = false;
            el.quantity = 0;
          });
          assignedInventories[idx].inventoryItems = datum;
          this.setState({ assignedInventories }, function () {
            this.handleCategoryValidation();
          });
        }
      });
    } else {
      this.setState({ assignedInventories });
    }
  };

  handleCategoryValidation = () => {
    let assignedInventories = [...this.state.assignedInventories];
    let categories = [...this.state.categories];
    categories.forEach((el) => {
      var check = assignedInventories.filter(
        (c) => parseInt(c.feeCategoryId) === el.id
      );
      if (check.length > 0) {
        el.isDisabled = true;
      } else {
        el.isDisabled = false;
      }
    });
    this.setState({ categories });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "educationLevel") {
        this.state.educationLevels.forEach((el) => {
          if (el.id === parseInt(value)) {
            this.setState({
              yearLevels: el.yearLevels,
              yearLevel: "",
              classrooms: [],
              classroom: "",
            });
          }
        });
      }
      if (name === "yearLevel") {
        let data = {
          yearLevelId: value,
        };
        axiosPost(URL.selectClassroom, data, (response) => {
          if (response.status === 200) {
            this.setState({
              classrooms: response.data.data,
              classroom: "",
            });
          }
        });
      }
      if (name === "classroom") {
        this.setState({ displayCategoruy: true });
        this.getStudentList();
      }
    });
  };

  handleCheckboxChange = (e, mIdx, index) => {
    let assignedInventories = [...this.state.assignedInventories],
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    assignedInventories[mIdx].inventoryItems[index].isChecked = value;
    if (value === false) {
      assignedInventories[mIdx].inventoryItems[index].quantity = 0;
    }
    this.setState({ assignedInventories });
  };

  handleQuantityChange = (e, mIdx, index) => {
    let assignedInventories = [...this.state.assignedInventories],
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let totalStock = assignedInventories[mIdx].inventoryItems[index].instock;
    if (this.state.allStudents) {
      let totalStudents = this.state.studentList.length,
        totalValue = totalStudents * value;
      if (totalValue <= totalStock) {
        assignedInventories[mIdx].inventoryItems[index].quantity = value;
        this.setState({ assignedInventories });
      } else {
        swal(
          "Error",
          "There are not enough stock to assign the given quantity to all students"
        );
        return false;
      }
    } else {
      let totalStudents = this.state.selectedIndividual.length,
        totalValue = totalStudents * value;
      if (totalValue <= totalStock) {
        assignedInventories[mIdx].inventoryItems[index].quantity = value;
        this.setState({ assignedInventories });
      } else {
        swal(
          "Error",
          "There are not enough stock to assign the given quantity to all selected students"
        );
        return false;
      }
    }
  };

  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  resetData = () => {
    this.setState(
      {
        allStudents: true,
        assignedInventories: [
          {
            feeCategoryId: "",
            inventoryItems: [],
          },
        ],
        classroom: "",
        displayCategoruy: false,
        educationLevel: "",
        selectedIndividual: [],
        yearLevel: "",
      },
      function () {
        this.handleCategoryValidation();
      }
    );
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="container-fluid">
          <div className="tt-group-header">
            Assign Inventory Items
            {checkWidgetAdminOrNot("Account") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <AssignInventoryItemHeader
            educationLevel={this.state.educationLevel}
            educationLevels={this.state.educationLevels}
            handleChange={this.handleChange}
            yearLevel={this.state.yearLevel}
            yearLevels={this.state.yearLevels}
            classroom={this.state.classroom}
            classrooms={this.state.classrooms}
            allStudents={this.state.allStudents}
            selectedIndividual={this.state.selectedIndividual}
            studentList={this.state.studentList}
            handleIndividualSelect={this.handleIndividualSelect}
          ></AssignInventoryItemHeader>
          {this.state.displayCategoruy ? (
            <AssignInventoryItemList
              assignedInventories={this.state.assignedInventories}
              categories={this.state.categories}
              handleAssignChange={this.handleAssignChange}
              handleCheckboxChange={this.handleCheckboxChange}
              handleQuantityChange={this.handleQuantityChange}
              addAssignedInventories={this.addAssignedInventories}
              deleteAssignedInventories={this.deleteAssignedInventories}
            ></AssignInventoryItemList>
          ) : null}
          {this.state.displayCategoruy ? (
            <div className="row">
              <div className="col text-right">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.assignInventories}
                >
                  Submit
                </button>
                <button
                  className="tt-button tt-button-danger ml-3"
                  onClick={this.resetData}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : null}
        </div>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Account"
              moduleName="Inventory"
              header="Assign Inventory Items"
              activityName="assign-inventory-items"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default AssignInventoryItems;

import React, { Component } from "react";
import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import {
  checkWidgetAdminOrNot,
  userRole,
  userRoleCode,
  checkForSubRoles,
} from "../../../utils/Constants";

class CalendarWidgetHeader extends Component {
  state = {
    permissionModal: false,
    termAlias:
      localStorage.getItem("educationLevelDivision") === null
        ? "Term"
        : localStorage.getItem("educationLevelDivision"), //get from localStorage
  };

  checkToEnablePermission = () => {
    let enable = false;
    if (userRole === userRoleCode.roleAdmin) {
      enable = true;
    } else {
      const educationLevelIndex = this.props.educationLevels.findIndex(
        (level) => level.id === parseInt(this.props.educationLevelIdMultiple)
      );

      if (educationLevelIndex !== -1) {
        const assessmentModuleCode =
          this.props.educationLevels[educationLevelIndex].assessmentModuleCode;
        this.props.assessmentModules.forEach((assessmentModule) => {
          if (
            assessmentModuleCode === assessmentModule.code &&
            checkForSubRoles(`${assessmentModule.code}_ADMTR`)
          )
            enable = true;
        });
      }
    }
    return enable;
  };

  render() {
    return (
      <div
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "100000",
          background: "white",
        }}
      >
        <div className="container-fluid">
          <div className="row tt-calendar-dateNavigation ">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4 tt-calendarLabel">
                  <label>Education Level:</label>
                </div>
                <div className="col-md-8">
                  <select
                    name="educationLevelIdMultiple"
                    value={this.props.educationLevelIdMultiple}
                    onChange={this.props.handleEventChange}
                    className="form-control"
                  >
                    <option value="" disabled>
                      Choose a Level
                    </option>
                    <option value="0">All</option>
                    {this.props.educationLevels.map((eduLevel, idx) => {
                      return (
                        <option value={eduLevel.id} key={idx}>
                          {eduLevel.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-right">
              {checkWidgetAdminOrNot("Academic Administration") === true ||
              this.checkToEnablePermission() ? (
                <button
                  className="tt-button tt-button-primary"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                  style={{ position: "absolute", right: "30px" }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
            {/* <div className="col-md-6">
              <div className="row">
                <div className="col-md-4 tt-calendarLabel">
                  <label>View Calendar By:</label>
                </div>
                <div className="col-md-8">
                  <select
                    name="termId"
                    value={this.props.termId}
                    onChange={this.props.handleCalendarChange}
                    className="form-control"
                  >
                    <option value="0">All {this.state.termAlias}</option>
                    {this.props.terms.map((term, idx) => {
                      return (
                        <option value={term.id} key={idx}>
                          {term.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="row">
          <div className="col tt-group-header tt-calendaHeaderTitle">
            {this.props.eduLevelTitle} Level - {this.props.termTitle}
            {checkWidgetAdminOrNot("Academic Administration") === true ||
            this.checkToEnablePermission() ? (
              <button
                className="tt-button tt-button-primary"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
                style={{ position: "absolute", right: "30px" }}
              >
                Permissions
              </button>
            ) : null}
          </div>
        </div> */}
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          id="tt-permissionModal"
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="Calendar"
              header="View Calendar"
              // header="Add Calendar Event"
              activityName="select-calendar"
              // activityName="insert-calendar-event"
              educationLevels={this.props.educationLevels}
              educationLevelIdMultiple={this.props.educationLevelIdMultiple}
            />
          }
        ></ModalWindow>
        <hr></hr>
      </div>
    );
  }
}

export default CalendarWidgetHeader;

import React, { Component } from "react";
import "../../../assets/scss/assessmentrecordreport.scss";
import ScaleNameType from "./ScaleTypeName";
import AssessmentRecordForm from "./ScaleTypeNumberForm";
import ScaleTypeIndicatorDescriptorForm from "./ScaleTypeIndicatorDescriptorForm";
import swal from "sweetalert";
import ModalWindow from "../../UI/ModalWindow";
import ShowAssociateIndicatorForm from "./ShowAssociateIndicatorForm";
import ScaleTypeGradeForm from "./ScaleTypeGradeForm";
import {
  displayConfirmDeleteAlert,
  displaySuccessAlert,
  showErroMessage,
} from "../../../utils/Utils";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import { connect } from "react-redux";
import { getAssessmentScales } from "../../../ducks/PrimaryARRDucks";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";

/**
 * @author Ashok Maharjan
 */

class AssessmentRecordSetup extends Component {
  state = {
    aggregate: "",
    assessmentScaleLevelId: "",
    assessmentScaleLevelList: [],
    assessmentScales: [],
    assignEducationlevel: true,
    associateLevel: false,
    checked: false,
    changeColor: "",
    color: {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    },
    defaultGpaValues: [],
    displayColorPicker: false,
    errors: {},
    gpaName: "Gpa",
    grade: "",
    gradeTypes: [],
    gradeValues: [],
    isAvailable: false,
    leftGradeValue: "",
    levelId: "",
    levelList: [],
    levelName: "Grade",
    levelType: "alphabets",
    minAchievementLevel: 35,
    modal: false,
    multipleGpa: [
      {
        grade: "",
        percentageMin: "",
        percentageMax: "",
        gradePoint: "",
        gpaMin: "",
        gpaMax: "",
        gpaEvaluation: "",
        errors: {
          grade: false,
          percentageMin: false,
          percentageMax: false,
          gradePoint: false,
          gpaMin: false,
          gpaMax: false,
          gpaEvaluation: false,
        },
      },
    ],
    multipleGrades: [
      {
        descriptor: "",
        descrip: "",
        grade: "",
        gradeText: "",
        errors: {
          grade: false,
          descriptor: false,
          descrip: false,
        },
        leftGradeValue: {},
        rightGradeValue: {},
        color: {
          r: "241",
          g: "112",
          b: "19",
          a: "1",
        },
        changeColor: "#F17013",
      },
    ],
    nameError: false,
    practicalMax: 0,
    practicalMin: 0,
    range: 0,
    ranges: [],
    rangeNext: 100,
    rightGradeValue: "",
    scaleNameForGpa: "Grade point",
    scaleNameForLevels: "Achievement grades",
    scaleNameForMarks: "Examination marks",
    scaleType: "sct-mrk",
    score: "",
    scoreName: "Marks",
    selectedOptionValues: [],
    selectedValuesForNumber: [],
    tempRanges: [],
    theoryMax: 0,
    theoryMin: 0,
    useLevel: true,
  };

  addMultipleGpa = (e) => {
    this.setState((prevState) => ({
      multipleGpa: [
        ...prevState.multipleGpa,
        {
          id: "",
          grade: "",
          percentageMin: "",
          percentageMax: "",
          gradePoint: "",
          gpaMin: "",
          gpaMax: "",
          gpaEvaluation: "",
          errors: {
            grade: false,
            percentageMin: false,
            percentageMax: false,
            gradePoint: false,
            gpaMin: false,
            gpaMax: false,
            gpaEvaluation: false,
          },
        },
      ],
    }));
  };

  addMultipleGrade = (e) => {
    var o = Math.round,
      number = Math.random,
      s = 255;
    let r = o(number() * s),
      g = o(number() * s),
      b = o(number() * s),
      a = o(number() * s);

    let result = this.rgba2hex(r, g, b, a);
    this.setState((prevState) => ({
      multipleGrades: [
        ...prevState.multipleGrades,
        {
          descriptor: "",
          descrip: "",
          grade: this.state.levelType === "number" ? 0 : "",
          errors: {
            grade: false,
            descriptor: false,
            descrip: false,
          },
          color: {
            r: r,
            g: g,
            b: b,
            a: a,
          },
          changeColor: `#${result.slice(0, 6)}`,
        },
      ],
    }));
  };

  aggregateAsPerChange = (e) => {
    this.setState({ aggregate: e.target.value });
  };

  associateIndicatorDescriptorHandler = (e, idx, name) => {
    let ranges = [...this.state.ranges];
    let value = e.target.value;
    if (value > 100) {
      swal({
        title: "Warning",
        text: "Value can't be greater than 100",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
    if (name === "leftGradeValue") {
      ranges[idx].rangeMin = value;
    } else {
      ranges[idx].rangeMax = value;
    }
    this.setState({ ranges });
  };

  associateScaleClick = () => {
    var min = this.state.range;
    var max = this.state.rangeNext;
    var parts = this.state.levelList.length;
    this.divideInequalParts(min, max, parts);
  };

  associateScaleSubmit = () => {
    let ranges = [...this.state.ranges];
    let levelList = [...this.state.levelList];
    levelList.forEach((level, i) => {
      ranges.forEach((range, j) => {
        if (i === j) {
          range.levelId = level.id;
        }
      });
    });
    this.setState({ associateLevel: true, modal: false });
  };

  closeAssociateModal = () => {
    this.setState(
      { modal: !this.state.modal, ranges: this.state.tempRanges },
      function () {
        this.setState({ tempRanges: [] });
      }
    );
  };

  componentDidMount() {
    this.gradeValues();
    this.getGpaDefaultValues();
    this.getAssessmentScaleType();
  }

  decreaseMinAchievementLevel = () => {
    if (this.state.minAchievementLevel > 10) {
      let newVal = this.state.minAchievementLevel - 1;
      this.setState({ minAchievementLevel: newVal });
    }
  };

  decreaseRangeNextValue = () => {
    if (this.state.rangeNext > 0) {
      let newVal = this.state.rangeNext - 1;
      this.setState({ rangeNext: newVal });
    }
  };

  decreaseValue = () => {
    if (this.state.range > 0) {
      let newVal = this.state.range - 1;
      this.setState({ range: newVal });
    }
  };

  divideInequalParts = (min, max, parts) => {
    min = 0;
    max = 100;
    var result = [],
      m = (max - min) / parts;
    while (min < max) {
      result.push(min);
      min += m;
    }
    result.push(max);
    for (let i = 0; i < result.length; i++) {
      result[i] = result[i].toFixed(2);
    }
    let newResult = result.reverse();
    let values = this.state.multipleGrades;
    let totalValues = values.length;
    for (let i = totalValues - 1; i >= 0; i--) {
      values[i].leftGradeValue = newResult[i + 1];
      values[i].rightGradeValue = newResult[i];
    }
    this.setState({ multipleGrades: values });
    var leftVal = document.getElementsByName("leftGradeValue");
    var totalNumberOfVal = leftVal.length;
    let ranges = [...this.state.ranges];
    for (let i = 0; i < totalNumberOfVal; i++) {
      let range = {};
      range.rangeMin = newResult[i + 1];
      range.rangeMax = newResult[i];
      ranges.push(range);
    }
    this.setState({ ranges });
  };

  getAssessmentScaleLevels = (e) => {
    let param = {
      assessmentScaleTypeCode: "sct-lvl",
      assessmentModuleCode: this.props.code,
    };
    axiosPost(URL.selectAssessmentScale, param, (response) => {
      if (response.status === 200) {
        this.setState({
          assessmentScaleLevelList: response.data.data,
        });
      } else {
        showErroMessage(response);
      }
    });
  };

  getAssessmentScaleType = () => {
    axiosPost(URL.selectAssessmentScales, {}, (response) => {
      this.setState({ assessmentScales: response.data.data }, function () {});
    });
  };

  getEducationLevelByAssessmentModule = () => {
    let data = {
      code: this.props.code,
    };
    axiosPost(URL.getEducationLevelByAssessmentModule, data, (response) => {
      if (response.status === 200) {
        this.setState({ educationLevels: response.data.data }, () => {});
      }
    });
  };

  getGpaDefaultValues = () => {
    axiosPost(URL.gpaSelect, {}, (response) => {
      this.setState({ defaultGpaValues: response.data.data }, () => {
        let defaultGpaValues = [...this.state.defaultGpaValues];
        defaultGpaValues.forEach((gpa) => {
          gpa.errors = {
            grade: false,
            percentageMax: false,
            percentageMin: false,
            gradePoint: false,
            gpaMax: false,
            gpaMin: false,
            gpaEvaluation: false,
          };
        });
        this.setState({ defaultGpaValues });
      });
    });
  };

  //grades list A A+ A-
  gradeValues = () => {
    let newArr = [...this.state.gradeTypes];
    for (let i = 0; i < 26; i++) {
      newArr.push((i + 10).toString(36).toUpperCase() + "");
      newArr.push((i + 10).toString(36).toUpperCase() + "+");
      newArr.push((i + 10).toString(36).toUpperCase() + "-");
    }
    this.setState({ gradeTypes: newArr });
  };

  handleAssessementScaleLevelHandler = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value, ranges: [] }, () => {
      let param = {
        id: this.state.assessmentScaleLevelId,
      };
      axiosPost(URL.selectAssessmentScaleLevel, param, (response) => {
        if (response.status === 200) {
          this.setState({
            levelList: response.data.data.assessmentScaleLevels,
          });
          this.associateScaleClick();
        } else {
          showErroMessage(response);
        }
      });
    });
  };

  handleChangeForColorPicker = (color, index) => {
    let gradeValues = Object.assign([], this.state.multipleGrades);
    gradeValues.forEach((grade, i) => {
      if (index === i) {
        grade.color = color.rgb;
        grade.changeColor = color.hex;
      }
    });
    this.setState({ multipleGrades: gradeValues }, () => {});
  };

  handleChangeLevelType = (e) => {
    this.setState({
      multipleGrades: [
        {
          descriptor: "",
          descrip: "",
          grade: "",
          errors: {
            grade: false,
            descriptor: false,
            descrip: false,
          },
          color: {
            r: "241",
            g: "112",
            b: "19",
            a: "1",
          },
          changeColor: "#F17013",

          //id: Math.random()
        },
      ],
      gradeValues: [],
    });
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {});
  };

  handleClickColorPicker = (index) => {
    let gradeValues = Object.assign([], this.state.multipleGrades);
    gradeValues.forEach((grade, i) => {
      index === i
        ? (grade.isColorPickerOpen = !grade.isColorPickerOpen)
        : (grade.isColorPickerOpen = false);
    });
    this.setState({ multipleGrades: gradeValues });
  };

  handleCloseForColorPicker = (index) => {
    let gradeValues = Object.assign([], this.state.multipleGrades);
    gradeValues.forEach((grade, i) => {
      if (index === i) {
        grade.isColorPickerOpen = false;
      }
    });
    this.setState({ multipleGrades: gradeValues });
  };

  handleDeleteGpa = (id) => {
    let arr = [...this.state.defaultGpaValues];
    arr.splice(id, 1);
    this.setState({ defaultGpaValues: arr });
  };

  handleDeleteGrade = (id) => {
    let arr = [...this.state.gradeValues];
    arr.splice(id, 1);
    this.setState({ gradeValues: arr });
  };

  handleDeleteGradeFormField = (id) => {
    let arr = [...this.state.multipleGrades];
    arr.splice(id, 1);
    this.setState({ multipleGrades: arr });
  };

  handleGradeChange = (e, index) => {
    let multipleGrades = [...this.state.multipleGrades];
    let selectedOptionValues = [...this.state.selectedOptionValues];
    const name = e.target.name;
    multipleGrades[index][name] = e.target.value;
    if (name === "descriptor") {
      if (e.target.value.length >= 40) {
        swal("Warning", "Maximum 40 characters are allowed!!!");
      }
    } else if (name === "descrip") {
      if (e.target.value.length >= 256) {
        swal("Warning", "Maximum 256 characters are allowed!!!");
      }
    }
    this.setState({ multipleGrades, selectedOptionValues }, () => {
      this.validateGradeForm(name, index);
      if (this.state.levelType === "text") {
        let valueArr = [...this.state.multipleGrades];
        let newArr = valueArr.map(function (item) {
          return item.grade;
        });
        let isDupicate = newArr.some(function (item, idx) {
          return newArr.indexOf(item) !== newArr.lastIndexOf(item);
        });
        let duplicateItem = [
          ...new Set(
            newArr.filter((item, index) => newArr.indexOf(item) !== index)
          ),
        ];
        if (isDupicate) {
          if (duplicateItem[0] === "") {
            return;
          } else {
            swal({
              title: "Warning",
              text: `${duplicateItem} Already Used!!! Use different word!!!`,
              dangerMode: true,
              allowOutsideClick: false,
              closeOnClickOutside: false,
            });
            draggable();
          }
        }
      }
    });
  };

  //push as object in gradeValues from form
  handleGradeValuesSubmit = (e) => {
    if (this.state.useLevel) {
      if (
        this.state.levelType === "image" ||
        this.state.levelType === "colourStickers"
      ) {
        var stateName = ["descriptor", "descrip"];
      } else {
        var stateName = ["grade", "descriptor", "descrip"];
      }
    } else {
      var stateName = ["descriptor", "descrip"];
    }
    let multipleGrades = [...this.state.multipleGrades];
    let errorCounter = 0;
    let gradeValues = [...this.state.gradeValues];
    if (multipleGrades.length > 0) {
      multipleGrades.forEach((multipleGrade) => {
        stateName.forEach((name) => {
          if (multipleGrade[name].length === 0) {
            multipleGrade.errors[name] = true;
            errorCounter = errorCounter + 1;
          }
        });
      });
      this.setState({ multipleGrades }, () => {
        if (errorCounter === 0) {
          multipleGrades.forEach((multipleGrade) => {
            let gradeArr = {};
            gradeArr.descriptor = multipleGrade.descriptor;
            gradeArr.description = multipleGrade.descrip;
            if (this.state.levelType === "colourStickers") {
              gradeArr.changeColor = multipleGrade.changeColor;
            } else {
              gradeArr.grade = multipleGrade.grade;
            }
            gradeValues.push(gradeArr);
            this.setState({ gradeValues }, () => {
              this.setState({
                multipleGrades: [
                  {
                    descriptor: "",
                    descrip: "",
                    grade: "",
                    gradeText: "",
                    errors: {
                      grade: false,
                      descriptor: false,
                      descrip: false,
                    },
                    leftGradeValue: {},
                    rightGradeValue: {},
                    color: {
                      r: "241",
                      g: "112",
                      b: "19",
                      a: "1",
                    },
                    changeColor: "#F17013",
                  },
                ],
              });
            });
          });
        } else {
          swal({
            title: "Warning",
            text: `${errorCounter} Empty Fields`,
            dangerMode: true,
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        }
      });
    } else {
      swal({
        title: "Warning",
        text: "Add first and click submit",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ gradeValues }, () => {});
    }
  };

  handleLevelTypeImageChange = (e, index) => {
    let multipleGrades = [...this.state.multipleGrades];
    multipleGrades.forEach((grade, i) => {
      if (i === index) {
        grade.grade = e.target.files[0];
      }
    });
    this.setState({ multipleGrades });
  };

  handleLevelTypeNumberChange = (val, index) => {
    let multipleGrades = [...this.state.multipleGrades];
    multipleGrades.forEach((grade, i) => {
      if (i === index) {
        grade.grade = val;
      }
    });
    this.setState({ multipleGrades }, () => {
      let multiple = [...this.state.multipleGrades];
      if (multiple[index].grade !== null) {
        multiple[index]["errors"].grade = false;
      } else {
        multiple[index]["errors"].grade = true;
      }
      this.setState({ multipleGrades: multiple }, () => {
        let valueArr = [...this.state.multipleGrades];

        let newArr = valueArr.map(function (item) {
          return item.grade;
        });

        let isDupicate = newArr.some(function (item, idx) {
          return newArr.indexOf(item) !== newArr.lastIndexOf(item);
        });

        let duplicateItem = [
          ...new Set(
            newArr.filter((item, index) => newArr.indexOf(item) != index)
          ),
        ];
        if (isDupicate) {
          swal({
            title: "Warning",
            text: `${duplicateItem} Already Used!!! Use different number!!!`,
            dangerMode: true,
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        }
      });
    });
  };

  handleMultipleGpaFormValidate = (name, index) => {
    let multipleGpa = [...this.state.multipleGpa];
    if (multipleGpa[index][name].length > 0) {
      multipleGpa[index].errors[name] = false;
    } else {
      multipleGpa[index].errors[name] = true;
    }
    this.setState({ multipleGpa });
  };

  handlePracticalValue = (e) => {
    let { name, value } = e.target;
    if (value > this.state.rangeNext || value < 0) {
      return false;
    } else {
      let max = this.state.rangeNext;
      if (name === "practicalMin") {
        this.setState({ practicalMax: value });
      }
      if (name === "practicalMax") {
        let theoryMax = max - value;
        this.setState({ theoryMax });
      }
      if (name === "theoryMin") {
        this.setState({ theoryMax: value });
      }
      if (name === "theoryMax") {
        let practicalMax = max - value;
        this.setState({ practicalMax });
      }
      this.setState({ [name]: value });
    }
  };

  handleRemoveFormField = (id) => {
    let arr = [...this.state.multipleGpa];
    if (arr.length === 1) {
      swal({ title: "Error", text: "Atleast 1 GPA format should be present" });
    } else {
      arr.splice(id, 1);
      this.setState({ multipleGpa: arr });
    }
  };

  handleRemoveGpas = (id) => {
    displayConfirmDeleteAlert(id, this.handleDeleteGpa);
  };

  handleRemoveGradeFormField = (id) => {
    displayConfirmDeleteAlert(id, this.handleDeleteGradeFormField);
  };

  handleRemoveGrades = (id) => {
    displayConfirmDeleteAlert(id, this.handleDeleteGrade);
  };

  handleRemoveItemFromForm = (id) => {
    displayConfirmDeleteAlert(id, this.handleRemoveFormField);
  };

  handleResetGpaFormat = () => {
    this.getGpaDefaultValues();
  };

  //push as object in gpas from the gpa form
  handleSaveNewGpa = (e) => {
    let stateName = [
      "grade",
      "percentageMin",
      "percentageMax",
      "gradePoint",
      "gpaMin",
      "gpaMax",
      "gpaEvaluation",
    ];
    let errorCounter = 0;
    let multipleGpas = [...this.state.multipleGpa];

    let defaultGpaValues = [...this.state.defaultGpaValues];

    if (multipleGpas.length > 0) {
      multipleGpas.forEach((multipleGpa) => {
        stateName.forEach((name) => {
          if (multipleGpa[name].length === 0) {
            multipleGpa.errors[name] = true;
            errorCounter = errorCounter + 1;
          }
        });
      });
      this.setState({ multipleGpa: multipleGpas }, () => {
        if (errorCounter === 0) {
          multipleGpas.forEach((multipleGpa) => {
            let gpa = {};
            gpa.id =
              this.state.defaultGpaValues[
                this.state.defaultGpaValues.length - 1
              ].id + 1;
            gpa.grade = multipleGpa.grade;
            gpa.percentageMin = `${multipleGpa.percentageMin}`;
            gpa.percentageMax = `${multipleGpa.percentageMax}`;
            gpa.gradePoint = multipleGpa.gradePoint;
            gpa.gpaMin = `${multipleGpa.gpaMin}`;
            gpa.gpaMax = `${multipleGpa.gpaMax}`;
            gpa.gpaEvaluation = multipleGpa.gpaEvaluation;
            gpa.errors = {
              grade: false,
              percentageMin: false,
              percentageMax: false,
              gradePoint: false,
              gpaMin: false,
              gpaMax: false,
              gpaEvaluation: false,
            };

            defaultGpaValues.push(gpa);
            this.setState({ defaultGpaValues }, () => {
              this.setState({
                multipleGpa: [
                  {
                    grade: "",
                    percentageMin: "",
                    percentageMax: "",
                    gradePoint: "",
                    gpaMin: "",
                    gpaMax: "",
                    gpaEvaluation: "",
                    errors: {
                      grade: false,
                      percentageMin: false,
                      percentageMax: false,
                      gradePoint: false,
                      gpaMin: false,
                      gpaMax: false,
                      gpaEvaluation: false,
                    },
                  },
                ],
              });
            });
          });
        } else {
          swal({
            title: "Warning",
            text: `${errorCounter} Empty Fields`,
            dangerMode: true,
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        }
      });
    } else {
      swal({
        title: "Warning",
        text: "Add first and click submit",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ defaultGpaValues: defaultGpaValues }, () => {});
    }
  };

  handleSubmitGpaValues = (e) => {
    const stateName = [
      "grade",
      "percentageMin",
      "percentageMax",
      "gradePoint",
      "gpaMin",
      "gpaMax",
      "gpaEvaluation",
    ];
    let defaultGpaValues = [...this.state.defaultGpaValues];
    let errorCounter = 0;
    defaultGpaValues.forEach((gpa, index) => {
      stateName.forEach((name) => {
        if (gpa[name].length === 0) {
          gpa.errors[name] = true;
          errorCounter = errorCounter + 1;
        }
      });
    });
    this.setState({ defaultGpaValues }, () => {
      if (errorCounter === 0) {
        let multiplevalues = [...this.state.multipleGpa];
        let erCount = 0;
        if (multiplevalues.length > 0) {
          multiplevalues.forEach((gpa, index) => {
            stateName.forEach((name) => {
              if (gpa[name] !== "") {
                erCount = erCount + 1;
              }
            });
          });
          if (erCount > 0) {
            swal({
              title: MESSAGEHEADER.error,
              text: "Submit your new added GPA values first",
              allowOutsideClick: false,
              closeOnClickOutside: false,
            });
            draggable();
            return false;
          }
        }

        if (this.state.scaleNameForGpa === "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Scale name cannot be empty.",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          this.setState({ nameError: true });
        } else {
          let param = {
            assessmentScaleTypeCode: this.state.scaleType,
            scaleName: this.state.scaleNameForGpa,
            scoreName: this.state.gpaName,
            assessmentScaleGradePoints: this.state.defaultGpaValues,
            assessmentModuleCode: this.props.code,
          };

          axiosPost(URL.insertAssessmentScaleLevel, param, (response) => {
            if (response.status === 200) {
              displaySuccessAlert(response);
              this.getGpaDefaultValues();
              this.props.getAssessmentScales(this.props.code);
            } else {
              showErroMessage(response);
            }
          });
        }
      } else {
        swal({
          title: "Warning",
          text: `${errorCounter} Empty Fields`,
          dangerMode: true,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      }
    });
  };

  handleSubmitLevels = () => {
    let levels = [...this.state.gradeValues];
    let tempLevels = [];
    if (this.state.scaleNameForLevels === "") {
      swal({
        title: MESSAGEHEADER.error,
        text: "Scale name cannot be empty.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else {
      if (this.state.useLevel) {
        if (
          this.state.levelType === "image" ||
          this.state.levelType === "colourStickers"
        ) {
          var stateName = ["descriptor", "descrip"];
        } else {
          var stateName = ["grade", "descriptor", "descrip"];
        }
      } else {
        var stateName = ["descriptor", "descrip"];
      }
      let multipleGrades = [...this.state.multipleGrades];
      let errorCounter = 0;
      if (multipleGrades.length > 0) {
        multipleGrades.forEach((multipleGrade) => {
          stateName.forEach((name) => {
            if (multipleGrade[name].length !== 0) {
              errorCounter = errorCounter + 1;
            }
          });
        });
        if (errorCounter > 0) {
          swal({
            title: MESSAGEHEADER.error,
            text: "Submit newly added Levels first",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          return false;
        }
      }
      let formData = new FormData();
      if (this.state.levelType === "image") {
        levels.forEach((level, i) => {
          let tempData = {};
          tempData.descriptor = level.descriptor;
          tempData.description = level.description;
          tempData.level =
            this.state.levelType === "image"
              ? ""
              : this.state.levelType === "colourStickers"
              ? level.changeColor
              : level.grade;

          tempData.levelType = this.state.levelType;
          if (this.state.levelType === "image") {
            formData.append("files", level.grade);
            // delete level["grade"];
          }
          tempLevels.push(tempData);
        });
      } else {
        levels.forEach((level, i) => {
          level.descriptor = level.descriptor;
          level.description = level.description;
          level.level =
            this.state.levelType === "image"
              ? ""
              : this.state.levelType === "colourStickers"
              ? level.changeColor
              : level.grade;

          level.levelType = this.state.levelType;
        });
        tempLevels = levels;
      }

      this.setState({ gradeValues: levels }, () => {
        if (this.state.levelType === "image") {
          formData.append(
            "levelRecord",
            JSON.stringify({
              assessmentScaleTypeCode: this.state.scaleType,
              scaleName: this.state.scaleNameForLevels,
              scoreName: this.state.levelName,
              assessmentModuleCode: this.props.code,
            })
          );

          formData.append("levels", JSON.stringify(tempLevels));

          axiosPost(
            URL.insertAssessmentScaleLevelImage,
            formData,
            (response) => {
              if (response.status === 200) {
                swal(MESSAGEHEADER.success, "Successfully Inserted");
                this.props.getAssessmentScales(this.props.code);
                this.setState({ gradeValues: [] });
              } else {
                showErroMessage(response);
              }
            }
          );
        } else {
          let param = {
            assessmentScaleTypeCode: this.state.scaleType,
            scaleName: this.state.scaleNameForLevels,
            scoreName: this.state.levelName,
            assessmentScaleLevels: tempLevels,
            assessmentModuleCode: this.props.code,
          };
          axiosPost(URL.insertAssessmentScaleLevel, param, (response) => {
            if (response.status === 200) {
              displaySuccessAlert(response);
              this.setState({ levelId: response.data.data.id });
              this.setState({ gradeValues: [] });
              this.props.getAssessmentScales(this.props.code);
            } else {
              showErroMessage(response);
            }
          });
        }
      });
    }
  };

  handleSubmitMark = () => {
    let paramForMark = {
      rangeMin: this.state.range,
      rangeMax: this.state.rangeNext,
      minAchievementLevel: this.state.minAchievementLevel,
      showPercentage:
        this.state.score === "both"
          ? true
          : this.state.score === "pctg"
          ? true
          : false,
      showScore:
        this.state.score === "both"
          ? true
          : this.state.score === "Nopctg"
          ? true
          : false,
      showAggregatedPercentage:
        this.state.aggregate === "aggregateAndPer"
          ? true
          : this.state.aggregate === "per"
          ? true
          : false,
      showAggregatedScore:
        this.state.aggregate === "aggregateAndPer"
          ? true
          : this.state.aggregate === "noPer"
          ? true
          : false,
      assessmentScaleMarkLevels: this.state.ranges,
      theoryMax: this.state.theoryMax,
      theoryMin: this.state.theoryMin,
      practicalMax: this.state.practicalMax,
      practicalMin: this.state.practicalMin,
    };

    if (this.state.scaleNameForMarks === "") {
      swal({
        title: MESSAGEHEADER.error,
        text: "Scale name cannot be empty.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else {
      let param = {
        assessmentScaleTypeCode: this.state.scaleType,
        scaleName: this.state.scaleNameForMarks,
        scoreName: this.state.scoreName,
        assessmentScaleMark: paramForMark,
        assessmentModuleCode: this.props.code,
      };

      axiosPost(URL.insertAssessmentScaleLevel, param, (response) => {
        if (response.status === 200) {
          displaySuccessAlert(response);
          this.setState({ associateLevel: false });
          this.props.getAssessmentScales(this.props.code);
        } else {
          showErroMessage(response);
        }
      });
    }
  };

  //changed values save in state
  handleValueEditable = (e, id) => {
    let tmpArr = [...this.state.defaultGpaValues];
    const name = e.target.name;
    tmpArr[id][name] = e.target.value;
    if (name === "percentageMin" || name === "percentageMax") {
      if (e.target.value.length > 3) {
        swal("Warning", "Maximum 3 digits are allowed");
        var str = e.target.value;
        var res = str.toString().substr(0, str.toString().length - 1);
        tmpArr[id][name] = res;
      }
    } else if (
      name === "gradePoint" ||
      name === "gpaMin" ||
      name === "gpaMax"
    ) {
      if (e.target.value.length > 5) {
        swal("Warning", "Maximum 5 digits are allowed");
        var str = e.target.value;
        var res = str.toString().substr(0, str.toString().length - 1);
        tmpArr[id][name] = res;
      }
    } else if (name === "gpaEvaluation") {
      if (e.target.value.length >= 40) {
        swal("Warning", "Maximum 40 characters are allowed");
      }
    }

    this.setState({ defaultGpaValues: tmpArr }, () => {
      this.validateGpaForm(name, id);
      let valueArr = [...this.state.defaultGpaValues];
      let newArr = valueArr.map(function (item) {
        return item.grade;
      });
      let isDupicate = newArr.some(function (item, idx) {
        return newArr.indexOf(item) !== newArr.lastIndexOf(item);
      });
      let duplicateItem = [
        ...new Set(
          newArr.filter((item, index) => newArr.lastIndexOf(item) != index)
        ),
      ];
      if (isDupicate) {
        if (duplicateItem[0] === "") {
          return;
        } else {
          swal({
            title: "Warning",
            text: `${duplicateItem} Already Used!!! Use different Alphabet!!!`,
            dangerMode: true,
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        }
      }
    });
  };

  increaseMinAchievementLevel = () => {
    if (this.state.minAchievementLevel < 45) {
      let newVal = this.state.minAchievementLevel + 1;
      this.setState({ minAchievementLevel: newVal });
    }
  };

  increaseRangeNextValue = () => {
    if (this.state.rangeNext < 100) {
      let newVal = this.state.rangeNext + 1;
      this.setState({ rangeNext: newVal });
    }
  };

  increaseValue = () => {
    let newVal = this.state.range + 1;
    this.setState({ range: newVal });
  };

  multipleGpaChange = (e, index) => {
    let multipleGpa = [...this.state.multipleGpa];
    const name = e.target.name;
    multipleGpa[index][name] = e.target.value;

    if (name === "percentageMin" || name === "percentageMax") {
      if (e.target.value.length > 3) {
        swal("Warning", "Maximum 3 digits are allowed");
        var str = e.target.value;
        var res = str.toString().substr(0, str.toString().length - 1);
        multipleGpa[index][name] = res;
      }
    } else if (
      name === "gradePoint" ||
      name === "gpaMin" ||
      name === "gpaMax"
    ) {
      if (e.target.value.length > 5) {
        swal("Warning", "Maximum 5 digits are allowed");
        var str1 = e.target.value;
        var res1 = str1.toString().substr(0, str1.toString().length - 1);
        multipleGpa[index][name] = res1;
      }
    } else if (name === "gpaEvaluation") {
      if (e.target.value.length >= 40) {
        swal("Warning", "Maximum 40 characters are allowed");
      }
    }

    this.setState({ multipleGpa }, () => {
      this.handleMultipleGpaFormValidate(name, index);
    });
  };

  multipleGradeChange = (e) => {
    if (["descriptor", "descrip", "grade"].includes(e.target.name)) {
      let multipleGrades = [...this.state.multipleGrades];
      multipleGrades[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ multipleGrades });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  rgba2hex = (r, g, b, a) => {
    if (r > 255 || g > 255 || b > 255 || a > 255)
      throw "Invalid color component";
    return (
      (256 + r).toString(16).substr(1) +
      (((1 << 24) + (g << 16)) | (b << 8) | a).toString(16).substr(1)
    );
  };

  scoreAsPerChange = (e) => {
    this.setState({ score: e.target.value });
  };

  toggleAssociateModal = () => {
    this.setState({
      modal: !this.state.modal,
      tempRanges: JSON.parse(JSON.stringify(this.state.ranges)),
    });
  };

  //for modal open and close
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      assessmentScaleLevelId: "",
      tempRanges: JSON.parse(JSON.stringify(this.state.ranges)),
    }));
    this.setState({ levelList: [] });
  };

  universalHandleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (
      name === "scaleNameForMarks" ||
      name === "scaleNameForLevels" ||
      name === "scaleNameForGpa" ||
      name === "scaleType"
    ) {
      value.length > 0 && this.setState({ nameError: false });
    }
    this.setState({ [name]: value });
  };

  /**
   * validation function to add descriptor and description field in error to validate each one
   */
  validateForm = () => {
    let valuesToBeChecked = this.state.multipleGrades;
    let totalValues = valuesToBeChecked.length;

    for (let i = 0; i < totalValues; i++) {
      if (valuesToBeChecked[i].descriptor === "") {
        valuesToBeChecked[i].errors.descriptor = true;
      } else {
        valuesToBeChecked[i].errors.descriptor = false;
      }
      if (valuesToBeChecked[i].descrip === "") {
        valuesToBeChecked[i].errors.description = true;
      } else {
        valuesToBeChecked[i].errors.description = false;
      }
    }
    this.setState({ multipleGrades: valuesToBeChecked }, () => {
      this.validation();
    });
  };

  validateGradeForm = (name, index) => {
    let multipleGrades = [...this.state.multipleGrades];
    if (multipleGrades[index][name].length > 0) {
      multipleGrades[index].errors[name] = false;
    } else {
      multipleGrades[index].errors[name] = true;
    }
    this.setState({ multipleGrades });
  };

  validation = () => {
    var descriptor = document.getElementsByName("descriptor");
    var description = document.getElementsByName("descrip");

    let totalIteration = descriptor.length;
    let val = this.state.multipleGrades;
    if (this.state.useLevel) {
      for (let i = 0; i < totalIteration; i++) {
        if (i === totalIteration - 1) {
          break;
        }
        if (val[i].errors.descriptor && !val[i].errors.description) {
          descriptor[i].style.borderColor = "red";
        }

        if (val[i].errors.description && !val[i].errors.descriptor) {
          description[i].style.borderColor = "red";
        }
        if (totalIteration > 1) {
          if (
            !val[i].errors.descriptor &&
            !val[i].errors.descriptor &&
            val[i + 1].errors.descriptor &&
            val[i + 1].errors.description
          ) {
            descriptor[i + 1].style.borderColor = "red";
            description[i + 1].style.borderColor = "red";
          }
        }
      }
    } else {
      for (let i = 0; i < totalIteration; i++) {
        if (val[i].errors.descriptor) {
          descriptor[i].style.borderColor = "red";
        } else {
          descriptor[i].style.borderColor = "unset";
        }

        if (i === totalIteration - 1) {
          break;
        }

        if (!val[i].errors.description) {
          description[i + 1].style.borderColor = "red";
        }
      }
    }
  };

  validateGpaForm = (name, id) => {
    let defaultGpaValues = [...this.state.defaultGpaValues];
    if (defaultGpaValues[id][name].length > 0) {
      defaultGpaValues[id].errors[name] = false;
    } else {
      defaultGpaValues[id].errors[name] = true;
    }
    this.setState({ defaultGpaValues });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          Create Assessment Scale
          {checkWidgetAdminOrNot(this.props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div
          className="tt-newListBox container-fluid"
          style={{ paddingRight: "15px", paddingLeft: "15px" }}
        >
          <ScaleNameType
            assessmentScales={this.state.assessmentScales}
            nameError={this.state.nameError}
            scaleNameForGpa={this.state.scaleNameForGpa}
            scaleNameForLevels={this.state.scaleNameForLevels}
            scaleNameForMarks={this.state.scaleNameForMarks}
            scaleType={this.state.scaleType}
            scaleTypeChange={this.universalHandleChange}
          />
          <div className="inside-content">
            {this.state.scaleType === "sct-mrk" ? (
              <AssessmentRecordForm
                aggregate={this.state.aggregate}
                aggregateAsPerChange={this.aggregateAsPerChange}
                checked={this.state.checked}
                decreaseMinAchievementLevel={this.decreaseMinAchievementLevel}
                decreaseRangeNextValue={this.decreaseRangeNextValue}
                decreaseValue={this.decreaseValue}
                handlePracticalValue={this.handlePracticalValue}
                increaseMinAchievementLevel={this.increaseMinAchievementLevel}
                increaseRangeNextValue={this.increaseRangeNextValue}
                increaseValue={this.increaseValue}
                minAchievementLevel={this.state.minAchievementLevel}
                practicalMax={this.state.practicalMax}
                practicalMin={this.state.practicalMin}
                range={this.state.range}
                rangeNext={this.state.rangeNext}
                score={this.state.score}
                scoreAsPerChange={this.scoreAsPerChange}
                scoreName={this.state.scoreName}
                theoryMax={this.state.theoryMax}
                theoryMin={this.state.theoryMin}
                universalHandleChange={this.universalHandleChange}
              />
            ) : this.state.scaleType === "sct-lvl" ? (
              <ScaleTypeIndicatorDescriptorForm
                addMultipleGrade={this.addMultipleGrade}
                changeColor={this.state.changeColor}
                color={this.state.color}
                descriptor={this.state.descriptor}
                displayColorPicker={this.state.displayColorPicker}
                errors={this.state.errors}
                grade={this.state.grade}
                gradeTypes={this.state.gradeTypes}
                gradeValues={this.state.gradeValues}
                handleChangeForColorPicker={this.handleChangeForColorPicker}
                handleChangeLevelType={this.handleChangeLevelType}
                handleClickColorPicker={this.handleClickColorPicker}
                handleCloseForColorPicker={this.handleCloseForColorPicker}
                handleGradeChange={this.handleGradeChange}
                handleGradeValuesSubmit={this.handleGradeValuesSubmit}
                handleLevelTypeImageChange={this.handleLevelTypeImageChange}
                handleLevelTypeNumberChange={this.handleLevelTypeNumberChange}
                handleRemoveGradeFormField={this.handleRemoveGradeFormField}
                handleRemoveGrades={this.handleRemoveGrades}
                levelName={this.state.levelName}
                levelType={this.state.levelType}
                multipleGradeChange={this.multipleGradeChange}
                multipleGrades={this.state.multipleGrades}
                selectedFile={this.state.selectedFile}
                selectedOptionValues={this.state.selectedOptionValues}
                universalHandleChange={this.universalHandleChange}
                useLevel={this.state.useLevel}
              />
            ) : (
              <ScaleTypeGradeForm
                addMultipleGpa={this.addMultipleGpa}
                defaultGpaValues={this.state.defaultGpaValues}
                errorsForGpa={this.state.errorsForGpa}
                gpaName={this.state.gpaName}
                gradeTypes={this.state.gradeTypes}
                handleRemoveGpas={this.handleRemoveGpas}
                handleRemoveItemFromForm={this.handleRemoveItemFromForm}
                handleResetGpaFormat={this.handleResetGpaFormat}
                handleSaveNewGpa={this.handleSaveNewGpa}
                handleValueEditable={this.handleValueEditable}
                multipleGpa={this.state.multipleGpa}
                multipleGpaChange={this.multipleGpaChange}
                universalHandleChange={this.universalHandleChange}
              />
            )}

            <div className="row record-button">
              <div className="col-md-1"></div>
              <div className="col text-right">
                {this.state.scaleType === "sct-mrk" ? (
                  <button
                    className="tt-button tt-button-primary mr-4"
                    onClick={
                      this.state.associateLevel
                        ? this.toggleAssociateModal
                        : (e) => {
                            this.toggleModal(e);
                            this.getAssessmentScaleLevels(e);
                          }
                    }
                  >
                    Associate levels
                  </button>
                ) : null}
                {this.state.scaleType === "sct-mrk" ? (
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.handleSubmitMark}
                  >
                    Save assessment scale
                  </button>
                ) : (
                  <button
                    className="tt-button tt-button-primary scale-type-indicator mr-2"
                    onClick={
                      this.state.scaleType !== "sct-lvl"
                        ? this.handleSubmitGpaValues
                        : this.handleSubmitLevels
                    }
                  >
                    Save assessment scale
                  </button>
                )}
              </div>
              {this.state.scaleType === "sct-mrk" ? (
                <div className="col-md-1"></div>
              ) : null}
            </div>

            <ModalWindow
              modal={this.state.modal}
              size="lg"
              toggleModal={this.closeAssociateModal}
              modalHeader="Associate Levels to Marksheet"
              modalBody={
                <ShowAssociateIndicatorForm
                  cancelHandle={this.closeAssociateModal}
                  associateIndicatorDescriptorHandler={
                    this.associateIndicatorDescriptorHandler
                  }
                  associateScaleSubmit={this.associateScaleSubmit}
                  levelList={this.state.levelList}
                  assessmentScaleLevelList={this.state.assessmentScaleLevelList}
                  handleAssessementScaleLevelHandler={
                    this.handleAssessementScaleLevelHandler
                  }
                  assessmentScaleLevelId={this.state.assessmentScaleLevelId}
                  ranges={this.state.ranges}
                />
              }
            />
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Recording Setup"
              header="Create Assessment Scale"
              activityName="insert-assessment-scale"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assessmentScales: state.primaryArr.assessmentScaleList,
});

const mapActionsToProps = {
  getAssessmentScales: getAssessmentScales,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AssessmentRecordSetup);

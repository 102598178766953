import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Spinner } from "reactstrap";

class AddTermsModal extends Component {
  state = {};
  render() {
    return (
      <>
        <div
          className="container-fluid tt-widgetContent-tab-holder"
          style={{ maxHeight: "71vh", height: "auto" }}
        >
          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>{this.props.termAlias} Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Weekday</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.newTerms.map((term, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={term.name}
                        onChange={e => this.props.handleTermDataChange(e, idx)}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        name="startDate"
                        value={term.startDate}
                        className="form-control"
                        onChange={e => this.props.handleTermDataChange(e, idx)}
                        min={
                          idx === 0
                            ? this.props.minStartDate
                            : this.props.newTerms[idx - 1].endDate !== ""
                            ? this.props.newTerms[idx - 1].endDate
                            : this.props.minStartDate
                        }
                        max={this.props.maxEndDate}
                        disabled={
                          idx === 0
                            ? false
                            : this.props.newTerms[idx - 1].endDate !== ""
                            ? false
                            : true
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        name="endDate"
                        value={term.endDate}
                        className="form-control"
                        onChange={e => this.props.handleTermDataChange(e, idx)}
                        min={
                          term.startDate !== ""
                            ? term.startDate
                            : this.props.minStartDate
                        }
                        max={this.props.maxEndDate}
                        disabled={term.startDate !== "" ? false : true}
                      />
                    </td>
                    <td>
                      {this.props.weekDays
                        ? this.props.weekDays.map((day, weekIdx) => {
                            return (
                              <div
                                key={weekIdx}
                                className="row tt-weekday-wrapper"
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  type="checkbox"
                                  id={day.id}
                                  onChange={e =>
                                    this.props.handleChangeForNewTermCheckBox(
                                      e,
                                      idx,
                                      day.id
                                    )
                                  }
                                ></input>
                                <label className="ml-2" htmlFor={day.id}>
                                  {day.name}
                                </label>
                              </div>
                            );
                          })
                        : null}
                    </td>
                    <td className="pl-2">
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="removeMcqOption"
                        onClick={e => this.props.deleteTermRow(e, idx)}
                      ></FontAwesomeIcon>
                      {idx === this.props.newTerms.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="addMcqOption ml-3"
                          onClick={e => this.props.addTermRow(e)}
                        ></FontAwesomeIcon>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait.Adding Terms ...
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default AddTermsModal;

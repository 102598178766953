import React, { Component } from "react";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { URL, userRole, userRoleCode } from "../../utils/Constants";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import ModalWindow from "../UI/ModalWindow";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faMinus } from "@fortawesome/free-solid-svg-icons";
const animatedComponents = makeAnimated();

export class Reminder extends Component {
  state = {
    title: "",
    dateFrom: "",
    dateTo: "",
    notificationType: "",
    description: "",
    notifyDate: moment(new Date()).format("YYYY-MM-DD"),
    selectedGroups: [],
    selectedIndividual: [],
    spinner: false,

    notificationTypeName: "",
    notificationTypeModal: false,
    groupsArray: [],
    notificationTypeArr: [],
    peopleArray: [],

    notifyViewModal: false,
    notifyData: [],
  };

  handleViewNotify = () => {
    this.setState({
      notifyViewModal: true,
    });
  };

  toggleViewModal = () => {
    this.setState({
      notifyViewModal: !this.state.notifyViewModal,
    });
  };

  addNotificationType = () => {
    if (this.state.notificationTypeName === "") {
      swal("Error", "Notification name is required");
      return;
    } else {
      let data = {
        name: this.state.notificationTypeName,
      };

      let allNotificationTypes = this.state.notificationTypeArr;

      const lowCaseTypes = allNotificationTypes.map((nt) => {
        return nt.name.toLowerCase();
      });
      if (lowCaseTypes.includes(data.name.toLowerCase())) {
        swal("Error", "Notification Type already exists!!");
        return;
      } else {
        this.setState(
          {
            spinner: true,
            notificationTypeModal: false,
          },
          () => {
            axiosPost(
              URL.insertNotificationType,
              data,
              (response) => {
                if (response.status === 200) {
                  swal("Success", "New Notification type added successfully.");
                  this.setState({
                    notificationType: "",
                    spinner: false,
                    // notificationTypeName: "",
                  });
                  this.getAllNotificationTypes();
                }
              },
              (err) => {
                this.setState({ spinner: false });
                swal("Error", err.response ? err.response.data : err);
                return;
              }
            );
          }
        );
      }
    }
  };

  componentDidMount() {
    this.getGroupsArray();
    this.getAllNotificationTypes();

    if (this.props.editMode) {
      let groupsData = this.props.updateRemData.groups;
      let peoplesData = this.props.updateRemData.peoples;

      groupsData !== null
        ? groupsData.forEach((gd) => {
            gd.label = gd.name;
            gd.value = gd.id;
          })
        : peoplesData.forEach((pd) => {
            pd.label = pd.name;
            pd.value = pd.id;
          });

      this.setState({
        title: this.props.updateRemData.heading,
        dateFrom: moment(this.props.updateRemData.dateFrom).format(
          "yyyy-MM-DD"
        ),
        dateTo: moment(this.props.updateRemData.dateTo).format("yyyy-MM-DD"),
        notificationType: this.props.updateRemData.notificationTypeId,
        description: this.props.updateRemData.message,
        notifyDate: moment(this.props.updateRemData.notifyDate).format(
          "yyyy-MM-DD"
        ),
        selectedGroups: groupsData || [],
        selectedIndividual: peoplesData || [],
      });
    }
  }

  getAllNotificationTypes = () => {
    axiosPost(URL.getAllNotificationsType, {}, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            notificationTypeArr: response.data.data,
          },
          () => {
            let newType = this.state.notificationTypeArr.filter(
              (name) => name.name === this.state.notificationTypeName
            );
            newType.length > 0 &&
              this.setState({
                notificationType: newType[0].id,
              });
          }
        );
      }
    });
  };

  getGroupsArray = () => {
    if (userRole === userRoleCode.roleAdmin) {
      axiosPost(URL.getAllGroups, {}, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          this.setState({
            groupsArray,
          });
        }
      });
    } else {
      let peopleId = localStorage.getItem("peopleId");

      let data = {
        peopleId: peopleId,
      };
      axiosPost(URL.getGroups, data, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          this.setState({
            groupsArray,
          });
        }
      });
    }
  };

  getGroupAssociatedPeople = () => {
    let data = {
      peopleId: localStorage.getItem("peopleId"),
    };
    this.setState({ peopleSpinner: true }, function () {
      axiosPost(
        URL.getGroupAssociatedPeople,
        data,
        (response) => {
          if (response.status === 200) {
            let people = response.data.data;
            let peopleArray = [];
            let yearLevel = localStorage.getItem("yearLevel");
            let classroom = localStorage.getItem("classroom");
            if (people.length > 0) {
              people.forEach((el) => {
                let displayName = "";
                if (el.userRoleCode === userRoleCode.roleStudent) {
                  displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
                } else {
                  displayName = `${el.name} [${el.userRole}]`;
                }
                peopleArray.push({ label: displayName, value: el.id });
              });
            }

            this.setState({ peopleArray, peopleSpinner: false });
          }
        },
        (failur) => {
          this.setState({ peopleSpinner: false });
        }
      );
    });
  };

  handleChangeSelect = (selectedItem) => {
    this.setState({
      selectedGroups: selectedItem,
      selectedIndividual: [],
    });
  };

  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
      selectedGroups: [],
    });
  };

  handleCreateReminder = (schedule) => {
    let selectedGroupArr = [];
    let selectedPeopleArr = [];
    this.state.selectedGroups !== null && this.state.selectedGroups.length > 0
      ? this.state.selectedGroups.forEach((sg) => {
          selectedGroupArr.push(sg.value);
        })
      : this.state.selectedIndividual !== null &&
        this.state.selectedIndividual.length > 0 &&
        this.state.selectedIndividual.forEach((si) => {
          selectedPeopleArr.push(si.value);
        });

    let data = {
      heading: this.state.title,
      message: this.state.description,
      startDate: this.state.dateFrom,
      endDate: this.state.dateTo,
      notificationTypeId: this.state.notificationType,
      notifyDate:
        this.state.notifyDate !== ""
          ? this.state.notifyDate
          : moment(new Date()).format("YYYY-MM-DD"),
      groupIds: selectedGroupArr,
      peopleIds: selectedPeopleArr,
      isScheduled: schedule === "later" ? true : false,
    };

    if (
      this.state.title !== "" &&
      this.state.description !== "" &&
      this.state.notificationType !== "" &&
      (selectedGroupArr.length > 0 || selectedPeopleArr.length > 0)
    ) {
      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(URL.insertNotification, data, (response) => {
            if (response.status === 200) {
              swal("Success", response.data.message);
              this.setState({
                spinner: false,
              });
              this.resetDataState();
            }
          });
        }
      );
    } else {
      swal("Error", "Please fill all the required fields!!");
      this.setState({
        spinner: false,
      });
      return;
    }
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    // if (name === "dateFrom") {
    //   debugger;
    //   this.setState({
    //     notifyDate: moment(value).subtract(1, "days").format("YYYY-MM-DD"),
    //   });
    // }
    this.setState({
      [name]: value,
    });
  };

  handleUpdateReminder = (schedule) => {
    let selectedGroupArr = [];
    let selectedPeopleArr = [];
    this.state.selectedGroups !== null && this.state.selectedGroups.length > 0
      ? this.state.selectedGroups.forEach((sg) => {
          selectedGroupArr.push(sg.value);
        })
      : this.state.selectedIndividual !== null &&
        this.state.selectedIndividual.length > 0 &&
        this.state.selectedIndividual.forEach((si) => {
          selectedPeopleArr.push(si.value);
        });
    let data = {
      id: this.props.updateRemData.id,
      heading: this.state.title,
      message: this.state.description,
      startDate: this.state.dateFrom,
      endDate: this.state.dateTo,
      notificationTypeId: this.state.notificationType,
      notifyDate: this.state.notifyDate,
      groupIds: selectedGroupArr,
      peopleIds: selectedPeopleArr,
      isEnabled: null,
    };

    if (
      this.state.title !== "" &&
      this.state.description !== "" &&
      this.state.notificationType !== "" &&
      (selectedGroupArr.length > 0 || selectedPeopleArr.length > 0)
    ) {
      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(URL.updateNotification, data, (response) => {
            if (response.status === 200) {
              swal("Success", response.data.message);
              this.setState({
                spinner: false,
              });
              this.props.toggleUpdateModal();
              this.props.getAllNotifications();
            }
          });
        }
      );
    } else {
      swal("Error", "Please fill all the required fields!!", "error");
      this.setState({
        spinner: false,
      });
      return;
    }
  };

  resetDataState = () => {
    this.setState({
      title: "",
      dateFrom: "",
      dateTo: "",
      notificationType: "",
      description: "",
      notifyDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedGroups: [],
      selectedIndividual: [],
    });
  };

  toggleEventTypeAddModal = () => {
    this.setState({
      notificationTypeModal: true,
    });
  };

  toggleEventModal = () => {
    this.setState({
      notificationTypeModal: !this.state.notificationTypeModal,
    });
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait !! Processing.....
            </div>
          </div>
        ) : null}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          {!this.props.editMode ? (
            <div className="tt-group-header">Create Reminder</div>
          ) : (
            ""
          )}
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Title:
                  <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span>
                </strong>
              </div>
              <div className="col">
                <input
                  type="text"
                  name="title"
                  placeholder="Enter title"
                  className="form-control"
                  value={this.state.title}
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Description:
                  <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span>
                </strong>
              </div>
              <div className="col">
                <textarea
                  rows={4}
                  name="description"
                  placeholder="Enter description"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.description}
                ></textarea>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Start date:
                  {/* <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span> */}
                </strong>
              </div>
              <div className="col-md-4">
                <input
                  type="date"
                  className="form-control"
                  name="dateFrom"
                  value={this.state.dateFrom}
                  onChange={this.handleChange}
                  placeholder="Enter start date"
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  End date:
                  {/* <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span> */}
                </strong>
              </div>
              <div className="col-md-4">
                <input
                  type="date"
                  className="form-control"
                  name="dateTo"
                  value={this.state.dateTo}
                  onChange={this.handleChange}
                  placeholder="Enter end date"
                  min={this.state.dateFrom}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Notification Type:
                  <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span>
                </strong>
              </div>
              <div className="col-md-4">
                <select
                  name="notificationType"
                  value={this.state.notificationType}
                  onChange={this.handleChange}
                  className="form-control"
                >
                  <option value="" disabled selected>
                    Choose a notification Type
                  </option>
                  {this.state.notificationTypeArr.map((notification, id) => (
                    <option value={notification.id} key={id}>
                      {notification.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-auto">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.toggleEventTypeAddModal}
                >
                  Add New Type
                </button>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Notify Date:
                  <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span>
                </strong>
              </div>
              <div className="col-md-4">
                <input
                  type="date"
                  name="notifyDate"
                  placeholder="Enter notify date"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.notifyDate}
                  max={moment(this.state.dateFrom)
                    .subtract(1, "days")
                    .format("YYYY-MM-DD")}
                ></input>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Notify To:
                  <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span>
                </strong>
              </div>
              <div className="col-md-4">
                <Select
                  closeMenuOnSelect={false}
                  isClearable={true}
                  components={animatedComponents}
                  isMulti
                  options={this.state.groupsArray}
                  placeholder="Choose Group"
                  name="colors"
                  onChange={this.handleChangeSelect}
                  menuPlacement="auto"
                  value={this.state.selectedGroups}
                />
              </div>
              <div className="col-md-1 text-center p-3">
                <span>OR</span>
              </div>
              <div className="col-md-4">
                <Select
                  closeMenuOnSelect={false}
                  isClearable={true}
                  components={animatedComponents}
                  isMulti
                  options={this.state.peopleArray}
                  placeholder="Choose Individual People"
                  name="people"
                  onChange={this.handleIndividualSelect}
                  menuPlacement="auto"
                  value={this.state.selectedIndividual}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col text-right">
                {this.props.editMode ? (
                  <button
                    className="tt-button tt-button-primary"
                    onClick={() => this.handleUpdateReminder()}
                  >
                    Update
                  </button>
                ) : (
                  <DropdownButton
                    as={ButtonGroup}
                    key="up"
                    id="dropdown-button-drop-up"
                    drop="up"
                    variant="tt-button tt-button-primary"
                    title="Save"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => this.handleCreateReminder("later")}
                    >
                      Schedule for later
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => this.handleCreateReminder("instant")}
                    >
                      Notify Now
                    </Dropdown.Item>
                  </DropdownButton>
                )}
              </div>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state.notificationTypeModal}
          toggleModal={this.toggleEventModal}
          modalHeader="Add New Notification Type"
          modalBody={
            <>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-2">
                    <strong>Name: </strong>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="notificationTypeName"
                      className="form-control"
                      value={this.state.notificationTypeName}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                </div>
                <div className="row mt-3">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.addNotificationType}
                  >
                    Add
                  </button>
                </div>
              </div>
            </>
          }
        ></ModalWindow>
      </>
    );
  }
}

export default Reminder;

import React, { Component } from "react";
import { Spinner } from "reactstrap";
import Pagination from "../UI/Pagination";
import { checkSystemAdmin, URL } from "./../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import { FormGroup, Input, Label } from "reactstrap";
import { axiosPost } from "../../utils/AxiosApi";
import makeAnimated from "react-select/animated";
import swal from "sweetalert";
import AssignPermission from "./AssignPermission";

const animatedComponents = makeAnimated();
class AllUsers extends Component {
  state = {
    rolesModal: false,
    allActivity: [],
    activityIds: [],
    subRoleModal: false,
    permissionsModal: false,

    userSubRoles: [],
    userSubRoleIds: [],
    UserId: "",
    user: "",

    roleOptions: [],
    roleId: "",

    subRoles: [],
    roleIds: [],
    permissions: [],
    permissionIds: [],
    userRoles: [],
    submitSpinner: false,
  };

  toggleRolesModal = (user) => {
    this.setState(
      {
        rolesModal: !this.state.rolesModal,
        userSubRoleIds: [],
      },
      () => {
        if (this.state.rolesModal == true) {
          this.getUserSubRole(user);
          this.setState({
            UserId: user.id,
            user: user,
          });
        }
      }
    );
  };
  togglePermissionsModal = (user) => {
    this.setState({
      permissionsModal: !this.state.permissionsModal,
      UserId: user.id,
    });
  };

  toggleSubRoleModal = (e) => {
    this.setState(
      {
        subRoleModal: !this.state.subRoleModal,
      },
      () => {
        this.getUserRoles();
      }
    );
  };

  getUserRoles = () => {
    axiosPost(URL.getUserRoles, {}, (response) => {
      if (response.status === 200) {
        this.setState({ userRoles: response.data.data }, () => {
          let roleOptions = [];
          this.state.userRoles.forEach((item) => {
            roleOptions.push({ label: item.name, value: item.code });
          });
          this.setState({
            roleOptions: roleOptions,
          });
        });
      }
    });
  };
  checkSystemAdminInList = (user) => {
    if (
      user.subRoleCodes.findIndex(
        (subRoleCode) => subRoleCode.subRoleCode === "SYS_ADMIN"
      ) !== -1
    )
      return true;
    else return false;
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleUserSubRoleCheck = (e) => {
    const userSubRoleIds = this.state.userSubRoleIds;
    let userSubRoles = this.state.userSubRoles;

    if (e.target.checked) {
      userSubRoleIds.push(e.target.value);
      userSubRoles.forEach((item) => {
        if (item.id == e.target.value) {
          item.hasSubRole = true;
        }
      });
    } else {
      userSubRoleIds.splice(userSubRoleIds.indexOf(e.target.value), 1);
      userSubRoles.forEach((item) => {
        if (item.id == e.target.value) {
          item.hasSubRole = false;
        }
      });
    }

    this.setState({
      userSubRoleIds: userSubRoleIds,
      userSubRoles: userSubRoles,
    });
  };
  handleSubRoleActivityCheck = (e) => {
    const activityIds = this.state.allActivity;
    if (e.target.checked) {
      activityIds.push(e.target.value);
    } else {
      activityIds.splice(activityIds.indexOf(e.target.value), 1);
    }
    this.setState({
      activityIds: activityIds,
    });
  };

  reassignUserSubRole = () => {
    this.setState({
      submitSpinner: true,
    });
    let data = {
      id: this.state.UserId,
      subRoleIds: this.state.userSubRoleIds,
    };
    axiosPost(URL.reassignUserSubRole, data, (response) => {
      if (response.status === 200) {
        this.setState({
          rolesModal: false,
          submitSpinner: false,
        });
        swal({
          title: "Success",
          text: `${response.data.message}`,
          allowOutsideClick: true,
          closeOnClickOutside: true,
        });
        this.getUserSubRole(this.state.user);
      }
    });
  };

  handlePermissionCheck = (e) => {
    const permissionIds = this.state.permissions;
    if (e.target.checked) {
      permissionIds.push(e.target.value);
    } else {
      permissionIds.splice(permissionIds.indexOf(e.target.value), 1);
    }
    this.setState({
      permissionIds: permissionIds,
    });
  };

  getAllPermissions = () => {
    axiosPost(URL.getAllActivity, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          allActivity: response.data.data,
        });
      }
    });
  };

  getUserSubRole = (user) => {
    let data = {
      id: user.id,
      userRoleCode: user.rolecode,
    };
    axiosPost(URL.getUserSubRoleData, data, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            userSubRoles: response.data.data,
          },
          () => {
            let existingRoles = this.state.userSubRoleIds;
            this.state.userSubRoles.forEach((item) => {
              if (item.hasSubRole) {
                existingRoles.push(`${item.id}`);
              }
            });
            this.setState({
              userSubRoleIds: existingRoles,
            });
          }
        );
      }
    });
  };

  handleRoleChange = (e) => {
    this.setState({
      roleId: e,
    });
  };

  handleSelectAllSubRole = (e) => {
    let newSubRoles = this.state.userSubRoles;
    if (e.target.checked) {
      let sRoleIds = [];
      newSubRoles.forEach((item) => {
        item.hasSubRole = true;
        sRoleIds.push(`${item.id}`);
      });
      this.setState({
        userSubRoleIds: sRoleIds,
      });
    } else {
      newSubRoles.forEach((item) => {
        item.hasSubRole = false;
      });
      this.setState({
        userSubRoleIds: [],
      });
    }
    this.setState({
      userSubRoles: newSubRoles,
    });
  };

  componentDidMount() {
    this.getAllPermissions();
  }

  render() {
    let userList = this.props.allUsers;
    return (
      <>
        {this.state.submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Submitting
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="p-2">
          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>People Name</th>
                <th>Username</th>
                <th>User Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userList.length > 0 ? (
                userList.map((user, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{user.peopleName}</td>
                      <td>{user.username}</td>
                      <td>{user.userRole}</td>
                      <td className="text-center">
                        {checkSystemAdmin() &&
                        this.checkSystemAdminInList(user) ? null : (
                          <>
                            <button
                              className="tt-button tt-button-primary mr-3"
                              onClick={() =>
                                this.props.toggleForgotModal(
                                  user.username,
                                  user.id
                                )
                              }
                            >
                              Update Password
                            </button>
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() =>
                                this.props.enableDisableUser(
                                  user.id,
                                  !user.enabled
                                )
                              }
                            >
                              {user.enabled ? "Disable User" : "Enable User"}
                            </button>
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() => this.toggleRolesModal(user)}
                            >
                              Roles
                            </button>
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() => this.togglePermissionsModal(user)}
                            >
                              Permissions
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    {this.props.displayAllUserSpinner ? (
                      <>
                        <Spinner color="primary"></Spinner>
                        <br></br>Loading data...
                      </>
                    ) : (
                      "No users"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemsCount={this.props.allUserTotalRecords}
              pageSize={this.props.allUserRowCountLimit}
              currentPage={this.props.allUserCurrentPage}
              onPageChange={this.props.allUserHandlePageChange}
              nextPage={this.props.allUserHandleNextPage}
              previousPage={this.props.allUserHandlePreviousPage}
            ></Pagination>
          </div>
          <ModalWindow
            modal={this.state.rolesModal}
            modalHeader="Roles"
            id="tt-permissionModal"
            size="lg"
            fullWidth={true}
            toggleModal={this.toggleRolesModal}
            modalBody={
              <>
                <div className="row mt-3 mb-3">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      className="tt-button tt-button-primary"
                      onClick={this.toggleSubRoleModal}
                    >
                      Create Sub Role
                    </button>
                  </div>
                </div>
                <div className="tt-permissionColumn">
                  <table className="table">
                    <thead className="tt-group-header permissionTable">
                      <tr>
                        <th className="text-center" width="10%">
                          <input
                            type="checkbox"
                            className="activity-checkbox"
                            onChange={this.handleSelectAllSubRole}
                          />
                        </th>
                        <th width="90%">Select All</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="tt-permissionColumn">
                    <table className="table">
                      {this.state.userSubRoles.length > 0
                        ? this.state.userSubRoles.map((subRole, id) => {
                            return (
                              <tbody className="permissionTable">
                                <tr>
                                  <th width="10%" className="text-center">
                                    {/* <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          value={subRole.id}
                                          defaultChecked={subRole.hasSubRole}
                                          // checked={subRole.hasSubRole}
                                          onChange={this.handleUserSubRoleCheck}
                                        />
                                      </Label>
                                    </FormGroup> */}
                                    <input
                                      type="checkbox"
                                      value={subRole.id}
                                      checked={subRole.hasSubRole}
                                      className="activity-checkbox"
                                      onChange={this.handleUserSubRoleCheck}
                                    />
                                  </th>
                                  <th width="90%">{subRole.name}</th>
                                </tr>
                              </tbody>
                            );
                          })
                        : null}
                    </table>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      className="tt-button tt-button-primary"
                      onClick={this.reassignUserSubRole}
                    >
                      Sumbit
                    </button>
                  </div>
                </div>
              </>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.permissionsModal}
            modalHeader="Permissions"
            id="tt-permissionModal"
            size="lg"
            fullWidth={true}
            toggleModal={this.togglePermissionsModal}
            modalBody={
              <>
                <AssignPermission
                  UserId={this.state.UserId}
                  togglePermissionsModal={this.togglePermissionsModal}
                  user={this.state.user}
                  createSubRole={false}
                  roleOptions={this.state.roleOptions}
                  toggleSubRoleModal={this.toggleSubRoleModal}
                />
              </>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.subRoleModal}
            modalHeader="Create Sub Role"
            id="tt-permissionModal"
            fullWidth={true}
            toggleModal={this.toggleSubRoleModal}
            modalBody={
              <>
                <AssignPermission
                  UserId={this.state.UserId}
                  togglePermissionsModal={this.togglePermissionsModal}
                  user={this.state.user}
                  createSubRole={true}
                  roleOptions={this.state.roleOptions}
                  toggleSubRoleModal={this.toggleSubRoleModal}
                />
              </>
            }
          ></ModalWindow>
        </div>
      </>
    );
  }
}

export default AllUsers;

import React, { Component } from "react";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL, userRole, userRoleCode } from "../../utils/Constants";
import ModalWindow from "./../UI/ModalWindow";
import GranularPermissionModal from "./../Permissions/GranularPermissionModal";
import swal from "sweetalert";
import moment from "moment";
import DatePicker from "./../UI/DatePicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Spinner } from "reactstrap";
import { displayErrorAlert, displayErrorMessage } from "../../utils/Utils";

const animatedComponents = makeAnimated();

class LeaveRequestForm extends Component {
  state = {
    userRole: userRole,
    DOE: { year: null, month: null, day: null },
    subject: "",
    reasonForLeave: "",
    additionalDetails: "",
    leaveType: "",
    leaveFrom: "",
    leaveTo: "",
    fromHalfleaveType: "",
    toHalfleaveType: "",
    showFromHalfCheckbox: false,
    showToHalfCheckbox: false,
    toHalfDayChecked: false,
    fromHalfDayChecked: false,
    leaveTypeOptions: [],
    sendToOptions: [],
    ccToOptions: [],
    studentOptions: [],
    sendTo: "",
    ccTo: [],
    student: null,
  };

  componentDidMount() {
    this.getAllEmailConfig();
    this.getLeaveTypes();

    if (userRole === userRoleCode.roleStudentContact) {
      axiosPost(
        URL.getStdContactChildren,
        {},
        (response) => {
          let studentOptions = response.data.data.students.map((dt) => ({
            ...dt,
            label: dt.name,
            vaue: dt.id,
          }));

          if (response.data.status === 200) {
            this.setState({
              studentOptions: studentOptions,
            });
          }
        },
        (err) => {
          displayErrorMessage(err);
        }
      );
    }
  }
  handleLeaveTypeChange = (e) => {
    this.setState({
      leaveType: e,
    });
  };
  handleSendToChange = (e) => {
    this.setState({
      sendTo: e,
    });
  };
  handleCCToChange = (e) => {
    this.setState({
      ccTo: e,
    });
  };
  handleStudentChange = (data) => {
    this.setState({
      student: data,
    });
  };

  getAllEmailConfig = () => {
    axiosPost(URL.emailConfigurationGetAll, {}, (response) => {
      const SendToOptions = response?.data?.data?.sendToValues?.map((item) => ({
        ...item,
        label: item.email,
        value: item.id,
      }));
      const CcToOptions = response?.data?.data?.sendCcValues?.map((item) => ({
        ...item,
        label: item.email,
        value: item.id,
      }));

      this.setState({
        sendToOptions: SendToOptions,
        ccToOptions: CcToOptions,
      });
    });
  };

  getLeaveTypes = () => {
    axiosPost(URL.getLeaveTypes, {}, (response) => {
      if (response.data.status === 200) {
        let optionData = response.data.data.map((dt) => ({
          ...dt,
          label: dt.name,
          value: dt.id,
        }));

        this.setState({
          leaveTypeOptions: optionData,
        });
      }
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "leaveFrom") {
          this.setState({
            showFromHalfCheckbox: name != null || name != "" ? true : false,
          });
        }
        if (name == "leaveTo") {
          this.setState({
            showToHalfCheckbox: name != null || name != "" ? true : false,
          });
        }
      }
    );
  };

  handleCheckBox = (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: e.target.checked,
    });
  };

  sendLeaveReq = () => {
    let data = {};
    if (this.props.staff) {
      data = {
        // admissionId: this.state.student?.admissionId || "",
        subject: this.state.subject,
        leaveTypeId: this.state.leaveType.value,
        description: this.state.reasonForLeave,
        additionalDescription: this.state.additionalDetails,
        fromDate: this.state.leaveFrom,
        toDate: this.state.leaveTo,
        sendTos: this.state.sendTo,
        sendCcs: this.state.ccTo,
      };
    } else {
      data = {
        admissionId: this.state.student?.admissionId || "",
        subject: this.state.subject,
        leaveTypeId: this.state.leaveType.value,
        description: this.state.reasonForLeave,
        additionalDescription: this.state.additionalDetails,
        fromDate: this.state.leaveFrom,
        toDate: this.state.leaveTo,
      };
    }

    if (
      this.state.subject === "" ||
      this.state.leaveType === "" ||
      this.state.reasonForLeave === "" ||
      this.state.leaveFrom === "" ||
      this.state.leaveTo === ""
    ) {
      swal("Error", "Fill all the required fields");
      return;
    } else {
      if (this.props.staff && this.state.sendTo === "") {
        swal("Error", "Select email for sending the leave email");
        return;
      } else {
        if (
          userRole === userRoleCode.roleStudentContact &&
          this.state.admissionId === ""
        ) {
          swal("Error", "Choose student for whom you are applying leave for");
          return;
        } else {
          this.setState(
            {
              spinner: true,
            },
            () => {
              axiosPost(
                URL.sendLeaveReq,
                data,
                (response) => {
                  if (response.data.status === 200) {
                    this.setState({ spinner: false });

                    this.resetState();
                    swal("Success", response.data.message);
                    this.props.getAllLeaveList();
                    this.props.handleLeaveFormModal();
                  }
                },
                (err) => {
                  this.setState({ spinner: false });
                  displayErrorAlert(err);
                  // this.props.getAllLeaveList();
                }
              );
            }
          );
        }
      }
    }
  };

  resetState = () => {
    this.setState({
      DOE: { year: null, month: null, day: null },
      subject: "",
      reasonForLeave: "",
      additionalDetails: "",
      leaveType: "",
      leaveFrom: "",
      leaveTo: "",

      sendTo: [],
      ccTo: [],
    });
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-newListBox container-fluid">
            <div className="tt-group-header">
              <div
                className="row"
                style={{
                  fontWeight: "normal",
                  textShadow: "none",
                  color: "black",
                }}
              >
                {!this.props.staff ? (
                  <>
                    {userRole === userRoleCode.roleStudentContact ? (
                      <>
                        <div className="col-md-6">
                          <Select
                            menuPlacement="auto"
                            menuPosition="fixed"
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={this.state.studentOptions}
                            placeholder="Choose Student"
                            name="student"
                            onChange={this.handleStudentChange}
                            closeMenuOnScroll={false}
                            value={this.state.student}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                <div className={this.props.staff ? "col-md-12" : "col-md-6"}>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    value={this.state.subject}
                    placeholder="Subject for leave"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <Select
                    menuPlacement="auto"
                    menuPosition="fixed"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    options={this.state.leaveTypeOptions}
                    placeholder="Choose Leave Type"
                    name="leaveType"
                    onChange={this.handleLeaveTypeChange}
                    closeMenuOnScroll={false}
                    value={this.state.leaveType}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <textarea
                    className="form-control"
                    name="reasonForLeave"
                    placeholder="Reason for leave"
                    onChange={this.handleChange}
                    value={this.state.reasonForLeave}
                  ></textarea>
                </div>
                <div className="col-md-6 mt-3">
                  <textarea
                    className="form-control"
                    name="additionalDetails"
                    placeholder="Additional Details"
                    onChange={this.handleChange}
                    value={this.state.additionalDetails}
                  ></textarea>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="text-white mb-2">From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={this.state.leaveFrom}
                    onChange={this.handleChange}
                    name="leaveFrom"
                  />
                </div>

                {/* <div className="col-md-3 mt-3">
                  <div
                    className={!this.state.showFromHalfCheckbox ? "d-none" : ""}
                  >
                    <label>Half Day</label>
                    <input
                      type="checkbox"
                      checked={this.state.fromHalfDayChecked}
                      onChange={this.handleCheckBox}
                      name="fromHalfDayChecked"
                    />
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div
                    className={!this.state.fromHalfDayChecked ? "d-none" : ""}
                  >
                    <select
                      name="fromHalfleaveType"
                      value={this.state.fromHalfleaveType}
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value="" disabled>
                        Select Half Leave Type
                      </option>
                      <option value="null">Before Half</option>
                      <option value="null">After Half</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-md-6 mt-3">
                  <label className="text-white mb-2">To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    min={this.state.leaveFrom}
                    value={this.state.leaveTo}
                    onChange={this.handleChange}
                    name="leaveTo"
                  />
                </div>
                {/* <div className="col-md-3 mt-3">
                  <div
                    className={!this.state.showToHalfCheckbox ? "d-none" : ""}
                  >
                    <label>Half Day</label>
                    <input
                      type="checkbox"
                      checked={this.state.toHalfDayChecked}
                      onChange={this.handleCheckBox}
                      name="toHalfDayChecked"
                    />
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className={!this.state.toHalfDayChecked ? "d-none" : ""}>
                    <select
                      name="toHalfleaveType"
                      value={this.state.toHalfleaveType}
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value="" disabled>
                        Select Half Leave Type
                      </option>
                      <option value="null">Before Half</option>
                      <option value="null">After Half</option>
                    </select>
                  </div>
                </div> */}
                {this.props.staff ? (
                  <>
                    <div className="col-md-6 mt-3">
                      <label className="text-white mb-2">Send To</label>

                      <Select
                        // isMulti
                        menuPlacement="auto"
                        menuPosition="fixed"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={this.state.sendToOptions}
                        placeholder="Send to"
                        name="sendTo"
                        onChange={this.handleSendToChange}
                        closeMenuOnScroll={false}
                        value={this.state.sendTo}
                      />
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="text-white mb-2">CC to</label>

                      <Select
                        isMulti
                        menuPlacement="auto"
                        menuPosition="fixed"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={this.state.ccToOptions}
                        placeholder="CC to"
                        name="ccTo"
                        onChange={this.handleCCToChange}
                        closeMenuOnScroll={false}
                        value={this.state.ccTo}
                      />
                    </div>
                  </>
                ) : null}

                {/* {this.props.leaveForStaff ? (
                  ""
                ) : (
                  <>
                    <div className="col-md-6 mt-3">
                      <label>Student</label>
                      <Select
                        menuPlacement="auto"
                        menuPosition="fixed"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={this.state.ccToOptions}
                        placeholder="CC to"
                        name="ccTo"
                        onChange={this.handleCCToChange}
                        closeMenuOnScroll={false}
                        value={this.state.ccTo}
                      />
                    </div>
                  </>
                )} */}

                <div className=" text-right mt-3 ml-3">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={() => {
                      this.sendLeaveReq();
                    }}
                  >
                    Send Leave Request
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LeaveRequestForm;

import React, { Component } from "react";

class UserSearchSection extends Component {
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-1"></div>
          <div className="col-md-2 text-right">
            <strong>People Name: </strong>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              name="peopleName"
              value={this.props.peopleName}
              onChange={this.props.handleChange}
            ></input>
          </div>
          <div className="col-md-1 text-right">
            <strong>Username: </strong>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              name="username"
              value={this.props.username}
              onChange={this.props.handleChange}
            ></input>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mb-3">
          <div className="col-md-1"></div>
          <div className="col-md-2 text-right">
            <strong>User Role: </strong>
          </div>
          <div className="col-md-3">
            <select
              className="form-control"
              name="userRole"
              value={this.props.userRole}
              onChange={this.props.handleChange}
            >
              <option disabled>Choose User role</option>
              <option value="">None</option>
              {this.props.userRoles.map((role, idx) => {
                return (
                  <option key={idx} value={role.code}>
                    {role.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-1 text-right">
            <strong>Status: </strong>
          </div>
          <div className="col-md-3">
            {this.props.selectedTabId === "allUser" ? (
              <select
                className="form-control"
                name="enabled"
                value={this.props.enabled}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Choose Status
                </option>
                {this.props.enableStatus.map((stat, idx) => {
                  return (
                    <option value={stat.value} key={idx}>
                      {stat.name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <select
                className="form-control"
                name="status"
                value={this.props.status}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Choose Status
                </option>
                {this.props.statuses.map((stat, idx) => {
                  return (
                    <option value={stat} key={idx}>
                      {stat}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
          <div className="col-md-2" style={{ paddingLeft: "7px" }}>
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.handleSearch}
            >
              Search
            </button>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    );
  }
}

export default UserSearchSection;

import { faSyncAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { URL as URLs } from "../../utils/Constants";
import flashCardDefaultPic from "../../assets/images/flashCardDefault2.png";

class FlashCardTemplate extends Component {
  state = {
    cardPreview: "",
    selectedCards: [],
    flashCards: [],
    cardFlipped: false,
    noImage: false,
    allFlashCards: [],
  };

  componentDidMount() {
    this.randomCardSelection();
  }

  randomCardSelection = () => {
    if (this.props.flashCards) {
      let flashCardBox = this.props.flashCards;
      let newflashCards = [];
      if (flashCardBox.flashCards.length > 0) {
        while (
          // newflashCards.length < 6 &&
          newflashCards.length !== flashCardBox.flashCards.length
        ) {
          const random = Math.floor(
            Math.random() * flashCardBox.flashCards.length
          );
          let ranCard = flashCardBox.flashCards[random];
          if (newflashCards.length !== flashCardBox.flashCards.length) {
            const found = newflashCards.some((el) => el.id === ranCard.id);
            if (!found) {
              newflashCards.push(ranCard);
            }
          }
        }
      }
      this.setState({
        flashCards: newflashCards,
        allFlashCards: this.props.flashCards,
      });
    }
  };

  shuffleCards = () => {
    this.setState(
      { cardPreview: "", cardFlipped: false, noImage: false },
      function () {
        // if (this.state.cardFlipped) {
        //   this.handleFlipCard();
        // }
        this.setState({ selectedCards: [], cardPreview: "" }, function () {
          this.randomCardSelection();
        });
      }
    );
  };

  handleChooseCard = () => {
    let flashCards = this.state.flashCards;
    let selectedCards = this.state.selectedCards;
    this.setState(
      { cardPreview: "", cardFlipped: false, noImage: false },
      function () {
        // if (this.state.cardFlipped) {
        //   this.handleFlipCard();
        // }
        if (flashCards.length > 0) {
          let cardPreview = flashCards[0];
          selectedCards.push(flashCards[0]);
          flashCards.splice(0, 1);
          this.setState({
            cardPreview: cardPreview,
            selectedCards,
            flashCards,
          });
        }
      }
    );
  };

  handleFlipCard = () => {
    let card = document.getElementById("flipDisplayCard");
    this.setState({ cardFlipped: !this.state.cardFlipped }, function () {
      if (this.state.cardFlipped) {
        card.classList.add("flip");
      } else {
        card.classList.remove("flip");
      }
    });
  };

  toggleHover = (action) => {
    let element = document.getElementById("hoverDiv");
    if (action === "show") {
      element.style.opacity = 1;
    } else {
      element.style.opacity = 0;
    }
  };

  selectCard = (card) => {
    this.setState(
      { cardPreview: "", cardFlipped: false, noImage: false },
      function () {
        // if (this.state.cardFlipped) {
        //   this.handleFlipCard();
        // }
        this.setState({ cardPreview: card });
      }
    );
  };

  handleCardError = (e) => {
    this.setState({ noImage: true });
    // return (e.target.src = flashCardDefaultPic);
  };

  handleCardThumbError = (e) => {
    return (e.target.src = flashCardDefaultPic);
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "86.9vh" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <div className="row">
                <div className="col">
                  {this.state.flashCards.length > 0 ? (
                    <div
                      className="tt-cardDeckHolder"
                      onClick={this.handleChooseCard}
                    >
                      <div className="tt-cardDeck">
                        FLASH<br></br>CARDS
                      </div>
                    </div>
                  ) : (
                    <div className="tt-cardDeck-empty">
                      <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  {/* <button
                    className="tt-button tt-button-primary mt-1"
                    onClick={this.handleFlipCard}
                    disabled={this.state.cardPreview === "" ? true : false}
                  >
                    Flip Card
                  </button> */}
                  {/* <br></br> */}
                  <button
                    className="tt-button tt-button-primary mt-1"
                    onClick={this.shuffleCards}
                  >
                    Shuffle
                  </button>
                  <br></br>
                  {/* <button
                    className="tt-button tt-button-primary mt-1"
                    onClick={this.props.toggleFlashCardModal}
                  >
                    Close
                  </button> */}
                </div>
              </div>
              <br></br>
              <div className="flash-hint">* Hover on the card to get hint.</div>
              <div
                style={{ position: "absolute", bottom: "15px", right: "15px" }}
              >
                <div
                  onClick={
                    this.state.cardPreview === "" ? null : this.handleFlipCard
                  }
                  className={this.state.cardPreview === "" ? "disableFlip" : ""}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  <FontAwesomeIcon icon={faSyncAlt}></FontAwesomeIcon>
                  <br></br>
                  Flip Card
                </div>
              </div>
            </div>
            <div
              className="col-md-7"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.state.cardPreview !== "" ? (
                <div className="flip-card">
                  <div className="flip-card-inner" id="flipDisplayCard">
                    <div
                      className="flip-card-front"
                      onMouseEnter={() => this.toggleHover("show")}
                      onMouseLeave={() => this.toggleHover("hide")}
                    >
                      <div className="flash-hoverDiv" id="hoverDiv">
                        {this.state.cardPreview.hint !== ""
                          ? this.state.cardPreview.hint
                          : ""}
                      </div>
                      <div className="flip-card-front-content-holder">
                        {this.state.cardPreview.imageName ? (
                          <div
                            className="flip-image-holder"
                            data-tip={this.state.cardPreview.hint}
                          >
                            {this.state.noImage ? (
                              <div className="tt-flashCardImageError">
                                FLASH<br></br>CARDS
                              </div>
                            ) : (
                              <img
                                src={
                                  URLs.imageSelectURL +
                                  this.state.cardPreview.imageName
                                }
                                alt="Card Preview"
                                onError={(e) => this.handleCardError(e)}
                              />
                            )}
                          </div>
                        ) : null}

                        <div
                          className="flashCard-question"
                          style={
                            this.state.cardPreview.imageName
                              ? null
                              : { fontSize: "36px" }
                          }
                        >
                          {this.state.cardPreview.question}
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back">
                      <div className="flip-card-back-content-holder">
                        {/* <div className="mt-2" style={{ fontSize: "18px" }}>
                          <strong>Answer: </strong>
                        </div>
                        <hr className="flash-hr"></hr> */}
                        <div>{this.state.cardPreview.answer}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flip-card flip-card-blank">Choose a Card</div>
              )}
            </div>
            <div className="col-md-3">
              <div className="tt-listCards">
                {this.state.selectedCards.length > 0 ? (
                  this.state.selectedCards.map((card, idx) => {
                    return (
                      <div
                        className="tt-flashCardThumb"
                        key={idx}
                        onClick={() => this.selectCard(card)}
                      >
                        <div>
                          <img
                            src={URLs.imageSelectURL + card.imageName}
                            alt="Card Preview"
                            onError={(e) => this.handleCardThumbError(e)}
                          ></img>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flash-selection-empty">No cards selected</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FlashCardTemplate;

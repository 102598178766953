import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  checkRolePermissions,
  draggable,
  recruitmentStatus,
  URL,
} from "../../../../utils/Constants";
import CreateCourseClass from "./CreateCourseClass";
import ListCourseClasses from "./ListCourseClasses";

class CourseClasses extends Component {
  state = {
    courseClasses: [],
    courseClassOffset: 0,
    courseClassCurrentPage: 1,
    courseClassTotalRecords: 0,
    courseClassRowCountLimit: 5,
    courseClassSpinner: false,
    classroomLocations: [],
    venue: "",
    activityType: "",
    activityName: "",
    date: "",
    startTime: "",
    endTime: "",
    recruitmentStatusId: "",
    recruitments: [],
    selectedRecruitment: [],
  };

  componentDidMount() {
    this.getCourseClasses();
    this.getAssignedRoom();
    this.getRecruitmentStatusByKey();
  }

  getRecruitmentStatusByKey = () => {
    let data = {
      code: recruitmentStatus.recruitmentCurrent,
    };
    axiosPost(URL.getRecruitmentStatusByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { recruitmentStatusId: response.data.data.id },
          function () {
            let data = {
              recruitmentStatusId: this.state.recruitmentStatusId,
            };
            this.getStaffList(data);
          }
        );
      }
    });
  };

  getStaffList = (data) => {
    axiosPost(URL.getStaffList, data, (response) => {
      if (response.status === 200) {
        let recruitments = [];
        let staffList = response.data.data;
        staffList.forEach((element) => {
          recruitments.push({
            value: element.id,
            label: element.people.name,
          });
        });

        this.setState({
          recruitments,
        });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  getAssignedRoom = () => {
    axiosPost(URL.roomSelect, {}, (response) => {
      if (response.status === 200) {
        this.setState({ classroomLocations: response.data.data });
      }
    });
  };

  getCourseClasses = () => {
    let data = {
      subjectId: this.props.subjectId,
      offset: this.state.courseClassOffset,
      rowCountLimit: this.state.courseClassRowCountLimit,
    };
    if (this.props.topicModal) {
      data.topicId = this.props.topicId;
    }
    this.setState({ courseClassSpinner: true }, function () {
      axiosPost(
        URL.getCourseClass,
        data,
        (response) => {
          if (response.status === 200) {
            this.setState({
              courseClasses: response.data.data,
              courseClassSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({ courseClassSpinner: false });
        }
      );
    });
  };

  createCourseClass = () => {
    if (
      this.state.activityType === "" ||
      this.state.activityName === "" ||
      this.state.startTime === "" ||
      this.state.endTime === "" ||
      this.state.venue === "" ||
      this.state.date === ""
    ) {
      swal({
        title: "Error",
        text: "Fill all the required fields first",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    }
    let recruitmentIds = [];
    if (this.state.selectedRecruitment.length > 0) {
      this.state.selectedRecruitment.forEach((el) => {
        recruitmentIds.push(el.value);
      });
    }
    let data = {
      activityName: this.state.activityName,
      activityType: this.state.activityType,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      date: this.state.date,
      venue: this.state.venue,
      subjectId: this.props.subjectId,
      recruitmentIds: recruitmentIds,
    };

    if (this.props.topicModal) {
      data.topicId = this.props.topicId;
    }
    axiosPost(URL.insertCourseClass, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Course Class successfully created",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.clearState();
        this.getCourseClasses();
      }
    });
  };

  clearState = () => {
    this.setState({
      courseClassOffset: 0,
      courseClassCurrentPage: 1,
      courseClassTotalRecords: 0,
      courseClassRowCountLimit: 5,
      courseClassSpinner: false,
      classroomLocations: [],
      venue: "",
      activityType: "",
      activityName: "",
      startTime: "",
      date: "",
      endTime: "",
      selectedRecruitment: [],
    });
  };

  handleRecruitmentChange = (selectedItem) => {
    this.setState({
      selectedRecruitment: selectedItem,
    });
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={
          this.props.detailView || this.props.courseWidget
            ? { height: "auto", maxHeight: "87.5vh", overflow: "auto" }
            : { height: "87.5vh" }
        }
      >
        <div className="tt-group-header">List of Classes</div>
        <ListCourseClasses
          courseClasses={this.state.courseClasses}
          courseClassOffset={this.state.courseClassOffset}
          courseClassCurrentPage={this.state.courseClassCurrentPage}
          courseClassTotalRecords={this.state.courseClassTotalRecords}
          courseClassRowCountLimit={this.courseClassRowCountLimit}
          courseClassSpinner={this.state.courseClassSpinner}
          detailView={this.props.detailView}
          courseWidget={this.props.courseWidget}
          getCourseClasses={this.getCourseClasses}
        ></ListCourseClasses>
        {this.props.detailView || this.props.courseWidget ? null : (
          <>
            {checkRolePermissions("can_create_program_class", "activity") ? (
              <>
                <div className="tt-group-header">Create a new Class</div>
                <CreateCourseClass
                  classroomLocations={this.state.classroomLocations}
                  venue={this.state.venue}
                  activityName={this.state.activityName}
                  activityType={this.state.activityType}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}
                  date={this.state.date}
                  handleChange={this.handleChange}
                  createCourseClass={this.createCourseClass}
                  clearState={this.clearState}
                  recruitments={this.state.recruitments}
                  handleRecruitmentChange={this.handleRecruitmentChange}
                  selectedRecruitment={this.state.selectedRecruitment}
                ></CreateCourseClass>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

export default CourseClasses;

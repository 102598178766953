import React, { Component } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import Comment from "../Notice/Comment";
import ModalWindow from "../../UI/ModalWindow";
import { displayNepaliDate } from "../../../utils/Utils";
import { postType, reactServer } from "../../../utils/Constants";
import MediaTemplate from "./MediaTemplate";
import ArticleTemplate from "./ArticleTemplate";

class DiscussionReadMoreTemplate extends Component {
  state = {
    posts: "",
    keywordModal: false,
  };

  // getInsitePOstData = () => {};

  displayKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.props.post
          ? this.props.post.postKeywords
            ? this.props.post.postKeywords.map((keyword, idx) => {
                return <li key={idx}>{keyword.keyword}</li>;
              })
            : null
          : null}
      </ol>
    );
  };

  // renderIfPostInsiteExist = () => {
  //   {
  //     return this.props.insertedInsitePostData ? (
  //       this.props.insertedInsitePostData.contents &&
  //       this.props.insertedInsitePostData.contents.length > 0 ? (
  //         <div className="row">
  //           <div className="col">
  //             <div className="tt-linkHolder">
  //               <div className="tt-group-header mb-0">Insite Post</div>

  //               <div>
  //                 {this.props.insertedInsitePostData.contents.map(
  //                   (content, idx) => {
  //                     if (
  //                       content.contentTypeCode === "LNK" &&
  //                       content.content !== "" &&
  //                       content.content.includes(reactServer)
  //                     ) {
  //                       return (
  //                         <>
  //                           <div className="tt-linkReference">
  //                             <a
  //                               href={content.content}
  //                               target="_blank"
  //                               rel="noopener noreferrer"
  //                               key={idx}
  //                             >
  //                               <div className="row text-left">
  //                                 <div className="col">
  //                                   <div className="row tt-videoLNK">
  //                                     {content.thumbnail ? (
  //                                       <div className="col-md-2 tt-videoLNKImage">
  //                                         <img
  //                                           alt="thumbnail"
  //                                           src={content.thumbnail}
  //                                         ></img>
  //                                       </div>
  //                                     ) : null}
  //                                     <div
  //                                       className="col"
  //                                       style={{
  //                                         display: "flex",
  //                                         alignItems: "Center",
  //                                         wordBreak: "break-all",
  //                                       }}
  //                                     >
  //                                       <p>
  //                                         {content.linkTitle !== ""
  //                                           ? content.linkTitle
  //                                           : content.content}
  //                                       </p>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                             </a>
  //                           </div>

  //                           {this.props.insertedInsitePostData.postType ===
  //                           postType.mediaCode ? (
  //                             <MediaTemplate
  //                               key={idx}
  //                               insitePost={true}
  //                               post={this.props.post}
  //                               insertedInsitePostData={
  //                                 this.props.insertedInsitePostData
  //                               }
  //                             />
  //                           ) : (
  //                             <ArticleTemplate
  //                               key={idx}
  //                               insitePost={true}
  //                               post={this.props.post}
  //                               insertedInsitePostData={
  //                                 this.props.insertedInsitePostData
  //                               }
  //                             />
  //                           )}
  //                         </>
  //                       );
  //                     }
  //                   }
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       ) : null
  //     ) : null;
  //   }
  // };

  render() {
    return (
      <>
        <div
          className={
            "container-fluid " + (this.props.moderation ? "autoHeight" : "")
          }
          id="noticeModalBody"
        >
          <div className="row mb-1">
            <div className="col">
              {this.props.moderation ? (
                <p className="mb-0">
                  <strong>{this.props.post.heading}</strong>
                </p>
              ) : null}
              <p className="tt-noticeModalDate">
                {this.props.post ? (
                  <span>
                    {moment(this.props.post.createdAt).format(
                      "dddd, MMMM Do YYYY"
                    )}{" "}
                    {displayNepaliDate(
                      this.props.post.createdAt,
                      "strDayOfWeek"
                    )}
                  </span>
                ) : null}
              </p>
            </div>
          </div>
          {/* <div className="tt-mobilePostKeywords">
            {this.props.post ? (
              this.props.post.postKeywords.length > 0 ? (
                <div className="row mb-3">
                  <div className="tt-postTargetList col">
                    {this.props.post
                      ? this.props.post.postKeywords.map((keyWord, idx) => {
                          return idx <= 3 ? (
                            keyWord.keyword !== "" ? (
                              <div key={idx}>{keyWord.keyword}</div>
                            ) : null
                          ) : idx ===
                            this.props.post.postKeywords.length - 1 ? (
                            <div
                              key={idx}
                              style={{ cursor: "pointer" }}
                              onClick={this.displayKeywordModal}
                            >
                              + {this.props.post.postKeywords.length - 4} more
                            </div>
                          ) : null;
                        })
                      : ""}
                  </div>
                </div>
              ) : null
            ) : null}
          </div> */}
          {/* <div className="tt-desktopPostKeywords">
            {this.props.post ? (
              this.props.post.postKeywords.length > 0 ? (
                <div className="row mb-3">
                  <div className="tt-postTargetList col">
                    {this.props.post
                      ? this.props.post.postKeywords.map((keyWord, idx) => {
                          return idx <= 11 ? (
                            keyWord.keyword !== "" ? (
                              <div key={idx}>{keyWord.keyword}</div>
                            ) : null
                          ) : idx ===
                            this.props.post.postKeywords.length - 1 ? (
                            <div
                              key={idx}
                              style={{ cursor: "pointer" }}
                              onClick={this.displayKeywordModal}
                            >
                              + {this.props.post.postKeywords.length - 12} more
                            </div>
                          ) : null;
                        })
                      : ""}
                  </div>
                </div>
              ) : null
            ) : null}
          </div> */}
          {/* {this.props.post ? (
            this.props.post.postThemes.length > 0 ||
            this.props.post.postQuestions.length > 0 ? (
              <div className="container-fluid tt-themeQuestionHolder">
                {this.props.post ? (
                  this.props.post.postThemes ? (
                    this.props.post.postThemes.length > 0 ? (
                      <div className="row">
                        <div className="col tt-theme-header">
                          {this.props.post.postThemes.map((theme, idx) => {
                            return (
                              <p key={idx}>
                                <strong>Theme : </strong>
                                {theme.themeName}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}
                {this.props.post ? (
                  this.props.post.postQuestions ? (
                    this.props.post.postQuestions.length > 0 ? (
                      <div className="row mt-1">
                        <div className="col tt-question-header">
                          <div className="mb-0">
                            Questions that this article addresses :{" "}
                          </div>
                          {this.props.post.postQuestions.map(
                            (question, idx) => {
                              return (
                                <>
                                  <p key={idx}>{question.question}</p>
                                  <span>, </span>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}
              </div>
            ) : null
          ) : null} */}
          <div className="row">
            <div className="col-12">
              <div className="row mt-3 mb-2">
                <div className="col-12 wordBreakHyphen editorPostContent">
                  {ReactHtmlParser(
                    this.props.post
                      ? this.props.post.textContent
                      : "No text content"
                  )}
                </div>
              </div>

              {/* {this.props.post ? (
                this.props.post.contents.length > 0 ? (
                  <div className="row">
                    <div className="col">
                      <div className="tt-linkHolder">
                        <div className="tt-group-header mb-0">
                          Link References
                        </div>

                        <div>
                          {this.props.post.contents.map((content, idx) => {
                            if (
                              content.contentTypeCode === "LNK" &&
                              content.content !== "" &&
                              // content.content.includes(reactServer) === false

                              content.origin !== "insitePost"
                            ) {
                              return (
                                <>
                                  <div className="tt-linkReference">
                                    <a
                                      href={content.content}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      key={idx}
                                    >
                                      <div className="row text-left">
                                        <div className="col">
                                          <div className="row tt-videoLNK">
                                            {content.thumbnail ? (
                                              <div className="col-md-2 tt-videoLNKImage">
                                                <img
                                                  alt="thumbnail"
                                                  src={content.thumbnail}
                                                ></img>
                                              </div>
                                            ) : null}
                                            <div
                                              className="col"
                                              style={{
                                                display: "flex",
                                                alignItems: "Center",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              <p>
                                                {content.linkTitle !== ""
                                                  ? content.linkTitle
                                                  : content.content}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">No links added</div>
                )
              ) : null} */}

              {/* {this.props.insertedInsitePostData
                ? this.renderIfPostInsiteExist()
                : null} */}
            </div>
          </div>
          {this.props.comments ? (
            this.props.comments.length > 0 ? (
              <div className="tt-noticeRSVP">
                <div className="tt-group-header text-center">Comments</div>
                <ul className="list-group">
                  {this.props.comments
                    ? this.props.comments.map((comment, idx) => {
                        return (
                          <Comment
                            key={comment.id}
                            comment={comment}
                            deleteComment={this.props.deleteComment}
                            toggleEditable={this.props.toggleEditable}
                            idx={idx}
                            newCommentValue={this.props.newCommentValue}
                            handleChange={this.props.handleChange}
                            updateComment={this.props.updateComment}
                            post={this.props.post}
                          />
                        );
                      })
                    : null}
                </ul>
              </div>
            ) : null
          ) : null}
        </div>
        <ModalWindow
          modal={this.state.keywordModal}
          toggleModal={this.displayKeywordModal}
          modalHeader="Keywords Assigned"
          modalBody={this.keywordModalBody()}
        ></ModalWindow>
      </>
    );
  }
}

export default DiscussionReadMoreTemplate;

import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ProfilePage from "./components/Profile/ProfilePage";
import NotFound from "./components/Error Page/NotFound";
import LoginPage from "./components/Login/LoginPage";
import AcademicYears from "./components/AcademicAdministration/AcademicYears/AcademicYears";
import SystemSetup from "./components/SystemSetup/SystemSetup";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ReportingConfiguration from "./components/AssessmentRecordReport/ReportingConfiguration/ReportingConfiguration";
import GroupPage from "./components/GroupPage/GroupPage";
import PermissionConfiguration from "./components/Permissions/PermissionConfiguration";
import FamilyPage from "./components/FamilyPage/FamilyPage";
import ArticleTemplate from "./components/Profile/Templates/ArticleTemplate";
import MediaTemplate from "./components/Profile/Templates/MediaTemplate";
import CreateMCQ from "./components/MCQ/CreateMCQ";
import ListMCQ from "./components/MCQ/ListMCQ/ListMCQ";
import UserManager from "./components/Permissions/UserManager";
import Attendance from "./components/Attendance/Attendance";
import { axiosPost } from "./utils/AxiosApi";
import { URL } from "./utils/Constants";
import ARRManager from "./components/Permissions/ARRManager";
import ModerationManager from "./components/Permissions/ModerationManager";
import ChangePassword from "./components/Login/ChangePassword";
import ReceiptTemplate from "./components/Accounting/ReceiptTemplate";
import DiscussionTemplate from "./components/Profile/Templates/DiscussionTemplate";
import SystemExamConfiguration from "./components/ExamModule/SystemExamConfiguration";
import ExamForm from "./components/ExamModule/ExamForm";
import SystemExamAssessment from "./components/ExamModule/SystemExamAssessment";
import OTPpage from "./components/Login/OTPpage";
import SchoolLMS from "./components/SchoolLMS/SchoolLMS";
import Template1 from "./components/CardsAndCertificates/CardTemplates/Template1";
import Template2 from "./components/CardsAndCertificates/CardTemplates/Template2";
import Template3 from "./components/CardsAndCertificates/CardTemplates/Template3";
import AuthorizedPage from "./components/Login/authorizedPage";
import DefaultSettings from "./components/Permissions/DefaultSettings";

class App extends Component {
  constructor() {
    super();
    let token = localStorage.getItem("token");
    if (token) {
      this.state = {
        isLoggedIn: true,
        systemSetup:
          localStorage.getItem("schoolSetup") === "true" ? true : false,
        academicYears:
          localStorage.getItem("academicYears") === "true" ? true : false,
      };
    } else {
      this.state = {
        isLoggedIn: false,
        systemSetup:
          localStorage.getItem("schoolSetup") === "true" ? true : false,
        academicYears:
          localStorage.getItem("academicYears") === "true" ? true : false,
      };
    }
  }

  componentDidMount() {
    this.state.isLoggedIn && this.checkSystemAcademicSetup();
  }

  checkSystemAcademicSetup = () => {
    axiosPost(URL.checkSystemAcademicSetup, {}, (response) => {
      if (response.status === 200) {
        let systemSetup = response.data.data.systemSetup,
          academicYears = response.data.data.academicYearSetup;
        localStorage.setItem("schoolSetup", systemSetup);
        localStorage.setItem("academicYears", academicYears);
        this.setState({ systemSetup, academicYears });
        // if (systemSetup && academicYear) {
        // } else if (systemSetup && !academicYear) {
        //   history.push(`${process.env.PUBLIC_URL}/academicYears`);
        // } else {
        //   history.push(`${process.env.PUBLIC_URL}/systemSetup`);
        // }
      }
    });
  };

  render() {
    return (
      <Switch>
        <PrivateRoute
          authed={this.state.isLoggedIn}
          path="/profile/:username"
          component={ProfilePage}
        />
        <PrivateRoute
          authed={this.state.isLoggedIn}
          path="/systemSetup"
          component={SystemSetup}
        />
        <PrivateRoute
          authed={this.state.isLoggedIn}
          path={`/academicYears`}
          component={AcademicYears}
        />
        <Route path="/otp" component={OTPpage} />
        <Route
          path="/profile"
          component={
            this.state.isLoggedIn &&
            this.state.systemSetup &&
            this.state.academicYears
              ? ProfilePage
              : this.state.isLoggedIn && !this.state.systemSetup
              ? SystemSetup
              : this.state.isLoggedIn &&
                this.state.systemSetup &&
                !this.state.academicYears
              ? AcademicYears
              : LoginPage
          }
        />
        <Route
          path="/"
          exact
          component={
            this.state.isLoggedIn &&
            this.state.systemSetup &&
            this.state.academicYears
              ? ProfilePage
              : this.state.isLoggedIn && !this.state.systemSetup
              ? SystemSetup
              : this.state.isLoggedIn &&
                this.state.systemSetup &&
                !this.state.academicYears
              ? AcademicYears
              : LoginPage
          }
        />
        <Route
          path="/permissionConfiguration"
          component={PermissionConfiguration}
        />
        <Route path="/usermanager" component={UserManager} />
        <Route path="/arrmanager" component={ARRManager} />
        <Route path="/moderation_manager" component={ModerationManager} />
        <Route path="/default_settings" component={DefaultSettings} />
        <Route path="/groupPage/:groupName" component={GroupPage}></Route>
        <Route path="/auth-redirect/" component={AuthorizedPage}></Route>
        <Route
          path="/post/article/:referenceId"
          component={ArticleTemplate}
        ></Route>
        <Route
          path="/post/discussion/:referenceId"
          component={DiscussionTemplate}
        ></Route>
        <Route
          path="/post/media/:referenceId"
          component={MediaTemplate}
        ></Route>
        <Route path="/familyPage/:familyId" component={FamilyPage}></Route>
        {/* <Route
          path="/reportingConfig"
          component={ReportingConfiguration}
        ></Route> */}
        {/* <Route path="/exam" component={SystemExamConfiguration}></Route> */}
        {/* <Route path="/takeexam" component={ExamForm}></Route> */}
        {/* <Route path="/assessexam" component={SystemExamAssessment}></Route> */}

        {/* <Route path="/attendance" component={Attendance}></Route> */}
        <Route path="/resetPassword/:userId" component={ChangePassword}></Route>
        <Route path="/slms" component={SchoolLMS}></Route>

        {/* <Route path="/template1" component={ReceiptTemplate}></Route> */}
        <Route path="/template1" component={ReceiptTemplate}></Route>
        <Route path="/cardTemplate" component={Template3}></Route>
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    );
  }
}

export default withRouter(App);

import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../utils/Constants";
import SubjectTopicSetting from "../LearningManagementSystem/SubjectSetting/SubjectTopicSetting";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";
import ModalWindow from "../UI/ModalWindow";

export default class CreateCourseSyllabus extends Component {
  state = {
    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: "",

    classrooms: [],
    selectedClassrooms: "",

    subjects: [],
    selectedSubjects: "",

    addCourseModal: "",
    permissionModal: false,
  };

  componentDidMount = () => {
    this.getEducationLevels();
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
            selectedEducationLevelId: data[0].id,
          });
      }
    });
  };

  handleEducationLevelChange = (e) => {
    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          selectedYearLevel: datum.yearLevels[0].id,

          classrooms: [],
          selectedClassrooms: "",

          subjects: [],
          selectedSubjects: "",
          selectedEducationLevelId: e.target.value,
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState({ selectedYearLevel: e.target.value });
    var data = {
      yearLevelId: e.target.value,
    };

    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.data.status === 200) {
        const datas = response.data.data;
        if (datas.length > 0)
          this.setState({
            classrooms: datas,
            selectedClassrooms: datas[0].id,
            subjects: [],
            selectedSubjects: "",
          });
      }
    });
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value });
    var data = {
      classroomId: e.target.value,
    };

    axiosPost(URL.getSubjectByClassroom, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            subjects: data,
            selectedSubjects: data[0].id,
          });
      }
    });
  };

  handleSubjects = (e) => {
    this.setState({
      selectedSubjects: e.target.value,
    });
  };

  handleAddCourse = (subject) => {
    this.setState({
      // selectedSubject: subject,
      addCourseModal: !this.state.addCourseModal,
    });
  };

  toggleAddCourseModal = () => {
    this.setState({ addCourseModal: !this.state.addCourseModal });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Create Course Plan
            {checkWidgetAdminOrNot("Course Plan") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-3 col-md">
                  <strong>Education Level</strong>
                  <select
                    className="form-control"
                    name="educationLevel"
                    // value={this.state.gradeId}
                    onChange={this.handleEducationLevelChange}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.educationLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Class</strong>
                  <select
                    className="form-control"
                    name="yearLevel"
                    // value={this.state.gradeId}
                    onChange={this.handleYearLevel}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.yearLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Section</strong>
                  <select
                    className="form-control"
                    name="sectionId"
                    // value={this.state.selectedClassrooms}
                    onChange={this.handleSectionChange}
                  >
                    <option value="" selected isabled>
                      Select
                    </option>
                    {this.state.classrooms.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Subject: </strong>
                  <select
                    className="form-control"
                    name="subjectId"
                    // value={this.state.selectedSubjects}
                    onChange={this.handleSubjects}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select Subjects
                    </option>
                    {this.state.subjects.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md-2">
                  <button
                    className="mt-4 mx-auto d-block tt-button tt-button-primary"
                    onClick={() => this.handleAddCourse("subject")}
                    disabled={this.state.selectedSubjects === "" ? true : false}
                  >
                    Add Course
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state.addCourseModal}
          toggleModal={this.toggleAddCourseModal}
          size="xl"
          modalHeader="Add New Course Plan for Subject"
          modalBody={
            <SubjectTopicSetting
              selectedEducationLevelId={this.state.selectedEducationLevelId}
              selectedYearLevel={this.state.selectedYearLevel}
              selectedClassrooms={this.state.selectedClassrooms}
              selectedSubjects={this.state.selectedSubjects}
              academicRelated={true}
              toggleAddCourseModal={this.toggleAddCourseModal}
            ></SubjectTopicSetting>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Course Plan"
              moduleName="Course Syllabus"
              header="Add Courses syallabus configuration"
              activityName="insert-course-syllabus"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

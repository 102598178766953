import React, { Component } from "react";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "./../UI/ModalWindow";
import GranularPermissionModal from "./../Permissions/GranularPermissionModal";
import swal from "sweetalert";
import moment from "moment";
import DatePicker from "./../UI/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBook,
  faCalendar,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import { data } from "jquery";

class LeaveRequestList extends Component {
  render() {
    return (
      <>
        {this.props.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Creating Academic Year
            </div>
          </div>
        ) : null}
        <div className="row mb-4">
          <div className="col-md-2">
            <label>From Date</label>
            <input
              type="date"
              className="form-control"
              value={this.props.leaveFrom}
              name="leaveFrom"
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
          <div className="col-md-2">
            <label>To Date</label>
            <input
              type="date"
              className="form-control"
              value={this.props.leaveTo}
              min={this.props.leaveFrom}
              name="leaveTo"
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
          <div className="col-md-2">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              value={this.props.name}
              name="name"
              placeholder="Name"
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
          <div className="col-md-2">
            <label>Status</label>

            <select
              name="status"
              className="form-control"
              value={this.props.status}
              onChange={(e) => this.props.handleChange(e)}
            >
              <option selected value="">
                Choose status
              </option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="pending">Pending</option>
            </select>
          </div>
          <div className="col-md-3 mt-4">
            <button
              className="tt-button tt-button-primary"
              onClick={() => this.props.getAllLeaveList()}
            >
              Search
            </button>
          </div>
        </div>
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>SN.</th>
              <th>Applied By</th>
              <th>Leave From</th>
              <th>Leave To</th>
              <th>Duration (Days)</th>
              <th>Leave Type</th>
              <th>Subject</th>
              {/* <th>Description</th> */}
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ fontWeight: "normal", textShadow: "none" }}>
            {this.props.leaveLists?.length > 0 ? (
              this.props.leaveLists.map((data, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>
                    {data?.parentPeople
                      ? data.parentPeople.name
                      : data.people?.name}
                  </td>
                  <td>{moment(data?.fromDate).format("YYYY-MM-DD")}</td>
                  <td>{moment(data?.toDate).format("YYYY-MM-DD")}</td>
                  <td>
                    {this.props.getDateDiff(data?.fromDate, data?.toDate) + 1}{" "}
                    {this.props.getDateDiff(data?.fromDate, data?.toDate) === 1
                      ? "Day"
                      : "Days"}
                  </td>
                  <td>{data?.leaveTypeName}</td>
                  <td>{data?.subject}</td>
                  {/* <td>{data.description}</td> */}
                  <td className="text-capitalize">{data?.status}</td>
                  <td>
                    <button
                      className="tt-button tt-button-primary"
                      onClick={() => this.props.handleLeaveDetailModal(data)}
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan={9}>No records to display</td>
              </tr>
            )}
          </tbody>
        </table>
        {/* leave request reject modal start */}
        <ModalWindow
          modal={this.props.remarkLeaveModal}
          // size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              remarkLeaveModal: !this.props.remarkLeaveModal,
            });
          }}
          modalHeader={"Remarks"}
          modalBody={
            <>
              <textarea
                className="form-control"
                name="remarks"
                placeholder="Remarks"
                onChange={this.props.handleChange}
                value={this.props.remarks}
              ></textarea>
              <button
                className="tt-button tt-button-primary mt-2"
                onClick={() => this.props.handleLeaveStatusChange()}
              >
                Submit
              </button>
            </>
          }
        ></ModalWindow>
        {/* leave request reject modal end */}
        {/* leave request details start */}
        {/* <ModalWindow
          modal={this.props.leaveDetailModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              leaveDetailModal: !this.props.leaveDetailModal,
            });
          }}
          modalHeader={`Detail: ${"Unwell"}`}
          modalBody={
            this.props.leaveDetailData !== "" && (
              <div className="p-3">
                <div class="row d-flex justify-content-between">
                  <div class="col-md-3 d-flex justify-content-between">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          style={{ color: "#243C57" }}
                        />{" "}
                        Applied by
                      </small>{" "}
                      <br />
                      {this.props.leaveDetailData?.parentPeople !== "" ? (
                        <b className="text-primary">
                          {this.props.leaveDetailData?.parentPeople.name}
                        </b>
                      ) : (
                        <b className="text-primary">
                          {this.props.leaveDetailData?.people.name}
                        </b>
                      )}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <small className="text-secondary">
                        {" "}
                        <FontAwesomeIcon icon={faBook} /> Subject
                      </small>{" "}
                      <br />
                      {this.props.leaveDetailData?.subject}
                    </p>
                  </div>
                  <div class="col-md-3 d-flex justify-content-between">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{ color: "gray" }}
                        />{" "}
                        From
                      </small>{" "}
                      <br />
                      {moment(this.props.leaveDetailData?.fromDate).format(
                        "YYYY-MM-DD"
                      )}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{ color: "gray" }}
                        />{" "}
                        To
                      </small>{" "}
                      <br />
                      {moment(this.props.leaveDetailData?.toDate).format(
                        "YYYY-MM-DD"
                      )}
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faBook}
                          style={{ color: "gray" }}
                        />{" "}
                        Description
                      </small>{" "}
                      <br />
                      {this.props.leaveDetailData?.description}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          style={{ color: "gray" }}
                        />{" "}
                        Additional Details
                      </small>{" "}
                      <br />
                      {this.props.leaveDetailData?.additionalDescription}
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <p>
                      <small className="text-secondary">Status</small> <br />
                      {this.props.leaveDetailData?.status === "accepted" ? (
                        <b className="text-capitalize text-success">
                          {this.props.leaveDetailData?.status}
                        </b>
                      ) : this.props.leaveDetailData?.status === "rejected" ? (
                        <b className="text-capitalize text-danger">
                          {this.props.leaveDetailData?.status}
                        </b>
                      ) : (
                        <b className="text-capitalize">
                          {this.props.leaveDetailData?.status}
                        </b>
                      )}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p>
                      <small className="text-secondary">Remarks</small> <br />
                      <p className="">This is remarsks</p>
                    </p>
                  </div>
                </div>
              </div>
            )
          }
        ></ModalWindow> */}
        {/* leave request details end */}
      </>
    );
  }
}
export default LeaveRequestList;

import React, { Component } from "react";
import { checkRolePermissions } from "../../../utils/Constants";

class CalendarEventList extends Component {
  state = {
    updatePermission: false,
    deletePermission: false,
  };

  componentDidMount() {
    this.setState({
      updatePermission: checkRolePermissions(
        "update-calendar-event-type",
        "activity"
      ),
      deletePermission: checkRolePermissions(
        "delete-calendar-event-type",
        "activity"
      ),
    });
  }

  render() {
    return (
      <>
        <div style={{ marginTop: "10px" }}>
          <div className="tt-newListBox" style={{ height: "43.6vh" }}>
            <div className="container">
              <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                  <th>Event Type</th>
                  <th>Color Code</th>
                  <th>isHoliday</th>
                  {this.state.updatePermission ||
                  this.state.deletePermission ? (
                    <th>Options</th>
                  ) : null}
                </thead>
                <tbody>
                  {this.props.holidaysOption.length > 0 ? (
                    this.props.holidaysOption.map((el, id) => {
                      return (
                        <tr key={el.colorCode} id={el.colorCode}>
                          <td>
                            {el.edit ? (
                              <input
                                type="text"
                                className="form-control"
                                name="updateEventName"
                                value={this.props.updateEventName}
                                onChange={this.props.handleChange}
                              />
                            ) : (
                              el.eventTypeName
                            )}
                          </td>
                          <td>
                            <div
                              className="calendarEventColorBox"
                              style={{ background: el.colorCode }}
                            ></div>
                          </td>
                          <td>
                            {el.edit ? (
                              <input
                                type="checkbox"
                                name="updateIsHoliday"
                                checked={this.props.updateIsHoliday}
                                onChange={this.props.handleChange}
                              />
                            ) : el.isHoliday ? (
                              "true"
                            ) : (
                              "false"
                            )}
                          </td>
                          {this.state.updatePermission ||
                          this.state.deletePermission ? (
                            <td>
                              {el.edit ? (
                                <>
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={this.props.updateEvent}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="tt-button tt-button-danger ml-1"
                                    onClick={this.props.cancelUpdate}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  {this.state.updatePermission ? (
                                    <button
                                      className="tt-button tt-button-primary"
                                      onClick={() =>
                                        this.props.handleUpdate(el)
                                      }
                                    >
                                      Update
                                    </button>
                                  ) : null}
                                  {this.state.deletePermission ? (
                                    <button
                                      className="tt-button tt-button-danger ml-1"
                                      onClick={() =>
                                        this.props.handleDelete(el.colorCode)
                                      }
                                    >
                                      Delete
                                    </button>
                                  ) : null}
                                </>
                              )}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <td
                      colSpan={
                        this.state.updatePermission ||
                        this.state.deletePermission
                          ? 4
                          : 3
                      }
                      className="text-center"
                    >
                      No Event to display
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CalendarEventList;

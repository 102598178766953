import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { displayMessage } from "../../utils/Utils";
import { draggable, URL } from "../../utils/Constants";
import history from "../../utils/History";
import swal from "sweetalert";
import Swal from "sweetalert2";

export default class ChangePassword extends Component {
  state = {
    password: null,
    confirmPassword: null,
    // user:this.props.location.pathname ?
  };

  // componentDidMount = () => {
  //   if (
  //     this.props.match !== undefined &&
  //     this.props.match.path === "/resetPasword/:param"
  //   ) {
  //   }
  // };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validatePassword = () => {
    let error = 0;
    // let validateRegex = /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
    const validateRegex = "^[a-zA-Z0-9]*$";
    let ps = this.state.password;
    if (ps && (ps !== "" || ps !== null)) {
      if (ps.match(validateRegex)) {
        error = 0;
      } else {
        error++;
      }
    }
    if (error > 0 || ps.length < 8) {
      let texts = `<p>Password should have<br><ol><li>At least 8 characters</li><li>Alphanumeric characters</li></ol></p>`;
      Swal.fire({
        title: "Error",
        html: texts,
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      //   this.setState({ password: null });
    }
  };

  handleChangePassword = () => {
    if (this.state.password !== null && this.state.confirmPassword !== null) {
      if (this.state.password === this.state.confirmPassword) {
        let url = "";
        if (
          this.props.location &&
          this.props.location.pathname !== `/resetPassword/:userId`
        ) {
          url = URL.updatePasswordViaEmail;
        } else {
          url = URL.updatePassword;
        }
        let params = {
          password: this.state.password,
          username:
            this.props.location &&
            this.props.location.pathname !== `/resetPassword/:userId`
              ? this.props.location.pathname.split("/")[3]
              : "",
        };

        axiosPost(url, params, (response) => {
          if (response.status === 200) {
            displayMessage(
              "Success",
              `Password changed.
                Login with your new password.`,
              () => {
                localStorage.clear();
                // localStorage.setItem(
                //   "user",
                //   CryptoJS.AES.encrypt(
                //     JSON.stringify(),
                //     "saurav_imagine_sys"
                //   )
                // );

                history.push(`${process.env.PUBLIC_URL}/`);
              }
            );
          } else {
            displayMessage(
              "Error",
              "There was an error updating your password. Please try again."
            );
          }
        });
      } else {
        swal({
          title: "Error",
          text: "New Password and Confirm Password do not match. Please re-check and submit again.",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      }
    } else {
      displayMessage("Error", "Please enter passwords on both fields.");
    }
  };
  render() {
    return (
      <div>
        {/* <div className="row mb-2">
          <div className="col-md-4">
            <label>
              <strong>Old password</strong>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="password"
              name="oldPassword"
              className="form-control"
            />
          </div>
        </div> */}

        {this.props.location &&
        this.props.location.pathname !== "/resetPassword/:userId" ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-3"></div>
                  <div
                    className="col-md-6"
                    style={{
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      boxShadow: "2px 3px 3px rgba(0,0,0,0.33)",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="row">
                      <div className="col text-center">
                        <div
                          className="tt-group-header mb-3"
                          style={{ borderRadius: 0 }}
                        >
                          Password Change for
                          {this.props.location.pathname.split("/")[3]}
                        </div>
                      </div>
                    </div>
                    <div className="  row">
                      <div className="col-md-4">
                        <label>
                          <strong>New password: </strong>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          id="pass"
                          type="password"
                          name="password"
                          value={this.state.password}
                          className="form-control"
                          onChange={this.handleChange}
                          onBlur={this.validatePassword}
                        />
                      </div>
                    </div>
                    <div className="  row mt-2 ">
                      <div className="col-md-4">
                        <label>
                          <strong>Confirm password: </strong>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col text-right">
                        <button
                          className="tt-button tt-button-primary change-password"
                          onClick={this.handleChangePassword}
                        >
                          Update password
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mb-2">
              <div className="col-md-4">
                <label>
                  <strong>New password</strong>
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.handleChange}
                  onBlur={this.validatePassword}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label>
                  <strong>Confirm password</strong>
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row mt-1 mb-2">
              <div className="col text-right">
                <button
                  className="tt-button tt-button-primary change-password"
                  onClick={this.handleChangePassword}
                >
                  Change password
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

import React, { Component } from "react";
import "../../../assets/scss/cardTemplate.scss";
import download from "../../../assets/images/cardTemplates/download.png";
import schoolLogo from "../../../assets/images/logo/logo2.png";
import signature from "../../../assets/images/cardTemplates/signature.PNG";
import { URL } from "../../../utils/Constants";

class Template1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="cardTemplateContainer">
        <div className="padding">
          <div className="front">
            <div className="top">
              <img
                src={this.props.userImage ? this.props.userImage : download}
              />
            </div>
            <div className="bottom">
              <p>
                {this.props.studentName
                  ? this.props.studentName
                  : "People Name"}
              </p>
              <p className="desi">
                {this.props.subtitle
                  ? this.props.subtitle
                  : "Class - Section / Designation"}
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <div className="schoolInfo">
                  <div className="barcode">
                    <img
                      src={
                        this.props.schoolLogo
                          ? this.props.schoolLogo
                          : schoolLogo
                      }
                    />
                  </div>
                  <p className="no schoolCardName">
                    {this.props.schoolName
                      ? this.props.schoolName
                      : "School Name"}
                  </p>
                  <p className="no schoolCardAddress">
                    {this.props.schoolAddress
                      ? this.props.schoolAddress
                      : "School Address"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="back">
          <div className="details-info">
            <p>
              <b>Emergency Contact: </b>
            </p>
            <p>
              {this.props.emergencyContact
                ? this.props.emergencyContact.name
                : "Emergency Contact Name"}
            </p>
            <p>
              {this.props.emergencyContact
                ? this.props.emergencyContact.contactNumber
                : "Emergency Contact Number"}
            </p>
            <div className="signatureBox">
              <div className="signatureDiv">
                <img
                  src={this.props.signature ? this.props.signature : signature}
                />
              </div>
              <p>_______________________</p>
              {/* <div className="idSignature idSignatureHolder"><img src = {this.props?.authorizedSignature ? this.props?.authorizedSignature : 'Authorized Signature'}/></div> */}
              <p className="text-center">
                {this.props.authorizedSignatureName || "Sample signature Name"}
              </p>
            </div>
          </div>
          <div className="schoolData">
            <p className="validDateCss">
              <strong>Issued Date: </strong>{" "}
              {this.props.issuedDate ? this.props.issuedDate : "Date"}
            </p>
            <p className="validDateCss mb-1">
              <strong>Valid Until: </strong>{" "}
              {this.props.validityDate ? this.props.validityDate : "Date"}
            </p>
            <div className="barcode">
              <img
                src={this.props.schoolLogo ? this.props.schoolLogo : schoolLogo}
              />
            </div>
            <div style={{ display: "inline-block" }}>
              <small className="webAddress" style={{ display: "inline-block" }}>
                {this.props.schoolWebAddress ? this.props.schoolWebAddress : ""}
              </small>
              <small className="webAddress" style={{ display: "inline-block" }}>
                {this.props.schoolContact
                  ? this.props.schoolContact
                  : "Tel: Contact Number"}
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Template1;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ShowCardDetails from "./ShowCardDetails";
import * as htmlToImage from "html-to-image";

const ListCards = () => {
  const [userTypeOptions, setUserTypeOptions] = useState([
    {
      label: "Student",
      value: 1
    },
    {
      label: "Staff",
      value: 2
    }
  ]);
  const [userType, setUserType] = useState();
  const [academicLevelOption, setAcademicLevelOption] = useState();
  const [academicLevelValue, setAcademicLevelValue] = useState();
  const [classOption, setClassOptions] = useState();
  const [classOptionValue, setClassOptionValue] = useState();
  const [sectionOption, setSectionOption] = useState();
  const [sectionOptionValue, setSectionOptionValue] = useState();
  const [cardDetails, setCardDetails] = useState();
  const [schoolDetails, setSchoolDetails] = useState();
  const [staffList, setStaffList] = useState("");
  const [studentList, setStudentList] = useState("");

  const handleUserType = e => {
    setUserType(e);
  };

  const handleAcademicLevelChange = e => {
    setAcademicLevelValue(e);
  };

  const handleClassChange = e => {
    setClassOptionValue(e);
  };

  const handleSectionChange = e => {
    setSectionOptionValue(e);
  };

  const getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, response => {
      const options = response.data.data;
      setClassOptions(options);
      const acadamicOptions = options.map(item => ({
        label: item.name,
        value: item.id
      }));
      setAcademicLevelOption(acadamicOptions);

      const classOptionsValue = options.map(items =>
        items.yearLevels.map(item => ({
          label: item.name,
          value: item.id
        }))
      );
      setClassOptions(classOptionsValue);
    });
  };

  const getAllIdCardData = () => {
    const Params = {
      cardType:
        userType?.value === 1 ? "student" : userType?.value ? "staff" : ""
    };
    axiosPost(URL.getAllIdCard, Params, response => {
      if (response.status === 200) {
        setCardDetails(response.data.data);
      }
    });
  };

  const getAllSections = param => {
    const Params = {
      yearLevelId: param.value
    };
    axiosPost(URL.selectClassroom, Params, response => {
      const options = response.data.data;
      const optionVal = options.map(item => ({
        label: item.name,
        value: item.id
      }));
      setSectionOption(optionVal);
    });
  };

  const getSchoolDetails = () => {
    axiosPost(URL.getSchoolInfo, {}, response => {
      setSchoolDetails(response.data.data);
    });
  };

  const getAllStaffList = () => {
    const Params={
      status :'current'
    }
    axiosPost(URL.getStaffList, Params, response => {
      if (response.status === 200) {
        setStaffList(response.data.data);
        setStudentList("");
      }
    });
  };

  const getAllStudent = data => {
    const Params = {
      status: "current",
      yearLevelId: data?.value
    };
    axiosPost(URL.getStudentList, Params, response => {
      if (response.status) {
        setStudentList(response?.data?.data);
        setStaffList("");
      }
    });
  };

  useEffect(() => {
    getEducationLevel();
    getSchoolDetails();
  }, []);

  useEffect(() => {
    if (userType?.value) {
      getAllIdCardData();
    }
    if (userType?.value === 2) {
      getAllStaffList();
    }
  }, [userType?.value]);



  const handleDownloadCard = async () => {
    await htmlToImage
      .toJpeg(document.getElementById("downloadCard"), {
        quality: 1.0,
        backgroundColor: "white",
      })
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = "download.jpeg";
        link.href = dataUrl;
        link.click();

      });
    
  };

  useEffect(() => {
    if (classOptionValue) {
      getAllSections(classOptionValue);
      getAllStudent(classOptionValue);
    }
  }, [classOptionValue]);

  return (
    <>
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="row mt-2 ">
          <div className="col-md-3">
            <Select
              options={userTypeOptions}
              onChange={e => handleUserType(e)}
              value={userType}
            />
          </div>
          {userType?.value === 1 ? (
            <>
              <div className="col-md-3">
                <Select
                  options={academicLevelOption}
                  onChange={e => handleAcademicLevelChange(e)}
                  value={academicLevelValue}
                />
              </div>

              <div className="col-md-3">
                <Select
                  options={
                    academicLevelValue?.value === 1
                      ? classOption[0]
                      : "" || academicLevelValue?.value === 2
                      ? classOption[1]
                      : "" || academicLevelValue?.value === 3
                      ? classOption[2]
                      : ""
                  }
                  onChange={e => handleClassChange(e)}
                  value={classOptionValue}
                />
              </div>
              
            </>
          ) : (
            ""
          )}
        </div>

        <div className="container payment-print">
          <div className="row">
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary ml-3"
                onClick={() => handleDownloadCard()}
              >
                Download
              </button>
            </div>
          </div>
        </div>
        <div id="downloadCard">
          <ShowCardDetails
            cardDetails={cardDetails}
            userType={userType}
            sectionOptionValue={sectionOptionValue}
            schoolDetails={schoolDetails}
            staffList={staffList}
            studentList={studentList}
            classValue={classOptionValue}
          />
        </div>
      </div>
    </>
  );
};

export default ListCards;

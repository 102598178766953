/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, YearLevelAcademicLevel, render the select field for the year levels and academic levels
 */

import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";

class YearLevelAcademicLevel extends Component {
  state = {
    yearLevels: [],
    academicLevels: [],
  };

  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches all the Admission Configuration
   * from the database.
   *
   */
  componentDidMount() {
    axiosPost(URL.getAcademicYears, {}, (response) => {
      if (response.status === 200) {
        let academicLevels = response.data.data;
        this.setState({
          academicLevels,
        });
      }
    });
    this.getYearLevels();
  }

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        let yearLevels = response.data.data;
        this.setState({
          yearLevels,
        });
      }
    });
  };

  render() {
    let errors = this.props.errors;
    return (
      <>
        <div className="tt-group-header">
          {localStorage.getItem("yearLevel")} and Academic Level
        </div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="yearLevel">
              <strong>{localStorage.getItem("yearLevel")}</strong>
            </label>
          </div>
          <div className="col-md-7">
            <select
              name="yearLevel"
              onChange={this.props.handleChange}
              value={this.props.yearLevel}
              className={
                errors.yearLevel
                  ? "form-control indicate-error"
                  : "form-control"
              }
            >
              <option value={""} selected disabled>
                Choose a year level
              </option>
              {this.state.yearLevels.map((yearLevel, idx) => {
                return (
                  <option value={yearLevel.id} key={idx}>
                    {yearLevel.name}
                  </option>
                );
              })}
            </select>
          </div>
          {/* <div className="col-md-1">
            {errors.yearLevel ? (
              <span className="error-text">mandatory</span>
            ) : null}
          </div> */}
        </div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="academicLevel">
              <strong>Academic level</strong>
            </label>
          </div>
          <div className="col-md-7">
            <select
              name="academicLevel"
              id="academicLevel"
              className={
                errors.academicLevel
                  ? "form-control indicate-error"
                  : "form-control"
              }
              onChange={this.props.handleChange}
              value={this.props.academicLevel}
            >
              <option value="" selected disabled>
                Choose an academic level
              </option>
              {this.state.academicLevels.map((academicLevel, idx) => {
                return (
                  <option value={academicLevel.id} key={idx}>
                    {academicLevel.name}
                  </option>
                );
              })}
            </select>
          </div>
          {/* <div className="col-md-1">
            {errors.academicLevel ? (
              <span className="error-text">mandatory</span>
            ) : null}
          </div> */}
        </div>
      </>
    );
  }
}

export default YearLevelAcademicLevel;

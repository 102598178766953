import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { draggable, URL } from "../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import CourseClassResources from "../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseResourceTemplate";
import ModalWindow from "../UI/ModalWindow";
import CourseSyllabusDetailReRender from "./CourseSyllabusDetailReRender";
import CourseSyllabusResource from "./CourseSyllabusResource";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export class ARRCoursePlan extends Component {
  state = {
    courseSyllabus: [],

    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: "",

    classrooms: [],
    selectedClassrooms: "",

    subjects: [],
    selectedSubjects: "",

    syllabusDetailModal: false,
    topicList: "",

    updateSyllabusDetailModal: false,
    updateTopicName: "",
    updateTopicLectureHour: "",
    updateTopicPracticalHour: "",

    syllabusResourcesModal: false,
    fileModal: false,
    selectedSubjectResources: "",

    coursePlanId: "",
    coursePlanSyllabusId: "",
    viewSpinner: false,
  };

  componentDidMount = () => {
    this.setState({ assessmentModuleCode: this.props.code }, function () {
      this.getEducationLevel();
    });
    // this.getCourseSyllabus();
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getCourseSyllabus = () => {
    this.setState({ courseSyllabus: [] });
    let filter = {
      educationLevelId: this.state.selectedEducationLevelId,
      yearlevelId: this.state.selectedYearLevel,
      classroomId: this.state.selectedClassrooms,
      subjectId: this.state.selectedSubjects,
    };

    if (filter.yearlevelId !== "" && filter.classroomId !== "") {
      this.setState(
        {
          courseSyllabus: [],
          viewSpinner: true,
        },
        () => {
          axiosPost(URL.getCoursePlan, filter, (response) => {
            if (response.data.status === 200) {
              const data = response.data.data;
              if (data.length > 0) {
                this.setState({
                  courseSyllabus: data,
                  viewSpinner: false,
                });
              } else {
                this.setState({
                  courseSyllabus: [],
                  viewSpinner: false,
                });
              }
            }
          });
        }
      );
    } else {
      swal(
        "Error",
        `${localStorage.getItem("yearLevel")} and Section is mandatory`
      );
    }
  };

  handleSyllabusPlanUpdate = (id) => {};

  getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        let yearLevels = [];
        if (data.length > 0)
          yearLevels = data.filter(
            (dt) => dt.assessmentModuleCode === this.state.assessmentModuleCode
          );
        this.setState({
          educationLevels: data,
          yearLevels: yearLevels[0].yearLevels || null,
        });
      }
    });
  };
  // getEducationLevel = () => {
  //   axiosPost(
  //     URL.getEducationLevelByAssessmentModule,
  //     { code: this.state.assessmentModuleCode },
  //     (response) => {
  //       if (response.status === 200) {
  //         this.setState(
  //           {
  //             educationLevels: response.data.data,
  //             // selectedEducationLevelId: response.data.data[0].id,
  //           },
  //           () => {
  //             //   this.handleEducationLevelChange();
  //           }
  //         );
  //       }
  //       if (response.status === 204) {
  //         this.setState({ educationLevels: [] });
  //       }
  //     }
  //   );
  // };

  handleEducationLevelChange = (e) => {
    this.setState({ selectedEducationLevelId: e.target.value }, () => {
      this.getCourseSyllabus();
    });
    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          selectedYearLevel: "",

          classrooms: [],
          selectedClassrooms: "",

          subjects: [],
          selectedSubjects: "",
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState(
      { selectedYearLevel: e.target.value, selectedClassrooms: "" },
      () => {
        // this.getCourseSyllabus();
      }
    );
    var data = {
      yearLevelId: e.target.value,
    };

    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.data.status === 200) {
        const datas = response.data.data;
        if (datas.length > 0)
          this.setState({
            classrooms: datas,
            // selectedClassrooms: datas[0].id,
            subjects: [],
            selectedSubjects: "",
          });
      }
    });
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value }, () => {
      // this.getCourseSyllabus();
    });

    var data = {
      classroomId: e.target.value,
    };

    axiosPost(URL.getSubjectByClassroom, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState(
            {
              subjects: data,
              // selectedSubjects: data[0].id,
            },
            () => {
              // this.getCourseSyllabus();
            }
          );
      }
    });
  };

  handleSubjects = (e) => {
    this.setState(
      {
        selectedSubjects: e.target.value,
      },
      () => {
        // this.getCourseSyllabus();
      }
    );
  };

  handleSyllabusDetailModal = (sub) => {
    this.setState({
      topicList: sub,
      syllabusDetailModal: !this.state.syllabusDetailModal,
    });
  };

  toggleSyllabusDetailModal = () => {
    this.setState({ syllabusDetailModal: !this.state.syllabusDetailModal });
  };

  // tigger subject resource modal
  subjectResourceModal = (param) => {
    this.setState({ coursePlanId: param }, this.toggleSyllabusResourcesModal());
  };

  // View subject resource modal
  viewSubjectResourceModal = (id) => {
    const data = {
      coursePlanId: id,
    };
    axiosPost(URL.getCoursePlanResources, data, (response) => {
      if (response.data.status === 200) {
        this.setState(
          {
            selectedSubjectResources: response.data.data,
          },
          () => {
            this.toggleFileModal();
          }
        );
      }
    });
  };

  //tigger syllabus wise resource modal
  syllabusResourceModal = (param) => {
    this.setState(
      { coursePlanSyllabusId: param.id, coursePlanId: param.coursePlanId },
      this.toggleSyllabusResourcesModal()
    );
  };

  //toggle resources modal
  toggleSyllabusResourcesModal = () => {
    this.setState({
      syllabusResourcesModal: !this.state.syllabusResourcesModal,
    });
  };

  toggleSyllabusResourcesModalView = (param) => {
    let data = {
      coursePlanSyllabusId: param.id,
      coursePlanId: param.coursePlanId,
    };
    axiosPost(URL.getCoursePlanResources, data, (res) => {
      if (res.status == 200) {
        this.setState({ selectedSubjectResources: res.data.data }, () => {
          this.toggleFileModal();
        });
      }
    });
  };

  toggleFileModal = () => {
    this.setState({
      fileModal: !this.state.fileModal,
    });
  };

  handleExportPdf = async (idx) => {
    document.getElementById("btnExport" + idx).style.display = "none";
    const element = document.getElementById("collapse" + idx);

    const canvas = await html2canvas(element);
    document.getElementById("btnExport" + idx).style.display = "block";
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 10, 10, pdfWidth, pdfHeight);

    pdf.save("course_plan.pdf");
  };

  render() {
    return (
      <div>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">List Course Plan</div>
          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-3 col-md">
                  <strong>{localStorage.getItem("yearLevel")}</strong>
                  <select
                    className="form-control"
                    name="yearLevel"
                    onChange={this.handleYearLevel}
                    disabled={false}
                  >
                    <option
                      value=""
                      selected={
                        this.state.selectedYearLevel == "" ? true : false
                      }
                      disabled={
                        this.state.selectedYearLevel == "" ? false : true
                      }
                    >
                      Select
                    </option>
                    {this.state.yearLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Section</strong>
                  <select
                    className="form-control"
                    name="sectionId"
                    onChange={this.handleSectionChange}
                    value={this.state.selectedClassrooms}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {this.state.classrooms.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Subject: </strong>
                  <select
                    className="form-control"
                    name="subjectId"
                    onChange={this.handleSubjects}
                    // disabled={false}
                    value={this.state.selectedSubjects}
                  >
                    <option value="" selected>
                      Select Subjects
                    </option>
                    {this.state.subjects.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-3 mt-4">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.getCourseSyllabus}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="container mt-4">
                <div>
                  {this.state.courseSyllabus?.length > 0 ? (
                    this.state.courseSyllabus.map((cs, index) => (
                      <div
                      // className="tt-widgetContent-tab-holder"
                      // style={{ height: "87.5vh" }}
                      >
                        <div className="row tt-group-header">
                          <div className="col-md-10">
                            Course Syllabus ({cs.subjectName})
                          </div>

                          {/* <div className="col-md-2">
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() => this.handleExportPdf(index)}
                            >
                              Export as PDF
                            </button>
                          </div> */}
                          <div className="col-md-1">
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() =>
                                this.viewSubjectResourceModal(cs.id)
                              }
                            >
                              View
                            </button>
                          </div>

                          <div className="col-md-1 text-right">
                            <button
                              className="tt-button tt-button-primary"
                              data-toggle="collapse"
                              data-target={`#collapse${index}`}
                              aria-expanded="false"
                              aria-controls={`#collapse${index}`}
                              type="button"
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="collapse mb-3" id={`collapse${index}`}>
                          <div className="text-right mb-2">
                            <button
                              id={`btnExport${index}`}
                              className="tt-button tt-button-primary"
                              onClick={() => this.handleExportPdf(index)}
                            >
                              Export as PDF
                            </button>
                          </div>
                          <div className="py-2 text-center">
                            <h3> {cs.subjectName}</h3>
                          </div>
                          <div className="row font-weight-bold mb-2">
                            <div className="col">Course Name</div>
                            <div className="col-md-3">
                              <div className="row">
                                <div className="col text-center">
                                  Lecture Hour
                                </div>
                                <div className="col text-center">
                                  Practical Hour
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4"></div>
                          </div>
                          <div className="">
                            {cs.coursePlanSyllabus &&
                            cs.coursePlanSyllabus.length > 0 ? (
                              cs.coursePlanSyllabus.map((topic, idx) => {
                                return (
                                  <React.Fragment key={idx}>
                                    <div
                                      className="row"
                                      key={idx}
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        lineHeight: "2",
                                      }}
                                    >
                                      <div
                                        className="col"
                                        style={{ display: "flex" }}
                                      >
                                        <span className="backGround">
                                          {idx + 1}. {topic.name}
                                        </span>
                                        <span
                                          style={{
                                            height: "inherit",
                                            width: "5px",
                                          }}
                                          className="backGround"
                                        ></span>
                                        <div className="dotsRender"></div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="row">
                                          <div className="col text-center">
                                            {topic.lectureHour}
                                          </div>
                                          <div className="col text-center">
                                            {topic.practicalHour}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 text-center">
                                        {topic.hasResource ? (
                                          <button
                                            className="tt-button tt-button-primary"
                                            onClick={() =>
                                              this.toggleSyllabusResourcesModalView(
                                                topic
                                              )
                                            }
                                          >
                                            View Resource
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    {topic.subTopic &&
                                    topic.subTopic.length > 0 ? (
                                      <CourseSyllabusDetailReRender
                                        fromArr={true}
                                        topicList={topic.subTopic}
                                        handleUpdateSyllabusDetail={
                                          this.props.handleUpdateSyllabusDetail
                                        }
                                        index={1}
                                        handleSyllabusDetailDelete={
                                          this.props.handleSyllabusDetailDelete
                                        }
                                        syllabusResourceModal={
                                          this.props.syllabusResourceModal
                                        }
                                        toggleSyllabusResourcesModalView={
                                          this.props
                                            .toggleSyllabusResourcesModalView
                                        }
                                      ></CourseSyllabusDetailReRender>
                                    ) : null}
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <div className="col text-center">
                                No Courses available
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      {this.state.viewSpinner ? (
                        <Spinner color="primary">Loading data...</Spinner>
                      ) : (
                        "No records to display!!"
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <ModalWindow
          modal={this.state.syllabusDetailModal}
          toggleModal={this.handleSyllabusDetailModal}
          modalHeader={"Course Syllabus"}
          size="xl"
          modalBody={
            <CourseSyllabusDetailModal
              topicList={this.state.topicList ? this.state.topicList : []}
              handleUpdateSyllabusDetail={this.handleUpdateSyllabusDetail}
              handleSyllabusDetailDelete={this.handleSyllabusDetailDelete}
              handleSyllabusPlanDelete={this.handleSyllabusPlanDelete}
              toggleSyllabusResourcesModal={this.toggleSyllabusResourcesModal}
              toggleSyllabusResourcesModalView={
                this.toggleSyllabusResourcesModalView
              }
              syllabusResourceModal={this.syllabusResourceModal}
            ></CourseSyllabusDetailModal>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.updateSyllabusDetailModal}
          toggleModal={this.toggleUpdateSyllabusDetail}
          modalHeader="Edit Course"
          modalBody={
            <CourseSyllabusUpdateModal
              updateTopicName={this.state.updateTopicName}
              updateTopicLectureHour={this.state.updateTopicLectureHour}
              updateTopicPracticalHour={this.state.updateTopicPracticalHour}
              handleChange={this.handleChange}
            ></CourseSyllabusUpdateModal>
          }
          modalFooter={
            <div className="container-fluid">
              <div className="row">
                <div className="col text-right">
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.handleSyllabusDetailUpdate}
                  >
                    Save
                  </button>
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.toggleUpdateSyllabusDetail}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>

      

 */}
        <ModalWindow
          modal={this.state.syllabusResourcesModal}
          toggleModal={this.toggleSyllabusResourcesModal}
          modalHeader="Add Resource"
          size="xl"
          modalBody={
            <CourseSyllabusResource
              assignment={true}
              updateAssignment={false}
              toggleAddResourceModal={this.toggleSyllabusResourcesModal}
              saveResources={this.saveResources}
              assignmentContents={this.state.contents}
              assignmentLinks={this.state.links}
              coursePlanId={this.state.coursePlanId}
              coursePlanSyllabusId={this.state.coursePlanSyllabusId}
            ></CourseSyllabusResource>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.fileModal}
          toggleModal={this.toggleFileModal}
          modalHeader="Resource"
          size="xl"
          modalBody={
            <CourseClassResources
              selectedResources={this.state.selectedSubjectResources}
            ></CourseClassResources>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default ARRCoursePlan;

import { axiosPost } from "../utils/AxiosApi";
import { postType, URL, rowCountLimit } from "../utils/Constants";
import moment from "moment";
import { isEqual } from "lodash";
import $ from "jquery";

const noticesLOAD = "notices/LOAD";
const postsLOAD = "posts/LOAD";
const groupPostsLOAD = "group/posts/LOAD";
const groupDiscussionLOAD = "group/Disucssion/LOAD";
const personProfilePostsLOAD = "personProfile/posts/LOAD";
const personProfilePostsScrollLOAD = "personProfile/posts/scroll/LOAD";
const familyProfilePostsLOAD = "familyProfile/posts/LOAD";
const discussionPostsLOAD = "discussion/posts/LOAD";

const personProfileRecentPostLOAD = "personProfile/posts/recentPosts/LOAD";
const personAcademicRepoPostLOAD = "personProfile/posts/academicRepo/LOAD";
const personPostSectionAcademicRepoPostLOAD =
  "personProfile/postSection/academicRepo/LOAD";
const personPostSectionAcademicRepoPostRESET =
  "personProfile/postSection/academicRepo/RESET";

const searchPersonProfilePostWithoutRepoLOAD = "search/post/withoutrepo";
const searchOnlyRepoPostLOAD = "search/RepoPost";
const searchPersonProfilePostLOAD = "personProfile/posts/searchPost/LOAD";
const searchAcademicRepoPostLOAD =
  "personProfile/posts/academicRepoSearch/LOAD";
const noticeSpinnerLOAD = "notice/spinner";
const postSpinnerLOAD = "post/spinner";
const totalSearchLOAD = "post/search/total";
const resetPostSearchLOAD = "post/search/resetBoolean";
const totalRecordsLOAD = "post/totalRecords";
const changePERSONALPOSTOFFSET = "post/personal/offset/change";
const changePERSONALPOSTPAGE = "post/personal/page/change";
const changeAcademicPostPage = "post/academicrepo/page/change";
const changeFAMILYPOSTPAGE = "post/family/page/change";
const changeDISCUSSIONPOSTPAGE = "post/discussions/page/change";
const changeGROUPPOSTPAGE = "post/group/page/change";
const changeGROUPDISCUSSIONPAGE = "post/group/DISCUSSION/change";

const resetgroupPostsLOAD = "post/group/page/reset";
const resetGROUPDISCUSSIONS = "post/group/DISCUSSION/reset";

const resetfamilyProfilePostsLOAD = "post/family/page/reset";
const resetDiscussionPostsLOAD = "post/discussion/page/reset";

const totalAcademicRepoPostsLOAD = "post/academicrepo/total";
const changeISSEARCHING = "post/search/change";

export default function reducer(
  state = {
    notices: [],
    posts: [],
    groupPosts: [],
    groupDiscussions: [],
    personProfilePosts: [],
    familyPosts: [],
    discussionPosts: [],
    personProfileRecentPosts: [],
    personAcademicRepoPosts: [],
    personPostSectionRepoPosts: [],
    postSpinner: false,
    recentPostSpinner: false,
    academicPostSpinner: false,
    noticeSpinner: false,
    totalSearchResult: 0,
    searchPostBoolean: false,
    totalRecords: 0,
    currentRecordsCount: 0,
    totalPosts: 0,
    personalPostOffset: 0,
    personalPostPage: 1,
    personalPostPageSize: rowCountLimit,
    totalAcademicRepoPosts: 0,
    currentAcademicRepoPosts: 0,
    academicPostOffset: 0,
    academicPostPage: 1,
    academicPostPageSize: rowCountLimit,
    groupPostOffset: 0,
    groupPostPage: 1,
    groupPostPageSize: rowCountLimit,
    totalGroupPosts: 0,
    currentGroupPosts: 0,
    groupDiscussionOffset: 0,
    groupDiscussionPage: 1,
    groupDiscussionPageSize: rowCountLimit,
    totalGroupDiscussions: 0,
    currentGroupDiscussions: 0,
    familyPostOffset: 0,
    familyPostPage: 1,
    familyPostPageSize: rowCountLimit,
    totalFamilyPosts: 0,
    currentFamilyPosts: 0,
    discussionPostOffset: 0,
    discussionPostPage: 1,
    discussionPostPageSize: rowCountLimit,
    totalDiscussionPosts: 0,
    currentDiscussionPosts: 0,
    isSearching: false,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case changeISSEARCHING:
      newState.isSearching = action.bool;
      return newState;
    case changeAcademicPostPage:
      newState.academicPostPage = action.academicPostPage;
      return newState;
    case totalAcademicRepoPostsLOAD:
      newState.totalAcademicRepoPosts = action.totalAcademicRepoPosts;
      return newState;
    case changePERSONALPOSTOFFSET:
      newState.personalPostOffset = action.personalPostOffset;
      return newState;
    case changePERSONALPOSTPAGE:
      newState.personalPostPage = action.personalPostPage;
      return newState;
    case changeGROUPPOSTPAGE:
      newState.groupPostPage = action.groupPostPage;
      return newState;
    case changeGROUPDISCUSSIONPAGE:
      newState.groupPostPage = action.groupPostPage;
      return newState;
    case changeFAMILYPOSTPAGE:
      newState.familyPostPage = action.familyPostPage;
      return newState;
    case changeDISCUSSIONPOSTPAGE:
      newState.discussionPostPage = action.discussionPostPage;
      return newState;
    case noticesLOAD:
      if (!isEqual(newState.notices, action.notices)) {
        newState.notices = action.notices;
      }
      newState.noticeSpinner = action.noticeSpinner;
      return newState;
    case postsLOAD:
      newState.posts = action.posts;
      return newState;
    case totalRecordsLOAD:
      newState.totalPosts = action.totalPosts;
      return newState;
    case groupPostsLOAD:
      newState.groupPosts = [...newState.groupPosts, ...action.groupPosts];
      newState.postSpinner = action.postSpinner;
      newState.totalGroupPosts = action.totalGroupPosts;
      newState.currentGroupPosts =
        newState.currentGroupPosts + action.currentGroupPosts;
      return newState;
    case groupDiscussionLOAD:
      newState.groupDiscussions = [
        ...newState.groupDiscussions,
        ...action.groupDiscussions,
      ];
      newState.postSpinner = action.postSpinner;
      newState.totalGroupDiscussions = action.totalGroupDiscussions;
      newState.currentGroupDiscussions =
        newState.currentGroupDiscussions + action.currentGroupDiscussions;
      return newState;
    case resetgroupPostsLOAD:
      newState.groupPosts = action.groupPosts;
      newState.postSpinner = action.postSpinner;
      newState.totalGroupPosts = action.totalGroupPosts;
      newState.currentGroupPosts = action.currentGroupPosts;
      return newState;
    case resetGROUPDISCUSSIONS:
      newState.groupDiscussions = action.groupDiscussions;
      newState.postSpinner = action.postSpinner;
      newState.totalGroupDiscussions = action.totalGroupDiscussions;
      newState.currentGroupDiscussions = action.currentGroupDiscussions;
      return newState;
    case personProfilePostsLOAD:
      newState.personProfilePosts = action.personProfilePosts;
      newState.postSpinner = action.postSpinner;
      newState.totalRecords = action.totalRecords;
      newState.currentRecordsCount = action.currentRecordsCount;
      return newState;
    case personProfilePostsScrollLOAD:
      newState.personProfilePosts = [
        ...newState.personProfilePosts,
        ...action.personProfilePosts,
      ];
      newState.postSpinner = action.postSpinner;
      newState.currentRecordsCount =
        newState.currentRecordsCount + action.currentRecordsCount;
      return newState;
    case familyProfilePostsLOAD:
      newState.familyPosts = [...newState.familyPosts, ...action.familyPosts];
      newState.postSpinner = action.postSpinner;
      newState.totalFamilyPosts = action.totalFamilyPosts;
      newState.currentFamilyPosts =
        newState.currentFamilyPosts + action.currentFamilyPosts;
      return newState;
    case discussionPostsLOAD:
      newState.discussionPosts = [
        ...newState.discussionPosts,
        ...action.discussionPosts,
      ];
      newState.postSpinner = action.postSpinner;
      newState.totalDiscussionPosts = action.totalDiscussionPosts;
      newState.currentDiscussionPosts =
        newState.currentDiscussionPosts + action.currentDiscussionPosts;
      return newState;
    case resetDiscussionPostsLOAD:
      newState.discussionPosts = action.discussionPosts;
      newState.postSpinner = action.postSpinner;
      newState.totalDiscussionPosts = action.totalDiscussionPosts;
      newState.currentDiscussionPosts = action.currentDiscussionPosts;
      return newState;
    case resetfamilyProfilePostsLOAD:
      newState.familyPosts = action.familyPosts;
      newState.postSpinner = action.postSpinner;
      newState.totalFamilyPosts = action.totalFamilyPosts;
      newState.currentFamilyPosts = action.currentFamilyPosts;
      return newState;
    case personProfileRecentPostLOAD:
      newState.personProfileRecentPosts = action.personProfileRecentPosts;
      newState.recentPostSpinner = action.recentPostSpinner;
      return newState;
    case personAcademicRepoPostLOAD:
      newState.personAcademicRepoPosts = action.personAcademicRepoPosts;
      newState.totalAcademicRepoPosts = action.totalAcademicRepoPosts;
      newState.currentAcademicRepoPosts =
        newState.currentAcademicRepoPosts + action.currentAcademicRepoPosts;
      return newState;
    case searchPersonProfilePostLOAD:
      newState.personProfilePosts = action.personProfilePosts;
      newState.personProfileRecentPosts = action.personProfileRecentPosts;
      newState.personPostSectionRepoPosts = action.personPostSectionRepoPosts;
      newState.groupPosts = action.groupPosts;
      newState.familyPosts = action.familyPosts;
      newState.postSpinner = action.postSpinner;
      newState.recentPostSpinner = action.recentPostSpinner;
      newState.academicPostSpinner = action.academicPostSpinner;
      return newState;
    case searchOnlyRepoPostLOAD:
      newState.personPostSectionRepoPosts = action.personPostSectionRepoPosts;
      newState.academicPostSpinner = action.academicPostSpinner;
      return newState;
    case searchPersonProfilePostWithoutRepoLOAD:
      newState.personProfilePosts = action.personProfilePosts;
      newState.personProfileRecentPosts = action.personProfileRecentPosts;
      newState.groupPosts = action.groupPosts;
      newState.familyPosts = action.familyPosts;
      newState.postSpinner = action.postSpinner;
      newState.recentPostSpinner = action.recentPostSpinner;
      return newState;
    case searchAcademicRepoPostLOAD:
      newState.personAcademicRepoPosts = action.personAcademicRepoPosts;
      newState.totalAcademicRepoPosts = action.totalAcademicRepoPosts;
      return newState;
    case personPostSectionAcademicRepoPostLOAD:
      newState.personPostSectionRepoPosts = [
        ...newState.personPostSectionRepoPosts,
        ...action.personPostSectionRepoPosts,
      ];
      newState.academicPostSpinner = action.academicPostSpinner;
      newState.totalAcademicRepoPosts = action.totalAcademicRepoPosts;
      newState.currentAcademicRepoPosts =
        newState.currentAcademicRepoPosts + action.currentAcademicRepoPosts;
      return newState;
    case personPostSectionAcademicRepoPostRESET:
      newState.personPostSectionRepoPosts = action.personPostSectionRepoPosts;
      newState.academicPostSpinner = action.academicPostSpinner;
      newState.totalAcademicRepoPosts = action.totalAcademicRepoPosts;
      newState.currentAcademicRepoPosts = action.currentAcademicRepoPosts;
      return newState;
    case noticeSpinnerLOAD:
      newState.noticeSpinner = action.noticeSpinner;
      return newState;
    case totalSearchLOAD:
      newState.totalSearchResult = action.totalSearchResult;
      newState.searchPostBoolean = action.searchPostBoolean;
      return newState;
    case resetPostSearchLOAD:
      newState.searchPostBoolean = action.searchPostBoolean;
      return newState;
    case postSpinnerLOAD:
      newState.postSpinner = action.postSpinner;
      return newState;
    default:
      return newState;
  }
}

export function changeIsSearching(bool) {
  return { type: changeISSEARCHING, bool };
}

export function changePersonalOffset(offset) {
  return { type: changePERSONALPOSTOFFSET, offset };
}
export function changePersonalPage(personalPostPage) {
  return { type: changePERSONALPOSTPAGE, personalPostPage };
}
export function changeAcademicRepoPage(academicPostPage) {
  return { type: changeAcademicPostPage, academicPostPage };
}

export function changeTotalAcademicRepoPosts(totalAcademicRepoPosts) {
  return { type: totalAcademicRepoPostsLOAD, totalAcademicRepoPosts };
}

export function changeGroupPostPage(groupPostPage) {
  return { type: changeGROUPPOSTPAGE, groupPostPage };
}

export function changeGroupDiscussionPage(groupDiscussionPage) {
  return { type: changeGROUPDISCUSSIONPAGE, groupDiscussionPage };
}

export function changeFamilyPostPage(familyPostPage) {
  return { type: changeFAMILYPOSTPAGE, familyPostPage };
}

export function changeDiscussionPostPage(discussionPostPage) {
  return { type: changeDISCUSSIONPOSTPAGE, discussionPostPage };
}

export function loadResetSearchPostBoolean(searchPostBoolean) {
  return { type: resetPostSearchLOAD, searchPostBoolean };
}

export function loadNotices(notices, noticeSpinner) {
  return { type: noticesLOAD, notices, noticeSpinner };
}

export function loadPosts(posts) {
  return { type: postsLOAD, posts };
}

export function loadGroupPosts(
  groupPosts,
  postSpinner,
  totalGroupPosts,
  currentGroupPosts
) {
  return {
    type: groupPostsLOAD,
    groupPosts,
    postSpinner,
    totalGroupPosts,
    currentGroupPosts,
  };
}

export function loadGroupDiscussions(
  groupDiscussions,
  postSpinner,
  totalGroupDiscussions,
  currentGroupDiscussions
) {
  return {
    type: groupDiscussionLOAD,
    groupDiscussions,
    postSpinner,
    totalGroupDiscussions,
    currentGroupDiscussions,
  };
}

export function resetGroupPosts(
  groupPosts,
  postSpinner,
  totalGroupPosts,
  currentGroupPosts
) {
  return {
    type: resetgroupPostsLOAD,
    groupPosts,
    postSpinner,
    totalGroupPosts,
    currentGroupPosts,
  };
}

export function resetGroupDiscussions(
  groupDiscussions,
  postSpinner,
  totalGroupDiscussions,
  currentGroupDiscussions
) {
  return {
    type: resetGROUPDISCUSSIONS,
    groupDiscussions,
    postSpinner,
    totalGroupDiscussions,
    currentGroupDiscussions,
  };
}

export function loadPersonProfilePosts(
  personProfilePosts,
  postSpinner,
  totalRecords,
  currentRecordsCount
) {
  return {
    type: personProfilePostsLOAD,
    personProfilePosts,
    postSpinner,
    totalRecords,
    currentRecordsCount,
  };
}

export function loadPersonProfilePostsScroll(
  personProfilePosts,
  postSpinner,
  currentRecordsCount
) {
  return {
    type: personProfilePostsScrollLOAD,
    personProfilePosts,
    postSpinner,
    currentRecordsCount,
  };
}

export function loadFamilyPosts(
  familyPosts,
  postSpinner,
  totalFamilyPosts,
  currentFamilyPosts
) {
  return {
    type: familyProfilePostsLOAD,
    familyPosts,
    postSpinner,
    totalFamilyPosts,
    currentFamilyPosts,
  };
}

export function loadDiscussionPosts(
  discussionPosts,
  postSpinner,
  totalDiscussionPosts,
  currentDiscussionPosts
) {
  return {
    type: discussionPostsLOAD,
    discussionPosts,
    postSpinner,
    totalDiscussionPosts,
    currentDiscussionPosts,
  };
}

export function resetFamilyPosts(
  familyPosts,
  postSpinner,
  totalFamilyPosts,
  currentFamilyPosts
) {
  return {
    type: resetfamilyProfilePostsLOAD,
    familyPosts,
    postSpinner,
    totalFamilyPosts,
    currentFamilyPosts,
  };
}

export function resetDiscussionPosts(
  discussionPosts,
  postSpinner,
  totalDiscussionPosts,
  currentDiscussionPosts
) {
  return {
    type: resetDiscussionPostsLOAD,
    discussionPosts,
    postSpinner,
    totalDiscussionPosts,
    currentDiscussionPosts,
  };
}

export function loadPersonProfileRecentPosts(
  personProfileRecentPosts,
  recentPostSpinner
) {
  return {
    type: personProfileRecentPostLOAD,
    personProfileRecentPosts,
    recentPostSpinner,
  };
}

export function loadPersonAcademicRepoPosts(
  personAcademicRepoPosts,
  totalAcademicRepoPosts,
  currentAcademicRepoPosts
) {
  return {
    type: personAcademicRepoPostLOAD,
    personAcademicRepoPosts,
    totalAcademicRepoPosts,
    currentAcademicRepoPosts,
  };
}

export function loadSearchOnlyRepoPost(
  personPostSectionRepoPosts,
  academicPostSpinner
) {
  return {
    type: searchOnlyRepoPostLOAD,
    personPostSectionRepoPosts,
    academicPostSpinner,
  };
}

export function loadSearchPersonProfilePost(
  personProfilePosts,
  personProfileRecentPosts,
  personPostSectionRepoPosts,
  groupPosts,
  familyPosts,
  postSpinner,
  recentPostSpinner,
  academicPostSpinner
) {
  return {
    type: searchPersonProfilePostLOAD,
    personProfilePosts,
    personProfileRecentPosts,
    personPostSectionRepoPosts,
    groupPosts,
    familyPosts,
    postSpinner,
    recentPostSpinner,
    academicPostSpinner,
  };
}

export function loadSearchPersonProfilePostWithoutRepo(
  personProfilePosts,
  personProfileRecentPosts,
  groupPosts,
  familyPosts,
  postSpinner,
  recentPostSpinner
) {
  return {
    type: searchPersonProfilePostWithoutRepoLOAD,
    personProfilePosts,
    personProfileRecentPosts,
    groupPosts,
    familyPosts,
    postSpinner,
    recentPostSpinner,
  };
}

export function loadSearchAcademicRepoPost(
  personAcademicRepoPosts,
  totalAcademicRepoPosts
) {
  return {
    type: searchAcademicRepoPostLOAD,
    personAcademicRepoPosts,
    totalAcademicRepoPosts,
  };
}

export function loadPersonPostSectionRepoPosts(
  personPostSectionRepoPosts,
  academicPostSpinner,
  totalAcademicRepoPosts,
  currentAcademicRepoPosts
) {
  return {
    type: personPostSectionAcademicRepoPostLOAD,
    personPostSectionRepoPosts,
    academicPostSpinner,
    totalAcademicRepoPosts,
    currentAcademicRepoPosts,
  };
}

export function resetPersonPostSectionRepoPosts(
  personPostSectionRepoPosts,
  academicPostSpinner,
  totalAcademicRepoPosts,
  currentAcademicRepoPosts
) {
  return {
    type: personPostSectionAcademicRepoPostRESET,
    personPostSectionRepoPosts,
    academicPostSpinner,
    totalAcademicRepoPosts,
    currentAcademicRepoPosts,
  };
}

export function loadNoticeSpinner(noticeSpinner) {
  return { type: noticeSpinnerLOAD, noticeSpinner };
}

export function loadPostSpinner(postSpinner) {
  return { type: postSpinnerLOAD, postSpinner };
}

export function loadTotalSearchResult(totalSearchResult, searchPostBoolean) {
  return { type: totalSearchLOAD, totalSearchResult, searchPostBoolean };
}

export function loadTotalRecords(totalPosts) {
  return { type: totalRecordsLOAD, totalPosts };
}

//Dispatcher functions

export function resetSearchBoolean() {
  return (dispatch) => {
    dispatch(loadResetSearchPostBoolean(false));
  };
}

export function setIsSearching(bool) {
  return (dispatch) => {
    dispatch(changeIsSearching(bool));
  };
}

export function getNotices(param, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const noticesInState = state.posts.notices;
    let allPromises = [];
    let personalNoticePromise = new Promise((resolve, reject) => {
      axiosPost(URL.getPersonalNotice, param, (response) => {
        if (response.status === 200) {
          let notices = response.data.data;
          resolve(notices);
        }
      });
    });
    allPromises.push(personalNoticePromise);
    let notices = await personalNoticePromise;
    const bool = isEqual(notices, noticesInState);
    if (bool && callback !== undefined) {
      callback();
      dispatch(loadNoticeSpinner(false));
    } else {
      dispatch(loadNotices([], true));
      notices.forEach(async (notice, idx) => {
        if (notice.postType === postType.videoConference) {
          let param2 = {
            postId: notice.id,
            profilePeopleId: param.peopleId,
          };
          let selectVideoConferencePostPromise = new Promise(
            (resolve, reject) => {
              axiosPost(URL.videoConferenceSelectByPostId, param2, (res) => {
                if (res.status === 200) {
                  resolve(res.data.data);
                }
              });
            }
          );
          allPromises.push(selectVideoConferencePostPromise);
          let replaceData = await selectVideoConferencePostPromise;
          let startDateTime = replaceData?.startDateTime
            ? moment(replaceData?.startDateTime).format(
                "Do MMMM, YYYY [at] hh:mm A"
              )
            : null;
          let endDateTime = replaceData?.endDateTime
            ? moment(replaceData?.endDateTime).format(
                "Do MMMM, YYYY [at] hh:mm A"
              )
            : null;

          notice.heading = notice.heading
            ? notice.heading.replace("[sessionName]", replaceData?.sessionName)
            : null;
          notice.textContent = notice.textContent.replace(
            "[peopleName]",
            replaceData?.peopleName
          );
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message
          );
          notice.textContent = notice.textContent.replace(
            "[sessionName]",
            replaceData?.sessionName
          );
          notice.textContent = notice.textContent.replace(
            "[startDateTime]",
            startDateTime
          );
          notice.textContent = notice.textContent.replace(
            "[endDateTime]",
            endDateTime
          );
          notice.textContent = notice.textContent.replace(
            "[hostName]",
            replaceData?.hostName
          );
        }
        if (notice.postType === postType.assignment) {
          let paramobj = {
            postId: notice.id,
            profilePeopleId: param.peopleId,
          };
          let selectAssignmentPostPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getAssignmentByPostId, paramobj, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });
          allPromises.push(selectAssignmentPostPromise);
          let replaceData = await selectAssignmentPostPromise;
          if (replaceData) {
            notice.textContent = notice.textContent.replace(
              "[message]",
              replaceData?.message ? replaceData?.message : ""
            );
            notice.textContent = notice.textContent.replace(
              "[peopleName]",
              replaceData?.peopleName ? replaceData?.peopleName : ""
            );
            notice.isTargettedStudent = replaceData.isTargettedStudent;
            notice.assignmentStatus = replaceData.assignmentStatus;
            notice.isAudience = replaceData.isAudience;
          }
        }
        if (notice.postType === postType.mcq) {
          let paramMCQ = {
            postId: notice.id,
            profilePeopleId: param.peopleId,
          };
          let selectMCQPostPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getMcqByPostId, paramMCQ, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });
          allPromises.push(selectMCQPostPromise);
          let replaceData = await selectMCQPostPromise;
          notice.heading = notice.heading.replace(
            "[topic]",
            replaceData?.topic ? replaceData?.topic : ""
          );
          notice.textContent = notice.textContent.replace(
            "[peopleName]",
            replaceData?.peopleName ? replaceData?.peopleName : ""
          );
          notice.textContent = notice.textContent.replace(
            "[topic]",
            replaceData?.topic ? replaceData?.topic : ""
          );
        }
        if (notice.postType === postType.profilePictureNotice) {
          let paramProfilePicture = {
            postId: notice.id,
            profilePeopleId: param.peopleId,
          };
          let selectProfilePicPromise = new Promise((resolve, reject) => {
            axiosPost(
              URL.selectProfilePictureNoticeById,
              paramProfilePicture,
              (res) => {
                if (res.status === 200) {
                  resolve(res.data.data);
                }
              }
            );
          });
          allPromises.push(selectProfilePicPromise);
          let replaceData = await selectProfilePicPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;
          notice.moderatedStatus = replaceData?.moderatedStatus
            ? replaceData.moderatedStatus
            : "";
          notice.profilePictureForModeration =
            replaceData?.profilePictureForModeration
              ? replaceData.profilePictureForModeration
              : "";
        }
        if (notice.postType === postType.coverPictureNotice) {
          let coverPictureParam = {
            postId: notice.id,
            profilePeopleId: param.peopleId,
          };
          let selectCoverPicPromise = new Promise((resolve, reject) => {
            axiosPost(
              URL.selectCoverPictureNoticeById,
              coverPictureParam,
              (res) => {
                if (res.status === 200) {
                  resolve(res.data.data);
                }
              }
            );
          });
          allPromises.push(selectCoverPicPromise);
          let replaceData = await selectCoverPicPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.coverPicturesForModeration =
            replaceData?.coverPicturesForModeration
              ? replaceData?.coverPicturesForModeration
              : "";
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;
          // notice.moderatedStatus = replaceData?.moderatedStatus
          //   ? replaceData.moderatedStatus
          //   : "";
        }
        if (notice.postType === postType.postModeration) {
          let paramPost = {
            postId: notice.id,
            profilePeopleId: param.peopleId,
          };
          let selectPostPromise = new Promise((resolve, reject) => {
            axiosPost(URL.selectPostNoticeById, paramPost, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });
          allPromises.push(selectPostPromise);
          let replaceData = await selectPostPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;
          notice.moderatedStatus = replaceData?.moderatedStatus
            ? replaceData.moderatedStatus
            : "";
          notice.post = replaceData;
          if (
            notice.post &&
            notice.post.contents &&
            notice.post.contents.length > 0
          ) {
            notice.post.contents.forEach((content) => {
              if (content.contentTypeCode === "LNK" && content.content !== "") {
                let videoId = "";
                if (
                  content.content.includes("youtube") ||
                  content.content.includes("youtu.be")
                ) {
                  var regExp =
                    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                  var match = content.content.match(regExp);
                  if (match && match[7].length == 11) {
                    videoId = match[7];
                  }

                  var url = "https://www.youtube.com/watch?v=" + videoId;
                  //to get youtube title
                  $.getJSON(
                    "https://noembed.com/embed",
                    { format: "json", url: url },
                    function (data) {
                      if (data !== undefined || data) {
                        content.youtubeTitle = data.title;
                      } else {
                        content.youtubeTitle = content.content;
                      }
                    }
                  );

                  //to get thumbnail
                  content.thumbnail = `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
                } else if (
                  content.content.includes("dailymotion") ||
                  content.content.includes("dai.ly")
                ) {
                  let url = content.content;
                  var m = url.match(
                    /^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/
                  );
                  if (m === null) {
                    var n = url.match(
                      /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
                    );
                    if (n !== null) {
                      if (n[4] !== undefined) {
                        videoId = n[4];
                      }
                      videoId = n[2];
                    }
                  } else if (m !== null) {
                    if (m[4] !== undefined) {
                      videoId = m[4];
                    }
                    videoId = m[2];
                  }
                  $.ajax({
                    type: "GET",
                    url:
                      "https://api.dailymotion.com/video/" +
                      videoId +
                      "?fields=title,duration,user",
                    dataType: "jsonp",
                    cache: true,
                    success: function (data) {
                      content.youtubeTitle = data.title;
                    },
                  });
                  content.thumbnail = `https://www.dailymotion.com/thumbnail/video/${videoId}`;
                } else if (content.content.includes("vimeo")) {
                  var regExp =
                    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
                  var parseUrl = content.content.match(regExp);
                  videoId = parseUrl[5];
                  $.ajax({
                    type: "GET",
                    url: "http://vimeo.com/api/v2/video/" + videoId + ".json",
                    jsonp: "callback",
                    dataType: "jsonp",
                    success: function (data) {
                      content.youtubeTitle = data[0].title;
                      content.thumbnail = data[0].thumbnail_medium;
                    },
                  });
                } else if (
                  content.content.includes("slideshare") &&
                  content.youtubeTitle !== undefined
                ) {
                  $.getJSON(
                    "https://noembed.com/embed",
                    { format: "json", url: content.content },
                    function (data) {
                      if (data !== undefined || data) {
                        content.youtubeTitle = data.title;
                        content.thumbnail = data.thumbnail;
                      }
                    }
                  );
                } else {
                  $.getJSON(
                    "https://noembed.com/embed",
                    { format: "json", url: content.content },
                    function (data) {
                      if (data !== undefined && data && !data.error) {
                        content.youtubeTitle = data.title;
                        if (data.thumbnail_url) {
                          content.thumbnail = data.thumbnail_url;
                        }
                      } else {
                        content.youtubeTitle = content.content;
                      }
                    }
                  );
                }
              }
            });
          }
        }

        if (notice.postType === postType.academicPostCommentModeration) {
          let paramobj = {
            id: notice.id,
            profilePeopleId: param.peopleId,
          };
          let postAcademicCommmentPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getAcademicRepoCommentByPost, paramobj, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });

          allPromises.push(postAcademicCommmentPromise);

          let replaceData = await postAcademicCommmentPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;

          notice.moderatedStatus = replaceData?.academicRepoComment
            ?.moderatedStatus
            ? replaceData.academicRepoComment.moderatedStatus
            : "";
        }

        if (notice.postType === postType.postCommentModeration) {
          let paramobj = {
            id: notice.id,
            profilePeopleId: param.peopleId,
          };
          let postCommmentPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getCommentByPost, paramobj, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });

          allPromises.push(postCommmentPromise);

          let replaceData = await postCommmentPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;

          notice.moderatedStatus = replaceData?.postComment?.moderatedStatus
            ? replaceData.postComment.moderatedStatus
            : "";
        }

        if (notice.postType === postType.AcademicCommentModeration) {
          let paramobj = {
            id: notice.id,
            profilePeopleId: param.peopleId,
          };
          let postCommmentPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getCommentByAcademicPost, paramobj, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });

          allPromises.push(postCommmentPromise);

          let replaceData = await postCommmentPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;

          notice.moderatedStatus = replaceData?.postComment?.moderatedStatus
            ? replaceData.postComment.moderatedStatus
            : "";
        }
      });

      Promise.all(allPromises).then((data) => {
        dispatch(loadNotices(data[0], false));
      });
    }
  };
}

export function getNoticeSpinner(param) {
  return (dispatch) => {
    if (param === true) {
      dispatch(loadNotices([], true));
    } else {
      dispatch(loadNoticeSpinner(param));
    }
  };
}

export function getGroupNotices(param) {
  return (dispatch) => {
    dispatch(loadNotices([], true));
    axiosPost(URL.getGroupNotices, param, (response) => {
      // if (response.status === 200) {
      dispatch(loadNotices(response.data.data, false));
      // }
    });
  };
}

export function getFamilyNotices(param) {
  return async (dispatch) => {
    dispatch(loadNotices([], true));
    let allPromises = [];
    let familyNoticePromise = new Promise((resolve, reject) => {
      axiosPost(URL.getFamilyNotices, param, (response) => {
        // if (response.status === 200) {
        resolve(response.data.data);
        // }
      });
    });
    allPromises.push(familyNoticePromise);
    let familyNotice = await familyNoticePromise;
    familyNotice.forEach(async (notice) => {
      if (notice.postType === postType.videoConference) {
        let param2 = {
          postId: notice.id,
          familyId: param.familyId,
        };
        let selectVideoConferenceFamilyPostPromise = new Promise(
          (resolve, reject) => {
            axiosPost(URL.videoConferenceSelectByPostId, param2, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          }
        );

        allPromises.push(selectVideoConferenceFamilyPostPromise);

        let replaceData = await selectVideoConferenceFamilyPostPromise;
        if (replaceData) {
          let startDateTime =
            replaceData && replaceData.startDateTime
              ? moment(replaceData.startDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;
          let endDateTime =
            replaceData && replaceData.endDateTime
              ? moment(replaceData.endDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;

          notice.heading =
            replaceData && notice.heading
              ? notice.heading.replace("[sessionName]", replaceData.sessionName)
              : null;
          notice.textContent = notice.textContent.replace(
            "[peopleName]",
            replaceData.peopleName
          );
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData.message
          );
          notice.textContent = notice.textContent.replace(
            "[sessionName]",
            replaceData.sessionName
          );
          notice.textContent = notice.textContent.replace(
            "[startDateTime]",
            startDateTime
          );
          notice.textContent = notice.textContent.replace(
            "[endDateTime]",
            endDateTime
          );
          notice.textContent = notice.textContent.replace(
            "[hostName]",
            replaceData.hostName
          );
        }
      }
      if (notice.postType === postType.assignment) {
        let paramobj = {
          postId: notice.id,
          familyId: param.familyId,
        };
        let selectAssignmentPostPromise = new Promise((resolve, reject) => {
          axiosPost(URL.getAssignmentByPostId, paramobj, (res) => {
            if (res.status === 200) {
              resolve(res.data.data);
            }
          });
        });
        allPromises.push(selectAssignmentPostPromise);
        let replaceData = await selectAssignmentPostPromise;
        notice.textContent = notice.textContent.replace(
          "[message]",
          replaceData?.message ? replaceData?.message : ""
        );
        notice.textContent = notice.textContent.replace(
          "[peopleName]",
          replaceData?.peopleName ? replaceData?.peopleName : ""
        );
      }
      if (notice.postType === postType.mcq) {
        let paramMCQ = {
          postId: notice.id,
        };
        let selectMCQPostPromise = new Promise((resolve, reject) => {
          axiosPost(URL.getMcqByPostId, paramMCQ, (res) => {
            if (res.status === 200) {
              resolve(res.data.data);
            }
          });
        });
        allPromises.push(selectMCQPostPromise);
        let replaceData = await selectMCQPostPromise;
        notice.heading = notice.heading.replace(
          "[topic]",
          replaceData?.topic ? replaceData?.topic : ""
        );
        notice.textContent = notice.textContent.replace(
          "[peopleName]",
          replaceData?.peopleName ? replaceData?.peopleName : ""
        );
        notice.textContent = notice.textContent.replace(
          "[topic]",
          replaceData?.topic ? replaceData?.topic : ""
        );
      }
      if (notice.postType === postType.profilePictureNotice) {
        let paramProfilePicture = {
          postId: notice.id,
          familyId: param.familyId,
        };
        let selectProfilePicPromise = new Promise((resolve, reject) => {
          axiosPost(
            URL.selectProfilePictureNoticeById,
            paramProfilePicture,
            (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            }
          );
        });
        allPromises.push(selectProfilePicPromise);
        let replaceData = await selectProfilePicPromise;
        notice.textContent = notice.textContent.replace(
          "[message]",
          replaceData?.message ? replaceData?.message : ""
        );
        notice.canModerate = replaceData?.canModerate
          ? replaceData?.canModerate
          : false;
        notice.moderatedStatus = replaceData?.moderatedStatus
          ? replaceData.moderatedStatus
          : "";
        notice.profilePictureForModeration =
          replaceData?.profilePictureForModeration
            ? replaceData.profilePictureForModeration
            : "";
      }
      if (notice.postType === postType.coverPictureNotice) {
        let coverPictureParam = {
          postId: notice.id,
          familyId: param.familyId,
        };
        let selectCoverPicPromise = new Promise((resolve, reject) => {
          axiosPost(
            URL.selectCoverPictureNoticeById,
            coverPictureParam,
            (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            }
          );
        });
        allPromises.push(selectCoverPicPromise);
        let replaceData = await selectCoverPicPromise;
        notice.textContent = notice.textContent.replace(
          "[message]",
          replaceData?.message ? replaceData?.message : ""
        );
        notice.coverPicturesForModeration =
          replaceData?.coverPicturesForModeration
            ? replaceData?.coverPicturesForModeration
            : "";
        notice.canModerate = replaceData?.canModerate
          ? replaceData?.canModerate
          : false;
        // notice.moderatedStatus = replaceData?.moderatedStatus
        //   ? replaceData.moderatedStatus
        //   : "";
      }
      if (notice.postType === postType.postModeration) {
        let paramPost = {
          postId: notice.id,
          familyId: param.familyId,
        };
        let selectPostPromise = new Promise((resolve, reject) => {
          axiosPost(URL.selectPostNoticeById, paramPost, (res) => {
            if (res.status === 200) {
              resolve(res.data.data);
            }
          });
        });
        allPromises.push(selectPostPromise);
        let replaceData = await selectPostPromise;
        notice.textContent = notice.textContent.replace(
          "[message]",
          replaceData?.message ? replaceData?.message : ""
        );
        notice.canModerate = replaceData?.canModerate
          ? replaceData?.canModerate
          : false;
        notice.moderatedStatus = replaceData?.moderatedStatus
          ? replaceData.moderatedStatus
          : "";
        notice.post = replaceData;
        if (notice.post.contents.length > 0) {
          notice.post.contents.forEach((content) => {
            if (content.contentTypeCode === "LNK" && content.content !== "") {
              let videoId = "";
              if (
                content.content.includes("youtube") ||
                content.content.includes("youtu.be")
              ) {
                var regExp =
                  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                var match = content.content.match(regExp);
                if (match && match[7].length == 11) {
                  videoId = match[7];
                }

                var url = "https://www.youtube.com/watch?v=" + videoId;
                //to get youtube title
                $.getJSON(
                  "https://noembed.com/embed",
                  { format: "json", url: url },
                  function (data) {
                    if (data !== undefined || data) {
                      content.youtubeTitle = data.title;
                    } else {
                      content.youtubeTitle = content.content;
                    }
                  }
                );

                //to get thumbnail
                content.thumbnail = `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
              } else if (
                content.content.includes("dailymotion") ||
                content.content.includes("dai.ly")
              ) {
                let url = content.content;
                var m = url.match(
                  /^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/
                );
                if (m === null) {
                  var n = url.match(
                    /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
                  );
                  if (n !== null) {
                    if (n[4] !== undefined) {
                      videoId = n[4];
                    }
                    videoId = n[2];
                  }
                } else if (m !== null) {
                  if (m[4] !== undefined) {
                    videoId = m[4];
                  }
                  videoId = m[2];
                }
                $.ajax({
                  type: "GET",
                  url:
                    "https://api.dailymotion.com/video/" +
                    videoId +
                    "?fields=title,duration,user",
                  dataType: "jsonp",
                  cache: true,
                  success: function (data) {
                    content.youtubeTitle = data.title;
                  },
                });
                content.thumbnail = `https://www.dailymotion.com/thumbnail/video/${videoId}`;
              } else if (content.content.includes("vimeo")) {
                var regExp =
                  /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
                var parseUrl = content.content.match(regExp);
                videoId = parseUrl[5];
                $.ajax({
                  type: "GET",
                  url: "http://vimeo.com/api/v2/video/" + videoId + ".json",
                  jsonp: "callback",
                  dataType: "jsonp",
                  success: function (data) {
                    content.youtubeTitle = data[0].title;
                    content.thumbnail = data[0].thumbnail_medium;
                  },
                });
              } else if (
                content.content.includes("slideshare") &&
                content.youtubeTitle !== undefined
              ) {
                $.getJSON(
                  "https://noembed.com/embed",
                  { format: "json", url: content.content },
                  function (data) {
                    if (data !== undefined || data) {
                      content.youtubeTitle = data.title;
                      content.thumbnail = data.thumbnail;
                    }
                  }
                );
              } else {
                $.getJSON(
                  "https://noembed.com/embed",
                  { format: "json", url: content.content },
                  function (data) {
                    if (data !== undefined && data && !data.error) {
                      content.youtubeTitle = data.title;
                      if (data.thumbnail_url) {
                        content.thumbnail = data.thumbnail_url;
                      }
                    } else {
                      content.youtubeTitle = content.content;
                    }
                  }
                );
              }
            }
          });
        }
      }
    });
    Promise.all(allPromises).then((data) => {
      dispatch(loadNotices(data[0], false));
    });
  };
}

export function getPosts() {
  return (dispatch) => {
    axiosPost(URL.searchNotice, {}, (response) => {
      if (response.status === 200) {
        dispatch(loadPosts(response.data.data));
      }
    });
  };
}

export function getGroupPosts(groupId, param) {
  return (dispatch, getState) => {
    const state = getState();
    let groupPostPage = state.posts.groupPostPage;
    if (param && param === "initCall") {
      dispatch(resetGroupPosts([], true, 0, 0));
      dispatch(changeGroupPostPage(1));
    } else {
      groupPostPage++;
      dispatch(changeGroupPostPage(groupPostPage));
    }

    dispatch(loadPostSpinner(true));

    const newState = getState();
    let page = newState.posts.groupPostPage;
    const pageSize = newState.posts.groupPostPageSize;
    const offset = page * pageSize - pageSize;
    const rowCountLimit = newState.posts.groupPostPageSize;

    const data = {
      groupId: groupId,
      offset,
      rowCountLimit,
    };
    axiosPost(URL.selectGroupPost, data, (response) => {
      if (response.status === 200) {
        let groupPosts = response.data.data;
        let totalGroupPosts = response.data.totalRecordsCount;
        let currentGroupPosts = groupPosts.length;
        dispatch(
          loadGroupPosts(groupPosts, false, totalGroupPosts, currentGroupPosts)
        );
      }
    });
  };
}

export function getGroupDiscussions(groupId, param) {
  return (dispatch, getState) => {
    const state = getState();
    let groupDiscussionPage = state.posts.groupDiscussionPage;
    if (param && param === "initCall") {
      dispatch(resetGroupDiscussions([], true, 0, 0));
      dispatch(changeGroupDiscussionPage(1));
    } else {
      groupDiscussionPage++;
      dispatch(changeGroupDiscussionPage(groupDiscussionPage));
    }
    dispatch(loadPostSpinner(true));

    const newState = getState();
    let page = newState.posts.groupDiscussionPage;
    const pageSize = newState.posts.groupDiscussionPageSize;
    const offset = page * pageSize - pageSize;
    const rowCountLimit = newState.posts.groupDiscussionPageSize;

    const data = {
      groupId: groupId,
      offset,
      rowCountLimit,
      postType: postType.discussion,
    };
    axiosPost(URL.selectGroupPost, data, (response) => {
      if (response.status === 200) {
        let groupDiscussions = response.data.data;
        let totalGroupDiscussions = response.data.totalRecordsCount;
        let currentGroupDiscussions = groupDiscussions.length;
        dispatch(
          loadGroupDiscussions(
            groupDiscussions,
            false,
            totalGroupDiscussions,
            currentGroupDiscussions
          )
        );
      }
    });
  };
}

export function getFamilyPosts(familyProfileId, param) {
  return (dispatch, getState) => {
    const state = getState();
    let familyPostPage = state.posts.familyPostPage;
    if (param && param === "initCall") {
      dispatch(resetFamilyPosts([], true, 0, 0));
      dispatch(changeFamilyPostPage(1));
    } else {
      familyPostPage++;
      dispatch(changeFamilyPostPage(familyPostPage));
    }

    const newState = getState();
    let page = newState.posts.familyPostPage;
    const pageSize = newState.posts.familyPostPageSize;
    const offset = page * pageSize - pageSize;
    const rowCountLimit = newState.posts.familyPostPageSize;

    dispatch(loadPostSpinner(true));

    const data = {
      familyId: familyProfileId,
      offset,
      rowCountLimit,
    };
    axiosPost(URL.selectFamilyProfile, data, (response) => {
      if (response.status === 200) {
        let familyPosts = response.data.data;
        let totalFamilyPosts = response.data.totalRecordsCount;
        let currentFamilyPosts = familyPosts.length;
        dispatch(
          loadFamilyPosts(
            familyPosts,
            false,
            totalFamilyPosts,
            currentFamilyPosts
          )
        );
        let datum = {
          familyId: familyProfileId,
        };
        axiosPost(URL.selectFamilyMembers, datum, (response) => {
          if (response.status === 200) {
            let count = 0;
            let peopleId = localStorage.getItem("peopleId");
            let datas = response.data.data;
            if (datas.length > 0) {
              datas.forEach((el) => {
                if (el.peopleId === parseInt(peopleId)) {
                  count++;
                }
              });
            }
            if (count === 0) {
              localStorage.setItem("profilePeopleId", null);
            } else {
              localStorage.setItem("profilePeopleId", peopleId);
            }
          }
        });
      }
    });
  };
}

export function getDiscussionPosts(familyProfileId, param) {
  return (dispatch, getState) => {
    const state = getState();
    let familyPostPage = state.posts.familyPostPage;
    if (param && param === "initCall") {
      dispatch(resetDiscussionPosts([], false, 0, 0));
      dispatch(changeDiscussionPostPage(1));
    } else {
      familyPostPage++;
      dispatch(changeDiscussionPostPage(familyPostPage));
    }

    const newState = getState();
    let page = newState.posts.discussionPostPage;
    const pageSize = newState.posts.discussionPostPageSize;
    const offset = page * pageSize - pageSize;
    const rowCountLimit = newState.posts.discussionPostPageSize;

    dispatch(loadPostSpinner(true));

    const data = {
      familyId: familyProfileId,
      offset,
      rowCountLimit,
      postType: postType.discussion,
    };

    axiosPost(URL.selectFamilyProfile, data, (response) => {
      if (response.status === 200) {
        let discussionPosts = response.data.data;
        let totalDiscussionPosts = response.data.totalRecordsCount;
        let currentDiscussionPosts = discussionPosts?.length;
        dispatch(
          loadDiscussionPosts(
            discussionPosts,
            false,
            totalDiscussionPosts,
            currentDiscussionPosts
          )
        );
        let datum = {
          familyId: familyProfileId,
        };
        axiosPost(URL.selectFamilyMembers, datum, (response) => {
          if (response.status === 200) {
            let count = 0;
            let peopleId = localStorage.getItem("peopleId");
            let datas = response.data.data;
            if (datas.length > 0) {
              datas.forEach((el) => {
                if (el.peopleId === parseInt(peopleId)) {
                  count++;
                }
              });
            }
            if (count === 0) {
              localStorage.setItem("profilePeopleId", null);
            } else {
              localStorage.setItem("profilePeopleId", peopleId);
            }
          }
        });
      }
    });
  };
}

export function getPersonProfileRecentPosts(peopleId) {
  return (dispatch) => {
    let data = {
      peopleId: peopleId,
    };
    dispatch(loadPersonProfileRecentPosts([], true));
    axiosPost(URL.getPersonProfileRecentPosts, data, (response) => {
      if (response.status === 200) {
        dispatch(loadPersonProfileRecentPosts(response.data.data, false));
      }
    });
  };
}

export function getPersonAcademicRepoPosts(isVerified, offset, rowCountLimit) {
  return (dispatch) => {
    let data = {
      isVerified: isVerified,
      offset: offset,
      rowCountLimit: rowCountLimit,
    };
    axiosPost(URL.getacademicRepositoryPost, data, (response) => {
      if (response.status === 200) {
        const totalAcademicRepoPosts = response.data.totalRecordsCount;
        dispatch(
          loadPersonAcademicRepoPosts(
            response.data.data,
            totalAcademicRepoPosts
          )
        );
      }
    });
  };
}

export function searchPersonalPosts(
  from,
  to,
  isMyPosts,
  academicRepo,
  peopleId,
  keywords,
  searchPost,
  groupId,
  familyId,
  moderatedStatus
) {
  return (dispatch) => {
    dispatch(changeIsSearching(true));
    let data = {
      from: from,
      to: to,
      isMyPosts: isMyPosts,
      keywords: keywords,
      searchPost: searchPost,
      isAcademicRepo: academicRepo,
      moderatedStatus: moderatedStatus,
    };
    let url = "";
    if (groupId !== null) {
      data.groupId = groupId;
      url = URL.searchGroupPost;
    } else if (familyId !== null) {
      data.familyId = familyId;
      url = URL.searchFamilyPost;
    } else {
      data.peopleId = peopleId;
      url = URL.searchPersonalPost;
    }
    // FamilyProfile/searchFamilyPost
    dispatch(loadSearchPersonProfilePost([], [], [], [], [], true, true, true));
    dispatch(loadTotalRecords(0));
    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        var post = response.data.data,
          myPosts = post.myPosts ? post.myPosts.length : 0,
          recentPosts = post.recentPosts ? post.recentPosts.length : 0,
          academicRepositories = post.academicRepositories
            ? post.academicRepositories.length
            : 0,
          groupPosts = post.groupPosts ? post.groupPosts.length : 0,
          familyPosts = post.familyPosts ? post.familyPosts.length : 0;
        let totalSearchPost =
          myPosts +
          recentPosts +
          academicRepositories +
          groupPosts +
          familyPosts;
        dispatch(loadTotalSearchResult(totalSearchPost, true));
        //added after bug report on total posts not in sync to search
        dispatch(loadTotalRecords(myPosts));
        dispatch(changeTotalAcademicRepoPosts(academicRepositories));
        //
        dispatch(
          loadSearchPersonProfilePost(
            response.data.data.myPosts,
            response.data.data.recentPosts,
            response.data.data.academicRepositories,
            response.data.data.groupPosts,
            response.data.data.familyPosts,
            false,
            false,
            false
          )
        );
      }
    });
  };
}

export function searchAcademicRepoPosts(data) {
  return (dispatch) => {
    axiosPost(URL.searchAcademicRepo, data, (response) => {
      if (response.status === 200) {
        let totalAcademicRepoPosts = response.data.totalRecordsCount;
        dispatch(
          loadSearchAcademicRepoPost(response.data.data, totalAcademicRepoPosts)
        );
      }
    });
  };
}

export function getPersonProfilePosts(profilePeopleId, param) {
  return (dispatch, getState) => {
    const state = getState();
    let personalPostPage = state.posts.personalPostPage;
    if (param && param === "initCall") {
      dispatch(loadPersonProfilePosts([], true, 0, 0));
      dispatch(changePersonalPage(1));
    } else {
      personalPostPage++;
      dispatch(changePersonalPage(personalPostPage));
    }
    const newState = getState();
    let page = newState.posts.personalPostPage;
    const pageSize = newState.posts.personalPostPageSize;
    const offset = page * pageSize - pageSize;
    const rowCountLimit = newState.posts.personalPostPageSize;
    dispatch(loadPostSpinner(true));
    const data = {
      offset,
      rowCountLimit,
      profilePeopleId,
    };
    axiosPost(
      URL.selectPersonProfilePosts,
      data,
      (response) => {
        if (response.status === 200) {
          let datas = response.data.data;
          let totalPosts = response.data.totalRecordsCount;
          let currentRecordsCount = datas.length;
          dispatch(loadTotalRecords(totalPosts));
          dispatch(
            loadPersonProfilePostsScroll(datas, false, currentRecordsCount)
          );
        } else {
          dispatch(loadPostSpinner(false));
        }
      },
      (err) => {
        dispatch(loadPostSpinner(false));
      }
    );
  };
}

export function getPersonPostSectionAcademicRepoPosts(isVerified, param) {
  return (dispatch, getState) => {
    const state = getState();
    let academicPostPage = state.posts.academicPostPage;
    if (param && param === "initCall") {
      dispatch(resetPersonPostSectionRepoPosts([], true, 0, 0));
      dispatch(changeAcademicRepoPage(1));
    } else {
      academicPostPage++;
      dispatch(changeAcademicRepoPage(academicPostPage));
    }
    const newState = getState();
    let page = newState.posts.academicPostPage;
    const pageSize = newState.posts.academicPostPageSize;
    const offset = page * pageSize - pageSize;
    const rowCountLimit = newState.posts.academicPostPageSize;

    const data = {
      isVerified: isVerified,
      offset,
      rowCountLimit,
    };
    axiosPost(URL.getacademicRepositoryPost, data, (response) => {
      if (response.status === 200) {
        let datas = response.data.data;
        let totalAcademicRepoPosts = response.data.totalRecordsCount;
        let currentAcademicRepoPosts = datas.length;
        dispatch(
          loadPersonPostSectionRepoPosts(
            datas,
            false,
            totalAcademicRepoPosts,
            currentAcademicRepoPosts
          )
        );
      }
    });
  };
}

import React, { Component } from "react";
import CourseSyllabusDetailReRender from "./CourseSyllabusDetailReRender";

class CourseSyllabusDetailModal extends Component {
  state = {};
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "87.5vh" }}>
        <div className="row tt-group-header">
          <div className="col">Course Name</div>
          <div className="col-md-3">
            <div className="row">
              <div className="col text-center">Lecture Hour</div>
              <div className="col text-center">Practical Hour</div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="tt-topicHolder">
          {this.props.topicList && this.props.topicList.length > 0 ? (
            this.props.topicList.map((topic, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div
                    className="row"
                    key={idx}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "2",
                    }}
                  >
                    <div className="col" style={{ display: "flex" }}>
                      <span className="backGround">
                        {idx + 1}. {topic.name}
                      </span>
                      <span
                        style={{ height: "inherit", width: "5px" }}
                        className="backGround"
                      ></span>
                      <div className="dotsRender"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col text-center">
                          {topic.lectureHour}
                        </div>
                        <div className="col text-center">
                          {topic.practicalHour}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <button
                        className="tt-button tt-button-primary mr-2"
                        onClick={() =>
                          this.props.handleUpdateSyllabusDetail(topic)
                        }
                      >
                        Edit
                      </button>

                      <button
                        className="tt-button tt-button-primary mr-2"
                        onClick={() =>
                          this.props.handleSyllabusDetailDelete(topic.id)
                        }
                      >
                        Delete
                      </button>

                      {topic.hasResource ? (
                        <button
                          className="tt-button tt-button-primary"
                          onClick={() =>
                            this.props.toggleSyllabusResourcesModalView(topic)
                          }
                        >
                          View Resource
                        </button>
                      ) : (
                        <button
                          className="tt-button tt-button-primary"
                          onClick={() =>
                            this.props.syllabusResourceModal(topic)
                          }
                        >
                          Add Resources
                        </button>
                      )}
                    </div>
                  </div>
                  {topic.subTopic && topic.subTopic.length > 0 ? (
                    <CourseSyllabusDetailReRender
                      topicList={topic.subTopic}
                      handleUpdateSyllabusDetail={
                        this.props.handleUpdateSyllabusDetail
                      }
                      index={1}
                      handleSyllabusDetailDelete={
                        this.props.handleSyllabusDetailDelete
                      }
                      syllabusResourceModal={this.props.syllabusResourceModal}
                      toggleSyllabusResourcesModalView={
                        this.props.toggleSyllabusResourcesModalView
                      }
                    ></CourseSyllabusDetailReRender>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <div className="col text-center">No Courses available</div>
          )}
        </div>
      </div>
    );
  }
}

export default CourseSyllabusDetailModal;

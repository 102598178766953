import {
  faMinusCircle,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import SubTopicReRender from "./SubTopicReRender";

class SubjectTopicSetting extends Component {
  state = {
    selectedIndex: [],
    topic: [
      {
        name: "",
        lectureHour: "",
        practicalHour: "",
      },
    ],
  };

  handleChange = (e, idx) => {
    let name = e.target.name;
    let value = e.target.value;
    let topic = [...this.state.topic];
    topic[idx][name] = value;
    this.setState({ topic });
  };

  addSubtopicForMainTopic = (idx) => {
    let topic = [...this.state.topic];
    let selectedIndex = [...this.state.selectedIndex];
    selectedIndex.push({ mainTopicIdx: idx });
    topic[idx].subTopic = [
      {
        name: "",
        lectureHour: "",
        practicalHour: "",
      },
    ];
    topic[idx].selectedIndex = [idx];
    this.setState({ topic, selectedIndex });
  };

  addTopic = () => {
    let topic = [...this.state.topic];
    topic.push({
      name: "",
      lectureHour: "",
      practicalHour: "",
    });
    this.setState({ topic });
  };

  removeTopic = (e, idx) => {
    e.preventDefault();
    let topic = [...this.state.topic];
    topic.splice(idx, 1);
    if (topic.length < 1) {
      swal({ title: "Error", text: "Atleast one topic is necessary" });
    } else {
      this.setState({ topic });
    }
  };

  //Add topic in Subject
  addTopicInSubject = () => {
    let data = {
      subjectId: this.props.selectedSubject.id,
      courseTopics: this.state.topic,
    };
    axiosPost(URL.addTopicsToSubject, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Topics successfully added" });
        this.clearState();
        this.props.getCourseSubjectList();
      }
    });
  };

  addCourseSyllabus = () => {
    let data = {
      educationLevelId: this.props.selectedEducationLevelId,
      yearlevelId: this.props.selectedYearLevel,
      classroomId: this.props.selectedClassrooms,
      subjectId: this.props.selectedSubjects,
      peopleId: localStorage.getItem('peopleId'),
      coursePlanSyllabus: this.state.topic,
    };
    axiosPost(URL.insertCoursePlan, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Topics successfully added" });
        this.clearState();
        this.props.toggleAddCourseModal();
      }
    });
  };

  clearState = () => {
    this.setState({
      topic: [
        {
          name: "",
          lectureHour: "",
          practicalHour: "",
        },
      ],
    });
  };

  //Dealing with infinite array of topic and subtopic
  addSubtopics = (data, idx) => {
    let topic = [...this.state.topic];
    data[idx].subTopic = [
      {
        name: "",
        lectureHour: "",
        practicalHour: "",
      },
    ];
    this.setState({ topic });
  };

  handleReRenderChange = (e, data, idx) => {
    let name = e.target.name;
    let value = e.target.value;
    let topic = [...this.state.topic];
    data[idx][name] = value;
    this.setState({ topic });
  };

  addSubtopicAsTopic = (data) => {
    let topic = [...this.state.topic];
    data.push({
      name: "",
      lectureHour: "",
      practicalHour: "",
    });
    this.setState({ topic });
  };

  removeSubtopicAsTopic = (e, data, idx) => {
    e.preventDefault();
    let topic = [...this.state.topic];
    data.splice(idx, 1);
    this.setState({ topic });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.6vh" }}>
        <div style={{ height: "72vh", overflowY: "auto", overflowX: "hidden" }}>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-3">
              <strong>Course Name</strong>
            </div>
            <div className="col-md-2">
              <strong>Lecture Hour</strong>
            </div>
            <div className="col-md-2">
              <strong>Practical Hour</strong>
            </div>
            <div className="col-md-4"></div>
          </div>
          {this.state.topic.length > 0
            ? this.state.topic.map((top, idx) => {
                return (
                  <span key={idx}>
                    <div className="row mt-1" key={idx}>
                      <div className="col-md-1 text-right">{idx + 1}.</div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={top.name}
                          onChange={(e) => this.handleChange(e, idx)}
                        ></input>
                      </div>
                      <div className="col-md-2">
                        <input
                          type="number"
                          className="form-control"
                          name="lectureHour"
                          value={top.lectureHour}
                          onChange={(e) => this.handleChange(e, idx)}
                        ></input>
                      </div>
                      <div className="col-md-2">
                        <input
                          type="number"
                          className="form-control"
                          name="practicalHour"
                          value={top.practicalHour}
                          onChange={(e) => this.handleChange(e, idx)}
                        ></input>
                      </div>
                      <div className="col-md-auto">
                        <button
                          className="tt-button tt-button-primary mr-3"
                          onClick={() => this.addSubtopicForMainTopic(idx)}
                          disabled={
                            top.subTopic && top.subTopic.length > 0
                              ? true
                              : false
                          }
                        >
                          Add topics
                        </button>
                      </div>
                      <div className="col-md-1 text-center">
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="removeMcqOption"
                          onClick={(e) => this.removeTopic(e, idx)}
                        ></FontAwesomeIcon>
                      </div>
                      <div className="col-md-1 text-center">
                        {idx === this.state.topic.length - 1 ? (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="addMcqOption"
                            onClick={this.addTopic}
                          ></FontAwesomeIcon>
                        ) : null}
                      </div>
                    </div>
                    {top.subTopic && top.subTopic.length > 0 ? (
                      <div className="row mt-1 mb-3">
                        <div className="col-md-1"></div>
                        <div className="col">
                          <SubTopicReRender
                            topic={top.subTopic}
                            parentData={top}
                            parentIndex={idx}
                            parentIndexes={top.selectedIndex}
                            addSubtopics={this.addSubtopics}
                            handleReRenderChange={this.handleReRenderChange}
                            addSubtopicAsTopic={this.addSubtopicAsTopic}
                            removeSubtopicAsTopic={this.removeSubtopicAsTopic}
                          ></SubTopicReRender>
                        </div>
                      </div>
                    ) : null}
                  </span>
                );
              })
            : null}
        </div>
        <div className="row button-bg-color">
          <div className="col text-right">
            {this.props.academicRelated ? (
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.addCourseSyllabus}
              >
                Create Course Syllabus
              </button>
            ) : (
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.addTopicInSubject}
              >
                Create Course
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SubjectTopicSetting;

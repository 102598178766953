import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../../utils/Constants";
import SearchStudentInventory from "./SearchStudentInventory";
import { orderBy } from "lodash";
import ModalWindow from "../../UI/ModalWindow";
import GranularPermissionModal from "../../Permissions/GranularPermissionModal";

class StudentInventory extends Component {
  state = {
    educationLevels: [],
    educationLevel: "",
    yearLevels: [],
    yearLevel: "",
    classrooms: [],
    classroom: "",
    studentInventoryList: [],
    studentList: [],
    admissionId: "",
    loader: false,
    permissionModal: false,
  };

  componentDidMount() {
    this.getEducationLevels();
    this.getStudentInventoryList({});
  }

  filterResult = () => {
    let param = {
      educationLevelId: this.state.educationLevel,
      yearLevelId: this.state.yearLevel,
      classroomId: this.state.classroom,
      admissionId: this.state.admissionId,
    };
    this.getStudentInventoryList(param);
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
          });
      }
    });
  };

  getStudentInventoryList = (param) => {
    this.setState({ loader: true, studentInventoryList: [] }, function () {
      axiosPost(
        URL.getStudentInventory,
        param,
        (response) => {
          if (response.status === 200) {
            this.setState({
              studentInventoryList: response.data.data,
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
        }
      );
    });
  };

  getStudentList = () => {
    let param = {
      yearLevelId: this.state.yearLevel,
      classroomId: this.state.classroom,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status == 200) {
        this.setState({ studentList: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "educationLevel") {
        this.state.educationLevels.forEach((el) => {
          if (el.id === parseInt(value)) {
            this.setState({
              yearLevels: el.yearLevels,
              yearLevel: "",
              classrooms: [],
              classroom: "",
              studentList: [],
              admissionId: "",
            });
          }
        });
      }
      if (name === "yearLevel") {
        let data = {
          yearLevelId: value,
        };
        axiosPost(URL.selectClassroom, data, (response) => {
          if (response.status === 200) {
            this.setState({
              classrooms: response.data.data,
              classroom: "",
              studentList: [],
              admissionId: "",
            });
          }
        });
      }
      if (name === "classroom") {
        this.setState({
          studentList: [],
          admissionId: "",
        });
        this.getStudentList();
      }
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">
          {" "}
          Student Inventory List
          {checkWidgetAdminOrNot("Account") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="container-fluid">
          <SearchStudentInventory
            educationLevel={this.state.educationLevel}
            educationLevels={this.state.educationLevels}
            yearLevel={this.state.yearLevel}
            yearLevels={this.state.yearLevels}
            handleChange={this.handleChange}
            classroom={this.state.classroom}
            studentList={this.state.studentList}
            classrooms={this.state.classrooms}
            filterResult={this.filterResult}
            admissionId={this.state.admissionId}
          ></SearchStudentInventory>
          <table className="table table-bordered table-striped mt-2">
            <thead className="tt-group-header">
              <tr>
                <th width="70px" className="text-center">
                  S.N
                </th>
                <th></th>
                <th>Student Name</th>
                <th>Inventory</th>
                <th width="90px" className="text-center">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.studentInventoryList.length > 0 ? (
                this.state.studentInventoryList.map((el, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td>
                        <strong>{el.educationLevelName}</strong>
                        <br />
                        {`${localStorage.getItem("yearLevel")} ${
                          el.yearLevelName
                        } - ${localStorage.getItem("classroom")} ${
                          el.classroomName
                        } `}
                      </td>
                      <td>{el.studentName}</td>
                      <td>
                        <strong>{el.feeCategoryName}</strong> - {el.itemName}
                      </td>
                      <td className="text-center">{el.quantity}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    {this.state.loader ? (
                      <>
                        <Spinner color="primary"></Spinner>
                        <br />
                        Loading...
                      </>
                    ) : (
                      "None of the Inventory items are not assigned to student"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Account"
              moduleName="Inventory"
              header="Student Inventory List"
              activityName="student-inventory"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default StudentInventory;

import React from "react";
import Template1 from "../CardsAndCertificates/CardTemplates/Template1";
import Template2 from "../CardsAndCertificates/CardTemplates/Template2";
import Template3 from "../CardsAndCertificates/CardTemplates/Template3";
import { URL } from "../../utils/Constants";

const ShowCardDetails = (props) => {
  const {
    cardDetails,
    userType,
    classOptionValue,
    schoolDetails,
    staffList,
    studentList,
    classValue,
  } = props;

  return (
    <>
      {staffList && userType.value === 2
        ? staffList?.map((value) => {
            return (
              <>
                {cardDetails?.map((items) => {
                  return (
                    <>
                      {schoolDetails &&
                        schoolDetails?.map((school) => {
                          return (
                            <>
                              {items?.templateId === 1 ? (
                                <Template1
                                  studentName={value?.people?.name}
                                  subtitle={value.designations?.map(
                                    (item) => item.name
                                  )}
                                  schoolLogo={`${URL.imageSelectURL}${school?.imageName}`}
                                  schoolName={school?.name}
                                  schoolAddress={school?.address}
                                  // signature={items?.authorizedSignatureImagePath}
                                  authorizedSignatureName={
                                    items?.authorizedSignatureName
                                  }
                                  signature={
                                    items?.authorizedSignatureImagePath
                                      ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                                      : ""
                                  }
                                  validityDate={items?.validTill}
                                  schoolContact={school?.phoneNumber}
                                  schoolWebAddress={school?.website}
                                  userImage={
                                    value?.photo
                                      ? `${URL.imageSelectURL}${value?.photo}`
                                      : ""
                                  }
                                />
                              ) : items.templateId === 2 ? (
                                <Template2
                                  studentName={value?.people?.name}
                                  subtitle={value.designations?.map(
                                    (item) => item.name
                                  )}
                                  schoolLogo={`${URL.imageSelectURL}${school?.imageName}`}
                                  schoolName={school?.name}
                                  schoolAddress={school?.address}
                                  // signature={items?.authorizedSignatureImagePath}
                                  authorizedSignatureName={
                                    items?.authorizedSignatureName
                                  }
                                  signature={
                                    items?.authorizedSignatureImagePath
                                      ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                                      : ""
                                  }
                                  validityDate={items?.validTill}
                                  schoolContact={school?.phoneNumber}
                                  schoolWebAddress={school?.website}
                                  userImage={
                                    value?.photo
                                      ? `${URL.imageSelectURL}${value?.photo}`
                                      : ""
                                  }
                                />
                              ) : items.templateId === 3 ? (
                                <Template3
                                  studentName={value?.people?.name}
                                  subtitle={value.designations?.map(
                                    (item) => item.name
                                  )}
                                  schoolLogo={`${URL.imageSelectURL}${school?.imageName}`}
                                  schoolName={school?.name}
                                  schoolAddress={school?.address}
                                  // signature={items?.authorizedSignatureImagePath}
                                  authorizedSignatureName={
                                    items?.authorizedSignatureName
                                  }
                                  signature={
                                    items?.authorizedSignatureImagePath
                                      ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                                      : ""
                                  }
                                  validityDate={items?.validTill}
                                  schoolContact={school?.phoneNumber}
                                  schoolWebAddress={school?.website}
                                  userImage={
                                    value?.photo
                                      ? `${URL.imageSelectURL}${value?.photo}`
                                      : ""
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                    </>
                  );
                })}
              </>
            );
          })
        : ""}
      {studentList && userType.value === 1
        ? studentList?.map((value) => {
            return (
              <>
                {cardDetails?.map((items) => {
                  return (
                    <>
                      {schoolDetails.map((school) => {
                        return (
                          <>
                            {items?.templateId === 1 ? (
                              <Template1
                                studentName={value?.people?.name}
                                subtitle={classValue?.label}
                                schoolLogo={`${URL.imageSelectURL}${school?.imageName}`}
                                schoolName={school?.name}
                                schoolAddress={school?.address}
                                // signature={items?.authorizedSignatureImagePath}
                                authorizedSignatureName={
                                  items?.authorizedSignatureName
                                }
                                signature={
                                  items?.authorizedSignatureImagePath
                                    ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                                    : ""
                                }
                                validityDate={items?.validTill}
                                schoolContact={school?.phoneNumber}
                                schoolWebAddress={school?.website}
                                userImage={
                                  value?.people?.photo
                                    ? `${URL.imageSelectURL}${value?.people?.photo}`
                                    : ""
                                }
                                emergencyContact={
                                  value?.studentContacts[0]?.contactPeople
                                }
                              />
                            ) : items.templateId === 2 ? (
                              <Template2
                                studentName={value?.people?.name}
                                subtitle={classValue?.label}
                                schoolLogo={`${URL.imageSelectURL}${school?.imageName}`}
                                schoolName={school?.name}
                                schoolAddress={school?.address}
                                // signature={items?.authorizedSignatureImagePath}
                                authorizedSignatureName={
                                  items?.authorizedSignatureName
                                }
                                signature={
                                  items?.authorizedSignatureImagePath
                                    ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                                    : ""
                                }
                                validityDate={items?.validTill}
                                schoolContact={school?.phoneNumber}
                                schoolWebAddress={school?.website}
                                userImage={
                                  value?.people?.photo
                                    ? `${URL.imageSelectURL}${value?.people?.photo}`
                                    : ""
                                }
                                emergencyContact={
                                  value?.studentContacts[0]?.contactPeople
                                }
                              />
                            ) : items.templateId === 3 ? (
                              <Template3
                                studentName={value?.people?.name}
                                subtitle={classValue?.label}
                                schoolLogo={`${URL.imageSelectURL}${school?.imageName}`}
                                schoolName={school?.name}
                                schoolAddress={school?.address}
                                // signature={items?.authorizedSignatureImagePath}
                                authorizedSignatureName={
                                  items?.authorizedSignatureName
                                }
                                signature={
                                  items?.authorizedSignatureImagePath
                                    ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                                    : ""
                                }
                                validityDate={items?.validTill}
                                schoolContact={school?.phoneNumber}
                                schoolWebAddress={school?.website}
                                userImage={
                                  value?.people?.photo
                                    ? `${URL.imageSelectURL}${value?.people?.photo}`
                                    : ""
                                }
                                emergencyContact={
                                  value?.studentContacts[0]?.contactPeople
                                }
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </>
            );
          })
        : ""}
    </>
  );
};
export default ShowCardDetails;

/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, schoolInformation, render the School schoolInformation with data initially filled
 *              through the System Setup Panel
 */

import React, { Component } from "react";
import {
  URL,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import { axiosPost } from "../../../../utils/AxiosApi";
import ModalWindow from "../../../UI/ModalWindow";
import InformationForm from "./InformationForm";
import swal from "sweetalert";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";

class schoolInfosrmation extends Component {
  state = {
    regEx: /^[0-9a-zA-Z!.;':" ]*$/,
    id: "",
    schoolInfos: [],
    settingModal: false,
    countryId: "",
    countrys: [],
    name: "",
    acronym: "",
    address: "",
    postalCode: "",
    phoneNumber: "",
    fax: "",
    emailAddress: "",
    website: "",
    panVatNo:"",
    dateOfEstablishment: "",
    calendarTypeId: "",
    editable: false,
    countryFirstLevelId: null,
    countrySecondLevelId: null,
    countryThirdLevelId: null,
    countryFirstLevel: [],
    countrySecondLevel: [],
    countryThirdLevel: [],
    isLoading: true,
    updatedImage: null,
    switchToggle: null,
  };

  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches all the list
   * from the school_schoolInfoss table from the database.
   *
   */
  componentDidMount() {
    this.getSchoolInfo();
    this.getCountry();
  }

  getCountry = () => {
    axiosPost(URL.getCountry, {}, (response) => {
      if (response.status === 200) {
        this.setState({ countrys: response.data.data });
      }
    });
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data[0],
          countryId: response.data.data[0].countryId,
          isLoading: false,
          switchToggle: response.data.data[0].logoLocation,
        });
      }
    });
  };

  handleImageChange = (e) => {
    this.setState({ updatedImage: e.target.files[0] });
  };
  /**
   * This method handles the input on Change
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "name") {
      if (!value.match(this.state.regEx)) {
        let errorCharacter = value.slice(-1);
        swal({
          title: "Warning",
          text: `${errorCharacter} not allowed`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
    if (name === "countryId") {
      const data = {
        countryId: e.target.value,
      };
      axiosPost(URL.getCountryFirstLevel, data, (response) => {
        this.setState({
          countryFirstLevel: response.data.data,
          countrySecondLevel: [],
          countryThirdLevel: [],
        });
      });
    }
    if (name === "firstLevelDisplayName") {
      const data = {
        countryId: parseInt(this.state.countryId),
        countryFirstLevelId: e.target.value,
      };
      this.setState({ countryFirstLevelId: value }, () => {
        axiosPost(URL.getCountrySecondLevel, data, (response) => {
          this.setState({
            countrySecondLevel: response.data.data,
          });
        });
      });
    }
    if (name === "secondLevelDisplayName") {
      const data = {
        countryId: parseInt(this.state.countryId),
        countryFirstLevelId: parseInt(this.state.countryFirstLevelId),
        countrySecondLevelId: e.target.value,
      };
      this.setState({ countrySecondLevelId: value }, () => {
        axiosPost(URL.getCountryThirdLevel, data, (response) => {
          this.setState({ countryThirdLevel: response.data.data });
        });
      });
    }
    if (name === "thirdLevelDisplayName") {
      this.setState({ countryThirdLevelId: value });
    }
  };
  //

  closeEditable = (e) => {
    e.preventDefault();
    this.setState({
      editable: false,
      updatedImage: null,
    });
  };

  /**
   * This method toggle the update modal
   */
  toggleSettingModal = (e) => {
    e.preventDefault();
    const data = {
      countryId: this.state.schoolInfos["countryId"],
    };
    axiosPost(URL.getCountryFirstLevel, data, (response) => {
      this.setState({
        countryFirstLevel: response.data.data,
      });
    });

    const data1 = {
      countryId: this.state.schoolInfos["countryId"],
      countryFirstLevelId: this.state.schoolInfos["countryFirstLevelId"],
    };
    axiosPost(URL.getCountrySecondLevel, data1, (response) => {
      this.setState({
        countrySecondLevel: response.data.data,
      });
    });

    const data2 = {
      countryId: this.state.schoolInfos["countryId"],
      countryFirstLevelId: this.state.schoolInfos["countryFirstLevelId"],
      countrySecondLevelId: this.state.schoolInfos["countrySecondLevelId"],
    };

    axiosPost(URL.getCountryThirdLevel, data2, (response) => {
      this.setState({ countryThirdLevel: response.data.data });
    });

    this.setState({
      //   settingModal: !this.state.settingModal,
      editable: true,
      id: this.state.schoolInfos["id"],
      name: this.state.schoolInfos["name"],
      acronym: this.state.schoolInfos["acronym"],
      address: this.state.schoolInfos["address"],
      postalCode: this.state.schoolInfos["postalCode"],
      phoneNumber: this.state.schoolInfos["phoneNumber"],
      fax: this.state.schoolInfos["fax"],
      emailAddress: this.state.schoolInfos["emailAddress"],
      website: this.state.schoolInfos["website"],
      panVatNo: this.state.schoolInfos["panVatNo"],
      dateOfEstablishment: this.state.schoolInfos["dateOfEstablishment"],
      calendarTypeId: this.state.schoolInfos["calendarTypeId"],
      countryFirstLevelId: this.state.schoolInfos["countryFirstLevelId"],
      countrySecondLevelId: this.state.schoolInfos["countrySecondLevelId"],
      countryThirdLevelId: this.state.schoolInfos["countryThirdLevelId"],
    });
  };
  /**
   * This method posts a update request to update school information
   */
  updateSchoolInformation = (e, id) => {
    e.preventDefault();
    let datas = {
      id: this.state.id,
      name: this.state.name,
      address: this.state.address,
      postalCode: this.state.postalCode,
      phoneNumber: this.state.phoneNumber,
      fax: this.state.fax,
      emailAddress: this.state.emailAddress,
      website: this.state.website,
      panVatNo:this.state.panVatNo,
      dateOfEstablishment: new Date(this.state.dateOfEstablishment),
      calendarTypeId: this.state.calendarTypeId,
      countryId: this.state.countryId,
      countryFirstLevelId: this.state.countryFirstLevelId,
      countrySecondLevelId: this.state.countrySecondLevelId,
      countryThirdLevelId: this.state.countryThirdLevelId,
      acronym: this.state.acronym,
      logoLocation: this.state.switchToggle,
    };
    if (datas.name === "") {
      swal({
        title: "Warning",
        text: "Enter school name first",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      var formData = new FormData();
      formData.append("jsonData", JSON.stringify(datas));
      formData.append("files", this.state.updatedImage);
      axiosPost(URL.updateSchoolInfo, formData, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: "Records updated successfully",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        }
        this.getSchoolInfo();
        this.setState({
          editable: false,
        });
      });
    }
  };

  handleSwitchChange = (e) => {
    // e.preventDefault();
    this.setState({ switchToggle: !this.state.switchToggle });
  };

  render() {
    const schoolInfos = this.state.schoolInfos;

    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Information
            {checkWidgetAdminOrNot("Academic Administration") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          {!this.state.isLoading ? (
            <InformationForm
              schoolInfos={schoolInfos}
              editable={this.state.editable}
              toggleSettingModal={this.toggleSettingModal}
              handleChange={this.handleChange}
              updateSchoolInformation={this.updateSchoolInformation}
              countrys={this.state.countrys}
              countryFirstLevelId={this.state.countryFirstLevelId}
              countrySecondLevelId={this.state.countrySecondLevelId}
              countryThirdLevelId={this.state.countryThirdLevelId}
              countryFirstLevel={this.state.countryFirstLevel}
              countrySecondLevel={this.state.countrySecondLevel}
              countryThirdLevel={this.state.countryThirdLevel}
              id={this.state.id}
              countryId={this.state.countryId}
              name={this.state.name}
              acronym={this.state.acronym}
              address={this.state.address}
              postalCode={this.state.postalCode}
              phoneNumber={this.state.phoneNumber}
              fax={this.state.fax}
              emailAddress={this.state.emailAddress}
              website={this.state.website}
              panVatNo={this.state.panVatNo}
              dateOfEstablishment={this.state.dateOfEstablishment}
              calendarTypeId={this.state.calendarTypeId}
              closeEditable={this.closeEditable}
              handleImageChange={this.handleImageChange}
              updatedImage={this.state.updatedImage}
              switchToggle={this.state.switchToggle}
              handleSwitchChange={this.handleSwitchChange}
            />
          ) : (
            <div className="text-center">
              <Spinner color="primary" style={{ marginTop: "200px" }} />
            </div>
          )}
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School"
              header="View School Information"
              activityName="select-school-information"

            />
          }
        ></ModalWindow>
      </>
    );
  }
}

export default schoolInfosrmation;

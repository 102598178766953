import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFileImage,
  faTimesCircle,
  faCheckCircle,
  faEllipsisV,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  userRoleCode,
  fileDownloadURL,
  reportDownloadURL,
  postType,
  draggable,
  userRole,
} from "../../../utils/Constants";
import Comment from "./Comment";
import "../../../../src/assets/scss/comment.scss";
import "../../../../src/assets/scss/template.scss";
import ReactTooltip from "react-tooltip";
import $, { post } from "jquery";
import jwtDecode from "jwt-decode";
import ModalWindow from "../../UI/ModalWindow";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
} from "../../../utils/Utils";
import swal from "sweetalert";
import ArticleReadMoreTemplate from "../Templates/ArticleReadMoreTemplate";
import ReadMoreTemplate from "../Templates/ReadMoreTemplate";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NoticeEditTemplate from "../Templates/NoticeEditTemplate";
import NoticeHistory from "../Templates/HistoryTemplates/NoticeHistory";
import HistoryTemplates from "../Templates/HistoryTemplates/HistoryTemplates";
// import FileModal from "../../ViewFile/FileModal";
import { FileViewer } from "react-file-viewer";
import FileModal from "../../ViewFile/FileModal";
import {
  URL as URLs,
  checkRolePermissions,
  modules,
} from "../../../utils/Constants";

class NoticeModalBody extends Component {
  state = {
    ackPeoples: [],
    acknowledge: false,
    user: { peopleId: 1 },
    groupPeoples: [],
    scrollable: false,
    slideShareURl: "",
    keywordModal: false,
    targetGroups: [],
    tooltipOpen: false,
    acknowledgedPeople: 0,
    peopleId: this.props.peopleId,
    profilePeopleId: this.props.profilePeopleId,
    ackPeopleName: "",
    minimizeAck: true,
    ackSpinner: false,
    accepReject: false,
    moderatedStatus:
      this.props.notice.postType === postType.postCommentModeration
        ? this.props.notice.postComment.moderatedStatus
        : this.props.notice.postType === postType.academicPostCommentModeration
        ? this.props.notice.academicRepoComment.moderatedStatus
        : this.props.notice.moderatedStatus,
    dropdownOpen: false,
    editNoticeModal: false,
    selectedNotice: "",
    showSpinner: false,
    noticeHistory: [],
  };

  toggleMenu = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleNoticeEditModal = (data, action) => {
    if (action === "open") {
      this.setState({ selectedNotice: data });
    }
    this.setState({
      editNoticeModal: !this.state.editNoticeModal,
    });
  };

  toggleFileViewer = () => {
    this.setState({
      // fileUrl: URLs.imageSelectURL + param,
      fileViwerModal: !this.state.fileViwerModal,
    });
  };
  handleFileViewer = (e, param, idx) => {
    this.toggleFileViewer();
    this.checkFileExist(param.content);
    this.setState({
      filePath: param.content,
      fileType: param.contentTypeCode,
      activeIndex: param.id,
    });
  };

  toggleHistoryEditModal = (e, data, action) => {
    if (action === "open") {
      this.getHistoryList(data);
      this.setState({ selectedNotice: data });
    } else {
      this.setState({
        historyNoticeModal: !this.state.historyNoticeModal,
      });
    }
  };

  getHistoryList = (param) => {
    let data = {
      updatedId: param.id,
    };
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.getPostHistory,
        data,
        (response) => {
          this.setState({ showSpinner: false });
          if (response.status === 200) {
            this.setState({ noticeHistory: response.data.data }, function () {
              this.setState({
                historyNoticeModal: !this.state.historyNoticeModal,
              });
            });
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
        }
      );
    });
  };

  confirmDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deletePost);
  };

  deletePost = (param) => {
    let data = {
      id: param.id,
    };
    axiosPost(URL.deletePost, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.closeModal();
        // this.props.handleCurrentPost();
      }
    });
  };

  componentDidMount() {
    this.filterFiles();
    this.props.getComments(this.props.notice.id);
    setInterval(() => {
      this.handleScroll();
    }, 100);
    this.getAckStatus();
    this.getGroupPeoples();
  }

  filterFiles = () => {
    let file = [];
    let image = [];
    this.props.notice.contents.length > 0 &&
      this.props.notice.contents.map((content) => {
        if (
          content.contentTypeCode === "PDF" ||
          content.contentTypeCode === "TXT"
          // content.contentTypeCode
        ) {
          file.push(content);
        } else if (content.contentTypeCode === "IMG") {
          image.push(content);
        }
      });
    this.setState({ files: file, images: image }, () => {
      if (this.state.files.length > 0) {
        // let initialFile = this.state.files[0];
        this.setState({
          filePath: this.state.files[0].content,
          fileType: this.state.files[0].contentTypeCode,
          activeIndex: 0,
        });
      }
    });
  };

  handleChangeFile = (file, content, contentTypeCode, idx) => {
    this.setState(
      {
        filePath: "",
        fileType: "",
      },
      () => {
        this.checkFileExist(content);
        this.setState({
          filePath: content,
          fileType: contentTypeCode,
          activeIndex: file.id,
        });
      }
    );
  };

  checkFileExist = (path) => {
    let data = URL.imageSelectURL + path;
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", data, false);
    xhr.send();

    let status = parseInt(xhr.status);
    if (status == 404 || status == 401) {
      this.setState({
        fileExist: false,
      });
    } else {
      this.setState({
        fileExist: true,
      });
    }
  };

  handleScroll = () => {
    let modalElement = document.getElementById("noticeModalBody");
    if (modalElement) {
      this.setState({
        scrollable: modalElement.scrollHeight > modalElement.offsetHeight,
      });
    }
  };

  getGroupPeoples = () => {
    this.setState({ ackSpinner: true }, function () {
      axiosPost(
        URL.selectGroupPeople,
        {
          postId: this.props.notice.id,
        },
        (response) => {
          if (response.status === 200) {
            let groupPeoples = response.data.data;
            let acknowledgedPeople = 0;
            if (groupPeoples.length > 0) {
              groupPeoples.forEach((el) => {
                if (el.acknowledged) {
                  acknowledgedPeople++;
                }
              });
            }
            this.setState({
              groupPeoples,
              acknowledgedPeople,
              ackSpinner: false,
            });
          }
        },
        (error) => {
          displayErrorAlert(error);
          this.setState({ ackSpinner: false });
        }
      );
    });
  };
  getLoggedInPeopleId = () => {
    try {
      let jwt = localStorage.getItem("token");
      let user = jwtDecode(jwt);
      this.setState({ user });
    } catch (error) {}
  };
  getAckStatus = () => {
    axiosPost(
      URL.getAcknowledgeStatus,
      { postId: this.props.notice.id },
      (response) => {
        if ((response.status = 200) && response.data.data) {
          let acknowledge = response.data.data;
          this.setState({ acknowledge: acknowledge.acknowledged });
        }
      }
    );
  };
  ackNotice = () => {
    axiosPost(
      URL.setAcknowledgeStatus,
      { postId: this.props.notice.id },
      (response) => {
        if ((response.status = 200)) {
          this.getAckStatus();
        }
      }
    );
  };

  setRemindStatus = (e, peopleId) => {
    let data = {
      postId: this.props.notice.id,
      peopleId: peopleId,
      remind: true,
    };
    axiosPost(URL.setRemindStatus, data, (response) => {
      if (response.status === 200) {
        this.getGroupPeoples();
      }
    });
  };

  getEmbedLink = (link) => {
    let embeddedURL = "";
    let videoId = "";
    if (link.includes("youtube") || link.includes("youtu.be")) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = link.match(regExp);
      if (match && match[7].length == 11) {
        videoId = match[7];
        embeddedURL = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (link.includes("dailymotion") || link.includes("dai.ly")) {
      videoId = this.getDailyMotionId(link);
      embeddedURL = `https://www.dailymotion.com/embed/video/${videoId}`;
    } else if (link.includes("vimeo")) {
      videoId = this.getVimeoId(link);
      embeddedURL = `https://player.vimeo.com/video/${videoId}`;
    } else if (link.includes("slideshare")) {
      let self = this;
      let src = "";
      if (self.state.slideShareURl === "") {
        $.getJSON(
          "https://noembed.com/embed",
          { format: "json", url: link },
          function (data) {
            if (data !== undefined || data) {
              let htmlCode = data.html;
              src = self.getSlideShareSrc(htmlCode);
              self.setState({ slideShareURl: src });
            }
          }
        );
      }
      embeddedURL = this.state.slideShareURl;
    }
    return embeddedURL;
  };

  getSlideShareSrc = (code) => {
    let count = 0;
    let link = "";
    for (let i = 0; i < code.length; i++) {
      if (code.charAt(i) === '"') {
        count++;
      }
      if (count !== 0 && count < 2 && code.charAt(i + 1) !== '"') {
        link += code.charAt(i + 1);
      }
    }
    return link;
  };

  getDailyMotionId = (url) => {
    var m = url.match(
      /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
    );
    if (m === null) {
      var n = url.match(
        /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
      );
      if (n !== null) {
        if (n[4] !== undefined) {
          return n[4];
        }
        return n[2];
      }
    } else if (m !== null) {
      if (m[4] !== undefined) {
        return m[4];
      }
      return m[2];
    } else {
      return null;
    }
  };

  getVimeoId = (url) => {
    var regExp =
      /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    var parseUrl = url.match(regExp);
    return parseUrl[5];
  };

  handleDownloadFile = (fileName) => {
    let formData = new FormData();
    formData.append("fileName", fileName);

    axiosPost(URL.downloadFile, formData, (response) => {
      if (response.status === 200) {
        window.open(response.data);
      }
    });
  };

  displayKeywordModal = (e, groups) => {
    this.setState({
      keywordModal: !this.state.keywordModal,
      targetGroups: groups,
    });
  };

  toggleKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.state.targetGroups
          ? this.state.targetGroups.map((group, idx) => {
              return <li key={idx}>{group.name}</li>;
            })
          : null}
      </ol>
    );
  };

  //Search Acknowledge
  searchAckPeople = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      let data = {
        peopleName: this.state.ackPeopleName,
        postId: this.props.notice.id,
      };
      axiosPost(URL.searchPostAudience, data, (response) => {
        if (response.status === 200) {
          let groupPeoples = response.data.data;
          let acknowledgedPeople = 0;
          if (groupPeoples.length > 0) {
            acknowledgedPeople = groupPeoples
              .filter((people) => people.acknowledged)
              .reduce((total, n) => total + n, 0);
          }
          this.setState({
            groupPeoples,
            acknowledgedPeople,
          });
        }
      });
    });
  };

  minimizeAck = (e, id) => {
    e.preventDefault();
    let ackBox = document.getElementById(id);
    this.setState({ minimizeAck: !this.state.minimizeAck }, function () {
      if (this.state.minimizeAck) {
        ackBox.classList.add("ackHeader-minimize");
      } else {
        ackBox.classList.remove("ackHeader-minimize");
      }
    });
  };

  handleAcceptReject = (e, notice, status) => {
    let data = {
      moderatedStatus: status,
    };
    let url = "";
    if (notice.postType === postType.postModeration) {
      data.postId = notice.id;
      url = URL.acceptRejectPost;
    } else {
      data.postId = notice.id;
      url = URL.acceptRejectPP;
    }

    if (notice.postType === postType.postCommentModeration) {
      data["postId"] = data["postNoticeId"];
      data.postNoticeId = notice.id;
      delete data["postId"];
      url = URL.handleCommentModeartion;
    }

    if (notice.postType === postType.academicPostCommentModeration) {
      data["postId"] = data["postNoticeId"];
      data.postNoticeId = notice.id;
      delete data["postId"];
      url = URL.handleAcademicRepoCommentModeration;
    }

    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: `This request has been ${response.data.data.moderatedStatus}`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        this.setState({
          accepReject: true,
          moderatedStatus: response.data.data.moderatedStatus,
        });
        this.props.callNoticeFunctionForModeration();
        draggable();
      }
    });
  };

  render() {
    const toggleFunc = this.toggleNoticeEditModal;
    let count = 0;
    return (
      <div className="container-fluid" id="noticeModalBody">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="row" id="top">
                  <div className="col-md-6">
                    <strong>{this.props.notice.peopleName}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p
                      className="tt-noticeModalDate"
                      data-tip={moment(this.props.notice.createdAt).format(
                        "LT"
                      )}
                    >
                      {moment(this.props.notice.createdAt).format(
                        "dddd, Do MMMM, YYYY"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <ReactTooltip
                place="bottom"
                className="tt-reactToolTip"
                globalEventOff="click"
              ></ReactTooltip>
              <ReactTooltip
                className="tt-reactTooltip"
                id="groupKey"
                globalEventOff="click"
              ></ReactTooltip>
              <div className="col text-right">
                {this.props.notice.postType === postType.profilePictureNotice ||
                this.props.notice.postType === postType.postModeration ||
                this.props.notice.postType === postType.postCommentModeration ||
                this.props.notice.postType ===
                  postType.academicPostCommentModeration ? (
                  this.props.notice.canModerate &&
                  this.state.moderatedStatus === "PENDING" &&
                  this.state.accepReject === false ? (
                    <>
                      <button
                        className="tt-button tt-button-primary acceptChangeButton tt-mobileButton"
                        onClick={(e) =>
                          this.handleAcceptReject(
                            e,
                            this.props.notice,
                            "ACCEPTED"
                          )
                        }
                      >
                        Accept
                      </button>
                      <button
                        className="tt-button tt-button-primary tt-mobileButton"
                        onClick={(e) =>
                          this.handleAcceptReject(
                            e,
                            this.props.notice,
                            "REJECTED"
                          )
                        }
                      >
                        Reject
                      </button>
                    </>
                  ) : (
                    <div className="tt-bubble-background text-right">
                      <div>{this.state.moderatedStatus}</div>
                    </div>
                  )
                ) : null}
                {this.props.notice.postType === postType.videoConference ? (
                  <button
                    className="tt-button tt-button-primary mr-3 tt-mobileButton"
                    onClick={(e) =>
                      this.props.handleJoinConference(this.props.notice.id)
                    }
                  >
                    Join
                  </button>
                ) : null}

                {this.props.notice.postType !== postType.profilePictureNotice &&
                this.props.notice.postType !== postType.coverPictureNotice &&
                this.props.notice.postType !== postType.postModeration &&
                this.props.notice.postType !== postType.assignment ? (
                  this.props.notice ? (
                    this.props.notice.peopleId ===
                    parseInt(localStorage.getItem("peopleId")) ? null : this
                        .state.acknowledge ? null : this.state.peopleId ===
                      this.state.profilePeopleId ? (
                      <button
                        className="tt-button tt-button-primary tt-mobileButton"
                        onClick={this.ackNotice}
                        //   disabled={this.state.acknowledge ? "disabled" : null}
                      >
                        Acknowledge Notice
                      </button>
                    ) : null
                  ) : null
                ) : null}
              </div>
              {this.props.notice.postType === postType.noticeCode ? (
                parseInt(this.props.notice.peopleId) ===
                parseInt(localStorage.getItem("peopleId")) ? (
                  <div
                    className="col-md-auto text-right"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="tt-postUpdateDeleteIcon"
                      style={{ position: "absolute", right: 0, top: "8px" }}
                    >
                      <Dropdown
                        className="dropdown-trigger"
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleMenu}
                      >
                        <DropdownToggle>
                          <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
                        </DropdownToggle>
                        <DropdownMenu className="tt-post-dropdown">
                          {userRole === userRoleCode.roleAdmin ? (
                            <>
                              <DropdownItem
                                className="tt-post-dropbtn"
                                onClick={() =>
                                  toggleFunc(this.props.notice, "open")
                                }
                              >
                                Edit
                              </DropdownItem>

                              <DropdownItem
                                className="tt-post-dropbtn"
                                onClick={() =>
                                  this.confirmDelete(this.props.notice.id)
                                }
                              >
                                Delete
                              </DropdownItem>
                            </>
                          ) : null}
                          <DropdownItem
                            className="tt-post-dropbtn"
                            onClick={(e) =>
                              this.toggleHistoryEditModal(
                                e,
                                this.props.notice,
                                "open"
                              )
                            }
                          >
                            History
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                ) : null
              ) : null}
            </div>
            <div className="tt-mobileNoticeGroup">
              {this.props.notice.groups &&
              this.props.notice.groups.length > 0 ? (
                <div className="tt-groupTargetList">
                  {this.props.notice.groups.map((group, idx) => {
                    if (group.name === "") {
                      return null;
                    } else {
                      return idx <= 1 ? (
                        <div
                          key={idx}
                          data-tip={group.name}
                          data-for="groupKey"
                        >
                          {group.name}
                        </div>
                      ) : idx === this.props.notice.groups.length - 1 ? (
                        <div
                          key={idx}
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            this.displayKeywordModal(
                              e,
                              this.props.notice.groups
                            )
                          }
                        >
                          + {this.props.notice.groups.length - 2} more
                        </div>
                      ) : (
                        ""
                      );
                    }
                  })}
                </div>
              ) : null}
            </div>
            <div className="tt-showMobileGroup">
              {this.props.notice.groups &&
              this.props.notice.groups.length > 0 ? (
                <div className="tt-groupTargetList">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      this.displayKeywordModal(e, this.props.notice.groups)
                    }
                  >
                    Groups Assigned
                  </div>
                </div>
              ) : null}
            </div>

            <div
              className={
                "mt-3 wordBreakHyphen editorPostContent" +
                (this.props.notice.postType === postType.profilePictureNotice
                  ? "noticeTextContent"
                  : "")
              }
            >
              {ReactHtmlParser(this.props.notice.textContent)}
              {this.props.notice.postType === postType.profilePictureNotice ? (
                <div className="noticeTextContent">
                  <p>
                    <img
                      src={
                        URL.imageSelectURL +
                        this.props.notice.profilePictureForModeration.name
                      }
                    ></img>
                  </p>
                </div>
              ) : null}

              {this.props.notice.postType === postType.postCommentModeration ? (
                <div
                  className="container-fluid postModerationHolder"
                  // id="noticeModalBody"
                >
                  <p>
                    <strong>Post Title:</strong>
                    {" " + this.props.notice.post.heading + " "}
                  </p>
                  <div>
                    <strong>Comment value</strong>:
                    {this.props.notice.postComment.commentValue}
                  </div>
                </div>
              ) : null}

              {this.props.notice.postType ===
              postType.academicPostCommentModeration ? (
                <div
                  className="container-fluid postModerationHolder"
                  // id="noticeModalBody"
                >
                  <p>
                    <strong>Academic Repository Post Title:</strong>
                    {" " + this.props.notice.academicRepository
                      ? this.props.notice.academicRepository.heading
                      : "" + " "}
                  </p>
                  <div>
                    <strong>Comment value</strong>:
                    {this.props.notice.academicRepoComment.commentValue}
                  </div>
                </div>
              ) : null}

              {this.props.notice.postType === postType.postModeration ? (
                <div className="postModerationHolder">
                  {this.props.notice.post.postType === postType.articleCode ? (
                    <ArticleReadMoreTemplate
                      post={this.props.notice.post}
                      moderation={true}
                    ></ArticleReadMoreTemplate>
                  ) : (
                    <ReadMoreTemplate
                      post={this.props.notice.post}
                      moderation={true}
                    ></ReadMoreTemplate>
                  )}
                </div>
              ) : null}
            </div>
            {this.props.notice.postType === postType.coverPictureNotice ? (
              <div className="row">
                {this.props.notice.coverPicturesForModeration.length > 0
                  ? this.props.notice.coverPicturesForModeration.map(
                      (pic, idx) => {
                        return (
                          <div
                            className="col-md-4 mt-1"
                            key={idx}
                            style={{ border: "1px solid #ccc" }}
                          >
                            <div className="cp-moderationImageHolder">
                              <img
                                src={URL.imageSelectURL + pic.name}
                                alt="pic for Moderation"
                              ></img>
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "6px",
                              }}
                            >
                              {this.props.notice.canModerate &&
                              pic.moderatedStatus === "PENDING" ? (
                                <>
                                  <button
                                    className="tt-button tt-button-primary mr-3"
                                    onClick={(e) =>
                                      this.props.handleModeration(
                                        e,
                                        pic.id,
                                        "ACCEPTED"
                                      )
                                    }
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={(e) =>
                                      this.props.handleModeration(
                                        e,
                                        pic.id,
                                        "REJECTED"
                                      )
                                    }
                                  >
                                    Reject
                                  </button>
                                </>
                              ) : (
                                <div className="tt-bubble-background text-center">
                                  <div>{pic.moderatedStatus}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )
                  : null}
              </div>
            ) : null}

            {this.props.isAssignment ? (
              <>
                {this.props.assignmentInfo ? (
                  <div className="row">
                    <div className="col">
                      <div className="tt-linkHolder">
                        <div
                          className="tt-group-header mb-2"
                          style={{ borderRadius: 0 }}
                        >
                          Assignment Details
                        </div>
                        <div className="tt-linkReference p-3">
                          <strong> Title: </strong>
                          {this.props.assignmentInfo.title}
                          <br />
                          <strong>Description:</strong>{" "}
                          {this.props.assignmentInfo.description !== ""
                            ? this.props.assignmentInfo.description
                            : "No description/instruction"}
                          <br />
                          <strong>Assigned To:</strong>{" "}
                          {this.props.assignmentInfo.yearLevelName +
                            "-" +
                            this.props.assignmentInfo.classroomName}
                          <br />
                          <strong> Subject: </strong>
                          {this.props.assignmentInfo.subjectName}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
            {this.props.notice.contents ? (
              this.props.notice.contents.length > 0 ? (
                <>
                  {this.props.notice.contents.map((content, idx) => {
                    if (content.contentTypeCode === "LNK") {
                      count = count + 1;
                    }
                  })}
                  {count > 0 ? (
                    <div className="row">
                      <div className="col">
                        <div className="tt-linkHolder">
                          <div className="tt-group-header mb-0">
                            Link References
                          </div>
                          <div>
                            {this.props.notice.contents.map((content, idx) => {
                              if (
                                content.contentTypeCode === "LNK" &&
                                content.content !== ""
                              ) {
                                return (
                                  <div className="tt-linkReference">
                                    <a
                                      href={content.content}
                                      target="_blank"
                                      key={idx}
                                      rel="noopener noreferrer"
                                    >
                                      <div className="row text-left">
                                        <div className="col">
                                          <div className="row tt-videoLNK">
                                            <div
                                              className="col"
                                              style={{
                                                display: "flex",
                                                alignItems: "Center",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              <p>
                                                {content.linkTitle !== ""
                                                  ? content.linkTitle
                                                  : content.content}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : null
            ) : // (
            //   <div className="text-center">No links added</div>
            // )
            null}
            {this.props.comments && this.props.comments.length > 0 ? (
              <div className="tt-noticeRSVP">
                <div className="tt-group-header text-center">Discussion</div>
                <ul className="list-group commentList">
                  {this.props.comments.map((comment, idx) => {
                    // if (!comment.deleteStatus)
                    return (
                      <Comment
                        key={comment.id}
                        comment={comment}
                        deleteComment={this.props.deleteComment}
                        toggleEditable={this.props.toggleEditable}
                        idx={idx}
                        newCommentValue={this.props.newCommentValue}
                        handleChange={this.props.handleChange}
                        updateComment={this.props.updateComment}
                        notice={true}
                      />
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>

          {this.props.notice.postType !== postType.profilePictureNotice &&
          this.props.notice.postType !== postType.coverPictureNotice &&
          this.props.notice.contents.length > 0 ? (
            <div className="col-md-3">
              {this.props.notice &&
              this.props.notice.contents.length > 0 &&
              (this.props.notice.contents.filter(
                (el) => el.contentTypeCode === "PDF"
              ).length > 0 ||
                this.props.notice.contents.filter(
                  (el) => el.contentTypeCode === "IMG"
                ).length > 0) ? (
                <div className="tt-attachedBox text-center">
                  <strong>Attached file(s)</strong>
                  <div className="row">
                    {this.props.notice.contents
                      ? this.props.notice.contents.map((content, idx) => {
                          if (content.contentTypeCode === "IMG")
                            return (
                              <div className="col-md-6">
                                <FontAwesomeIcon
                                  icon={faFileImage}
                                  size="3x"
                                ></FontAwesomeIcon>
                                <p
                                  style={{
                                    width: "100%",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    paddingLeft: "8px",
                                    whiteSpace: "nowrap",
                                    paddingRight: "8px",
                                  }}
                                >
                                  <a
                                    // href={URL.fileDownloadURL + content.content}
                                    // target="_blank"
                                    onClick={(e) =>
                                      this.handleFileViewer(e, content, idx)
                                    }
                                    className="d-block"
                                    target="_blank"
                                    download
                                  >
                                    {content.origin}
                                  </a>
                                </p>
                              </div>
                            );
                          if (
                            content.contentTypeCode === "PDF" ||
                            content.contentTypeCode === "TXT"
                          )
                            return (
                              <div className="col-md-6">
                                <a
                                  onClick={(e) =>
                                    this.handleFileViewer(e, content, idx)
                                  }
                                  className="d-block"
                                  target="_blank"
                                  download
                                >
                                  <FontAwesomeIcon
                                    icon={faFileAlt}
                                    size="3x"
                                  ></FontAwesomeIcon>
                                  <p
                                    style={{
                                      width: "100%",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      paddingLeft: "8px",
                                      whiteSpace: "nowrap",
                                      paddingRight: "8px",
                                      cursor: "default",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {/* <a
                                    href={URL.fileDownloadURL + content.content}
                                    target="_blank"
                                    download
                                  >
                                    {content.origin}
                                  </a> */}

                                    {content.origin}
                                  </p>
                                </a>
                              </div>
                            );
                        })
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {/* For video Conference */}
          {this.props.notice.postType === postType.videoConference ? (
            this.props.notice ? (
              <div
                className="tt-acknowledgeListBox ackHeader-minimize ackHeader-fixed"
                id="vidAckBox"
              >
                <div className="ackHeader">
                  {this.props.notice.peopleId ===
                  parseInt(localStorage.getItem("peopleId")) ? (
                    <>
                      <strong>{`Acknowledgements ( ${
                        this.state.acknowledgedPeople
                      } of ${
                        this.state.groupPeoples.length > 0
                          ? this.state.groupPeoples.length
                          : 0
                      })`}</strong>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.minimizeAck(e, "vidAckBox")}
                      >
                        <FontAwesomeIcon
                          icon={this.state.minimizeAck ? faPlus : faMinus}
                        ></FontAwesomeIcon>
                      </span>
                    </>
                  ) : (
                    <>
                      <strong>Other Attendees</strong>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.minimizeAck(e, "vidAckBox")}
                      >
                        <FontAwesomeIcon
                          icon={this.state.minimizeAck ? faPlus : faMinus}
                        ></FontAwesomeIcon>
                      </span>
                    </>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control mb-2"
                  name="ackPeopleName"
                  value={this.state.ackPeopleName}
                  onChange={this.searchAckPeople}
                ></input>

                <div className="ackBody">
                  <table className="tt-ackPeopleList table-striped">
                    <tbody>
                      {this.state.groupPeoples.length > 0 ? (
                        this.state.groupPeoples.map((groupPeople, idx) => {
                          return (
                            <tr key={idx}>
                              {this.props.notice.peopleId ===
                              parseInt(localStorage.getItem("peopleId")) ? (
                                <td className="text-center">
                                  <span>
                                    <FontAwesomeIcon
                                      icon={
                                        groupPeople.acknowledged
                                          ? faCheckCircle
                                          : faTimesCircle
                                      }
                                      className={
                                        groupPeople.acknowledged
                                          ? "tt-acknowledgeBoxGreen"
                                          : "tt-acknowledgeBoxRed"
                                      }
                                    ></FontAwesomeIcon>
                                  </span>
                                </td>
                              ) : null}
                              <td>
                                {groupPeople.peoples ? (
                                  groupPeople.peoples.userRole ===
                                  userRoleCode.roleStudentContact ? (
                                    <Link
                                      to={{
                                        pathname: `/familyPage/${groupPeople.peoples.familyId}`,
                                        familyPageOpen: true,
                                        userId: groupPeople.peoples.userId,
                                      }}
                                    >
                                      {groupPeople.peopleName}
                                    </Link>
                                  ) : (
                                    <Link
                                      to={{
                                        pathname: `/profile/${groupPeople.peoples.username}`,
                                        username: groupPeople.peoples.username,
                                      }}
                                    >
                                      {groupPeople.peopleName}
                                    </Link>
                                  )
                                ) : null}
                              </td>
                              {this.props.notice.peopleId ===
                              parseInt(localStorage.getItem("peopleId")) ? (
                                <td width="38%" className="text-center">
                                  {groupPeople.acknowledged ? null : (
                                    <p
                                      className="tt-noticeRemind"
                                      onClick={(e) =>
                                        this.setRemindStatus(
                                          e,
                                          groupPeople.peopleId
                                        )
                                      }
                                    >
                                      {groupPeople.remind
                                        ? "Remind Again"
                                        : "Remind"}
                                    </p>
                                  )}
                                </td>
                              ) : null}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={3} className="text-center">
                            {this.state.ackSpinner ? (
                              <>
                                <Spinner color="primary"></Spinner>
                              </>
                            ) : (
                              "No people"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null
          ) : null}
          {this.props.notice.postType !== postType.videoConference &&
          this.props.notice.postType !== postType.profilePictureNotice &&
          this.props.notice.postType !== postType.coverPictureNotice &&
          this.props.notice.postType !== postType.postModeration ? (
            this.props.notice ? (
              this.props.notice.peopleId ===
              parseInt(localStorage.getItem("peopleId")) ? (
                <div
                  className="tt-acknowledgeListBox ackHeader-minimize ackHeader-fixed"
                  id="ackBox"
                >
                  <div className="ackHeader">
                    <strong>{`Acknowledgements ( ${
                      this.state.acknowledgedPeople
                    } of ${
                      this.state.groupPeoples.length > 0
                        ? this.state.groupPeoples.length
                        : 0
                    })`}</strong>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.minimizeAck(e, "ackBox")}
                    >
                      <FontAwesomeIcon
                        icon={this.state.minimizeAck ? faPlus : faMinus}
                      ></FontAwesomeIcon>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-2"
                    name="ackPeopleName"
                    value={this.state.ackPeopleName}
                    onChange={this.searchAckPeople}
                  ></input>
                  <div className="ackBody">
                    <table className="tt-ackPeopleList table-striped">
                      <tbody>
                        {this.state.groupPeoples.length > 0 ? (
                          this.state.groupPeoples.map((groupPeople, idx) => {
                            return (
                              <tr key={idx}>
                                <td className="text-center">
                                  <span>
                                    <FontAwesomeIcon
                                      icon={
                                        groupPeople.acknowledged
                                          ? faCheckCircle
                                          : faTimesCircle
                                      }
                                      className={
                                        groupPeople.acknowledged
                                          ? "tt-acknowledgeBoxGreen"
                                          : "tt-acknowledgeBoxRed"
                                      }
                                    ></FontAwesomeIcon>
                                  </span>
                                </td>
                                <td>
                                  {/* <strong>{groupPeople.peopleName}</strong> */}
                                  {groupPeople.peoples ? (
                                    groupPeople.peoples.userRole ===
                                    userRoleCode.roleStudentContact ? (
                                      <Link
                                        to={{
                                          pathname: `/familyPage/${groupPeople.peoples.familyId}`,
                                          familyPageOpen: true,
                                          userId: groupPeople.peoples.userId,
                                        }}
                                      >
                                        {groupPeople.peopleName}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={{
                                          pathname: `/profile/${groupPeople.peoples.username}`,
                                          username:
                                            groupPeople.peoples.username,
                                        }}
                                      >
                                        {groupPeople.peopleName}
                                      </Link>
                                    )
                                  ) : null}
                                </td>
                                <td width="38%" className="text-center">
                                  {groupPeople.acknowledged ? null : (
                                    <p
                                      className="tt-noticeRemind"
                                      onClick={(e) =>
                                        this.setRemindStatus(
                                          e,
                                          groupPeople.peopleId
                                        )
                                      }
                                    >
                                      {groupPeople.remind
                                        ? "Remind Again"
                                        : "Remind"}
                                    </p>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              {this.state.ackSpinner ? (
                                <>
                                  <Spinner color="primary"></Spinner>
                                </>
                              ) : (
                                "No people"
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null
            ) : null
          ) : null}
        </div>
        <div className="row mt-3">
          <div className="col">
            <a href="#top">
              <button
                className={
                  "tt-button tt-button-primary tt-scrollButton " +
                  (this.state.scrollable ? "" : "hideGoToTop")
                }
              >
                Go to Top
              </button>
            </a>
          </div>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Fetching History...
            </div>
          </div>
        ) : null}
        <ModalWindow
          modal={this.state.keywordModal}
          toggleModal={this.toggleKeywordModal}
          modalHeader="Groups Targeted"
          modalBody={this.keywordModalBody()}
        ></ModalWindow>
        <ModalWindow
          modal={this.state.editNoticeModal}
          toggleModal={this.toggleNoticeEditModal}
          modalHeader="Edit Post"
          fullWidth={true}
          modalBody={
            <NoticeEditTemplate
              notice={this.state.selectedNotice}
              closeModal={this.props.closeModal}
            ></NoticeEditTemplate>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.historyNoticeModal}
          toggleModal={this.toggleHistoryEditModal}
          fullWidth={this.state.noticeHistory.length > 0 ? true : false}
          modalHeader={this.state.selectedNotice.heading + " - History"}
          modalBody={
            <HistoryTemplates
              postHistory={this.state.noticeHistory}
            ></HistoryTemplates>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.fileViwerModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Attached Files"
          modalBody={
            <FileModal
              files={this.state.files}
              images={this.state.images}
              fileType={this.state.fileType}
              filePath={this.state.filePath}
              handleChangeFile={this.handleChangeFile}
              activeIndex={this.state.activeIndex}
              fileExist={this.state.fileExist}
              isNotice={true}
            ></FileModal>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default NoticeModalBody;

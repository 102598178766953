import React, { Component } from "react";
import DurationCounter from "./durationCounter";
import JoditEditor from "jodit-react";
import { axiosPost } from "../../utils/AxiosApi";
import {
  checkWidgetAdminOrNot,
  draggable,
  regEx,
  URL,
} from "../../utils/Constants";
import swal from "sweetalert";
import { connect } from "react-redux";
import { getCourseList } from "../../ducks/LearningManagementSystemDucks";
import ModalWindow from "../UI/ModalWindow";
import AddStudentModal from "./AddStudentModal";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

// const config = {
//   readonly: false, // all options from https://xdsoft.net/jodit/doc/
//   fullSize: false,
//   width: "100%",
//   removeButtons: [
//     "source",
//     "hr",
//     "|",
//     "about",
//     "fullsize",
//     "eraser",
//     "undo",
//     "redo",
//     "table",
//     "brush",
//     "image",
//     "file",
//     "print",
//     "link",
//     "preview",
//     "copyformat",
//   ],

//   limitChars: 60000,
//   showTooltip: true,
//   toolbarButtonSize: "small",
//   showCharsCounter: false,
//   showWordsCounter: false,
//   showXPathInStatusbar: false,
//   height: "100px",
//   uploader: {
//     insertImageAsBase64URI: true,
//   },
//   style: {
//     border: "5px solid red",
//   },
// };

class CreateLMS extends Component {
  state = {
    name: "",
    duration: 1,
    description: "",
    courseTermFormat: "",
    abbreviation: "",
    courseTermFormatList: [],
    nameError: false,
    descriptionError: false,
    courseTermFormatError: false,
    addStudentModal: false,
    admissionIds: [],
    joditConfig: {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      fullSize: false,
      width: "100%",
      removeButtons: [
        "source",
        "hr",
        "|",
        "about",
        "fullsize",
        "eraser",
        "undo",
        "redo",
        "table",
        "brush",
        "image",
        "file",
        "print",
        "link",
        "preview",
        "copyformat",
      ],

      limitChars: 60000,
      showTooltip: true,
      toolbarButtonSize: "small",
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      height: "100px",
      uploader: {
        insertImageAsBase64URI: true,
      },
      style: {},
    },
    permissionModal: false,
  };

  toggleAddStudent = () => {
    this.setState({ addStudentModal: !this.state.addStudentModal });
  };

  assignAdmissionIds = (ids) => {
    this.setState({ admissionIds: ids });
    this.toggleAddStudent();
  };

  componentDidMount() {
    this.getCourseTermFormat();
  }

  getCourseTermFormat = () => {
    axiosPost(URL.getCourseTermFormat, {}, (response) => {
      if (response.status === 200) {
        this.setState({ courseTermFormatList: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      if (!value.match(regEx)) {
        swal({
          title: "Warning",
          text: "Character not allowed",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      } else {
        value.length > 0 && this.setState({ nameError: false });
        this.setState({ [name]: value });
      }
    } else {
      name === "courseTermFormat" &&
        value.length > 0 &&
        this.setState({ courseTermFormatError: false });
      this.setState({ [name]: value });
    }
  };

  handleTextEditor = (value) => {
    this.setState({ description: value });
  };

  insertCourse = () => {
    if (
      this.state.name === "" ||
      this.state.description === "" ||
      this.state.duration === "" ||
      this.state.courseTermFormat === ""
    ) {
      swal({
        title: "Error",
        text: "Fill all the required fields.",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      if (this.state.name === "") {
        this.setState({ nameError: true });
      }
      if (this.state.description === "") {
        this.setState({ descriptionError: true });
        const newConfig = { ...this.state.joditConfig };
        newConfig.style = {
          border: "1px solid red",
        };
        this.setState({ joditConfig: newConfig, descriptionError: false });
      } else if (
        this.state.description !== "" &&
        this.state.description !== null
      ) {
        const newConfig = { ...this.state.joditConfig };
        newConfig.style = {
          border: "",
        };
        this.setState({ joditConfig: newConfig, descriptionError: false });
      }
      // else {
      //   const newConfig = { ...this.state.joditConfig };
      //   newConfig.style = {
      //     border: "",
      //   };
      //   this.setState({ joditConfig: newConfig, descriptionError: false });
      // }
      if (this.state.courseTermFormat === "") {
        this.setState({ courseTermFormatError: true });
      }

      // this.setState({
      //   nameError: true,
      //   descriptionError: true,
      //   courseTermFormatError: true,
      // });
      // const newConfig = { ...this.state.joditConfig };
      // newConfig.style = {
      //   border: "1px solid red",
      // };
      // this.setState({ joditConfig: newConfig });
      return false;
    } else {
      let data = {
        name: this.state.name,
        abbreviation: this.state.abbreviation,
        description: this.state.description,
        duration: this.state.duration,
        courseTermFormatCode: this.state.courseTermFormat,
        admissionIds: this.state.admissionIds,
      };
      axiosPost(URL.insertCourse, data, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          this.clearState();
          this.props.getCourseList(0, 15);
        }
      });
    }
  };

  clearState = () => {
    this.setState({
      name: "",
      duration: 1,
      description: "",
      courseTermFormat: "",
      abbreviation: "",
      admissionIds: [],
    });
  };

  increaseValue = () => {
    this.setState({ duration: this.state.duration + 1 });
  };

  decreaseValue = () => {
    if (this.state.duration !== 1) {
      this.setState({ duration: this.state.duration - 1 });
    }
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "80.5vh" }}>
        <div className="tt-group-header">
          Create Program
          {checkWidgetAdminOrNot("LMS") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <strong>
              Program Name:{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-5" style={{ display: "flex" }}>
            <input
              type="text"
              className={
                this.state.nameError
                  ? "form-control indicate-error"
                  : "form-control"
              }
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <strong>Abbreviation: </strong>
          </div>
          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              name="abbreviation"
              value={this.state.abbreviation}
              onChange={this.handleChange}
            ></input>
          </div>
          <div className="col-md-5"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <strong>
              Description:
              <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-5" style={{ display: "flex" }}>
            <JoditEditor
              // className={
              //   this.state.descriptionError
              //     ? "form-control indicate-error"
              //     : "form-control"
              // }
              value={this.state.description}
              onChange={(e) => this.handleTextEditor(e)}
              // config={config}
              config={this.state.joditConfig}
            />
            {/* <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span> */}
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <strong>
              Program Duration:
              <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-2 pr-0">
            <DurationCounter
              duration={this.state.duration}
              increaseValue={this.increaseValue}
              decreaseValue={this.decreaseValue}
              handleChange={this.handleChange}
              inputEnable={true}
            ></DurationCounter>
          </div>
          <div className="col-md-auto mt-2 pl-2" style={{ display: "flex" }}>
            <strong>months</strong>
            {/* <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span> */}
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <strong>
              Terms:
              <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col" style={{ display: "flex" }}>
                <select
                  className={
                    this.state.courseTermFormatError
                      ? "form-control indicate-error"
                      : "form-control"
                  }
                  name="courseTermFormat"
                  value={this.state.courseTermFormat}
                  onChange={this.handleChange}
                >
                  <option value="" disabled selected>
                    Choose term
                  </option>
                  {this.state.courseTermFormatList.map((list, idx) => {
                    return (
                      <option key={idx} value={list.code}>
                        {list.name}
                      </option>
                    );
                  })}
                </select>
                {/* <span
                  className="tt-assessment-module-mandatory"
                  style={{ paddingLeft: "5px" }}
                >
                  *
                </span> */}
              </div>
              <div className="col-md-auto mr-3" style={{ marginTop: "3px" }}>
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.toggleAddStudent}
                >
                  Add Students
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3 button-bg-color">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.insertCourse}
            >
              Create
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.clearState}
            >
              Cancel
            </button>
          </div>
          <div className="col-md-2"></div>
        </div>
        <ModalWindow
          modal={this.state.addStudentModal}
          toggleModal={this.toggleAddStudent}
          fullWidth={true}
          modalHeader="Add Students to Program"
          modalBody={
            <AddStudentModal
              assignAdmissionIds={this.assignAdmissionIds}
              admissionIds={this.state.admissionIds}
            ></AddStudentModal>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="LMS"
              moduleName="Programs"
              header="Add Programs configuration"
              activityName="create-program"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionsToProps = {
  getCourseList,
};

export default connect(mapStateToProps, mapActionsToProps)(CreateLMS);

/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, SystemSetup, is for the parent view of setup one, setup two and setup three poritions of the System Setup Process
 */

import React, { Component } from "react";
import "../../assets/scss/academicYear.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL, regEx, draggable } from "../../utils/Constants";
import { displaySuccessAlert } from "../../utils/Utils";
import SetupOne from "./SetupOne";
import SetupTwo from "./SetupTwo";
import history from "../../utils/History";
import SetupThree from "./SetupThree";
import NavBar from "../Profile/Navbar";
import swal from "@sweetalert/with-react";
import uuid from "uuid";
import { Spinner } from "reactstrap";
import { countryId, nameFormat } from "../../utils/SchoolConfig";

class SystemSetup extends Component {
  state = {
    // regEx: /^[a-zA-Z!*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
    // regEx1: /^[0-9a-zA-Z!;'-:" ]*$/,
    // regEx: /^[a-zA-Z0-9 ]*$/,
    showSpinner: false,
    salutation: false,
    adultSalutation: false,
    nationality: false,
    ethnicity: false,
    currentIndex: 0,
    nameOfTheSchool: "",
    acronym: "",
    photo: "",
    DOE: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },

    country: countryId,
    countryFirstLevelId: null,
    countrySecondLevelId: null,
    countryThirdLevelId: null,
    countryFirstLevel: [],
    countrySecondLevel: [],
    countryThirdLevel: [],
    calendar: "1",
    calendarType: [],
    yearLevel: "",
    classroom: "",
    academicYearDivision: "",
    levels: [
      {
        level: "",
      },
    ],
    panNo: "",
    suggestions: [
      "Kindergarten",
      "Pre-primary",
      "Primary",
      "Lower secondary",
      "Secondary",
      "High",
      "Senior",
    ],
    baseSuggestions: [
      "Kindergarten",
      "Pre-primary",
      "Primary",
      "Lower Secondary",
      "Secondary",
      "High",
      "Senior",
    ],
    baseYearSuggestions: ["Class", "Grade", "Year", "Batch"],
    yearSuggestions: ["Class", "Grade", "Year", "Batch"],
    classSuggestions: ["Section", "Homeroom"],
    baseClassSuggestions: ["Section", "Homeroom"],
    academicSuggestions: ["Semester", "Term"],
    baseAcademicSuggestions: ["Semester", "Term"],
    labels: [
      {
        fieldName: "First Name",
        fieldTypeId: 1,
        value: nameFormat.firstName,
        isMandatory: true,
        index: 0,
      },
      {
        fieldName: "Middle Name",
        fieldTypeId: 1,
        value: nameFormat.middleName,
        isMandatory: false,
        index: 1,
      },
      {
        fieldName: "Last Name",
        fieldTypeId: 1,
        value: nameFormat.lastName,
        isMandatory: false,
        index: 2,
      },
    ],

    labelsCopy: [],
    initialCopy: [],
    editableNameTags: [],
    editableNameTagsValue: [],
    literalTags: [],
    setupData: [],
    symbols: [".", ",", "[", "]", "(", ")"],
    systemLabels: [],
    systemLabelInitials: [],
    systemTags: [],
    systemLiteralTags: [],
    systemTagsValue: [],
    systemSymbols: [],

    // address cluster
    addressClusterFields: [],
    addressClusterFieldsCopy: [],
    addressClusterFieldLables: [
      "Street Address",
      "Area",
      "Ward No.",
      "Postal Code",
    ],
    selectedAddressClusterFieldsForChips: [], //this is for selected chips
    errors: {
      nameOfTheSchool: false,
      dateOfEstablishment: {
        year: false,
        //month: false,
        //day: false
      },
      country: false,
      calendar: false,
      academicYearDivision: false,
      yearLevel: false,
      classroom: false,
      educationLevels: [{ level: false }],
      text: "required",
      totalError: 0,
      dropdownOpen: false,
      photo: null,
      panNo: false,
      website: false,
    },
    switchToggle: true,
    website: "",
  };

  renderErrorText = () => (
    <span className="error-text">{this.state.errors.text}</span>
  );

  toggleMenu = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  getSetupConfig = () => {
    axiosPost(URL.setupConfiguration, {}, (response) => {
      this.setState({
        setupData: response.data.data,
      });
      let setupData = response.data.data;

      setupData.forEach((sd) => {
        if (sd.key === "Year Level") {
          this.setState(
            {
              yearLevel: sd.value,
              baseYearSuggestions: [
                ...this.state.baseYearSuggestions,
                sd.value,
              ],
            },
            () => {
              this.universalSuggestionDisplayHandler(
                this.state.yearSuggestions,
                this.state.yearLevel,
                "this.props.yearSuggestions",
                "yearLevel"
              );
            }
          );
        }

        if (sd.key === "Division") {
          this.setState(
            {
              academicYearDivision: sd.value,
              baseAcademicSuggestions: [
                ...this.state.baseAcademicSuggestions,
                sd.value,
              ],
            },
            () => {
              this.universalSuggestionDisplayHandler(
                this.state.academicSuggestions,
                this.state.academicYearDivision,
                "this.props.academicSuggestions",
                "academicYearDivision"
              );
            }
          );
        }

        if (sd.key === "Classroom") {
          this.setState(
            {
              classroom: sd.value,
              baseClassSuggestions: [
                ...this.state.baseClassSuggestions,
                sd.value,
              ],
            },
            () => {
              this.universalSuggestionDisplayHandler(
                this.state.classSuggestions,
                this.state.classroom,
                "this.props.classSuggestions",
                "classroom"
              );
            }
          );
        }

        if (sd.key === "NameFormatInSystem") {
          let newSystemTagsValue = [];
          let labelsCopy = [...this.state.labels];
          let systemLabels = [...this.state.systemLabels];
          let systemLabelInitials = [...this.state.systemLabelInitials];
          sd.lists.forEach((name) => {
            if (name.includes("Initial")) {
              systemLabelInitials.forEach((sli) => {
                labelsCopy.forEach((lc) => {
                  if (
                    sli.fieldName.trim() === name.trim() &&
                    lc.index === sli.index
                  ) {
                    sli.isInitial = true;
                    sli.value = lc.value.charAt(0);
                    newSystemTagsValue.push(sli);
                  }
                });
              });

              systemLabelInitials = systemLabelInitials.filter(
                (sli) => sli.fieldName !== name
              );
            } else {
              systemLabels.forEach((sli) => {
                if (sli.fieldName.trim() === name.trim()) {
                  newSystemTagsValue.push(sli);
                }
              });

              systemLabels = systemLabels.filter(
                (sli) => sli.fieldName.trim() !== name.trim()
              );
            }
          });
          this.setState({
            systemLabels,
            systemLabelInitials,
            systemTags: newSystemTagsValue,
            systemTagsValue: newSystemTagsValue,
          });
        }

        if (sd.key === "NameFormatInReport") {
          let newSystemTagsValue = [];
          let labelsCopy = [...this.state.labels];
          let newLabelsCopy = [...this.state.labelsCopy];
          let initialCopy = [...this.state.initialCopy];
          sd.lists.forEach((name) => {
            if (name.includes("Initial")) {
              initialCopy.forEach((sli) => {
                labelsCopy.forEach((lc) => {
                  if (
                    sli.fieldName.trim() === name.trim() &&
                    lc.index === sli.index
                  ) {
                    sli.isInitial = true;
                    sli.value = lc.value.charAt(0);
                    newSystemTagsValue.push(sli);
                  }
                });
              });

              initialCopy = initialCopy.filter((sli) => sli.fieldName !== name);
            } else {
              newLabelsCopy.forEach((sli) => {
                if (sli.fieldName.trim() === name.trim()) {
                  newSystemTagsValue.push(sli);
                }
              });

              newLabelsCopy = newLabelsCopy.filter(
                (sli) => sli.fieldName.trim() !== name.trim()
              );
            }
          });
          this.setState({
            labelsCopy: newLabelsCopy,
            initialCopy,
            editableNameTags: newSystemTagsValue,
            editableNameTagsValue: newSystemTagsValue,
          });
        }
      });
    });
  };

  handleDefaultEducationLevels = (e) => {
    const setUpData = this.state.setupData.find(
      (sd) => sd.key === "Education Level"
    );
    let newData = [];
    let baseSuggestions = [...this.state.baseSuggestions];
    setUpData.lists.forEach((sl, index) => {
      newData = [...newData, { level: sl }];
      baseSuggestions.push(sl);
    });
    this.setState(
      {
        levels: newData,
        baseSuggestions,
      },
      () => {
        let suggestions = [...this.state.baseSuggestions];
        this.state.levels.forEach((sl, index) => {
          suggestions = suggestions.filter((bs) => bs !== sl.level.trim());
        });
        this.setState({ suggestions });
      }
    );
  };

  componentDidMount() {
    this.setState({ regEx: regEx });
    document.title = "System Setup Stage 1";
    this.setState({
      labelsCopy: this.state.labels,
      systemLabels: this.state.labels,
      systemSymbols: this.state.symbols,
    });

    this.setState(
      {
        initialCopy: [
          { fieldName: "First Name Initial", value: 0, index: 0 },
          { fieldName: "Middle Name Initial", value: 1, index: 1 },
          { fieldName: "Last Name Initial", value: 2, index: 2 },
        ],
        systemLabelInitials: [
          { fieldName: "First Name Initial", value: 0, index: 0 },
          { fieldName: "Middle Name Initial", value: 1, index: 1 },
          { fieldName: "Last Name Initial", value: 2, index: 2 },
        ],
      },
      () => {
        this.getSetupConfig();
      }
    );
    // to populate address cluster
    this.handleAddressClusterFieldsGeneration();
    axiosPost(URL.getCalendarType, {}, (response) => {
      this.setState({ calendarType: response.data.data });
    });

    const data = {
      countryId: parseInt(this.state.country),
    };
    axiosPost(URL.getCountryFirstLevel, data, (response) => {
      this.setState({ countryFirstLevel: response.data.data });
    });

    // this.handleSuggestionsChange();
  }

  /**
   * @author Saurav Sitaula
   */
  handleCheckBoxChange = (event, id, param) => {
    if (param !== "dynamic") {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value,
      });
    } else {
      let newLabels = [...this.state.labels];
      newLabels[id].isMandatory = event.target.checked;
      this.setState({ labels: newLabels });
    }
    if (param === "addressField") {
      let newAddressField = [...this.state.addressClusterFields];
      newAddressField[id].isMandatory = event.target.checked;
      this.setState({ addressClusterFields: newAddressField });
    }
  };

  handleSalutation = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    this.setState({ [name]: value });
  };

  handleDateChange = (e) => {
    let DOE = { ...this.state.DOE };
    DOE.year = e.target.value.substring(0, 4);
    DOE.month =
      e.target.value.substring(5, 7).substring(0, 1) === "0"
        ? e.target.value.substring(6, 7)
        : e.target.value.substring(5, 7);
    DOE.day =
      e.target.value.substring(8, 10).substring(0, 1) === "0"
        ? e.target.value.substring(9, 10)
        : e.target.value.substring(8, 10);

    this.setState({ DOE: DOE });
  };
  /**
   * @author Saurav Sitaula
   */
  showNameFormat = () => {
    if (this.state.labels.length === 1) {
      return this.state.editableNameTagsValue !== null
        ? this.state.editableNameTagsValue.map((value, id) => {
            return `${value.value} `;
          })
        : null;
    } else {
      return this.state.editableNameTagsValue !== null
        ? this.state.editableNameTagsValue.map((value, id) => {
            return `${value.value} `;
          })
        : null;
    }
  };
  /**
   * @author Saurav Sitaula
   */
  showSystemNameFormat = () => {
    return this.state.systemTagsValue !== null
      ? this.state.systemTagsValue.map((value, id) => {
          return `${value.value} `;
        })
      : null;
  };
  /**
   * @author Saurav Sitaula
   */
  changeToPrevIndex = () => {
    if (this.state.currentIndex !== 0)
      this.setState({ currentIndex: this.state.currentIndex - 1 });
  };
  /**
   * @author Saurav Sitaula
   */
  changeToNextIndex = () => {
    if (this.state.currentIndex !== 2) {
      this.setState({ currentIndex: this.state.currentIndex + 1 });
    }
  };
  /**
   * @author Saurav Sitaula
   */
  handleLiterals = (e, param) => {
    if (e.key === "Enter" && param === "report") {
      let newValues = [...this.state.editableNameTagsValue];
      //let newTags = [...this.state.literalTags];
      let newTags = [...this.state.editableNameTags];
      const index = uuid.v4();
      newTags.push({
        index: index,
        fieldName: e.target.value,
        value: e.target.value,
        isLiteral: true,
      });
      newValues.push({
        index: index,
        fieldName: e.target.value,
        value: e.target.value,
        isLiteral: true,
      });
      this.setState(
        {
          editableNameTags: newTags,
        },
        this.setState({ editableNameTagsValue: newValues })
      );
      document.getElementById("reportLiteral").value = "";
    }
    if (e.key === "Enter" && param === "system") {
      let newSystemTagsValue = [...this.state.systemTagsValue];
      let newSystemTags = [...this.state.systemTags];
      const index = uuid.v4();
      newSystemTags.push({
        index: index,
        fieldName: e.target.value,
        value: e.target.value,
        isLiteral: true,
      });
      newSystemTagsValue.push({
        index: index,
        fieldName: e.target.value,
        value: e.target.value,
        isLiteral: true,
      });
      this.setState(
        { systemTags: newSystemTags },
        this.setState({ systemTagsValue: newSystemTagsValue })
      );
      document.getElementById("systemLiteral").value = "";
    }
  };

  /**
   * @author Saurav Sitaula
   */
  handleLabelValueChange = (e, id) => {
    let length = this.state.labels.length;
    const tempFieldName = this.state.labels[id].fieldName;
    let newLabels = [...this.state.labels];
    newLabels[id].value = e.target.value;
    this.setState({ labels: newLabels });
    this.state.editableNameTags.forEach((tag) => {
      if (tag.fieldName.includes(tempFieldName)) {
        let newTagsValue = [...this.state.editableNameTagsValue];
        let changeIndex = newTagsValue.findIndex(
          (i) => i.fieldName === tempFieldName
        );
        let changeIndexInit = newTagsValue.findIndex(
          (i) => i.fieldName === `${tempFieldName} Initial`
        );

        if (changeIndexInit !== -1) {
          if (length === 1) {
            if (e.target.value === "") {
              newTagsValue[changeIndexInit].value = e.target.value;
            }
            let temp = "";
            let final = "";
            let name = e.target.value;
            for (let i = 0; i < name.length; i++) {
              if (name.charAt(i) === " " || i === name.length - 1) {
                let output = temp.substring(0, 1).toUpperCase();
                final = final + output + ". ";
                temp = "";
              }
              if (name.charAt(i) !== " ") temp += name.charAt(i);
            }
            newTagsValue[changeIndexInit].value = final;
          } else {
            newTagsValue[changeIndexInit].value = e.target.value
              .substring(0, 1)
              .toUpperCase();
          }
        }
        if (changeIndex !== -1)
          newTagsValue[changeIndex].value = e.target.value;

        this.setState({ editableNameTagsValue: newTagsValue });
      }
    });
    this.state.systemTags.forEach((tag) => {
      if (tag.fieldName.includes(tempFieldName)) {
        let newSystemTagsValue = [...this.state.systemTagsValue];
        let systemChangeIndex = newSystemTagsValue.findIndex(
          (i) => i.fieldName === tempFieldName
        );
        let systemChangeIndexInit = newSystemTagsValue.findIndex(
          (i) => i.fieldName === `${tempFieldName} Initial`
        );

        if (systemChangeIndex !== -1)
          newSystemTagsValue[systemChangeIndex].value = e.target.value;
        if (systemChangeIndexInit !== -1) {
          if (length === 1) {
            if (e.target.value === "") {
              newSystemTagsValue[systemChangeIndexInit].value = e.target.value;
            }
            let temp = "";
            let final = "";
            let name = e.target.value;
            for (let i = 0; i < name.length; i++) {
              if (name.charAt(i) === " " || i === name.length - 1) {
                let output = temp.substring(0, 1).toUpperCase();
                final = final + output + ". ";
                temp = "";
              }
              if (name.charAt(i) !== " ") temp += name.charAt(i);
            }
            newSystemTagsValue[systemChangeIndexInit].value = final;
          } else {
            newSystemTagsValue[systemChangeIndexInit].value = e.target.value
              .substring(0, 1)
              .toUpperCase();
          }
        }

        this.setState({ systemTagsValue: newSystemTagsValue });
      }
    });
  };
  /**
   * @author Saurav Sitaula
   */
  //   handleDeleteTags = (tag, param) => {
  //     if (param !== "system") {
  //       let newArr = [...this.state.editableNameTags];
  //       var index = newArr.indexOf(tag);
  //       if (index !== -1) newArr.splice(index, 1);
  //       this.setState({ editableNameTags: newArr }, () => {
  //         let valueCopy = [...this.state.editableNameTagsValue];
  //         valueCopy.splice(index, 1);
  //         this.setState({ editableNameTagsValue: valueCopy });
  //       });
  //     } else {
  //       let newSystemTags = [...this.state.systemTags];
  //       let tagIndex = newSystemTags.indexOf(tag);
  //       if (tagIndex !== -1) newSystemTags.splice(tagIndex, 1);
  //       this.setState({ systemTags: newSystemTags }, () => {
  //         let newSystemTagsValue = [...this.state.systemTagsValue];
  //         let systemChangeIndex = newSystemTagsValue.indexOf(temp);
  //         newSystemTagsValue[systemChangeIndex] = e.target.value;
  //         this.setState({ systemTagsValue: newSystemTagsValue });
  //     };

  //look here
  //

  /**
   * @author Saurav Sitaula
   */
  handleDeleteTags = (tag, param) => {
    if (param !== "system") {
      let newArr = [...this.state.editableNameTags];
      var index = newArr.findIndex(
        (i) => i.index === tag.index && i.fieldName === tag.fieldName
      );
      if (index !== -1) newArr.splice(index, 1);
      this.setState({ editableNameTags: newArr }, () => {
        let valueCopy = [...this.state.editableNameTagsValue];
        const indexToDel = valueCopy.findIndex(
          (i) => i.index === tag.index && i.fieldName === tag.fieldName
        );
        if (indexToDel !== -1) valueCopy.splice(indexToDel, 1);
        this.setState({ editableNameTagsValue: valueCopy });
      });
    } else {
      let newSystemTags = [...this.state.systemTags];
      let tagIndex = newSystemTags.findIndex(
        (i) => i.index === tag.index && i.fieldName === tag.fieldName
      );
      if (tagIndex !== -1) newSystemTags.splice(tagIndex, 1);
      this.setState({ systemTags: newSystemTags }, () => {
        let newSystemTagsValue = [...this.state.systemTagsValue];
        const indexToDel = newSystemTagsValue.findIndex(
          (i) => i.index === tag.index && i.fieldName === tag.fieldName
        );
        if (indexToDel !== -1) newSystemTagsValue.splice(indexToDel, 1);
        this.setState({ systemTagsValue: newSystemTagsValue });
      });
    }
  };

  /**
   * @author Saurav Sitaula
   */
  handleDeleteLiteralTags = (tag, id, param) => {
    if (param !== `system`) {
      let copyToDel = [...this.state.literalTags];
      copyToDel.splice(id, 1);
      this.setState(
        { literalTags: copyToDel },
        this.handleDeleteTags(tag, param)
      );
    } else {
      let copyToDel = [...this.state.systemLiteralTags];
      copyToDel.splice(id, 1);
      this.setState(
        { systemLiteralTags: copyToDel },
        this.handleDeleteTags(tag, param)
      );
    }
  };
  /**
   * @author Saurav Sitaula
   */
  handleDeleteEditableNameTags = (tag, id, param) => {
    if (param !== "system") {
      if (tag.isInitial) {
        let newArr = [...this.state.initialCopy];
        if (tag.isLiteral === undefined) {
          newArr.push(tag);
        }
        this.setState(
          { initialCopy: newArr },
          this.handleDeleteTags(tag, param)
        );
      } else {
        let data = {
          fieldName: tag.fieldName,
          fieldTypeId: 1,
          value: "",
          isMandatory: false,
          index: tag.index,
          isInitial: tag.isInitial,
        };
        let newLabelsCopy = [...this.state.labelsCopy];
        if (tag.isLiteral === undefined) {
          newLabelsCopy.push(data);
        }

        this.setState(
          { labelsCopy: newLabelsCopy },
          this.handleDeleteTags(tag)
        );
      }
    } else {
      if (tag.isInitial) {
        let newSystemLabelInitials = [...this.state.systemLabelInitials];
        if (tag.isLiteral === undefined) {
          newSystemLabelInitials.push(tag);
        }
        this.setState(
          { systemLabelInitials: newSystemLabelInitials },
          this.handleDeleteTags(tag, param)
        );
      } else {
        let data = {
          fieldName: tag.fieldName,
          isMandatory: false,
          fieldTypeId: 1,
          value: tag.value,
          index: tag.index,
          isInitial: tag.isInitial,
        };
        let newSystemLabels = [...this.state.systemLabels];
        if (tag.isLiteral === undefined) {
          newSystemLabels.push(data);
        }
        this.setState(
          { systemLabels: newSystemLabels },
          this.handleDeleteTags(tag, param)
        );
      }
    }
  };
  /**
   * @author Saurav Sitaula
   */
  handleEditableNameTags = (label, id, param) => {
    label.isInitial = false;

    if (param !== "system") {
      let newArr = [...this.state.editableNameTags];
      let newValArr = [...this.state.editableNameTagsValue];
      newArr.push(label);
      let elementIndex = this.state.labels.findIndex(
        (i) => i.fieldName === label.fieldName
      );
      if (elementIndex !== -1) {
        newValArr.push({
          fieldName: label.fieldName,
          value: this.state.labels[elementIndex].value,
          index: label.index,
          isInitial: false,
        });
      }
      this.setState({ editableNameTagsValue: newValArr });
      this.setState({ editableNameTags: newArr });
      let newLabelsCopy = [...this.state.labelsCopy];
      newLabelsCopy.splice(id, 1);
      this.setState({ labelsCopy: newLabelsCopy });
    }
    if (param === "system") {
      let newSystemTags = [...this.state.systemTags];
      let newSystemTagsValue = [...this.state.systemTagsValue];
      newSystemTags.push(label);
      let sysTagIndex = this.state.labels.findIndex(
        (i) => i.fieldName === label.fieldName
      );
      if (sysTagIndex !== -1) {
        newSystemTagsValue.push({
          fieldName: label.fieldName,
          value: this.state.labels[sysTagIndex].value,
          index: label.index,
          isInitial: false,
        });
      }

      this.setState({ systemTagsValue: newSystemTagsValue });
      this.setState({ systemTags: newSystemTags });
      let newSystemLabels = [...this.state.systemLabels];
      newSystemLabels.splice(id, 1);
      this.setState({ systemLabels: newSystemLabels });
    }
  };
  /**
   * @author Saurav Sitaula
   */
  insertName = (initCopy, param) => {
    const fieldNameToFind = initCopy.fieldName;
    if (param !== "system") {
      let newValArr = [...this.state.editableNameTagsValue];
      if (this.state.labels.length === 1) {
        let data = {
          fieldName: fieldNameToFind,
          index: initCopy.index,
          isInitial: true,
        };
        let value = this.state.labels[0].value.substring(0, 1).toUpperCase();
        for (let i = 0; i < this.state.labels[0].value.length; i++) {
          if (this.state.labels[0].value[i] === " ") {
            value =
              value +
              "." +
              " " +
              this.state.labels[0].value[i + 1].toUpperCase();
          }
        }
        data.value = `${value}.`;
        newValArr.push(data);
      } else {
        let elementIndex = this.state.labels.findIndex(
          (i) => i.fieldName === fieldNameToFind.replace("Initial", "").trim()
        );
        if (elementIndex !== -1) {
          newValArr.push({
            fieldName: fieldNameToFind,
            value: this.state.labels[elementIndex].value.substring(0, 1),
            index: initCopy.index,
            isInitial: true,
          });
        }
      }
      this.setState({ editableNameTagsValue: newValArr });
    } else {
      let newSystemTagsValue = [...this.state.systemTagsValue];
      if (this.state.labels.length === 1) {
        let data = {
          fieldName: fieldNameToFind,
          index: initCopy.index,
          isInitial: true,
        };
        let value = this.state.labels[0].value.substring(0, 1).toUpperCase();
        for (let i = 0; i < this.state.labels[0].value.length; i++) {
          if (this.state.labels[0].value[i] === " ") {
            value =
              value +
              "." +
              " " +
              this.state.labels[0].value[i + 1].toUpperCase();
          }
        }
        data.value = `${value}.`;
        newSystemTagsValue.push(data);
      } else {
        let systemTagsValueIndex = this.state.labels.findIndex(
          (i) => i.fieldName === fieldNameToFind.replace("Initial", "").trim()
        );
        if (systemTagsValueIndex !== -1)
          newSystemTagsValue.push({
            fieldName: fieldNameToFind,
            value: this.state.labels[systemTagsValueIndex].value.substring(
              0,
              1
            ),
            index: initCopy.index,
            isInitial: true,
          });
      }
      this.setState({ systemTagsValue: newSystemTagsValue });
    }
  };
  /**
   * @author Saurav Sitaula
   */
  handleEditableNameInitialTags = (initCopy, id, param) => {
    initCopy.isInitial = true;

    if (param !== "system") {
      let newArr = [...this.state.editableNameTags];

      //newArr.push(initCopy);
      newArr.push(initCopy);

      this.setState({ editableNameTags: newArr }, this.insertName(initCopy));

      let newInitCopy = [...this.state.initialCopy];
      newInitCopy.splice(id, 1);
      this.setState({ initialCopy: newInitCopy });
    } else {
      let newSystemTags = [...this.state.systemTags];
      //newSystemTags.push(initCopy);
      newSystemTags.push(initCopy);

      this.setState(
        { systemTags: newSystemTags },
        this.insertName(initCopy, param)
      );
      let newSystemLabelInitials = [...this.state.systemLabelInitials];
      newSystemLabelInitials.splice(id, 1);
      this.setState({ systemLabelInitials: newSystemLabelInitials });
    }
  };
  /**
   * @author Saurav Sitaula
   */
  handleLabelChange = (e, id, labelIndex) => {
    let newLabels = [...this.state.labels];
    let newLabelsCopy = [...this.state.labelsCopy];
    let newInitialCopy = [...this.state.initialCopy];
    let newSystemLabels = [...this.state.systemLabels];
    let newSystemLabelInitials = [...this.state.systemLabelInitials];
    let newEditableNameTags = [...this.state.editableNameTags];
    let newEditableNameTagsValues = [...this.state.editableNameTagsValue];
    let newSystemTags = [...this.state.systemTags];
    let newSystemTagsValue = [...this.state.systemTagsValue];

    //let typedValue = e.target.value;
    newLabels[id].fieldName = e.target.value;
    newLabelsCopy.forEach((label, idx) => {
      if (label.index === labelIndex) {
        newLabelsCopy[idx].fieldName = e.target.value;
      }
    });

    newInitialCopy.forEach((initLabel, idx) => {
      if (initLabel.index === labelIndex) {
        newInitialCopy[idx].fieldName = `${e.target.value} Initial`;
      }
    });

    newSystemLabels.forEach((sysLabel, idx) => {
      if (sysLabel.index === labelIndex) {
        newSystemLabels[idx].fieldName = e.target.value;
      }
    });

    newSystemLabelInitials.forEach((sysLabelInit, idx) => {
      if (sysLabelInit.index === labelIndex) {
        newSystemLabelInitials[idx].fieldName = `${e.target.value} Initial`;
      }
    });

    newEditableNameTags.forEach((nameTag, idx) => {
      if (nameTag.index === labelIndex) {
        if (nameTag.isInitial) {
          newEditableNameTags[idx].fieldName = `${e.target.value} Initial`;
        } else {
          newEditableNameTags[idx].fieldName = e.target.value;
        }
      }
    });
    newEditableNameTagsValues.forEach((nameTagValue, idx) => {
      if (nameTagValue.index === labelIndex) {
        if (nameTagValue.isInitial) {
          newEditableNameTags[idx].fieldName = `${e.target.value} Initial`;
        } else {
          newEditableNameTags[idx].fieldName = e.target.value;
        }
      }
    });
    newSystemTags.forEach((sysTag, idx) => {
      if (sysTag.index === labelIndex) {
        if (sysTag.isInitial) {
          newSystemTags[idx].fieldName = `${e.target.value} Initial`;
        } else {
          newSystemTags[idx].fieldName = e.target.value;
        }
      }
    });
    newSystemTagsValue.forEach((sysTagValue, idx) => {
      if (sysTagValue.index === labelIndex) {
        if (sysTagValue.isInitial) {
          newSystemTagsValue[idx].fieldName = `${e.target.value} Initial`;
        } else {
          newSystemTagsValue[idx].fieldName = e.target.value;
        }
      }
    });

    this.setState({
      labels: newLabels,
      labelsCopy: newLabelsCopy,
      initialCopy: newInitialCopy,
      systemLabels: newSystemLabels,
      systemLabelInitials: newSystemLabelInitials,
      editableNameTags: newEditableNameTags,
      editableNameTagsValue: newEditableNameTagsValues,
      systemTags: newSystemTags,
      systemTagsValue: newSystemTagsValue,
    });
  };
  /**
   * @author Saurav Sitaula
   */
  handleAddNewNameField = () => {
    this.setState((prevState) => ({
      labels: [
        ...prevState.labels,
        {
          fieldName: "",
          value: "",
          isMandatory: false,
          fieldTypeId: 1,
          index: this.state.labels.length,
        },
      ],
      systemLabels: [
        ...prevState.systemLabels,
        { fieldName: "", value: "", index: this.state.labels.length },
      ],
      labelsCopy: [
        ...prevState.labelsCopy,
        { fieldName: "", value: "", index: this.state.labels.length },
      ],
      initialCopy: [
        ...prevState.initialCopy,
        {
          fieldName: "",
          value: this.state.initialCopy.length,
          index: this.state.labels.length,
        },
      ],
      systemLabelInitials: [
        ...prevState.systemLabelInitials,
        {
          fieldName: "",
          value: this.state.systemLabelInitials.length,
          index: this.state.labels.length,
        },
      ],
    }));
  };
  /**
   * @author Saurav Sitaula
   */
  removeEditNameTags = (initLabel, labelValue) => {
    let newEditCopy = [...this.state.editableNameTags];
    var index3 = this.state.editableNameTags.findIndex(
      (tag) => tag.fieldName === initLabel
    );
    //var index3 = newEditCopy.indexOf(initLabel);
    if (index3 !== -1) newEditCopy.splice(index3, 1);
    this.setState({ editableNameTags: newEditCopy }, () => {
      let valueCopy = [...this.state.editableNameTagsValue];
      const valueIndex = valueCopy.findIndex((i) => i.fieldName === initLabel);
      //const valueIndex = valueCopy.indexOf(labelValue.substring(0, 1));
      if (valueIndex !== -1) valueCopy.splice(valueIndex, 1);
      this.setState({ editableNameTagsValue: valueCopy });
    });
    let newSystemTags = [...this.state.systemTags];
    var tagIndex = this.state.systemTags.findIndex(
      (tag) => tag.fieldName === initLabel
    );
    //var tagIndex = newSystemTags.indexOf(initLabel);
    if (tagIndex !== -1) newSystemTags.splice(tagIndex, 1);
    this.setState({ systemTags: newSystemTags }, () => {
      let newSystemTagsValue = [...this.state.systemTagsValue];
      const systemValueIndex = newSystemTagsValue.findIndex(
        (i) => i.fieldName === initLabel
      );
      // const systemValueIndex = newSystemTagsValue.indexOf(
      //   labelValue.substring(0, 1)
      // );
      if (systemValueIndex !== -1)
        newSystemTagsValue.splice(systemValueIndex, 1);
      this.setState({ systemTagsValue: newSystemTagsValue });
    });
  };
  /**
   * @author Saurav Sitaula
   */
  removeEditNameInitialTags = (label, labelValue) => {
    //for report
    let newEditXCopy = [...this.state.editableNameTags];
    var index4 = this.state.editableNameTags.findIndex(
      (l) => l.fieldName === label.fieldName
    );
    //var index4 = newEditXCopy.indexOf(`${label.fieldName}`);
    if (index4 !== -1) newEditXCopy.splice(index4, 1);
    this.setState({ editableNameTags: newEditXCopy }, () => {
      let valueCopy = [...this.state.editableNameTagsValue];
      const index4 = valueCopy.findIndex(
        (i) => i.fieldName === label.fieldName
      );
      //const index4 = valueCopy.indexOf(labelValue);
      if (index4 !== -1) valueCopy.splice(index4, 1);
      this.setState({ editableNameTagsValue: valueCopy });
    });
    let newSystemTags = [...this.state.systemTags];
    var sysTagsIndex = this.state.systemTags.findIndex(
      (tag) => tag.fieldName === label.fieldName
    );
    //var sysTagsIndex = newSystemTags.indexOf(`${label.fieldName}`);
    if (sysTagsIndex !== -1) newSystemTags.splice(sysTagsIndex, 1);
    this.setState({ systemTags: newSystemTags }, () => {
      let newSystemTagsValue = [...this.state.systemTagsValue];
      const sysTagsIndex = newSystemTagsValue.findIndex(
        (i) => i.fieldName === label.fieldName
      );
      //const sysTagsIndex = newSystemTagsValue.indexOf(labelValue);
      if (sysTagsIndex !== -1) newSystemTagsValue.splice(sysTagsIndex, 1);
      this.setState({ systemTagsValue: newSystemTagsValue });
    });
    //remove empty string from initCopy
    // let removeArr = [...this.state.initialCopy];
    // removeArr.forEach((arr, id) => {
    //   if (arr === "") {
    //     removeArr.splice(id, 1);
    //   }
    // });
    // this.setState({ initialCopy: removeArr });
    // //remove empty string from systemlabelinitials
    // let removeArr2 = [...this.state.systemLabelInitials];
    // removeArr2.forEach((arr2, id2) => {
    //   if (arr2 === "") {
    //     removeArr2.splice(id2, 1);
    //   }
    // });
    //this.setState({ systemLabelInitials: removeArr2 });
  };
  /**
   * @author Saurav Sitaula
   */
  handleRemoveNewNameField = ({ e, id, label, param }) => {
    e.preventDefault();
    let initLabel = `${label.fieldName} Initial`;
    let newLabels = [...this.state.labels];
    const labelValue = this.state.labels[id].value;
    newLabels.splice(id, 1);
    this.setState({ labels: newLabels });
    // for report
    let newlabelCopy = [...this.state.labelsCopy];
    var index1 = newlabelCopy.findIndex((i) => i.fieldName === label.fieldName);
    if (index1 !== -1) newlabelCopy.splice(index1, 1);
    this.setState(
      { labelsCopy: newlabelCopy },
      this.removeEditNameTags(initLabel, labelValue)
    );
    // for report
    let newInitialCopy = [...this.state.initialCopy];
    newInitialCopy.forEach((initCopyObj) => {
      if (initCopyObj.value === id) {
        newInitialCopy.splice(id, 1);
      }
    });
    newInitialCopy.forEach((initCopyObj, id) => {
      initCopyObj.value = id;
    });
    //var index2 = newInitialCopy.indexOf(initLabel);
    //if (index2 !== -1) newInitialCopy.splice(index2, 1);
    this.setState({ initialCopy: newInitialCopy }, () => {
      this.removeEditNameInitialTags(label, labelValue);
    });
    // for system
    let newSystemLabels = [...this.state.systemLabels];
    var systemLabelIndex = newSystemLabels.findIndex(
      (i) => i.fieldName === label.fieldName
    );
    if (systemLabelIndex !== -1) newSystemLabels.splice(systemLabelIndex, 1);
    this.setState(
      { systemLabels: newSystemLabels },
      this.removeEditNameTags(initLabel, labelValue)
    );
    // for system
    let newSystemLabelInitials = [...this.state.systemLabelInitials];
    newSystemLabelInitials.forEach((sysInitObj) => {
      if (sysInitObj.value === id) {
        newSystemLabelInitials.splice(id, 1);
      }
    });
    newSystemLabelInitials.forEach((sysInitObj, id) => {
      sysInitObj.value = id;
    });
    //var systemLabelInitialIndex = newSystemLabelInitials.indexOf(initLabel);
    //if (systemLabelInitialIndex !== -1)
    //  newSystemLabelInitials.splice(systemLabelInitialIndex, 1);
    this.setState({ systemLabelInitials: newSystemLabelInitials }, () => {
      this.removeEditNameInitialTags(label, labelValue);
    });
  };

  /**
   *@author Suyog Manandhar
   *
   * This method dynamically adds new level input field on click add level button
   */
  addLevel = (e) => {
    e.preventDefault();
    this.setState(
      {
        levels: [
          ...this.state.levels,
          {
            level: "",
          },
        ],
      }
      // () => {
      //   let errors = { ...this.state.errors };
      //   errors.educationLevels.push({ level: false });
      //   this.setState({ errors: errors });
      // }
    );
  };
  /**
   * @author Suyog Manandhar
   *
   * This method removes the particular index array full the 'levels' multi-dimensional array
   */
  removeLevel = (idx) => {
    this.state.levels.splice(idx, 1);
    this.setState(
      {
        levels: this.state.levels,
      },
      function () {
        //create a reference array to take note of the suggestion tags not utilized
        //which will be eventually updated to the "suggestions" array.
        let existingSuggestions = [];
        //loop through the levels array length
        this.state.levels.forEach((level) => {
          //again loop through baseSuggestions array and compare levels individuals with baseSuggestions individuals
          this.state.baseSuggestions.forEach((baseSuggestion) => {
            if (level.level !== baseSuggestion) {
              //only push the specific baseSuggestion value if existingSuggestions array doesn't
              //include that particular value already and other level input don't have the same value
              if (
                !existingSuggestions.includes(baseSuggestion) &&
                !this.state.levels.some(function (level) {
                  return level.level === baseSuggestion;
                })
              ) {
                existingSuggestions.push(baseSuggestion);
              }
            }
          });
        });

        this.setState(
          {
            suggestions: existingSuggestions,
          },
          () => {
            let errors = { ...this.state.errors };
            errors.educationLevels.splice(idx, 1);
            this.setState({ errors: errors });
          }
        );
      }
    );
  };

  /**
   * @author Suyog Manandhar
   *
   * This method handles the suggestion section of the Eduction Level Portion
   */
  handleDynamicFieldSuggestion = (e, idx, suggestionIndex, level) => {
    e.preventDefault();
    let newLevels = [...this.state.levels];
    newLevels[idx].level = this.state.suggestions[suggestionIndex];
    this.setState(
      {
        levels: newLevels,
      },
      () => {
        this.handleDynamicFieldSuggestionDisplay(level, idx);
      }
    );
  };

  /**
   * @author Suyog Manandhar
   *
   * Universal Method  Suggests Values for the input Fields
   *
   * @param  e event
   * @param  idx index of the clicked suggestion value
   * @param  inputStateName name of the state bind with controlled inputs
   * @param  suggestionArray state of the specific suggestion array
   * @param  baseSuggestionsArray state of the specific base suggestion array
   * @param  mappedPropsName name of the specific props array mapped, say "this.props.array_name"
   */
  universalSuggestionHandler = (
    e,
    idx,
    inputStateName,
    suggestionsArray,
    baseSuggestionsArray,
    mappedPropsName
  ) => {
    e.preventDefault();
    this.setState(
      {
        [inputStateName]: suggestionsArray[idx],
      },
      function () {
        let specificState = this.state[inputStateName];
        this.universalSuggestionDisplayHandler(
          baseSuggestionsArray,
          specificState,
          mappedPropsName,
          inputStateName
        );
      }
    );
  };

  /**
   * @author Suyog Manandhar
   *
   * Universal method modifies particular suggestion view checking into the different input field
   *
   * @param baseSuggestionsArray SuggestionsArray from the UniversalSuggestionHandler Method
   * @param specificState state with respect to the inputStateName from UniversalSuggestionHandler Method
   * @param mappedPropsName name of the props from UniversalSuggestionHandler Method
   */
  universalSuggestionDisplayHandler = (
    baseSuggestionsArray,
    specificState,
    mappedPropsName,
    name
  ) => {
    let suggestionArray = mappedPropsName.substring(11);
    let baseSuggestionArray = Object.assign([], baseSuggestionsArray);
    let existingSuggestionArray = [];

    baseSuggestionArray.forEach((el) => {
      if (specificState !== el) {
        existingSuggestionArray.push(el);
      }
    });
    this.setState(
      {
        [suggestionArray]: existingSuggestionArray,
      }
      // () => {
      //   this.validate(name);
      // }
    );
  };

  /**
   * @author Suyog Manandhar
   *
   * This method handles change event in the different level fields
   */
  handleLevelsChange = (e, idx) => {
    let newLevels = [...this.state.levels];
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "level" && (value[0] === "" || value[0] === " ")) {
      swal("warning", "Blank Spaces not allowed");
      return;
    }

    if (!value.match(this.state.regEx)) {
      swal({
        title: "Warning",
        text: "Character not allowed",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    } else {
      newLevels[idx][name] = value;
      //create a reference array to take note of the suggestion tags not utilized
      //which will be eventually updated to the "suggestions" array.
      let existingSuggestions = [];

      //loop through the baseSuggestion array length and compare it will the
      //event target input field
      this.state.baseSuggestions.forEach((baseSuggestion) => {
        if (value !== baseSuggestion) {
          //only push the specific baseSuggestion value if existingSuggestions array doesn't
          //include that particular value already and other level input don't have the same value
          if (
            !existingSuggestions.includes(baseSuggestion) &&
            !this.state.levels.some(function (level) {
              return level.level === baseSuggestion;
            })
          ) {
            existingSuggestions.push(baseSuggestion);
          }
        }
      });

      this.setState({
        suggestions: existingSuggestions,
      });

      this.setState(
        {
          levels: newLevels,
        }
        // () => {
        //   this.validate(name, idx);
        // }
      );
    }
  };
  /**
   * @author Suyog Manandhar
   *
   * Removes value from array
   */
  arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };
  /**
   * @author Suyog Manandhar
   *
   * This method modifies education level suggestion view checking into the level field
   */
  handleDynamicFieldSuggestionDisplay = (name, idx) => {
    let baseSuggestions = Object.assign([], this.state.baseSuggestions);
    //create a reference array to take note of the suggestion tags not utilized
    //which will be eventually updated to the "suggestions" array.
    let existingSuggestions = [];
    //loop through the levels array length
    this.state.levels.forEach((level) => {
      //again loop through baseSuggestions array and compare levels individuals with baseSuggestions individuals
      baseSuggestions.forEach((baseSuggestion) => {
        if (level.level !== baseSuggestion) {
          //only push the specific baseSuggestion value if existingSuggestions array doesn't
          //include that particular value already and other level input don't have the same value
          if (
            !existingSuggestions.includes(baseSuggestion) &&
            !this.state.levels.some(function (level) {
              return level.level === baseSuggestion;
            })
          ) {
            existingSuggestions.push(baseSuggestion);
          }
        }
      });
    });
    this.setState(
      {
        suggestions: existingSuggestions,
      }
      // () => {
      //   this.validate(name, idx);
      // }
    );
  };

  /**
   * @author Suyog Manandhar
   *
   * This method handles the event of backspace on the input fields of various suggestions binded
   * input fields
   *
   * @param e event
   * @param baseSuggestionsArray state of the baseSuggestionArray respective to the input field from handleChange Method
   * @param suggestionArrayName name of the state with respect to the suggestion displayed from handleChange Method
   */
  handleBackspace = (value, baseSuggestionsArray, suggestionsArrayName) => {
    let existingSuggestionsArray = [];
    baseSuggestionsArray.forEach((el) => {
      if (value !== el && !existingSuggestionsArray.includes(el)) {
        existingSuggestionsArray.push(el);
      }
    });

    this.setState({
      [suggestionsArrayName]: existingSuggestionsArray,
    });
  };

  /**
   * @author Suyog Manandhar
   *
   * This method maps the various input fields values with the states in the class
   * Only for the input fields with suggestions
   *
   * @param e event
   * @param baseSuggestionsArray state of the baseSuggestionArray respective to the input field
   * @param suggestionArrayName name of the state with respect to the suggestion displayed
   */
  handleSuggestionsChange = (e, baseSuggestionsArray, suggestionsArrayName) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    if (
      (name === "academicYearDivision" ||
        name === "yearLevel" ||
        name === "classroom") &&
      (value[0] === "" || value[0] === " ")
    ) {
      swal("warning", "Blank Spaces not allowed");
      return;
    }

    this.handleBackspace(value, baseSuggestionsArray, suggestionsArrayName);
    if (!value.match(this.state.regEx)) {
      swal({
        title: "Warning",
        text: "Character not allowed",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    } else {
      this.setState({ [name]: value }, () => {
        // this.validate(name);
      });
    }
  };

  /**
   * @author Suyog Manandhar
   *
   * This method maps the various input fields values with their respective states
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    //for validation of name of school with no space at begining
    if (
      (name === "nameOfTheSchool" || name === "acronym") &&
      (value[0] === "" || value[0] === " ")
    ) {
      swal("warning", "Blank Spaces not allowed");
      return;
    }

    if (name === "nameofTheSchool") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.validate(name);
        }
      );
    }

    //Since DOE is Nesting of JavaScript Objects we need to further granularize it before setting the state
    if (name === "year" || name === "day" || name === "month") {
      let tempDOE = this.state.DOE;
      tempDOE[name] = value;
      this.setState({
        DOE: tempDOE,
      });
    } else {
      if (name === "website" || name === "panNo") {
        this.setState({
          [name]: value,
        });
      } else {
        if (!value.match(this.state.regEx)) {
          swal({
            title: "Warning",
            text: "Character not allowed",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
        } else {
          this.setState(
            {
              [name]: value,
            }
            // () => {
            //   this.validate(name);
            // }
          );
        }
      }
    }

    if (name === "country") {
      const data = {
        countryId: e.target.value,
      };
      axiosPost(URL.getCountryFirstLevel, data, (response) => {
        this.setState({
          countryFirstLevel: response.data.data,
          countrySecondLevel: [],
          countryThirdLevel: [],
        });
      });
    }
    if (name === "firstLevelDisplayName") {
      const data = {
        countryId: parseInt(this.state.country),
        countryFirstLevelId: e.target.value,
      };
      this.setState({ countryFirstLevelId: value }, () => {
        axiosPost(URL.getCountrySecondLevel, data, (response) => {
          this.setState({
            countrySecondLevel: response.data.data,
          });
        });
      });
    }
    if (name === "secondLevelDisplayName") {
      const data = {
        countryId: parseInt(this.state.country),
        countryFirstLevelId: parseInt(this.state.countryFirstLevelId),
        countrySecondLevelId: e.target.value,
      };
      this.setState({ countrySecondLevelId: value }, () => {
        axiosPost(URL.getCountryThirdLevel, data, (response) => {
          this.setState({ countryThirdLevel: response.data.data });
        });
      });
    }
    if (name === "thirdLevelDisplayName") {
      this.setState({ countryThirdLevelId: value });
    }
  };

  handleFileChange = (e) => {
    const target = e.target;
    const name = target.name;

    if (name === "photo") {
      let data = e.target.files[0];
      this.setState({ photo: data });
    }
  };

  //validate on error
  validate = (name, idx) => {
    let newErrors = { ...this.state.errors };
    if (name === "level") {
      if (this.state.levels[0].level.length > 0) {
        newErrors.educationLevels[0].level = false;
      } else {
        newErrors.educationLevels[0].level = true;
      }
    } else if (name === "year" || name === "month" || name === "day") {
      if (this.state.DOE[name] !== null)
        newErrors.dateOfEstablishment[name] = false;
      else newErrors.dateOfEstablishment[name] = true;
    } else {
      if (this.state[name].length > 0) {
        newErrors[name] = false;
      } else {
        newErrors[name] = true;
      }
    }
    this.setState({ errors: newErrors });
  };
  /**
   * @author Saurav Sitaula
   * Validates the data to be sent and if no errors, the data is sent
   */
  validateAndSubmit = (data) => {
    const stateName = [
      { state: "nameOfTheSchool", name: "Name of the School" },
      // { state: "country", name: "Country" },
      // { state: "calendar", name: "Calendar" },
      // { state: "academicYearDivision", name: "Academic year division" },
      // { state: "yearLevel", name: "Year level" },
      // { state: "classroom", name: "Classroom" },
      // { state: "DOE", name: "Date of Establishment" },
      // { state: "levels", name: "Levels" },
      // {state:"panNo", name:"PAN/VAT No"}
    ];
    let errors = { ...this.state.errors };
    const level = this.state.levels[0].level;
    let emptyFields = [];
    errors.totalError = 0;
    stateName.forEach((name) => {
      if (this.state[name.state].length === 0) {
        errors[name.state] = true;
        errors.totalError = errors.totalError + 1;
        emptyFields.push(name.name);
      }
      // if (name.state === "DOE") {
      //   const dateNames = ["year", "month", "day"];
      //   let doe = { ...this.state.DOE };
      //   dateNames.forEach((name) => {
      //     if (doe[name] === null) {
      //       errors.dateOfEstablishment[name] = true;
      //       errors.totalError = errors.totalError + 1;
      //       emptyFields.push(name);
      //     }
      //   });
      // }
      // if (name.state === "levels") {
      //   //this.state.levels.forEach((level, id) => {
      //   if (level.length === 0) {
      //     errors.educationLevels[0].level = true;
      //     errors.totalError = errors.totalError + 1;
      //     emptyFields.push("Education level");
      //   }
      //   //});
      // }
    });
    this.setState({ errors: errors }, () => {
      if (this.state.errors.totalError === 0) {
        swal({
          title: "Are you sure the data is correct?",
          text: "This is a one time setup and cannot be changed later.",
          closeOnClickOutside: false,
          allowOutsideClick: false,
          buttons: true,
          dangerMode: true,
        }).then((isConfirm) => {
          if (isConfirm) {
            let formData = new FormData();
            formData.append("jsonData", JSON.stringify(data));
            formData.append("schoolLogo", this.state.photo);
            this.setState({ showSpinner: true }, function () {
              axiosPost(
                URL.insertSystemSetup,
                formData,
                (response) => {
                  this.setState({ showSpinner: false });
                  if (response.status === 200) {
                    localStorage.setItem("schoolSetup", true);

                    displaySuccessAlert(response, () => {
                      history.push(`${process.env.PUBLIC_URL}/academicYears`);
                    });
                  }
                },
                (error) => {
                  this.setState({ showSpinner: false });
                  let errorResponse = error.response
                    ? error.response.data
                    : error;
                  if (errorResponse.status === 400) {
                    //if condition to check spring boot validation errors
                    let errorMessage = "";
                    if (errorResponse.errors) {
                      errorResponse.errors.forEach((error) => {
                        errorMessage += `${error.field
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, function (str) {
                            return str.toUpperCase();
                          })} ${error.defaultMessage} \n`; //ishan
                      });
                      swal(errorResponse.error, errorMessage, "");
                    } else {
                      swal(errorResponse.error, errorResponse.message, "");
                    }
                  } else {
                    swal(
                      errorResponse.error || "Network Error",
                      errorResponse.message
                        ? errorResponse.message
                        : "Could Not Connect To The Server.",
                      ""
                    );
                  }
                }
              );
            });
          }
        });
      } else {
        swal({
          title: `${this.state.errors.totalError} Empty Fields`,
          content: (
            <div>
              {emptyFields.map((field) => {
                return (
                  <ul className="text-left">
                    <li>{field}</li>
                  </ul>
                );
              })}
            </div>
          ),
        });
        this.setState({ currentIndex: this.state.currentIndex - 2 });
      }
      // this.setState({ currentIndex: this.state.currentIndex - 2 });
    });
  };

  /**
   * @author Suyog Manandhar
   *
   * This method is invoked on clicking the Next button in the form and console logs
   * out the user inputted values
   */
  handleSubmit = (e) => {
    e.preventDefault();
    for (let i = 0; i < this.state.labels.length; i++) {
      if (this.state.labels[i].fieldName === "") {
        swal({
          title: "Warning",
          text: "Empty Name Field Label.",
        });
        return;
      }
    }
    for (let i = 0; i < this.state.addressClusterFields.length; i++) {
      if (this.state.addressClusterFields[i].fieldName === "") {
        swal({
          title: "Warning",
          text: "Empty Address Field Label.",
        });
        return;
      }
    }
    const transformed = this.state.levels.map(({ level }) => ({ name: level }));
    // ishan edit
    let newselectedAddressClusterFieldsForChips = JSON.parse(
      JSON.stringify(this.state.selectedAddressClusterFieldsForChips)
    );
    let addressDisplayFormats = [];
    newselectedAddressClusterFieldsForChips.forEach((element) => {
      let stringLiteral = null;
      if (element.literal) {
        stringLiteral = element.label;
      } else {
        stringLiteral = element.fieldName;
      }
      let addressDisplayFormat = {
        literal: element.literal,
        stringLiteral: stringLiteral,
      };
      addressDisplayFormats.push(addressDisplayFormat);
    });
    // end ishan edit
    //prep name display format
    const reportNameDisplayFormat = [];
    const systemNameDisplayFormat = [];
    let nameDisplayFormatData = {};
    this.state.editableNameTagsValue.forEach((tag) => {
      this.state.labels.forEach((label) => {
        if (label.value === tag.value) {
          let labelNameDisplayFormatData = {
            stringLiteral: label.fieldName,
          };
          reportNameDisplayFormat.push(labelNameDisplayFormatData);
        }
      });
      if (
        this.state.literalTags.findIndex((i) => i.value === tag.value) !== -1
      ) {
        nameDisplayFormatData = {
          stringLiteral: tag.value,
          literal: true,
        };
        reportNameDisplayFormat.push(nameDisplayFormatData);
      }
    });

    this.state.systemTagsValue.forEach((tag) => {
      this.state.labels.forEach((label) => {
        if (label.value === tag.value) {
          let labelNameDisplayFormatData = {
            stringLiteral: label.fieldName,
          };
          systemNameDisplayFormat.push(labelNameDisplayFormatData);
        }
      });
      if (this.state.systemLiteralTags.indexOf(tag.value) !== -1) {
        nameDisplayFormatData = {
          stringLiteral: tag.value,
          literal: true,
        };
        systemNameDisplayFormat.push(nameDisplayFormatData);
      }
    });
    let newaddressTableFields = JSON.parse(
      JSON.stringify(this.state.addressClusterFields)
    );

    newaddressTableFields.forEach((field) => {
      field.id = parseInt(field.id);
    });

    let data = {
      school: {
        name: this.state.nameOfTheSchool,
        acronym: this.state.acronym,
        dateOfEstablishment: new Date(
          `${this.state.DOE.year}-${this.state.DOE.month}-${this.state.DOE.day}`
        ),
        countryId: this.state.country,
        countryFirstLevelId: this.state.countryFirstLevelId,
        countrySecondLevelId: this.state.countrySecondLevelId,
        countryThirdLevelId: this.state.countryThirdLevelId,
        calendarTypeId: this.state.calendar,
        hasSalutation: this.state.salutation,
        hasAdultSalutation: this.state.adultSalutation,
        logoLocation: this.state.switchToggle,
        panVatNo: this.state.panNo,
        website: this.state.website,
        // photo: this.state.photo.name,
      },
      aliases: [
        {
          systemName: "peopleName",
          displayName: this.state.labels[0].fieldName,
        },
        {
          systemName: "educationLevelDivision",
          displayName: this.state.academicYearDivision,
        },
        {
          systemName: "yearLevel",
          displayName: this.state.yearLevel,
        },
        {
          systemName: "classroom",
          displayName: this.state.classroom,
        },
      ],
      peopleTableFields: this.state.labels,
      addressTableFields: newaddressTableFields,
      educationLevels: transformed,
      addressDisplayFormats,
      reportNameDisplayFormat,
      systemNameDisplayFormat,
    };
    localStorage.setItem(
      "educationLevelDivision",
      this.state.academicYearDivision
    );
    this.validateAndSubmit(data);
  };

  /**
   * @author Ishan Shrestha
   */
  handleDateOfEstablishmentChange = (objKey, objValue) => {
    let DOE = { ...this.state.DOE };
    DOE[objKey] = objValue;
    this.setState({ DOE }, () => {
      this.validate(objKey);
    });
  };

  /**
   * @author Ishan Shrestha
   * This method created default cluster fields based on
   * addressClusterFieldLables(labels stored in state later can be retrieved from database)
   * when component is rendered.
   */
  handleAddressClusterFieldsGeneration = () => {
    let addressClusterFieldLables = Object.assign(
      [],
      this.state.addressClusterFieldLables
    );
    let addressClusterFields = Object.assign(
      [],
      this.state.addressClusterFields
    );
    let addressClusterFieldsCopy = [...this.state.addressClusterFieldsCopy];
    addressClusterFieldLables.forEach((addressClusterFieldlabel) => {
      const data = {
        id: uuid.v4(),
        fieldName: addressClusterFieldlabel,
        exampleValue: "",
        fieldTypeId: 1,
        isMandatory: true,
        literal: false,
      };
      addressClusterFields.push(data);
      addressClusterFieldsCopy.push(data);
    });
    this.setState({
      addressClusterFields: addressClusterFields,
      addressClusterFieldsCopy: addressClusterFieldsCopy,
    });
  };

  /**
   * @author Ishan Shrestha
   * This method handles adding a label and example value field in setup 3.
   */
  handleAddAddressClusterField = () => {
    let addressClusterFields = Object.assign(
      [],
      this.state.addressClusterFields
    );
    let addressClusterFieldsCopy = [...this.state.addressClusterFieldsCopy];
    const addressClusterField = {
      id: uuid.v4(),
      fieldName: "",
      exampleValue: "",
      fieldTypeId: 1,
      isMandatory: false,
      literal: false,
    };
    addressClusterFields.push(addressClusterField);
    addressClusterFieldsCopy.push(addressClusterField);
    this.setState({
      addressClusterFields: addressClusterFields,
      addressClusterFieldsCopy: addressClusterFieldsCopy,
    });
  };

  /**
   * @author Ishan Shrestha
   * This method handles removing a label and example value field in setup 3.
   */
  handleRemoveAddressClusterField = ({ index, addressClusterField }) => {
    let addressClusterFields = Object.assign(
      [],
      this.state.addressClusterFields
    );
    let addressClusterFieldsCopy = [...this.state.addressClusterFieldsCopy];
    const indexAdd = addressClusterFields.findIndex(
      (i) => i.id === addressClusterField.id
    );
    const indexCopy = addressClusterFieldsCopy.findIndex(
      (i) => i.id === addressClusterField.id
    );
    addressClusterFields.splice(indexAdd, 1);
    addressClusterFieldsCopy.splice(indexCopy, 1);
    this.setState({
      addressClusterFields: addressClusterFields,
      addressClusterFieldsCopy,
    });
    let newSelectedAddressClusterFieldsForChips = [
      ...this.state.selectedAddressClusterFieldsForChips,
    ];
    let delIndex = this.state.selectedAddressClusterFieldsForChips.findIndex(
      (i) => i.id === addressClusterField.id
    );
    if (delIndex !== -1)
      newSelectedAddressClusterFieldsForChips.splice(delIndex, 1);
    this.setState({
      selectedAddressClusterFieldsForChips:
        newSelectedAddressClusterFieldsForChips,
    });
  };

  /**
   * @author Ishan Shrestha
   */
  handleAddressClusterFieldLabelChange = (e, addressClusterField) => {
    let addressClusterFields = Object.assign(
      [],
      this.state.addressClusterFields
    );
    let addressClusterFieldsCopy = [...this.state.addressClusterFieldsCopy];
    const indexAdd = addressClusterFields.findIndex(
      (i) => i.id === addressClusterField.id
    );
    const indexCopy = addressClusterFieldsCopy.findIndex(
      (i) => i.id === addressClusterField.id
    );
    if (indexAdd !== -1)
      addressClusterFields[indexAdd].fieldName = e.target.value;
    if (indexCopy !== -1)
      addressClusterFieldsCopy[indexCopy].fieldName = e.target.value;
    this.setState({ addressClusterFields, addressClusterFieldsCopy });
  };

  /**
   * @author Ishan Shrestha
   */
  handleAddressClusterFieldExampleValueChange = (e, addressClusterField) => {
    let addressClusterFields = Object.assign(
      [],
      this.state.addressClusterFields
    );
    let addressClusterFieldsCopy = [...this.state.addressClusterFieldsCopy];
    const indexAdd = addressClusterFields.findIndex(
      (i) => i.id === addressClusterField.id
    );
    const indexCopy = addressClusterFieldsCopy.findIndex(
      (i) => i.id === addressClusterField.id
    );
    if (indexAdd !== -1)
      addressClusterFields[indexAdd].exampleValue = e.target.value;
    if (indexCopy !== -1)
      addressClusterFieldsCopy[indexCopy].exampleValue = e.target.value;
    this.setState({ addressClusterFields, addressClusterFieldsCopy });
  };
  /**
   * @author Ishan Shrestha
   */
  handleUnselectedAddressLabelChipClick = (e, field) => {
    let newselectedAddressClusterFieldsForChips = [
      ...this.state.selectedAddressClusterFieldsForChips,
    ];
    let newaddressClusterFieldsCopy = [...this.state.addressClusterFieldsCopy];
    newselectedAddressClusterFieldsForChips.push(field);
    const index = newaddressClusterFieldsCopy.findIndex(
      (i) => i.id === field.id
    );
    if (index !== -1) newaddressClusterFieldsCopy.splice(index, 1);
    this.setState({
      selectedAddressClusterFieldsForChips:
        newselectedAddressClusterFieldsForChips,
      addressClusterFieldsCopy: newaddressClusterFieldsCopy,
    });
  };

  /**
   * @author Ishan Shrestha
   */
  // selectedAddressClusterFieldsForChips //this is for selected chips
  handleAddressClusterChipDelete = (e, field) => {
    let newselectedAddressClusterFieldsForChips = [
      ...this.state.selectedAddressClusterFieldsForChips,
    ];
    let newaddressClusterFieldsCopy = [...this.state.addressClusterFieldsCopy];
    if (field.literal === false) newaddressClusterFieldsCopy.push(field);
    const index = newselectedAddressClusterFieldsForChips.findIndex(
      (i) => i.id === field.id
    );
    if (index !== -1) newselectedAddressClusterFieldsForChips.splice(index, 1);
    this.setState({
      selectedAddressClusterFieldsForChips:
        newselectedAddressClusterFieldsForChips,
      addressClusterFieldsCopy: newaddressClusterFieldsCopy,
    });
  };

  /**
   * @author Ishan Shrestha
   * This method is called when custom text is added to input field next to chips
   */
  handleAddCustomTextToAddressFormat = (e) => {
    if (e.key === "Enter") {
      let selectedAddressClusterFieldsForChips = Object.assign(
        [],
        this.state.selectedAddressClusterFieldsForChips
      ); //this is for selected chips
      // let index = this.state.currentSelectedAddressClusterFieldsForChipIndex;
      let customTextFieldValue = e.target.value;
      if (customTextFieldValue)
        selectedAddressClusterFieldsForChips.push({
          id: uuid.v4(),
          fieldName: customTextFieldValue,
          exampleValue: customTextFieldValue,
          literal: true,
        });
      this.setState(
        {
          selectedAddressClusterFieldsForChips:
            selectedAddressClusterFieldsForChips,
          currentSelectedAddressClusterFieldsForChipIndex: -1,
        },
        () => {}
      );
      e.target.value = "";
    }
  };

  handleReturnChipsClick = () => {
    let selectedAddressClusterFieldsForChips = Object.assign(
      [],
      this.state.selectedAddressClusterFieldsForChips
    ); //this is for selected chips
    selectedAddressClusterFieldsForChips.push({
      id: uuid.v4(),
      literal: true,
      fieldName: "\n",
      exampleValue: <br></br>,
    });
    this.setState(
      {
        selectedAddressClusterFieldsForChips:
          selectedAddressClusterFieldsForChips,
        currentSelectedAddressClusterFieldsForChipIndex: -1,
      },
      () => {
        // e.target.value = "";
      }
    );
  };

  handleSwitchChange = (e) => {
    this.setState({ switchToggle: !this.state.switchToggle });
  };

  render() {
    return (
      <>
        <NavBar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          title={"System Setup in 2 stages"}
          nonSystemSetup={true}
        ></NavBar>
        {/* <div className="tt-group-header mx-2 mt-2">
          <p className="quote">System Setup in 2 stages</p>
        </div> */}
        <div className="container">
          <Tabs
            selectedIndex={this.state.currentIndex}
            onSelect={(currentIndex) => this.setState({ currentIndex })}
          >
            <TabList className="middle123">
              <Tab
                className="horizontaltwo customTab1"
                id={this.state.currentTab}
              >
                <span className="dot">1</span>
                <p className="topic create-academic">SCHOOL INFORMATION</p>
              </Tab>
              <div className="horizontalline" />
              <Tab className="horizontaltwo">
                <span className="dot">2</span>
                <p className="topic term-setup">NAME FORMATS</p>
              </Tab>
              <div className="horizontalline" />
              <Tab className="horizontaltwo">
                <span className="dot">3</span>
                <p className="topic weekend-setup addrFormat">
                  ADDRESS FORMATS
                </p>
              </Tab>
            </TabList>

            <div className="row academic-content" style={{ height: "auto" }}>
              <div className="tt-systemSetupContent">
                <TabPanel className="main-panel">
                  <SetupOne
                    photo={this.state.photo}
                    nameOfTheSchool={this.state.nameOfTheSchool}
                    acronym={this.state.acronym}
                    calendarType={this.state.calendarType}
                    DOE={this.state.DOE}
                    country={this.state.country}
                    countryFirstLevelId={this.state.countryFirstLevelId}
                    countrySecondLevelId={this.state.countrySecondLevelId}
                    countryThirdLevelId={this.state.countryThirdLevelId}
                    countryFirstLevel={this.state.countryFirstLevel}
                    countrySecondLevel={this.state.countrySecondLevel}
                    countryThirdLevel={this.state.countryThirdLevel}
                    calendar={this.state.calendar}
                    suggestions={this.state.suggestions}
                    yearLevel={this.state.yearLevel}
                    classroom={this.state.classroom}
                    classSuggestions={this.state.classSuggestions}
                    baseClassSuggestions={this.state.baseClassSuggestions}
                    yearSuggestions={this.state.yearSuggestions}
                    baseYearSuggestions={this.state.baseYearSuggestions}
                    academicSuggestions={this.state.academicSuggestions}
                    baseAcademicSuggestions={this.state.baseAcademicSuggestions}
                    academicYearDivision={this.state.academicYearDivision}
                    handleChange={this.handleChange}
                    handleFileChange={this.handleFileChange}
                    handleLevelsChange={this.handleLevelsChange}
                    onSubmit={this.onSubmit}
                    addLevel={this.addLevel}
                    levels={this.state.levels}
                    removeLevel={this.removeLevel}
                    handleDynamicFieldSuggestion={
                      this.handleDynamicFieldSuggestion
                    }
                    handleDefaultEducationLevels={
                      this.handleDefaultEducationLevels
                    }
                    universalSuggestionHandler={this.universalSuggestionHandler}
                    handleSuggestionsChange={this.handleSuggestionsChange}
                    changeToNextIndex={this.changeToNextIndex}
                    // handling date of establishment
                    handleDateOfEstablishmentChange={
                      this.handleDateOfEstablishmentChange
                    }
                    handleDateChange={this.handleDateChange}
                    errors={this.state.errors}
                    renderErrorText={this.renderErrorText}
                    switchToggle={this.state.switchToggle}
                    handleSwitchChange={this.handleSwitchChange}
                    website={this.state.website}
                    panNo={this.state.panNo}
                  />
                </TabPanel>
                <TabPanel className="main-panel">
                  <SetupTwo
                    handleLabelValueChange={this.handleLabelValueChange}
                    handleLabelChange={this.handleLabelChange}
                    handleAddNewNameField={this.handleAddNewNameField}
                    handleRemoveNewNameField={this.handleRemoveNewNameField}
                    handleEditableNameTags={this.handleEditableNameTags}
                    handleDeleteEditableNameTags={
                      this.handleDeleteEditableNameTags
                    }
                    handleEditableNameInitialTags={
                      this.handleEditableNameInitialTags
                    }
                    labels={this.state.labels}
                    labelsCopy={this.state.labelsCopy}
                    initialCopy={this.state.initialCopy}
                    editableNameTags={this.state.editableNameTags}
                    salutation={this.state.salutation}
                    adultSalutation={this.state.adultSalutation}
                    showNameFormat={this.showNameFormat}
                    symbols={this.state.symbols}
                    handleSymbols={this.handleSymbols}
                    {...this.state}
                    showSystemNameFormat={this.showSystemNameFormat}
                    handleCheckBoxChange={this.handleCheckBoxChange}
                    changeToPrevIndex={this.changeToPrevIndex}
                    changeToNextIndex={this.changeToNextIndex}
                    handleLiterals={this.handleLiterals}
                    handleDeleteLiteralTags={this.handleDeleteLiteralTags}
                    handleSalutation={this.handleSalutation}
                  />
                </TabPanel>
                <TabPanel className="main-panel">
                  <SetupThree
                    addressClusterFieldLables={
                      this.state.addressClusterFieldLables
                    }
                    addressClusterFields={this.state.addressClusterFields}
                    addressClusterFieldsCopy={
                      this.state.addressClusterFieldsCopy
                    }
                    handleAddAddressClusterField={
                      this.handleAddAddressClusterField
                    }
                    handleRemoveAddressClusterField={
                      this.handleRemoveAddressClusterField
                    }
                    handleAddressClusterFieldLabelChange={
                      this.handleAddressClusterFieldLabelChange
                    }
                    handleAddressClusterFieldExampleValueChange={
                      this.handleAddressClusterFieldExampleValueChange
                    }
                    handleAddressClusterChipDelete={
                      this.handleAddressClusterChipDelete
                    }
                    // for chips
                    selectedAddressClusterFieldsForChips={
                      this.state.selectedAddressClusterFieldsForChips
                    }
                    handleUnselectedAddressLabelChipClick={
                      this.handleUnselectedAddressLabelChipClick
                    }
                    handleAddCustomTextToAddressFormat={
                      this.handleAddCustomTextToAddressFormat
                    }
                    handleReturnChipsClick={this.handleReturnChipsClick}
                    changeToPrevIndex={this.changeToPrevIndex}
                    handleCheckBoxChange={this.handleCheckBoxChange}
                    handleSubmit={this.handleSubmit}
                    errors={this.state.errors}
                  />
                </TabPanel>
              </div>
            </div>
          </Tabs>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. The system setup is being saved ...
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default SystemSetup;

import React, { Component } from "react";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import swal from "sweetalert";
import { cloneDeep } from "lodash";
import makeAnimated from "react-select/animated";
import moment from "moment";
import ModalWindow from "../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import RoutineAliasModal from "../ClassRoutine/RoutineAliasModal";

const animatedComponents = makeAnimated();
export class ExamRoutineFormat extends Component {
  state = {
    aliasModal: false,
    weekDays: [],
    routine: [
      {
        subject: [],
        date: "",
        weekdayId: "",
        startTime: "",
        duration: "",
        endTime: "",
        teachers: [],
      },
    ],
    subjectOption: [],
    teacherOption: this.props.teachers,
  };

  addRoutineField = () => {
    let routine = [...this.state.routine];
    routine.push({
      subject: [],
      date: "",
      weekdayId: "",
      startTime: "",
      duration: "",
      endTime: "",
      teachers: [],
    });
    this.setState({
      routine,
    });
  };

  componentDidMount() {
    this.getSubjects();
    this.getWeekDays();

    this.getTeachers();
  }

  closeAliasModal = () => {
    this.setState({ aliasModal: !this.state.aliasModal });
  };

  getSubjects = () => {
    var subjectOption = this.props.subjects.map(function (sub) {
      return {
        value: sub.id,
        label: sub.name,
        hasAliasName: false,
        aliasName: "",
      };
    });
    this.setState({ subjectOption: subjectOption });
  };

  getTeachers = () => {
    var teacherOption = this.props.teachers.map(function (teach) {
      return { value: teach.peopleId, label: teach.people.name };
    });
    this.setState({ teacherOption: teacherOption });
  };

  getWeekDays = () => {
    axiosPost(URL.getWeekDays, {}, (response) => {
      if (response.status === 200) {
        this.setState({ weekDays: response.data.data });
      }
    });
  };

  handleChange = (e, idx) => {
    let routine = [...this.state.routine],
      { name, value } = e.target;

    if (name === "date") {
      routine[idx][name] = value;
      let date = value;
      let dayName = moment(date).format("dddd");
      let weekDays = [...this.state.weekDays];
      let index = weekDays.findIndex((el) => el.name === dayName);
      routine[idx].weekdayId = weekDays[index].id;
      this.setState({ routine });
    } else {
      routine[idx][name] = value;
      this.setState({
        routine,
      });
    }

    if (name === "duration") {
      routine[idx][name] = value;
      this.setState({
        routine,
      });

      const today = moment(new Date()).format("YYYY/MM/DD");
      const startTime = new Date(
        `${today} ` + routine[idx].startTime
      ).getTime();

      var startdate = moment(startTime);

      var returned_endate = moment(startdate)
        .add(value, "hours")
        .format("HH:mm");

      routine[idx].endTime = returned_endate;
      this.setState({
        routine,
      });
    }
  };

  handleCreateRoutine = () => {
    let routine = [...this.state.routine];

    let newRoutine = routine.map((r) => {
      // r.startDateTime = moment(
      //   `${r.date} ${r.startTime}`,
      //   "YYYY-MM-DD HH:mm:ss"
      // )
      //   .utcOffset(0, true)
      //   .format();

      r.startDateTime = r.date + " " + r.startTime + ":00";
      // r.endDateTime = moment(`${r.date} ${r.endTime}`, "YYYY-MM-DD HH:mm:ss")
      //   .utcOffset(0, true)
      //   .format();

      r.endDateTime = r.date + " " + r.endTime + ":00";
      r.subjectsArr = r.subject.map((s) => {
        return { id: s.value, aliasName: s.aliasName };
      });
      r.teacherArr = r.teachers.map((t) => {
        return t.value;
      });
      return r;
    });

    let rData = newRoutine.map((nr) => {
      let routine = [
        {
          subjects: nr.subjectsArr,
          teacher: nr.teacherArr,
          weekday: nr.weekdayId,
          startTime: nr.startDateTime,
          endTime: nr.endDateTime,
        },
      ];

      return routine;
    });

    let newRoutineData = [
      {
        educationLevelId: this.props.selectedEducationLevelId,
        yearlevelId: this.props.selectedYearLevel,
        classroom: [
          {
            id: this.props.selectedClassrooms,
            routine: rData,
          },
        ],
      },
    ];

    this.setState({
      newRoutineData,
    });
  };

  handleSubjectChange = (selectedSubject, idx) => {
    let routine = [...this.state.routine];
    if (selectedSubject.length > 0) {
      routine[idx].subject = selectedSubject;
      routine[idx].hasAliasName = true;

      this.setState({
        routine,
      });
    }
  };

  handleTeacherChange = (selectedTeacher, idx) => {
    let routine = [...this.state.routine];

    routine[idx].teachers = selectedTeacher;
    this.setState({
      routine,
    });
  };

  handleTimeChange = (e, idx) => {
    let routine = [...this.state.routine];
    const { name, value } = e.target;

    routine[idx].startTime = value;
    this.setState({
      routine,
    });
  };

  handleAliasDataChange = (e, idx) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let selectedAliasNameData = this.state.selectedAliasNameData;
    selectedAliasNameData.selectedOptionSubject[idx][name] = value;
    this.setState({ selectedAliasNameData });
  };

  handleAliasName = (routine, idx) => {
    let routineData = [...this.state.routine];
    let aliasData = [];

    if (routineData[idx].subject.length > 0) {
      routineData[idx].subject.map((el) => {
        aliasData = {
          hasAliasName: true,
          selectedOptionSubject: routineData[idx].subject,
        };
      });
      this.setState({
        selectedAliasNameData: JSON.parse(JSON.stringify(aliasData)),
        aliasModal: !this.state.aliasModal,
        selectedRoutineIdx: idx,
      });
    }
  };

  handleAliasSave = () => {
    let selectedAliasNameData = this.state.selectedAliasNameData;
    let error = 0;
    if (selectedAliasNameData.selectedOptionSubject.length > 0) {
      selectedAliasNameData.selectedOptionSubject.forEach((el) => {
        if (el.hasAliasName) {
          if (el.aliasName === "") {
            error++;
          }
        } else {
          el.aliasName = "";
        }
      });
      if (error > 0) {
        swal("Error", "Provide Alias Name for all checked subjects");
      } else {
        let routine = [...this.state.routine];

        routine[this.state.selectedRoutineIdx].subject =
          selectedAliasNameData.selectedOptionSubject;

        this.setState(
          {
            routine,
            selectedAliasNameData: {},
            selectedRoutineIdx: "",
            selectedWeekDaysId: "",
          },
          function () {
            this.closeAliasModal();
          }
        );
      }
    }
  };

  removeRoutineField = (index) => {
    let newRoutineArr = [...this.state.routine];
    newRoutineArr.splice(index, 1);
    this.setState({ routine: newRoutineArr });
  };

  renderAliasField = (routine, idx) => {
    // debugger;
    // let routine = [...this.state.routine]
    // var findSelctedOne = rout?.weekDays.find((wD) => colId === wD.weekdayId);
    return routine?.hasAliasName ? (
      <button
        className="tt-button tt-button-primary mt-1"
        onClick={(e) => this.handleAliasName(routine, idx)}
      >
        Add Alias Name
      </button>
    ) : null;
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "81.6vh" }}
        >
          <div style={{ height: "74vh", overflowY: "auto" }}>
            <table className="table mb-0 " style={{ height: "98%" }}>
              <thead className="tt-group-header text-center">
                <tr>
                  <th>Subject</th>
                  <th>Date</th>
                  <th>Weekday</th>
                  <th>Start Time</th>
                  <th>Duration(In Hours)</th>
                  <th>End Time</th>
                  <th>Cordinator</th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="text-center">
                {this.state.routine?.map((routine, idx) => (
                  <tr>
                    <td>
                      {" "}
                      <Select
                        value={routine.subject}
                        closeMenuOnSelect={false}
                        isClearable={true}
                        components={animatedComponents}
                        options={this.state.subjectOption}
                        isMulti
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) => this.handleSubjectChange(e, idx)}
                        menuPlacement="auto"
                        hideSelectedOptions={true}
                        required={true}
                      />
                      {this.renderAliasField(routine, idx)}
                    </td>
                    <td>
                      {" "}
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={routine.date}
                        onChange={(e) => this.handleChange(e, idx)}
                        placeholder="Select Date"
                        //   min={this.state.startDate}
                        //   max={this.state.endDate}
                      />
                    </td>
                    <td>
                      {" "}
                      <select
                        name="weekdayId"
                        value={routine.weekdayId}
                        className="form-control"
                        //   onChange={(e) => this.handleChange(e, idx)}
                        disabled
                      >
                        <option value="" disabled selected>
                          Week Day
                        </option>
                        {this.state.weekDays?.map((day, wIdx) => {
                          return (
                            <option key={wIdx} value={day.id}>
                              {day.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td>
                      {" "}
                      <input
                        type="time"
                        className="form-control"
                        name="startTime"
                        placeholder="Start Time"
                        onChange={(e) => this.handleTimeChange(e, idx)}
                        value={routine.startTime}
                        style={{ marginTop: "4px" }}
                      />
                    </td>
                    <td>
                      <input
                        name="duration"
                        type="number"
                        className="form-control"
                        value={routine.duration}
                        onChange={(e) => this.handleChange(e, idx)}
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        disabled
                        type="time"
                        className="form-control"
                        name="endTime"
                        placeholder="End Time"
                        // onChange={(e) => this.handleTimeChange(e, idx)}
                        value={routine.endTime}
                        style={{ marginTop: "4px" }}
                      />
                    </td>
                    <td>
                      <Select
                        required={true}
                        value={routine.teachers}
                        closeMenuOnSelect={false}
                        isClearable={true}
                        components={animatedComponents}
                        isMulti
                        name="teacher"
                        placeholder="Teachers"
                        onChange={(e) => this.handleTeacherChange(e, idx)}
                        options={this.state.teacherOption}
                        menuPlacement="auto"
                        hideSelectedOptions={true}
                        className="mt-1"
                      ></Select>
                    </td>
                    <td>
                      {this.state.routine.length !== 1 && (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="removeMcqOption mr-2"
                          onClick={(e) => this.removeRoutineField(idx)}
                        ></FontAwesomeIcon>
                      )}
                      {this.state.routine.length - 1 === idx && (
                        <FontAwesomeIcon
                          type="submit"
                          icon={faPlusCircle}
                          className="addMcqOption"
                          onClick={this.addRoutineField}
                          style={{ marginLeft: "1px" }}
                        ></FontAwesomeIcon>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="float-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.handleCreateRoutine}
              >
                Create routine
              </button>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state?.aliasModal}
          toggleModal={this.closeAliasModal}
          modalHeader={"Subject Alias Name"}
          size="md"
          modalBody={
            <RoutineAliasModal
              selectedAliasNameData={this.state.selectedAliasNameData}
              handleAliasDataChange={this.handleAliasDataChange}
              handleAliasSave={this.handleAliasSave}
              // handleUpdatedAliasName={this.handleUpdatedAliasName}
              // handleUpdateAliasSave={this.handleUpdateAliasSave}
              // edit={this.state.edit}
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

export default ExamRoutineFormat;

/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, StaffType, render the select field for the staff type and
 *              (date) input field for the date of hire
 */

import React, { Component } from "react";
import swal from "@sweetalert/with-react";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { axiosPost } from "../../../utils/AxiosApi";
import { draggable, URL } from "../../../utils/Constants";
import ModalWindow from "./../../UI/ModalWindow";
import AddNewDesignation from "../Recruitment/AddNewDesignation";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

const animatedComponents = makeAnimated();
class StaffType extends Component {
  state = {
    academicYears: [],
    designationModal: false,
    details: "",
    name: "",
  };

  componentDidMount() {
    axiosPost(URL.getAcademicYears, {}, (response) => {
      if (response.status === 200) {
        let academicYears = response.data.data;
        this.setState({
          academicYears,
        });
      }
    });
  }

  designationFooter = () => {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={(e) => this.submit(e)}
            >
              Add
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.openDesignationModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  handleDesignationChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  openDesignationModal = () => {
    this.setState({
      designationModal: !this.state.designationModal,
      name: "",
      details: "",
    });
  };

  submit = (e) => {
    e.preventDefault();
    if (this.state.name !== "") {
      let data = {
        name: this.state.name,
        details: this.state.details,
      };
      axiosPost(URL.insertDesignation, data, (response) => {
        if (response.status === 200) {
          swal({ title: "Success", text: "Designation Added Successfully" });
          this.props.getDesignations();
          this.openDesignationModal();
        }
      });
    } else {
      swal({
        title: "Error",
        text: "Name cannot be empty",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    }
  };

  render() {
    let errors = this.props.errors;
    return (
      <>
        <div className="tt-group-header">Staff Recruitment</div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="academicLevel">
              <strong>Academic Year</strong>
            </label>
          </div>
          <div className="col-md-7">
            <select
              name="academicYear"
              id="academicLevel"
              className={
                errors.academicYear
                  ? "form-control indicate-error"
                  : "form-control"
              }
              onChange={this.props.handleChange}
              value={this.props.academicYear}
            >
              <option value="" selected disabled>
                Choose an academic year
              </option>
              {this.state.academicYears.map((academicYear, idx) => {
                return (
                  <option value={academicYear.id} key={idx}>
                    {academicYear.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="staffTypeId">
              <strong>Staff type</strong>
            </label>
          </div>
          <div className="col-md-7">
            <select
              name="staffTypeId"
              onChange={this.props.handleChange}
              value={this.props.staffTypeId}
              className={
                errors.staffTypeId
                  ? "form-control indicate-error"
                  : "form-control"
              }
            >
              <option value="" selected disabled>
                Choose staff type
              </option>
              {this.props.staffType.map((el, idx) => {
                return (
                  <option value={el.id} key={idx}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="dateOfHire">
              <strong>Year of joining</strong>
            </label>
          </div>
          <div className="col-md-5">
            {/* <input
              type="date"
              id="dateOfHire"
              name="dateOfHire"
              value={this.props.dateOfHire}
              className={
                errors.dateOfHire
                  ? "form-control indicate-error"
                  : "form-control"
              }
              onChange={this.props.handleChange}
              min={this.props.schoolEstablishedDate}
              required
            /> */}

            {this.props.yearOfJoiningToggleDate && this.props.dateOfHireInBS ? (
              <>
                <div className="staffTypeCalender">
                  <Calendar
                    // hideDefaultValue={true}
                    className={
                      errors.dateOfHire
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    defaultDate={this.props.dateOfHireInBS}
                    onChange={this.props.handleChangeNepaliDate}
                  />
                </div>
              </>
            ) : (
              <input
                type="date"
                id="dateOfHire"
                name="dateOfHire"
                value={this.props.dateOfHire}
                className={
                  errors.dateOfHire
                    ? "form-control indicate-error"
                    : "form-control"
                }
                defaultValue={this.props.dateOfHire}
                onChange={this.props.handleChange}
                min={this.props.schoolEstablishedDate}
                required
              />
            )}
          </div>
          <div className="col-md-2">
            <span>
              {" "}
              <b>A.D.</b>
            </span>
            <label className="switch">
              <input
                name="yearOfJoiningToggleDate"
                type="checkbox"
                defaultChecked={this.props.yearOfJoiningToggleDate}
                onChange={this.props.toggleDatePicker}
              />
              <span className="slider round"></span>
            </label>{" "}
            <span>
              {" "}
              <b>B.S.</b>
            </span>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="dateOfHire">
              <strong>Department</strong>
            </label>
          </div>
          <div className="col-md-7">
            <Select
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={this.props.selectedDepartments}
              options={this.props.departments}
              name="department"
              className={
                errors.department
                  ? "basic-multi-select indicate-error"
                  : "basic-multi-select"
              }
              onChange={(items) =>
                this.props.handleDepartmentChange(items, "selectedDepartments")
              }
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="dateOfHire">
              <strong>Designation</strong>
            </label>
          </div>
          <div className="col">
            <Select
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={this.props.selectedDesignations}
              options={this.props.designations}
              name="designation"
              className={
                errors.department
                  ? "basic-multi-select indicate-error"
                  : "basic-multi-select"
              }
              onChange={(items) =>
                this.props.handleDepartmentChange(items, "selectedDesignations")
              }
            />
          </div>
          <div className="col-md-auto">
            <button
              className="tt-button tt-button-primary"
              onClick={this.openDesignationModal}
            >
              Add New Designation
            </button>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="form-group row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="parentCredentials">
              <strong>Do you have any credentials in our system? </strong>
            </label>
          </div>
          <div className="col-md-7">
            <input
              type="checkbox"
              name="parentCredentials"
              onChange={this.props.handleChange}
              checked={this.props.parentCredentials}
            />
          </div>
        </div>
        {this.props.parentCredentials && (
          <div className="form-group row">
            <div className="col-md-1"></div>
            <div className="col-md-3">
              <label htmlFor="parentCredentials">
                <strong>Enter your username in the system</strong>
              </label>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                name="username"
                id="username"
                className={
                  errors.username
                    ? "form-control indicate-error"
                    : "form-control"
                }
                value={this.props.username}
                onChange={this.props.handleChange}
                // onBlur={this.props.checkisUserAvailable}
                // onChange={e => { this.props.handleChange(e); this.props.checkisUserAvailable(e)}}
              />
            </div>
            <div className="col-md-1">
              {this.props.userFetching ? (
                <Spinner color="primary" />
              ) : this.props.userFound === true &&
                this.props.username &&
                this.props.username !== "" ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="primary-color username-icon"
                />
              ) : (
                this.props.userFound === false &&
                this.props.username !== "" && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    id="incorrect"
                    className="danger-color username-icon"
                  />
                )
              )}
            </div>
          </div>
        )}
        <ModalWindow
          modal={this.state.designationModal}
          toggleModal={this.openDesignationModal}
          modalHeader="Add New Designation"
          modalBody={
            <AddNewDesignation
              handleDesignationChange={this.handleDesignationChange}
              name={this.state.name}
              details={this.state.details}
            ></AddNewDesignation>
          }
          modalFooter={this.designationFooter()}
        ></ModalWindow>
      </>
    );
  }
}

export default StaffType;

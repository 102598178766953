import React, { Component } from "react";
import "../../../assets/scss/template.scss";
import "../../../assets/scss/noticeDisplay.scss";
import avatar from "../../../assets/images/user-image.png";
import ReactHtmlParser from "react-html-parser";
import $ from "jquery";
import moment from "moment";
import { axiosPost } from "../../../utils/AxiosApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import {
  URL,
  postType,
  reactServer,
  userRoleCode,
  draggable,
  userRole,
} from "../../../utils/Constants";
import DiscussionReadMoreTemplate from "./DiscussionReadMoreTemplate";
import ModalWindow from "../../UI/ModalWindow";
import ArticleAcademicRepoEditModal from "./ArticleAcademicRepoEditModal";
import { checkRolePermissions } from "../../../utils/Constants";
import { Link } from "react-router-dom";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  displayNepaliDate,
  handleError,
} from "../../../utils/Utils";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import swal from "sweetalert";
import { Spinner } from "react-mdl";
import HistoryTemplates from "./HistoryTemplates/HistoryTemplates";
import {
  getDiscussionPosts,
  getFamilyNotices,
  getNotices,
} from "../../../ducks/PostsDucks";
import { connect } from "react-redux";

class DiscussionTemplate extends Component {
  state = {
    modal: false,
    commentValue: "",
    comments: [],
    newCommentValue: "",
    keywordModal: false,
    linkCopied: false,
    post: null,
    dropdownOpen: false,
    editArticleAcademicModal: false,
    selectedPost: "",
    subjectList: [],
    showSpinner: false,
    articleHistoryModal: false,
    postHistory: [],
    history: false,
    pageType: "",
    groupId: "",
    familyId: "",
    isPostUpdateModerated: false,
    insertedInsitePostData: [],
  };

  toggleMenu = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleArticleEditModal = (e, data, action) => {
    if (action === "open") {
      this.getSubjectList();
      this.setState({ selectedPost: data });
    } else {
      this.setState({
        editArticleAcademicModal: !this.state.editArticleAcademicModal,
      });
    }
  };

  toggleHistoryEditModal = (e, data, action) => {
    if (action === "open") {
      this.getHistoryList(data);
      this.setState({ selectedPost: data });
    } else {
      this.setState({
        articleHistoryModal: !this.state.articleHistoryModal,
        history: false,
      });
    }
  };

  getHistoryList = (param) => {
    let data = {
      updatedId: param.id,
    };
    this.setState({ history: true, showSpinner: true }, function () {
      axiosPost(
        URL.getPostHistory,
        data,
        (response) => {
          this.setState({ showSpinner: false });
          if (response.status === 200) {
            this.setState({ postHistory: response.data.data }, function () {
              this.setState({
                articleHistoryModal: !this.state.articleHistoryModal,
              });
            });
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
        }
      );
    });
  };

  getSubjectList = () => {
    axiosPost(URL.subjectList, {}, (response) => {
      if (response.status === 200 || response.status === 204) {
        this.setState({ subjectList: response.data.data }, function () {
          this.setState({
            editArticleAcademicModal: !this.state.editArticleAcademicModal,
          });
        });
      }
    });
  };

  componentDidMount() {
    this.getPageType();
    if (
      this.props.match !== undefined &&
      this.props.match.path === "/post/discussion/:referenceId"
    ) {
      let endPoint;
      let data;
      if (this.props.match.params.referenceId.slice(-4) === "true") {
        endPoint = URL.getAcademicRepoByRefId;
        data = {
          postType: postType.articleCode,
          referenceId: this.props.match.params.referenceId.slice(0, -4),
        };
      } else {
        endPoint = URL.getPostByReferenceId;
        data = {
          postType: postType.articleCode,
          referenceId: this.props.match.params.referenceId,
        };
      }
      axiosPost(endPoint, data, (response) => {
        if (response.status === 200) {
          let newPosts = [];
          if (this.props.match.params.referenceId.slice(-4) === "true") {
            const obj = response.data.data[0];
            newPosts.push({ ...obj });
          } else {
            newPosts.push({ ...response.data.data });
          }
          newPosts.forEach((post) => {
            if (
              post.postType === postType.articleCode ||
              post.postType === postType.mediaCode
            ) {
              post.contents.forEach((content) => {
                if (
                  content.contentTypeCode === "LNK" &&
                  content.content !== ""
                ) {
                  let videoId = "";
                  if (
                    content.content.includes("youtube") ||
                    content.content.includes("youtu.be")
                  ) {
                    var regExp =
                      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                    var match = content.content.match(regExp);
                    if (match && match[7].length == 11) {
                      videoId = match[7];
                    }

                    var url = "https://www.youtube.com/watch?v=" + videoId;
                    //to get youtube title
                    $.getJSON(
                      "https://noembed.com/embed",
                      { format: "json", url: url },
                      function (data) {
                        if (data !== undefined || data) {
                          content.youtubeTitle = data.title;
                        } else {
                          content.youtubeTitle = content.content;
                        }
                      }
                    );

                    //to get thumbnail
                    content.thumbnail = `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
                  } else if (
                    content.content.includes("dailymotion") ||
                    content.content.includes("dai.ly")
                  ) {
                    let url = content.content;
                    var m = url.match(
                      /^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/
                    );
                    if (m === null) {
                      var n = url.match(
                        /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
                      );
                      if (n !== null) {
                        if (n[4] !== undefined) {
                          videoId = n[4];
                        }
                        videoId = n[2];
                      }
                    } else if (m !== null) {
                      if (m[4] !== undefined) {
                        videoId = m[4];
                      }
                      videoId = m[2];
                    }
                    $.ajax({
                      type: "GET",
                      url:
                        "https://api.dailymotion.com/video/" +
                        videoId +
                        "?fields=title,duration,user",
                      dataType: "jsonp",
                      cache: true,
                      success: function (data) {
                        content.youtubeTitle = data.title;
                      },
                    });
                    content.thumbnail = `https://www.dailymotion.com/thumbnail/video/${videoId}`;
                  } else if (content.content.includes("vimeo")) {
                    var regExp =
                      /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
                    var parseUrl = content.content.match(regExp);
                    videoId = parseUrl[5];
                    $.ajax({
                      type: "GET",
                      url: "http://vimeo.com/api/v2/video/" + videoId + ".json",
                      jsonp: "callback",
                      dataType: "jsonp",
                      success: function (data) {
                        content.youtubeTitle = data[0].title;
                        content.thumbnail = data[0].thumbnail_medium;
                      },
                    });
                  } else if (
                    content.content.includes("slideshare") &&
                    content.youtubeTitle !== undefined
                  ) {
                    $.getJSON(
                      "https://noembed.com/embed",
                      { format: "json", url: content.content },
                      function (data) {
                        if (data !== undefined || data) {
                          content.youtubeTitle = data.title;
                          content.thumbnail = data.thumbnail;
                        }
                      }
                    );
                  } else {
                    $.getJSON(
                      "https://noembed.com/embed",
                      { format: "json", url: content.content },
                      function (data) {
                        if (data !== undefined && data && !data.error) {
                          content.youtubeTitle = data.title;
                          if (data.thumbnail_url) {
                            content.thumbnail = data.thumbnail_url;
                          }
                        } else {
                          content.youtubeTitle = content.content;
                        }
                      }
                    );

                    // $.ajax({
                    //   type: "GET",
                    //   crossOrigin: true,
                    //   url: content.content,
                    //   jsonp: "callback",
                    //   dataType: "jsonp",
                    //   success: function(data) {
                    //   }
                    // });
                  }
                }
              });
              //initialPosts.push(el);
            }
          });

          // get Insite Post data by checking the contents property of post
          this.setState({ post: newPosts[0] }, function () {
            if (this.state.post && this.state.post.contents.length > 0) {
              this.state.post.contents.forEach((el) => {
                if (el.content.includes(reactServer)) {
                  this.getInsitePostDataURL(el);
                }
              });
            }
          });
        }
      });
    }

    if (this.props.post && this.props.post.contents.length > 0) {
      this.props.post.contents.forEach((el) => {
        if (el.content.includes(reactServer)) {
          this.getInsitePostData(el);
        }
      });
    }
  }

  getInsitePostDataURL = (el) => {
    if (el.origin === "insitePost") {
      if (el.content !== null || el.content !== "") {
        let data = {
          postType: el.content
            ? el.content.split("://")[1].split("/")[2] === "media"
              ? postType.mediaCode
              : postType.articleCode
            : null,
          referenceId: el.content
            ? el.content.split("://")[1].split("/")[3].trim()
            : null,
        };
        axiosPost(URL.getPostByReferenceId, data, (response) => {
          if (response.status === 200) {
            if (response.data.data) {
              this.setState({
                insertedInsitePostData: response.data.data,
              });
            }
          }
        });
      }
    }
  };

  getInsitePostData = (el) => {
    if (el.origin === "insitePost") {
      if (el.content !== null || el.content !== "") {
        let data = {
          postType: el.content
            ? el.content.split("://")[1].split("/")[2] === "media"
              ? postType.mediaCode
              : postType.articleCode
            : null,
          referenceId: el.content
            ? el.content.split("://")[1].split("/")[3].trim()
            : null,
        };
        axiosPost(URL.getPostByReferenceId, data, (response) => {
          if (response.status === 200) {
            if (response.data.data) {
              this.setState({
                insertedInsitePostData: response.data.data,
              });
            }
          }
        });
      }
    }
  };

  getPageType = () => {
    var origin = window.location.href;
    var response = origin.split("/").pop();
    if (origin.includes("groupPage")) {
      this.setState({ pageType: "groupPage" }, () => {
        const param = {
          name: response.replaceAll("_", " "),
        };
        axiosPost(URL.getGroupByName, param, (resp) => {
          if (resp.status === 200) {
            if (resp.data.data !== null) {
              this.setState({ groupId: resp.data.data.id });
            }
          }
        });
      });
    } else if (origin.includes("familyPage")) {
      this.setState({ pageType: "familyPage", familyId: response });
    } else if (origin.includes("profile")) {
      this.setState({ pageType: "personalProfile" }, () => {
        const data = {
          username: response,
        };
        axiosPost(URL.getLoggedInUser, data, (res) => {
          if (res.status === 200) {
            if (res.data.data !== null) {
              this.setState({ peopleId: res.data.data.peopleId });
            }
          }
        });
      });
    } else {
      this.setState({
        pageType: "personalProfile",
        peopleId: localStorage.getItem("profilePeopleId"),
      });
    }
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.type === "checked" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  toggleEditable = (idx) => {
    let comments = [...this.state.comments];
    comments.forEach((el) => {
      el.editable = false;
    });
    comments[idx].editable = true;
    this.setState({
      comments: comments,
      newCommentValue: comments[idx].commentValue,
    });
  };

  getComments = () => {
    let url = "",
      param = {};

    if (this.state.post && this.state.post.id !== undefined) {
      if (this.props.isAcademicRepo) {
        url = URL.getAcademicRepoComment;
        param = {
          academicRepositoryId: this.state.post.id,
        };
      } else {
        url = URL.getCommentByKey;
        param = { postId: this.state.post.id };
      }
      axiosPost(url, param, (response) => {
        if (response.status === 200) {
          let comments = response.data.data;
          comments.forEach((el) => {
            el.editable = false;
          });
          this.setState({ comments });
        }
      });
    } else {
      if (this.props.post && this.props.post.id !== undefined) {
        if (this.props.isAcademicRepo) {
          url = URL.getAcademicRepoComment;
          param = {
            academicRepositoryId: this.props.post.id,
          };
        } else {
          url = URL.getCommentByKey;
          param = { postId: this.props.post.id };
        }
        axiosPost(url, param, (response) => {
          if (response.status === 200) {
            let comments = response.data.data;
            comments.forEach((el) => {
              el.editable = false;
            });
            this.setState({ comments });
          }
        });
      }
    }
  };

  deleteComment = (id) => {
    let url = "";
    if (this.props.isAcademicRepo) {
      url = URL.deleteAcademicRepoComment;
    } else {
      url = URL.deleteComment;
    }
    axiosPost(
      url,
      {
        id: id,
        deleteStatus: true,
      },
      (response) => {
        if (response.status === 200) {
          this.getComments();
        }
      }
    );
  };

  updateComment = (commentValue, comment) => {
    let url = "",
      param = {};

    if (this.props.isAcademicRepo) {
      url = URL.updateAcademicRepoComment;
      param = {
        academicRepositoryId: this.state.post
          ? this.state.post.id
          : this.props.post.id,
        commentValue: this.state.newCommentValue,
        contentTypeCode: comment.contentTypeCode,
        id: comment.id,
        peopleId: comment.peopleId,
        moderatedStatus: comment.moderatedStatus,
        moderatorPeopleId: comment.moderatorPeopleId,
      };
    } else {
      url = URL.updateComment;
      param = {
        id: comment.id,
        contentTypeCode: comment.contentTypeCode,
        postId: comment.postId,
        peopleId: comment.peopleId,
        commentValue: this.state.newCommentValue,
      };
    }
    axiosPost(url, param, (response) => {
      if (response.status === 200) {
        this.getComments();
      }
    });
  };

  //For Notice Reload
  callNoticeFunctions = () => {
    if (this.props.groupPage) {
      this.groupNoticeConfig();
    } else if (this.props.familyPage) {
      this.familyNoticeConfig();
    } else {
      this.personalNoticeConfig();
    }
  };

  personalNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      if (!this.props.username) {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      } else {
        let data = {
          username: this.props.username,
        };
        axiosPost(URL.getLoggedInUser, data, (response) => {
          if (response.status === 200) {
            param.peopleId = response.data.data.peopleId;
            this.props.getNotices(param);
          }
        });
      }
    } else {
      if (userRole === userRoleCode.roleStudentContact) {
        axiosPost(
          URL.selectFamilyMembers,
          { familyId: localStorage.getItem("familyId") },
          (response) => {
            if (response.status === 200) {
              let profilePeopleId = this.props.profilePeopleId;
              let count = 0;
              if (response.data.data.length > 0) {
                response.data.data.forEach((el) => {
                  if (el.peopleId === parseInt(profilePeopleId)) {
                    count++;
                  }
                });
              }
              if (count > 0) {
                param.peopleId = profilePeopleId;
                this.props.getNotices(param);
              }
            }
          }
        );
      } else {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      }
    }
  };
  groupNoticeConfig = () => {
    let initialData = this.props.groupName;
    let groupName = initialData.replace(/_/g, " ");
    let param = {
      groupName: groupName,
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    this.props.getGroupNotices(param);
  };

  familyNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    let familyId = this.props.familyId;
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      param.familyId = familyId;
      this.props.getFamilyNotices(param);
    } else {
      this.getFamilyDetails(param, familyId);
    }
  };

  getFamilyDetails = (param, familyId) => {
    let data = {
      familyId: familyId,
    };
    let count = 0;
    axiosPost(URL.selectFamilyMembers, data, (response) => {
      if (response.status === 200) {
        let peopleId = localStorage.getItem("peopleId");
        let datas = response.data.data;
        if (datas.length > 0) {
          datas.forEach((el) => {
            if (el.peopleId === parseInt(peopleId)) {
              count++;
            }
          });
        }
        if (count === 0) {
          param.peopleId = localStorage.getItem("peopleId");
          this.props.getNotices(param);
        } else {
          param.familyId = familyId;
          this.props.getFamilyNotices(param);
        }
      }
    });
  };

  postComment = (id, isAcademicRepo) => {
    let url = "";
    let param = {};
    if (isAcademicRepo) {
      url = URL.insertAcademicRepoComment;
      param = {
        academicRepoId: id,
        commentValue: this.state.commentValue,
        contentTypeCode: "TXT",
        moduleCode: "post-cmnt-section",
      };
    } else {
      url = URL.postComment;
      param = {
        postId: id,
        commentValue: this.state.commentValue,
        contentTypeCode: "TXT",
        moduleCode: "post-cmnt-section",
      };
    }
    axiosPost(url, param, (response) => {
      if (response.status === 200) {
        if (response.data.data.moderatedStatus === "PENDING") {
          swal(MESSAGEHEADER.success, "Post Comment sent for Moderation", "");
        } else {
          swal({
            title: MESSAGEHEADER.success,
            text: "Successfully Commented",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
        }
        this.callNoticeFunctions();
        // if (this.props.groupPost) {
        //   this.props.getGroupPosts(this.props.groupData.id, "initCall");
        // } else if (this.props.familyPost) {
        //   this.props.getFamilyPosts(this.props.familyId, "initCall");
        // } else {
        //   this.props.getPersonProfilePosts(
        //     this.props.profilePeopleId,
        //     "initCall"
        //   );
        // }
        // this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
        this.getComments();
        this.setState({ commentValue: "" });
      }
    });
    draggable();
    // }
  };

  toggleModal = (e, action) => {
    if (action === "open") {
      this.getComments();
    }
    this.setState({ modal: !this.state.modal });
  };

  noticeModalFooter = () => {
    return (
      <>
        {this.state.post ? (
          <div className="row">
            <div className="col-md-2 text-right mt-1">
              <strong>Add a comment</strong>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="commentValue"
                value={this.state.commentValue}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="col-md-2" style={{ marginTop: "3px" }}>
              <button
                disabled={this.state.post ? false : true}
                className="tt-button tt-button-primary"
                onClick={(e) =>
                  this.postComment(
                    this.state.post.id,
                    this.props.isAcademicRepo
                  )
                }
              >
                Comment
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-2 text-right mt-1">
              <strong>Add a comment</strong>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="commentValue"
                value={this.state.commentValue}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="col-md-2" style={{ marginTop: "3px" }}>
              <button
                disabled={this.props.post ? false : true}
                className="tt-button tt-button-primary"
                onClick={(e) =>
                  this.postComment(
                    this.props.post.id,
                    this.props.isAcademicRepo
                  )
                }
              >
                Comment
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  displayKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.props.post
          ? this.props.post.postKeywords
            ? this.props.post.postKeywords.map((keyword, idx) => {
                return <li key={idx}>{keyword.keyword}</li>;
              })
            : null
          : null}
      </ol>
    );
  };

  renderLinkCopyText = () => {
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 1500);
  };

  renderContent = (content) => {
    let newData = content;
    var imgRegex = new RegExp("<img[^>]*?>", "g");
    let matchedData = newData.match(imgRegex);
    if (matchedData !== null) {
      for (let i = 0; i < matchedData.length; i++) {
        newData = newData.replace(matchedData[i], "");
      }
    }
    return newData;
  };

  renderForReferenceId = () => (
    <div className="container-fluid mt-3">
      <div className="row">
        <div
          className={
            "col-md-auto col-sm-auto mobilePPimage text-center " +
            (this.props.fullPage ? "fullpagePP" : "")
          }
        >
          {this.state.post?.peoples?.photo ? (
            <img
              src={URL.imageSelectURL + this.state.post?.peoples?.photo}
              onError={(e) => handleError(e)}
              className={
                "img-postDisplay " +
                (this.props.fullPage ? "imageNewDimension" : "image-dimension")
              }
            ></img>
          ) : (
            <img
              src={avatar}
              className={
                "img-fluid " +
                (this.props.fullPage ? "imageNewDimension" : "image-dimension")
              }
            ></img>
          )}
        </div>
        <div className="col">
          <div className="row">
            <p className="mb-0" style={{ lineHeight: "20px" }}>
              <strong>
                {this.state.post ? (
                  this.state.post.peoples ? (
                    this.state.post.peoples.userRole ===
                    userRoleCode.roleStudentContact ? (
                      <Link
                        to={{
                          pathname: `/familyPage/${this.state.post.peoples.familyId}`,
                          familyPageOpen: true,
                          userId: this.state.post.peoples.userId,
                        }}
                      >
                        {this.state.post.peoples.name}
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: `/profile/${this.state.post.peoples.username}`,
                          username: this.state.post.peoples.username,
                        }}
                      >
                        {this.state.post.peoples.name}
                      </Link>
                    )
                  ) : (
                    "Anonymous"
                  )
                ) : (
                  "Anonymous"
                )}
                {this.state.post ? (
                  this.state.post.groupName ? (
                    <>
                      &nbsp;&#62;{" "}
                      <Link
                        to={{
                          pathname: `/groupPage/${this.state.post.groupName.replace(
                            / /g,
                            "_"
                          )}`,
                        }}
                      >
                        {this.state.post.groupName}
                      </Link>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}{" "}
              </strong>
            </p>
          </div>
          <div className="row">
            {this.state.post ? (
              <>
                <span className="text-muted date-displayPosts">
                  <i>
                    posted {moment(this.state.post.createdAt).calendar()}{" "}
                    {displayNepaliDate(this.state.post.createdAt)}(
                    {moment(this.state.post.createdAt).fromNow()})
                  </i>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
          {this.state.post ? (
            this.state.post.heading !== "" ? (
              <div className="row">
                <p className="tt-postArticleTitle">
                  {this.state.post
                    ? this.state.post.heading
                    : "Here is the heading"}
                </p>
              </div>
            ) : null
          ) : null}
          <div className="tt-mobilePostKeywords">
            {this.state.post ? (
              this.state.post.postKeywords ? (
                <div className="row mb-3 mt-2">
                  <div className="tt-postTargetList">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={this.displayKeywordModal}
                    >
                      Keywords Assigned
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          <div className="tt-desktopPostkeywords">
            {this.state.post ? (
              this.state.post.postKeywords ? (
                <div className="row mb-3 mt-2">
                  <div className="tt-postTargetList">
                    {this.state.post.postKeywords.map((keyWord, idx) => {
                      return idx <= 2 ? (
                        keyWord.keyword !== "" ? (
                          <div key={idx}>{keyWord.keyword}</div>
                        ) : null
                      ) : idx === this.state.post.postKeywords.length - 1 ? (
                        <div
                          key={idx}
                          style={{ cursor: "pointer" }}
                          onClick={this.displayKeywordModal}
                        >
                          + {this.state.post.postKeywords.length - 3} more
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          {this.state.post ? (
            this.state.post.textContent &&
            this.state.post.textContent !== "" ? (
              <div className="row">
                <div className="text pl-2">
                  {ReactHtmlParser(
                    this.state.post
                      ? this.renderContent(this.state.post.textContent)
                      : "No text content"
                  )}
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-md-5"></div>
        <div className="col-md-7 text-right" style={{ paddingLeft: "11px" }}>
          <button
            className="tt-button tt-button-primary my-2"
            onClick={(e) => this.toggleModal(e, "open")}
          >
            Read More
          </button>
        </div>
      </div>
    </div>
  );

  copyToClipboard = (data) => {
    let dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("value", data);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  //Updating post
  updatePost = (e, data) => {
    if (data.isPostSection) {
      let uyrl = "";
      if (this.state.pageType === "personalProfile") {
        data.profilePeopleId = this.state.peopleId;
        uyrl = URL.updatePersonalProfilePost;
        this.setState({ isPostUpdateModerated: true });
      } else if (this.state.pageType === "familyPage") {
        data.familyId = this.state.familyId;
        uyrl = URL.updateFamilyProfilePost;
        this.setState({ isPostUpdateModerated: true });
      } else if (this.state.pageType === "groupPage") {
        data.groupId = this.state.groupId;
        uyrl = URL.updateGroupProfilePost;
        this.setState({ isPostUpdateModerated: true });
      }

      this.setState({ showSpinner: true }, function () {
        axiosPost(
          uyrl,
          data,
          (response) => {
            this.setState({ showSpinner: false });
            if (response.status === 200) {
              if (response.data.data.post.moderatedStatus === "PENDING") {
                swal(
                  MESSAGEHEADER.success,
                  "Post Update Send for Moderation",
                  ""
                );
              } else {
                swal(MESSAGEHEADER.success, "Succesfully updated", "");
              }
              // swal({
              //   title: MESSAGEHEADER.success,
              //   text: this.state.isPostUpdateModerated
              //     ? "Post Update Send for Moderation"
              //     : "Updated successfully",
              //   allowOutsideClick: false,
              //   closeOnClickOutside: false,
              // });
              // draggable();
              this.props.handleCurrentPost();
              this.toggleArticleEditModal();
            }
          },
          (err) => {
            this.setState({ showSpinner: false });
            displayErrorAlert(err);
          }
        );
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.updatePost,
          data,
          (response) => {
            this.setState({ showSpinner: false });
            if (response.status === 200) {
              swal({
                title: MESSAGEHEADER.success,
                text: "Updated successfully",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.props.handleCurrentPost();
              this.toggleArticleEditModal();
            }
          },
          (err) => {
            this.setState({ showSpinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  //Deleting Post
  confirmDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deletePost);
  };

  deletePost = (param) => {
    let data = {
      id: param.id,
    };
    axiosPost(URL.deletePost, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.handleCurrentPost();
      }
    });
  };

  render() {
    return (
      <>
        {this.state.post ? (
          this.renderForReferenceId()
        ) : (
          <div
            className="container-fluid mt-3"
            key={this.props.idx ? this.props.idx : ""}
          >
            <div className="row">
              {this.props.post?.isAcademicRepo ? (
                this.props.post?.isValidated ? (
                  <div className="col text-right academiRepoMarker text-muted">
                    - accepted for Academic Repository
                  </div>
                ) : (
                  <div className="col text-right academiRepoMarker text-muted">
                    - submitted for Academic Repository
                  </div>
                )
              ) : null}
            </div>
            <div className="row">
              <div
                className={
                  "col-md-auto col-sm-auto mobilePPimage text-center " +
                  (this.props.fullPage ? "fullpagePP" : "")
                }
              >
                {this.props.post ? (
                  this.props.post.peoples ? (
                    this.props.post.peoples.photo ? (
                      <img
                        src={URL.imageSelectURL + this.props.post.peoples.photo}
                        onError={(e) => handleError(e)}
                        className={
                          "img-postDisplay " +
                          (this.props.fullPage
                            ? "imageNewDimension"
                            : "image-dimension")
                        }
                      ></img>
                    ) : (
                      <img
                        src={avatar}
                        className={
                          "img-fluid " +
                          (this.props.fullPage
                            ? "imageNewDimension"
                            : "image-dimension")
                        }
                      ></img>
                    )
                  ) : (
                    <img
                      src={avatar}
                      className={
                        "img-fluid " +
                        (this.props.fullPage
                          ? "imageNewDimension"
                          : "image-dimension")
                      }
                    ></img>
                  )
                ) : (
                  <img
                    src={avatar}
                    className={
                      "img-fluid " +
                      (this.props.fullPage
                        ? "imageNewDimension"
                        : "image-dimension")
                    }
                  ></img>
                )}
              </div>
              <div className="col">
                <div className="row" style={{ position: "relative" }}>
                  <div className="col pl-0">
                    <p className="mb-0" style={{ lineHeight: "20px" }}>
                      <strong>
                        {this.props.post ? (
                          this.props.post.peoples ? (
                            this.props.post.peoples.userRole ===
                            userRoleCode.roleStudentContact ? (
                              <Link
                                to={{
                                  pathname: `/familyPage/${this.props.post.peoples.familyId}`,
                                  familyPageOpen: true,
                                  userId: this.props.post.peoples.userId,
                                }}
                              >
                                {this.props.post.peoples.name}
                              </Link>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/profile/${this.props.post.peoples.username}`,
                                  username: this.props.post.peoples.username,
                                }}
                              >
                                {this.props.post.peoples.name}
                              </Link>
                            )
                          ) : (
                            "Anonymous"
                          )
                        ) : (
                          "Anonymous"
                        )}
                        {this.props.post ? (
                          this.props.post.groupName ? (
                            <>
                              &nbsp;&#62;{" "}
                              <Link
                                to={{
                                  pathname: `/groupPage/${this.props.post.groupName.replace(
                                    / /g,
                                    "_"
                                  )}`,
                                }}
                              >
                                {this.props.post.groupName}
                              </Link>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}{" "}
                      </strong>
                    </p>
                  </div>
                  {this.props.isAcademicRepo || this.props.insitePost ? null : (
                    <div
                      className="col-md-auto tt-postUpdateDeleteIcon"
                      style={{ position: "absolute", right: 0 }}
                    >
                      <Dropdown
                        className="dropdown-trigger"
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleMenu}
                      >
                        <DropdownToggle>
                          <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
                        </DropdownToggle>
                        <DropdownMenu className="tt-post-dropdown">
                          {this.props.post?.peoples?.id ===
                            parseInt(localStorage.getItem("peopleId")) ||
                          userRole === userRoleCode.roleAdmin ? (
                            <>
                              <DropdownItem
                                className="tt-post-dropbtn"
                                onClick={(e) =>
                                  this.toggleArticleEditModal(
                                    e,
                                    this.props.post,
                                    "open"
                                  )
                                }
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                className="tt-post-dropbtn"
                                onClick={() =>
                                  this.confirmDelete(this.props.post.id)
                                }
                              >
                                Delete
                              </DropdownItem>
                            </>
                          ) : null}
                          <DropdownItem
                            className="tt-post-dropbtn"
                            onClick={(e) =>
                              this.toggleHistoryEditModal(
                                e,
                                this.props.post,
                                "open"
                              )
                            }
                          >
                            History
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                {/* <div className="row">
                    {this.props.post ? (
                      <>
                        <span className="text-muted date-displayPosts">
                          <i>
                            posted {moment(this.props.post.createdAt).calendar()}{" "}
                            {displayNepaliDate(this.props.post.createdAt)} (
                            {moment(this.props.post.createdAt).fromNow()})
                          </i>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div> */}
                <div className="row">
                  {this.props.insertedInsitePostData ? (
                    <>
                      <span className="text-muted date-displayPosts">
                        <i>
                          posted{" "}
                          {moment(
                            this.props.insertedInsitePostData.createdAt
                          ).calendar()}{" "}
                          {displayNepaliDate(
                            this.props.insertedInsitePostData.createdAt
                          )}
                          (
                          {moment(
                            this.props.insertedInsitePostData.createdAt
                          ).fromNow()}
                          )
                        </i>
                      </span>
                    </>
                  ) : this.props.post ? (
                    <>
                      <span className="text-muted date-displayPosts">
                        <i>
                          posted {moment(this.props.post.createdAt).calendar()}{" "}
                          {displayNepaliDate(this.props.post.createdAt)}(
                          {moment(this.props.post.createdAt).fromNow()})
                        </i>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {/* {this.props.post ? (
                    this.props.post.heading !== "" ? (
                      <div className="row">
                        <p className="tt-postArticleTitle">
                          {this.props.post
                            ? this.props.post.heading
                            : "Here is the heading"}
                        </p>
                      </div>
                    ) : null
                  ) : null} */}
                {this.props.insitePost ? (
                  this.props.insertedInsitePostData.heading !== "" ? (
                    <div className="row">
                      <p className="tt-postArticleTitle">
                        {this.props.post
                          ? this.props.insertedInsitePostData.heading
                          : "Here is the heading "}
                      </p>
                    </div>
                  ) : null
                ) : this.props.post ? (
                  this.props.post.heading !== "" ? (
                    <div className="row">
                      <p className="tt-postArticleTitle">
                        {this.props.post
                          ? this.props.post.heading
                          : "Here is the heading "}
                      </p>
                    </div>
                  ) : null
                ) : null}

                {/* <div className="tt-desktopPostKeywords">
                    {this.props.post ? (
                      this.props.post.postKeywords ? (
                        <div className="row mb-3 mt-2">
                          <div className="tt-postTargetList">
                            {this.props.post.postKeywords.map((keyWord, idx) => {
                              return idx <= 2 ? (
                                keyWord.keyword !== "" ? (
                                  <div key={idx}>{keyWord.keyword}</div>
                                ) : null
                              ) : idx ===
                                this.props.post.postKeywords.length - 1 ? (
                                <div
                                  key={idx}
                                  style={{ cursor: "pointer" }}
                                  onClick={this.displayKeywordModal}
                                >
                                  + {this.props.post.postKeywords.length - 3} more
                                </div>
                              ) : null;
                            })}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </div> */}

                <div className="tt-desktopPostKeywords">
                  {this.props.insitePost ? (
                    this.props.insertedInsitePostData &&
                    this.props.insertedInsitePostData.postKeywords ? (
                      <div className="row mb-3 mt-2">
                        <div className="tt-postTargetList">
                          {this.props.insertedInsitePostData.postKeywords.map(
                            (keyWord, idx) => {
                              return idx <= 2 ? (
                                keyWord.keyword !== "" ? (
                                  <div key={idx}>{keyWord.keyword}</div>
                                ) : null
                              ) : idx ===
                                this.props.insertedInsitePostData.postKeywords
                                  .length -
                                  1 ? (
                                <div
                                  key={idx}
                                  style={{ cursor: "pointer" }}
                                  onClick={this.displayKeywordModal}
                                >
                                  +{" "}
                                  {this.props.insertedInsitePostData
                                    .postKeywords.length - 3}{" "}
                                  more
                                </div>
                              ) : null;
                            }
                          )}
                        </div>
                      </div>
                    ) : null
                  ) : this.props.post && this.props.post.postKeywords ? (
                    <div className="row mb-3 mt-2">
                      <div className="tt-postTargetList">
                        {this.props.post.postKeywords.map((keyWord, idx) => {
                          return idx <= 2 ? (
                            keyWord.keyword !== "" ? (
                              <div key={idx}>{keyWord.keyword}</div>
                            ) : null
                          ) : idx ===
                            this.props.post.postKeywords.length - 1 ? (
                            <div
                              key={idx}
                              style={{ cursor: "pointer" }}
                              onClick={this.displayKeywordModal}
                            >
                              + {this.props.post.postKeywords.length - 3} more
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="tt-mobilePostKeywords">
                  {this.props.post ? (
                    this.props.post.postKeywords ? (
                      <div className="row mb-3 mt-2">
                        <div className="tt-postTargetList">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={this.displayKeywordModal}
                          >
                            Keywords Assigned
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>

                {/* {this.props.post ? (
                    this.props.post.textContent &&
                    this.props.post.textContent !== "" ? (
                      <div className="row">
                        <div className="text pl-2">
                          {ReactHtmlParser(
                            this.props.post
                              ? this.renderContent(this.props.post.textContent)
                              : "No text content"
                          )}
                        </div>
                      </div>
                    ) : null
                  ) : null} */}

                {this.props.insitePost ? (
                  this.props.insertedInsitePostData.textContent &&
                  this.props.insertedInsitePostData.textContent !== "" ? (
                    <div className="row">
                      <div className="text pl-2">
                        {ReactHtmlParser(
                          this.props.post
                            ? this.renderContent(
                                this.props.insertedInsitePostData.textContent
                              )
                            : "No text content"
                        )}
                      </div>
                    </div>
                  ) : null
                ) : this.props.post ? (
                  this.props.post.textContent &&
                  this.props.post.textContent !== "" ? (
                    <div className="row">
                      <div className="text pl-2">
                        {ReactHtmlParser(
                          this.props.post
                            ? this.renderContent(this.props.post.textContent)
                            : "No text content"
                        )}
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2"></div>
              <div
                className="col-md-10 text-right"
                style={{ paddingLeft: "11px" }}
              >
                {this.props.isAcademicRepo ? (
                  this.props.post.isVerified ? null : (
                    <>
                      {!checkRolePermissions(
                        "accept-academic-repository"
                      ) ? null : (
                        <button
                          className="tt-button tt-button-primary my-2 mr-2"
                          onClick={(e) =>
                            this.props.acceptAcademicRepo(e, this.props.post)
                          }
                        >
                          Accept Post
                        </button>
                      )}
                      {!checkRolePermissions(
                        "edit-academic-repository"
                      ) ? null : (
                        <button
                          className="tt-button tt-button-primary my-2 mr-2"
                          onClick={(e) =>
                            this.props.toggleArticleEditModal(
                              e,
                              this.props.post,
                              "open"
                            )
                          }
                        >
                          Edit
                        </button>
                      )}
                    </>
                  )
                ) : null}
                <button
                  className="tt-button tt-button-primary my-2 mr-2"
                  onClick={(e) => {
                    // navigator.clipboard
                    //   .writeText(`http://${reactServer}/post/article/${this.props.post.referenceId}
                    //   `);
                    this.copyToClipboard(`${reactServer}/post/discussion/${
                      this.props.isAcademicRepo
                        ? this.props.post.referenceId + "true"
                        : this.props.post.referenceId
                    }
                      `);
                    this.renderLinkCopyText();
                  }}
                >
                  {this.state.linkCopied ? "Copied" : "Copy Link"}
                </button>

                <button
                  className="tt-button tt-button-primary my-2"
                  onClick={(e) => this.toggleModal(e, "open")}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.post ? (
          <>
            <ModalWindow
              fullWidth={true}
              modal={this.state.modal}
              toggleModal={this.toggleModal}
              modalClass="tt-noticeModal"
              modalHeader={
                <>
                  <FontAwesomeIcon icon={faNewspaper}></FontAwesomeIcon>{" "}
                  {this.state.post ? this.state.post.heading : "Discussions"}
                </>
              }
              modalBody={
                <DiscussionReadMoreTemplate
                  insertedInsitePostData={this.state.insertedInsitePostData}
                  post={this.state.post}
                  comments={this.state.comments}
                  deleteComment={this.deleteComment}
                  toggleEditable={this.toggleEditable}
                  handleChange={this.handleChange}
                  newCommentValue={this.state.newCommentValue}
                  updateComment={this.updateComment}
                  isAcademicRepo={this.props.isAcademicRepo}
                />
              }
              modalFooter={this.noticeModalFooter()}
            ></ModalWindow>
            <ModalWindow
              modal={this.state.keywordModal}
              toggleModal={this.displayKeywordModal}
              modalHeader="Keywords Assigned"
              modalBody={this.keywordModalBody()}
            ></ModalWindow>
          </>
        ) : (
          <>
            <ModalWindow
              fullWidth={true}
              modal={this.state.modal}
              toggleModal={this.toggleModal}
              modalClass="tt-noticeModal"
              modalHeader={
                <>
                  <FontAwesomeIcon icon={faNewspaper}></FontAwesomeIcon>{" "}
                  {this.props.post ? this.props.post.heading : "Discussion"}
                </>
              }
              modalBody={
                <DiscussionReadMoreTemplate
                  insertedInsitePostData={this.state.insertedInsitePostData}
                  post={this.props.post}
                  comments={this.state.comments}
                  deleteComment={this.deleteComment}
                  toggleEditable={this.toggleEditable}
                  handleChange={this.handleChange}
                  newCommentValue={this.state.newCommentValue}
                  updateComment={this.updateComment}
                  isAcademicRepo={this.props.isAcademicRepo}
                />
              }
              modalFooter={this.noticeModalFooter()}
            ></ModalWindow>
            <ModalWindow
              modal={this.state.keywordModal}
              toggleModal={this.displayKeywordModal}
              modalHeader="Keywords Assigned"
              modalBody={this.keywordModalBody()}
            ></ModalWindow>
            <ModalWindow
              modal={this.props.editArticleAcademicModal}
              toggleModal={this.props.toggleArticleEditModal}
              modalHeader="Edit Academic Repository Post"
              fullWidth={true}
              modalBody={
                <ArticleAcademicRepoEditModal
                  post={this.props.selectedPost}
                  fullPage={this.props.fullPage}
                  subjectList={this.props.subjectList}
                  toggleRepoEditModal={this.props.toggleArticleEditModal}
                  updateAcademicRepo={this.props.updateAcademicRepo}
                ></ArticleAcademicRepoEditModal>
              }
            ></ModalWindow>

            <ModalWindow
              modal={this.state.editArticleAcademicModal}
              toggleModal={this.toggleArticleEditModal}
              modalHeader="Edit Post"
              fullWidth={true}
              modalBody={
                <ArticleAcademicRepoEditModal
                  post={this.state.selectedPost}
                  fullPage={true}
                  subjectList={this.state.subjectList}
                  toggleRepoEditModal={this.toggleArticleEditModal}
                  updateAcademicRepo={this.updatePost}
                  postSection={true}
                ></ArticleAcademicRepoEditModal>
              }
            ></ModalWindow>
            {/* <ModalWindow
                modal={this.state.articleHistoryModal}
                toggleModal={this.toggleHistoryEditModal}
                fullWidth={this.state.postHistory.length > 0 ? true : null}
                modalHeader={this.state.selectedPost.heading + " - History"}
                modalBody={
                  <HistoryTemplates
                    postHistory={this.state.postHistory}
                  ></HistoryTemplates>
                }
              ></ModalWindow> */}
          </>
        )}
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              {this.state.history
                ? "Please Wait. Fetching History..."
                : "Please Wait. Updating Article ..."}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapActionToProps = {
  getDiscussionPosts,
  getFamilyNotices,
  getNotices,
};

export default connect(mapStateToProps, mapActionToProps)(DiscussionTemplate);

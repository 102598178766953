import React, { Component } from "react";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import ArticleTemplate from "./Templates/ArticleTemplate";
import MediaTemplate from "./Templates/MediaTemplate";
import {
  postType,
  mainUserId,
  URL,
  schoolProfileGroup,
} from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import ModalWindow from "../UI/ModalWindow";
import Repository from "./RepositorySection/Repository";
import { Spinner } from "reactstrap";
import { checkWidgetAdminOrNot } from "./../../utils/Constants";
import GranularPermissionModal from "./../Permissions/GranularPermissionModal";
import InfiniteScroll from "react-infinite-scroll-component";
import DiscussionTemplate from "./Templates/DiscussionTemplate";

class PostSection extends Component {
  state = {
    school: null,
    tabId: "posts",
  };

  componentDidMount() {
    this.getSchool();
  }

  getSchool = () => {
    axiosPost(URL.selectByKeySchool, {}, (response) => {
      if (response.status === 200) {
        this.setState({ school: response.data.data });
      }
    });
  };

  render() {
    return (
      <div className="tt-post-section">
        <div className="tt-postTabs tt-inner-content">
          <Tabs
            onChange={(tabId) => {
              this.setState({ tabId });
            }}
            defaultTab={this.state.tabId}
          >
            <TabList>
              {this.props.familyPost ? (
                <Tab tabFor="posts">
                  Family Posts{" "}
                  <span className="mobileResultDisplay">
                    ({this.props.totalFamilyPosts})
                  </span>
                </Tab>
              ) : this.props.groupPost ? (
                <Tab tabFor="posts">
                  {this.props.groupData.name === schoolProfileGroup
                    ? "School"
                    : "Group"}{" "}
                  Posts{" "}
                  <span className="mobileResultDisplay">
                    ({this.props.totalGroupPosts})
                  </span>
                </Tab>
              ) : this.props.memberName === "ownProfile" ||
                parseInt(this.props.userId) === parseInt(mainUserId) ? (
                <Tab tabFor="posts">
                  My Posts{" "}
                  <span className="mobileResultDisplay">
                    ({this.props.totalPosts})
                  </span>
                </Tab>
              ) : (
                <Tab tabFor="posts">
                  {this.props.memberName.split(" ")[0]}
                  {"'s"} {" Posts"}{" "}
                  <span className="mobileResultDisplay">
                    ({this.props.posts.length})
                  </span>
                </Tab>
              )}
              {this.props.groupPost ? null : (
                <Tab tabFor="recentPosts">
                  Recent Posts{" "}
                  <span className="mobileResultDisplay">
                    ({this.props.personProfileRecentPosts.length})
                  </span>
                </Tab>
              )}
              <Tab tabFor="academicRepository">
                <span className="mobileResultDisplay">
                  Academic Repository{" "}
                </span>
                <span className="mobileCreatePost">Academic Repo</span>
                <span className="mobileResultDisplay">
                  ({this.props.totalAcademicRepoPosts})
                </span>
              </Tab>
              {(this.props.familyPost || this.props.groupPost) && (
                <Tab tabFor="discussion">
                  <span className="mobileResultDisplay">Discussion</span>
                  <span className="mobileResultDisplay">
                    (
                    {this.props.familyPost
                      ? this.props.totalDiscussionPosts
                      : this.props.groupPost
                      ? this.props.totalGroupDiscussions
                      : 0}
                    )
                  </span>
                </Tab>
              )}
            </TabList>

            <TabPanel
              tabId="posts"
              render={() =>
                this.state.tabId === "posts" ? (
                  <>
                    <InfiniteScroll
                      dataLength={
                        this.props.groupPost
                          ? this.props.currentGroupPosts
                          : this.props.familyPost
                          ? this.props.currentFamilyPosts
                          : this.props.currentRecordsCount
                      }
                      next={
                        this.props.groupPost
                          ? this.props.fetchGroupPostsForScroll
                          : this.props.familyPost
                          ? this.props.fetchFamilyPostsForScroll
                          : this.props.fetchPostsForScroll
                      }
                      hasMore={
                        this.props.groupPost
                          ? this.props.totalGroupPosts >
                            this.props.currentGroupPosts
                          : this.props.familyPost
                          ? this.props.totalFamilyPosts >
                            this.props.currentFamilyPosts
                          : this.props.totalPosts >
                            this.props.currentRecordsCount
                      }
                      loader={
                        <div className="text-center mb-3 mt-1">
                          <Spinner color="primary"></Spinner>
                        </div>
                      }
                      endMessage={
                        this.props.groupPost ? (
                          this.props.totalGroupPosts !== 0 &&
                          this.props.totalGroupPosts ===
                            this.props.currentGroupPosts ? (
                            <div className="text-center mt-3">
                              <p>
                                You have seen all the group posts of current
                                academic year.
                              </p>
                            </div>
                          ) : null
                        ) : this.props.familyPost ? (
                          this.props.totalFamilyPosts !== 0 &&
                          this.props.totalFamilyPosts ===
                            this.props.currentFamilyPosts ? (
                            <div className="text-center mt-3">
                              <p>
                                You have seen all the family posts of current
                                academic year.
                              </p>
                            </div>
                          ) : null
                        ) : this.props.totalPosts !== 0 &&
                          this.props.totalPosts ===
                            this.props.currentRecordsCount ? (
                          <div className="text-center mt-3">
                            <p>
                              You have seen all the posts of current academic
                              year.
                            </p>
                          </div>
                        ) : null
                      }
                    >
                      {this.props.posts ? (
                        this.props.posts.length > 0 ? (
                          this.props.posts.map((post, pidx) => {
                            return (
                              <div className="tt-post-template" key={pidx}>
                                {post?.posts[0]?.postType ===
                                postType.articleCode ? (
                                  <ArticleTemplate
                                    post={post.posts[0]}
                                    key={pidx}
                                    handleCurrentPost={
                                      this.props.handleCurrentPost
                                    }
                                  />
                                ) : post?.posts[0]?.postType ===
                                  postType.mediaCode ? (
                                  <MediaTemplate
                                    post={post.posts[0]}
                                    key={pidx}
                                    handleCurrentPost={
                                      this.props.handleCurrentPost
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-center mt-3 mb-3">
                            {this.props.postSpinner ? (
                              <>
                                <Spinner color="primary"></Spinner>
                                <br></br>
                                Loading Data ...
                              </>
                            ) : (
                              <p>No posts to display</p>
                            )}
                          </div>
                        )
                      ) : (
                        <p>No post to display</p>
                      )}
                    </InfiniteScroll>
                  </>
                ) : null
              }
            />
            <TabPanel
              tabId="recentPosts"
              render={() =>
                this.state.tabId === "recentPosts" ? (
                  <>
                    {this.props.personProfileRecentPosts ? (
                      this.props.personProfileRecentPosts.length > 0 ? (
                        this.props.personProfileRecentPosts.map(
                          (recentPosts, rIdx) => {
                            return (
                              <div className="tt-post-template" key={rIdx}>
                                {recentPosts?.postType ===
                                postType?.articleCode ? (
                                  <ArticleTemplate
                                    post={recentPosts}
                                    handleCurrentPost={
                                      this.props.handleCurrentPost
                                    }
                                  ></ArticleTemplate>
                                ) : recentPosts?.postType ===
                                  postType.mediaCode ? (
                                  <MediaTemplate
                                    post={recentPosts}
                                    handleCurrentPost={
                                      this.props.handleCurrentPost
                                    }
                                  ></MediaTemplate>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div className="text-center mt-3 mb-3">
                          {this.props.recentPostSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br></br>
                              Loading Data ...
                            </>
                          ) : (
                            <p>No posts to display</p>
                          )}
                        </div>
                      )
                    ) : (
                      <p>No posts to display</p>
                    )}
                  </>
                ) : null
              }
            />

            <TabPanel
              tabId="academicRepository"
              render={() =>
                this.state.tabId === "academicRepository" ? (
                  <>
                    <div
                      className="tt-group-header text-right mb-0 acaRepoMobileTaskbar"
                      style={{ borderRadius: 0 }}
                    >
                      {checkWidgetAdminOrNot("Academic Repository") === true ? (
                        <button
                          className="tt-button tt-button-primary float-right"
                          onClick={() => {
                            this.setState({
                              permissionModal: !this.state.permissionModal,
                            });
                          }}
                        >
                          Permissions
                        </button>
                      ) : null}
                      <button
                        className="tt-button tt-button-primary"
                        onClick={this.props.toggleRepository}
                      >
                        View Repository
                      </button>
                    </div>
                    <InfiniteScroll
                      dataLength={this.props.currentAcademicRepoPosts}
                      next={this.props.fetchRepoPostsForScroll}
                      hasMore={
                        this.props.totalAcademicRepoPosts >
                        this.props.currentAcademicRepoPosts
                      }
                    >
                      {this.props.personAcademicRepoPosts ? (
                        this.props.personAcademicRepoPosts.length > 0 ? (
                          this.props.personAcademicRepoPosts.map(
                            (academicPosts, aIdx) => {
                              return (
                                <div key={aIdx} className="tt-post-template">
                                  {academicPosts?.postType ===
                                  postType.articleCode ? (
                                    <ArticleTemplate
                                      post={academicPosts}
                                      isAcademicRepo={true}
                                      idx={aIdx}
                                      handleCurrentPost={
                                        this.props.handleCurrentPost
                                      }
                                    ></ArticleTemplate>
                                  ) : academicPosts?.postType ===
                                    postType.mediaCode ? (
                                    <MediaTemplate
                                      idx={aIdx}
                                      post={academicPosts}
                                      isAcademicRepo={true}
                                      handleCurrentPost={
                                        this.props.handleCurrentPost
                                      }
                                    ></MediaTemplate>
                                  ) : null}
                                </div>
                              );
                            }
                          )
                        ) : (
                          <div className="text-center mt-3 mb-3">
                            {this.props.academicPostSpinner ? (
                              <>
                                <Spinner color="primary"></Spinner>
                                {/* <br></br> */}
                                {/* Loading Data ... */}
                              </>
                            ) : (
                              <p>No posts to display</p>
                            )}
                          </div>
                        )
                      ) : (
                        <p>No posts to display</p>
                      )}
                    </InfiniteScroll>
                  </>
                ) : null
              }
            />
            {(this.props.familyPost || this.props.groupPost) && (
              <TabPanel
                tabId="discussion"
                render={() =>
                  this.state.tabId === "discussion" ? (
                    <>
                      <InfiniteScroll
                        dataLength={this.props.currentDiscussionPosts}
                        next={this.props.fetchDiscussionPostsForScroll}
                        hasMore={
                          this.props.totalDiscussionPosts >
                          this.props.currentDiscussionPosts
                        }
                        loader={
                          <div className="text-center mb-3 mt-1">
                            <Spinner color="primary"></Spinner>
                          </div>
                        }
                        endMessage={
                          this.props.familyPost || this.props.groupPost ? (
                            this.props.totalDiscussionPosts !== 0 &&
                            this.props.totalDiscussionPosts ===
                              this.props.currentDiscussionPosts ? (
                              <div className="text-center mt-3">
                                <p>You have seen all the discussion posts.</p>
                              </div>
                            ) : null
                          ) : null
                        }
                      >
                        {this.props.discussionPosts ? (
                          this.props.discussionPosts.length > 0 ? (
                            this.props.discussionPosts.map((post, pidx) => {
                              return (
                                <div className="tt-post-template" key={pidx}>
                                  {post?.posts[0]?.postType ===
                                  postType.discussion ? (
                                    <DiscussionTemplate
                                      post={post.posts[0]}
                                      key={pidx}
                                      handleCurrentPost={
                                        this.props.handleCurrentPost
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <div className="text-center mt-3 mb-3">
                              {this.props.postSpinner ? (
                                <>
                                  <Spinner color="primary"></Spinner>
                                  <br></br>
                                  Loading Data ...
                                </>
                              ) : (
                                <p>No posts to display</p>
                              )}
                            </div>
                          )
                        ) : (
                          <p>No post to display</p>
                        )}
                      </InfiniteScroll>
                    </>
                  ) : null
                }
              />
            )}
          </Tabs>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Repository"
              moduleName="Academic Repository"
              header="Add Academic Repository"
              activityName="accept-academic-repository"
            />
          }
        ></ModalWindow>
        <ModalWindow
          fullWidth={true}
          modal={this.props.repoModal}
          toggleModal={this.props.toggleRepository}
          modalHeader={
            (this.state.school ? this.state.school.name : "") +
            " Academic Repository"
          }
          modalBody={<Repository></Repository>}
        ></ModalWindow>
      </div>
    );
  }
}

export default PostSection;

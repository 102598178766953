import { axiosPost } from "../utils/AxiosApi";
import { displayErrorAlert } from "../utils/Utils";
import { URL } from "../utils/Constants";

// Actions
const yearLevelsLOAD = "school-groupings/year-level/LOAD";
const educationLevelsLOAD = "subject-setup/education-level/LOAD";
const classroomsLOAD = "subject-setup/classroom/LOAD";
const academicYearsLOAD = "academic-year/LOAD";
const assessmentModuleEducationLevelListLOAD =
  "assessment-module-education-level-list/LOAD";
const unAssignedYearLevelsLOAD = "school-groupings/assign-year-level/LOAD";
const groupsLOAD = "groups/LOAD";
const classroomLocationLOAD =
  "Academic-administration/school Groupings/classroom/LOAD";
const calendarLOAD = "calendar/LOAD";
const FamilyGroupListLOAD = "family/groupList";
const detailYearLevelLOAD = "school-groupings/detail-year-level/LOAD";
const toggleSpinnerLOAD = "groups/toggleSpinner/LOAD";
const toggleSectionSpinnerLOAD = "section/spinner/LOAD";
const groupTotalRecordsCountLOAD = "section/group/TotalRecords";
const moduleSpinnerLOAD = "assessment/module/LOAD";
const aaGroupListLOAD = "academicAdmin/group/grouplist/LOAD";
const aaGroupTotalLOAD = "academicAdmin/group/total/LOAD";
const aaGroupSpinnerLOAD = "academicAdmin/group/spinner/LOAD";

//reducer
export default function reducer(
  state = {
    aaGroupLists: [],
    aaGroupTotal: 0,
    aaGroupSpinner: false,
    yearLevels: [],
    educationLevels: [],
    classrooms: [],
    assessmentModuleEducationLevelList: [],
    academicYears: [],
    unAssignedYearLevels: [],
    groupLists: [],
    classroomLocations: [],
    calendarEvents: [],
    detailYearLevels: [],
    familyGroupLists: [],
    groupSpinner: false,
    sectionSpinner: false,
    groupTotalRecordsCount: 0,
    moduleSpinner: false,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case yearLevelsLOAD:
      newState.yearLevels = action.yearLevels;
      return newState;
    case educationLevelsLOAD:
      newState.educationLevels = action.educationLevels;
      return newState;
    case classroomsLOAD:
      newState.classrooms = action.classrooms;
      return newState;
    case academicYearsLOAD:
      newState.academicYears = action.academicYears;
      return newState;
    case assessmentModuleEducationLevelListLOAD:
      newState.assessmentModuleEducationLevelList =
        action.assessmentModuleEducationLevelList;
      return newState;
    case unAssignedYearLevelsLOAD:
      newState.unAssignedYearLevels = action.unAssignedYearLevels;
      return newState;
    case groupsLOAD:
      newState.groupLists = action.groupLists;
      return newState;
    case classroomLocationLOAD:
      newState.classroomLocations = action.classroomLocations;
      return newState;
    case calendarLOAD:
      newState.calendarEvents = action.calendarEvents;
      return newState;
    case detailYearLevelLOAD:
      newState.detailYearLevels = action.detailYearLevels;
      return newState;
    case FamilyGroupListLOAD:
      newState.familyGroupLists = action.familyGroupLists;
      return newState;
    case toggleSpinnerLOAD:
      newState.groupSpinner = action.groupSpinner;
      return newState;
    case toggleSectionSpinnerLOAD:
      newState.sectionSpinner = action.sectionSpinner;
      return newState;
    case groupTotalRecordsCountLOAD:
      newState.groupTotalRecordsCount = action.groupTotalRecordsCount;
      return newState;
    case moduleSpinnerLOAD:
      newState.moduleSpinner = action.moduleSpinner;
      return newState;
    case aaGroupListLOAD:
      newState.aaGroupLists = action.groupLists;
      return newState;
    case aaGroupTotalLOAD:
      newState.aaGroupTotal = action.groupTotal;
      return newState;
    case aaGroupSpinnerLOAD:
      newState.aaGroupSpinner = action.groupSpinner;
      return newState;
    default:
      return newState;
  }
}

// Action Creators
export function loadYearLevels(yearLevels) {
  return { type: yearLevelsLOAD, yearLevels };
}

export function loadModuleSpinner(moduleSpinner) {
  return { type: moduleSpinnerLOAD, moduleSpinner };
}

export function loadEducationLevels(educationLevels) {
  return { type: educationLevelsLOAD, educationLevels };
}
export function loadSectionLevels(classrooms) {
  return { type: classroomsLOAD, classrooms };
}
export function loadAcademicYears(academicYears) {
  return { type: academicYearsLOAD, academicYears };
}
export function loadAssessmentModuleEducationLevelList(
  assessmentModuleEducationLevelList
) {
  return {
    type: assessmentModuleEducationLevelListLOAD,
    assessmentModuleEducationLevelList,
  };
}
export function loadUnAssignedYearLevels(unAssignedYearLevels) {
  return {
    type: unAssignedYearLevelsLOAD,
    unAssignedYearLevels,
  };
}

export function loadGroups(groupLists) {
  return {
    type: groupsLOAD,
    groupLists,
  };
}

export function loadGroupTotalRecords(groupTotalRecordsCount) {
  return {
    type: groupTotalRecordsCountLOAD,
    groupTotalRecordsCount,
  };
}

export function loadClassroomLocations(classroomLocations) {
  return {
    type: classroomLocationLOAD,
    classroomLocations,
  };
}

export function loadCalendarEvents(calendarEvents) {
  return {
    type: calendarLOAD,
    calendarEvents,
  };
}

export function loadDetailYearLevels(detailYearLevels) {
  return {
    type: detailYearLevelLOAD,
    detailYearLevels,
  };
}

export function loadFamilyGroupList(familyGroupLists) {
  return {
    type: FamilyGroupListLOAD,
    familyGroupLists,
  };
}

export function loadTogglespinner(groupSpinner) {
  return {
    type: toggleSpinnerLOAD,
    groupSpinner,
  };
}

export function loadSectionSpinner(sectionSpinner) {
  return {
    type: toggleSectionSpinnerLOAD,
    sectionSpinner,
  };
}

export function loadAaGroupList(groupLists) {
  return {
    type: aaGroupListLOAD,
    groupLists,
  };
}

export function loadAaGroupSpinner(groupSpinner) {
  return {
    type: aaGroupSpinnerLOAD,
    groupSpinner,
  };
}

export function loadAaGroupTotal(groupTotal) {
  return {
    type: aaGroupTotalLOAD,
    groupTotal,
  };
}

//api call that dispatches action with function
export function getAssessmentModuleEducationLevelList() {
  return (dispatch) => {
    dispatch(loadModuleSpinner(true));
    axiosPost(
      URL.getEducationLevelAssessmentModule,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadAssessmentModuleEducationLevelList(response.data.data));
          dispatch(loadModuleSpinner(false));
        }
      },
      (error) => {
        displayErrorAlert(error);
        dispatch(loadModuleSpinner(false));
      }
    );
  };
}

export function getYearLevels() {
  return (dispatch) => {
    axiosPost(
      URL.getYearLevel,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadYearLevels(response.data.data));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

export function getEducationLevels() {
  return (dispatch) => {
    axiosPost(
      URL.getEducationLevel,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadEducationLevels(response.data.data));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

export function getEducationLevelsAcademicAdmin() {
  return (dispatch) => {
    axiosPost(
      URL.getEducationLevelSelectAll,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadEducationLevels(response.data.data));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

export function getClassroomLevels() {
  return (dispatch) => {
    axiosPost(
      URL.getClassroom,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadSectionLevels(response.data.data));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

export function getAcademicYears() {
  return (dispatch) => {
    axiosPost(
      URL.getAcademicYears,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadAcademicYears(response.data.data));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

export function getUnAssignedYearLevels() {
  return (dispatch) => {
    axiosPost(
      URL.getUnassignedYearLevels,
      {},
      (response) => {
        if (response.status === 200) {
          let yearLevels = response.data.data;
          yearLevels.forEach((yearLevel) => {
            yearLevel.checked = false;
          });
          dispatch(loadUnAssignedYearLevels(yearLevels));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

export function getGroups(peopleId, offset, rowCountLimit) {
  return (dispatch) => {
    let data = {
      peopleId: peopleId,
      offset: offset,
      rowCountLimit: rowCountLimit,
    };
    dispatch(loadGroups([]));
    dispatch(loadTogglespinner(true));
    axiosPost(
      URL.getGroups,
      data,
      (response) => {
        if (response.status === 200) {
          let totalRecordsCount = response.data.totalRecordsCount;
          dispatch(loadGroups(response.data.data));
          dispatch(loadGroupTotalRecords(totalRecordsCount));
          dispatch(loadTogglespinner(false));
        } else {
          dispatch(loadTogglespinner(false));
        }
      },
      (error) => {
        displayErrorAlert(error);
        dispatch(loadTogglespinner(false));
      }
    );
  };
}

export function getGroupsAA(peopleId, offset, rowCountLimit) {
  return (dispatch) => {
    let data = {
      peopleId: peopleId,
      offset: offset,
      rowCountLimit: rowCountLimit,
    };
    dispatch(loadAaGroupList([]));
    dispatch(loadAaGroupSpinner(true));
    axiosPost(
      URL.getGroups,
      data,
      (response) => {
        if (response.status === 200) {
          let totalRecordsCount = response.data.totalRecordsCount;
          dispatch(loadAaGroupList(response.data.data));
          dispatch(loadAaGroupTotal(totalRecordsCount));
          dispatch(loadAaGroupSpinner(false));
        } else {
          dispatch(loadAaGroupSpinner(false));
        }
      },
      (error) => {
        displayErrorAlert(error);
        dispatch(loadAaGroupSpinner(false));
      }
    );
  };
}

export function getFamilyGroup(familyId) {
  return (dispatch) => {
    let data = {
      familyId: familyId,
    };
    axiosPost(URL.getFamilyGroup, data, (response) => {
      if (response.status === 200) {
        dispatch(loadFamilyGroupList(response.data.data));
      }
    });
  };
}

export function getClassroomLocations() {
  return (dispatch) => {
    axiosPost(
      URL.roomSelect,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadClassroomLocations(response.data.data));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

export function getCalendarEvents(assessmentModuleandEducationLevel) {
  let modifiedEvents = [];
  modifiedEvents.push({
    daysOfWeek: [6], //Sundays and saturdays
    rendering: "background",
    color: `#668ef5`,
    overLap: false,
    allDay: true,
  });
  return (dispatch) => {
    assessmentModuleandEducationLevel &&
      assessmentModuleandEducationLevel.forEach((module) => {
        axiosPost(
          URL.getEventsByEducationLevel,
          {
            educationLevelId: module ? module.id : null,
          },
          (response) => {
            if (response.status === 200) {
              if (response.data.data) {
                const events = response.data.data;
                events.forEach((event) => {
                  event.end = new Date(event.end).toISOString();
                });
                modifiedEvents = [...modifiedEvents, ...events];
              }

              dispatch(loadCalendarEvents(modifiedEvents));
            }
          }
        );
      });
  };
}

export function getDetailYearLevels() {
  return (dispatch) => {
    dispatch(loadSectionSpinner(true));
    axiosPost(
      URL.getDetailYearLevel,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadDetailYearLevels(response.data.data));
          dispatch(loadSectionSpinner(false));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}

import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import logo3 from "../../assets/images/logo/logo3.png";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import history from "../../utils/History";
import CryptoJS from "crypto-js";
import { displayErrorAlert } from "../../utils/Utils";

export default function OTPpage(props) {
  const [timer, setTimer] = React.useState(120);

  const [char1, setchar1] = useState("");
  const [char2, setchar2] = useState("");
  const [char3, setchar3] = useState("");
  const [char4, setchar4] = useState("");
  const [char5, setchar5] = useState("");
  const [char6, setchar6] = useState("");
  const [spinner, setspinner] = useState(false);

  const [errCount, setErrCount] = useState(0);

  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, [timer]);

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  const resendOTP = () => {
    setspinner(true);
    axiosGet(URL.resendOTP, (response) => {
      if (response.data.status === 200) {
        setTimer(120);
        swal("Success", "New OTP has been generated and sent to your email.");
        setspinner(false);
      }
    });
  };

  const handleSubmit = () => {
    let data = {
      twoFaCode: char1 + char2 + char3 + char4 + char5 + char6,
    };

    if (
      char1 === "" ||
      char2 === "" ||
      char3 === "" ||
      char4 === "" ||
      char5 === "" ||
      char5 === ""
    ) {
      swal("Error", "Enter verification code");
    } else {
      setspinner(true);
      axiosPost(
        URL.verifyOTP,
        data,
        (response) => {
          if (response.status === 200) {
            // localStorage.setItem("token", props.responseLoginData.access_token);
            localStorage.setItem(
              "schoolSetup",
              props.responseLoginData.schoolSetup
            );
            localStorage.setItem(
              "educationCommunityGroupName",
              props.responseLoginData.educationCommunityGroupName
            );
            localStorage.setItem(
              "academicYears",
              props.responseLoginData.academicYears
            );
            localStorage.setItem(
              "refreshToken",
              props.responseLoginData.refresh_token
            );

            localStorage.setItem(
              "isFirstLogin",
              props.responseLoginData.isFirstLogin
            );

            localStorage.setItem(
              "widgets",
              CryptoJS.AES.encrypt(
                JSON.stringify(
                  props.responseLoginData.academicPermissionDto.widgets
                ),
                "saurav_imagine_sys"
              )
            );
            props.responseLoginData.substituteTeacherRolePermissions =
              props.responseLoginData.substituteTeacherRolePermissions || null;
            localStorage.setItem(
              "substituteTeacherRolePermissions",
              CryptoJS.AES.encrypt(
                JSON.stringify(
                  props.responseLoginData.substituteTeacherRolePermissions
                ),
                "saurav_imagine_sys"
              )
            );

            localStorage.setItem(
              "academicYearId",
              props.responseLoginData.academicPermissionDto.academicYearId
            );
            localStorage.setItem(
              "subRoles",
              CryptoJS.AES.encrypt(
                JSON.stringify(props.responseLoginData.subRoles),
                "saurav_imagine_sys"
              )
            );
            if (props.responseLoginData.associatedUsers) {
              localStorage.setItem(
                "associatedUsers",
                CryptoJS.AES.encrypt(
                  JSON.stringify(props.responseLoginData.associatedUsers),
                  "saurav_imagine_sys"
                )
              );
            }

            localStorage.setItem(
              "role",
              CryptoJS.AES.encrypt(
                props.responseLoginData.userRoleCode,
                "saurav_imagine_sys"
              ).toString()
            );
            localStorage.setItem("peopleId", props.responseLoginData.peopleId);
            localStorage.setItem(
              "profilePeopleId",
              props.responseLoginData.peopleId
            );
            localStorage.setItem("familyId", props.responseLoginData.familyId);
            localStorage.setItem("mainUserId", props.responseLoginData.userId);
            //   localStorage.setItem(
            //     "isCoordinator",
            //     props.responseLoginData.isCoordinator
            //   );
            //   localStorage.setItem(
            //     "isHomeRoomTeacher",
            //     props.responseLoginData.isHomeRoomTeacher
            //   );
            if (props.rememberMe) {
              localStorage.setItem(
                "username",
                CryptoJS.AES.encrypt(
                  props.username,
                  "saurav_imagine_sys"
                ).toString()
              );
              localStorage.setItem(
                "password",
                CryptoJS.AES.encrypt(
                  props.password,
                  "saurav_imagine_sys"
                ).toString()
              );
            }
            //this.getRecruitmentIdPromise(props.responseLoginData.peopleId);
            if (props.responseLoginData.userRoleCode === "RL_STC") {
              history.push(
                `${process.env.PUBLIC_URL}/familyPage/${props.responseLoginData.familyId}`
              );
            } else {
              props.responseLoginData.schoolSetup
                ? props.responseLoginData.academicYears
                  ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                  : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
            }

            setspinner(false);
          }
        },
        (err) => {
          displayErrorAlert(err);
          setspinner(false);
          setErrCount(errCount + 1);
          if (errCount > 4) {
            localStorage.clear();
            history.push(`${process.env.PUBLIC_URL}/`);
          }
        }
      );
    }
  };

  return (
    <div>
      {spinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please wait... Processing
          </div>
        </div>
      ) : null}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="otp-page">
          <div className="text-center">
            <div className="otp-img text-center">
              <img src={logo3} className="img-fluid" />
            </div>

            <h2>Please enter the OTP to Verify your Account</h2>
            <p>A One-Time Password has been sent to your account</p>
            <div className="otp-code">
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={(e) => setchar1(e.target.value)}
                name="char1"
                value={char1}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={(e) => setchar2(e.target.value)}
                name="char2"
                value={char2}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={(e) => setchar3(e.target.value)}
                name="char3"
                value={char3}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={(e) => setchar4(e.target.value)}
                name="char4"
                value={char4}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={(e) => setchar5(e.target.value)}
                name="char5"
                value={char5}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={(e) => setchar6(e.target.value)}
                name="char6"
                value={char6}
              />
            </div>

            {timer > 0 ? (
              <p>Your OTP will expire in {timer} seconds</p>
            ) : (
              <p>Your OTP has been expired. Please issue new OTP</p>
            )}

            <button
              className="tt-button tt-button-primary p-2"
              onClick={() => handleSubmit()}
            >
              Validate
            </button>
            <div className="text-right mt-2">
              <button
                disabled={timer === 0 ? false : true}
                style={{ background: "transparent", border: "none" }}
                onClick={() => resendOTP()}
              >
                Resend OTP
              </button>
            </div>
            <div className="text-right mt-2">
              <a href={process.env.PUBLIC_URL}>Go back to Login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

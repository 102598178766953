import React, { Component } from "react";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Notice from "./Notice";
import Article from "./Article";
import Discussion from "./Discussion";
import Media from "./Media";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  postType,
  reactServer,
  draggable,
  userRole,
  userRoleCode,
  rowCountLimit,
  modules,
  profilePeopleId,
} from "../../../utils/Constants";
import swal from "sweetalert";
import { connect } from "react-redux";
import { getPosts } from "../../../ducks/PostsDucks";
import ModalWindow from "../../UI/ModalWindow";
import AddTheme from "./AddTheme";
import PostQuestion from "./PostQuestion";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
} from "../../../utils/Utils";
import $ from "jquery";
import {
  getGroupPosts,
  getGroupDiscussions,
  getPersonProfilePosts,
  getFamilyPosts,
  getPersonPostSectionAcademicRepoPosts,
  getGroupNotices,
  getFamilyNotices,
  getNotices,
  getDiscussionPosts,
} from "../../../ducks/PostsDucks";
import { Spinner } from "reactstrap";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import isURL from "validator/lib/isURL";

class Posts extends Component {
  state = {
    heading: "General Notice",
    files: [],
    textContent: "",
    media: "",
    selectedMedias: [],
    links: "",
    selectedLinks: [],
    selectedOrigin: "",
    url: "",
    selectedURL: "",
    fileSize: 0,
    selectedGroups: [],
    selectedArticleGroups: [],
    selectedMediaGroups: [],
    articleKeywords: "",
    mediaKeywords: "",
    themes: null,
    addThemeModal: false,
    addQuestionModal: false,
    theme: "",
    description: "",
    themeList: [],
    questions: [
      {
        question: "",
      },
    ],
    postTypeCode: "",
    questionsForMedia: [
      {
        question: "",
      },
    ],
    isAcademicRepo: false,
    tabName: "",
    selectedIndividual: [],
    isSubmitting: false,
    showSpinner: false,
    error: false,
    insertedInsitePost: [],
  };

  componentDidMount() {
    this.getThemeList();
  }

  getThemeList = () => {
    axiosPost(URL.selectTheme, {}, (response) => {
      if (response.status === 200) {
        this.setState({ themeList: response.data.data });
      } else {
        swal(MESSAGEHEADER.error, response.message);
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "heading") {
      if (value.length > 100) {
        swal({
          title: "Warning",
          text: "Heading cannot be more than 100 words",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else if (name === "links" || name === "media" || name === "url") {
      this.setState({ [name]: value }, function () {
        if (
          this.state.media.includes("/soundcloud.com/") ||
          this.state.links.includes("/soundcloud.com/") ||
          this.state.url.includes("/soundcloud.com/")
        ) {
          this.setState({ selectedOrigin: "soundcloud" });
        } else if (
          this.state.media.includes("vimeo") ||
          this.state.links.includes("vimeo") ||
          this.state.url.includes("vimeo")
        ) {
          this.setState({ selectedOrigin: "vimeo" });
        } else if (
          this.state.media.includes("dailymotion") ||
          this.state.links.includes("dailymotion") ||
          this.state.url.includes("dailymotion") ||
          this.state.media.includes("dai.ly") ||
          this.state.links.includes("dai.ly") ||
          this.state.url.includes("dai.ly")
        ) {
          this.setState({ selectedOrigin: "dailymotion" });
        } else if (
          this.state.media.includes("slideshare") ||
          this.state.links.includes("slideshare") ||
          this.state.url.includes("slideshare")
        ) {
          this.setState({ selectedOrigin: "slideshare" });
        } else if (
          this.state.media.includes(reactServer) ||
          this.state.links.includes(reactServer) ||
          this.state.url.includes(reactServer)
        ) {
          this.setState({ selectedOrigin: "insitePost" });
        } else if (
          this.state.media.includes("youtube") ||
          this.state.links.includes("youtube") ||
          this.state.url.includes("youtube") ||
          this.state.media.includes("youtu.be") ||
          this.state.links.includes("youtu.be") ||
          this.state.url.includes("youtu.be")
        ) {
          this.setState({ selectedOrigin: "youtube" });
        } else {
          this.setState({ selectedOrigin: "externalLink" });
        }
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  insertArticleKeyword = (e, value) => {
    e.preventDefault();
    let newValue;
    let keywords = this.state.articleKeywords;
    if (keywords.slice(-1) !== "," && keywords.slice(-1) !== "") {
      newValue = keywords + ", " + value;
    } else if (keywords.slice(-1) === "") {
      newValue = keywords + value;
    } else {
      newValue = keywords + " " + value;
    }
    this.setState({ articleKeywords: newValue });
  };

  insertMediaKeyword = (e, value) => {
    e.preventDefault();
    let newValue;
    let keywords = this.state.mediaKeywords;
    if (keywords.slice(-1) !== "," && keywords.slice(-1) !== "") {
      newValue = keywords + ", " + value;
    } else if (keywords.slice(-1) === "") {
      newValue = keywords + value;
    } else {
      newValue = keywords + " " + value;
    }
    this.setState({ mediaKeywords: newValue });
  };

  handleTextEditor = (value) => {
    this.setState({ textContent: value });
  };

  handleFileSelect = (e) => {
    e.preventDefault();
    let fileSize = this.state.fileSize;
    let data = e.target.files;
    let files = [...this.state.files];
    for (let i = 0; i < data.length; i++) {
      var extension = data[i].name.split(".").pop();
      if (!data[i].type.includes("image")) {
        fileSize = fileSize + data[i].size;

        if (Math.round(fileSize / 1024) > 2048) {
          swal(MESSAGEHEADER.error, "File Size exceeded its limit", "");
        } else if (extension !== "pdf") {
          swal(MESSAGEHEADER.error, "Unsupported File Type", "");
        } else {
          files.push(data[i]);
          this.setState({ files, fileSize: fileSize });
        }
      } else {
        if (
          extension === "jpeg" ||
          extension === "png" ||
          extension === "jpg"
        ) {
          files.push(data[i]);
          this.setState({ files });
        } else {
          swal(MESSAGEHEADER.error, "Unsupported File Type", "");
        }
      }
    }
  };

  handleMediaChanges = (e) => {
    e.preventDefault();
    let selectedMedias = [...this.state.selectedMedias];
    selectedMedias.push(this.state.media);
    this.setState({ selectedMedias, media: "" });
  };

  handleLinkChanges = (e) => {
    e.preventDefault();
    let selectedLinks = [...this.state.selectedLinks];
    if (this.state.selectedOrigin === "insitePost") {
      let data = {
        postType:
          this.state.links.split("://")[1].split("/")[2] === "media"
            ? postType.mediaCode
            : postType.articleCode,
        referenceId: this.state.links.split("://")[1].split("/")[3].trim(),
      };
      this.state.links.includes(reactServer) &&
        axiosPost(URL.getPostByReferenceId, data, (response) => {
          if (response.status === 200) {
            if (response.data.data) {
              this.setState({
                selectedLinks: [this.state.links],
                links: "",
                insertedInsitePost: response.data.data,
              });
            }
          }
        });
    } else if (isURL(this.state.links)) {
      selectedLinks.push(this.state.links);
      this.setState({ selectedLinks: selectedLinks, links: "" });
    } else {
      this.setState({ error: true });
      swal({
        title: "Error",
        text: "Invalid Link",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
  };

  handleURLChanges = (e) => {
    e.preventDefault();
    let url = this.state.url;
    let embeddedUrl = "";
    let videoId = "";
    if (
      this.state.url.includes("youtube") ||
      this.state.url.includes("youtu.be")
    ) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[7].length == 11) {
        videoId = match[7];
        embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
        this.setState({ selectedURL: embeddedUrl });
      } else {
        this.setState({ selectedURL: this.state.url });
      }
    }
    if (
      this.state.url.includes("dailymotion") ||
      this.state.url.includes("dai.ly")
    ) {
      videoId = this.getDailyMotionId(this.state.url);
      if (videoId) {
        embeddedUrl = `https://www.dailymotion.com/embed/video/${videoId}`;
        this.setState({ selectedURL: embeddedUrl });
      }
    }
    if (this.state.url.includes("vimeo")) {
      videoId = this.getVimeoId(this.state.url);
      if (videoId) {
        embeddedUrl = `https://player.vimeo.com/video/${videoId}`;
        this.setState({ selectedURL: embeddedUrl });
      }
    }
    if (this.state.url.includes("slideshare")) {
      this.getSlideShareId(this.state.url);
    }
    if (this.state.url.includes("soundcloud.com")) {
      let url = "https://w.soundcloud.com/player/?url=" + this.state.url;
      this.setState({ selectedURL: url });
    }
  };

  getSlideShareId = (url) => {
    let self = this;
    $.getJSON(
      "https://noembed.com/embed",
      { format: "json", url: url },
      function (data) {
        if (data !== undefined || data) {
          let htmlCode = data.html;
          let src = self.getSlideShareSrc(htmlCode);
          self.setState({ selectedURL: src });
        }
      }
    );
  };

  getSlideShareSrc = (code) => {
    let count = 0;
    let link = "";
    for (let i = 0; i < code.length; i++) {
      if (code.charAt(i) === '"') {
        count++;
      }
      if (count !== 0 && count < 2 && code.charAt(i + 1) !== '"') {
        link += code.charAt(i + 1);
      }
    }
    return link;
  };

  getDailyMotionId = (url) => {
    var m = url.match(
      /^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/
    );
    if (m === null) {
      var n = url.match(
        /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
      );
      if (n !== null) {
        if (n[4] !== undefined) {
          return n[4];
        }
        return n[2];
      }
    } else if (m !== null) {
      if (m[4] !== undefined) {
        return m[4];
      }
      return m[2];
    } else {
      return null;
    }
  };

  getVimeoId = (url) => {
    var regExp =
      /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    var parseUrl = url.match(regExp);
    return parseUrl[5];
  };

  handleChangeSelect = (selectedItem) => {
    this.setState({
      selectedGroups: selectedItem,
    });
  };

  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  //   handleMediaChangeSelect = selectedItem => {
  //     this.setState({
  //       selectedMediaGroups: selectedItem
  //     });
  //   };

  deleteFileContent = (e, idx, file) => {
    let files = [...this.state.files];
    let fileSize = this.state.fileSize;
    if (!this.state.files[idx].type.includes("image")) {
      fileSize = fileSize - this.state.files[idx].size;
    }

    // let newfiles = this.state.files.filter((nfile) => !isEqual(nfile, file));
    files.splice(idx, 1);
    this.setState({
      files: files,
      fileSize: fileSize,
      selectedOrigin: "",
      url: "",
    });
  };
  //   textContent;

  deleteMediaContent = (e, idx) => {
    let selectedMedias = [...this.state.selectedMedias];
    selectedMedias.splice(idx, 1);
    this.setState({ selectedMedias });
  };

  deleteLinkContent = (e, idx) => {
    let selectedLinks = [...this.state.selectedLinks];
    selectedLinks.splice(idx, 1);
    this.setState({ selectedLinks });
  };

  deleteQuestions = (e, idx) => {
    let questions = [...this.state.questions];
    questions.splice(idx, 1);
    if (questions.length === 0) {
      questions.push({ question: "" });
    }
    this.setState({ questions });
  };
  removeSelectedURL = () => {
    this.setState({ selectedURL: "", files: [], url: "" });
  };

  clearStates = (tabName) => {
    this.setState({
      heading:
        tabName === "article" || tabName === "media" || tabName === "discussion"
          ? ""
          : "General Notice",
      files: [],
      textContent: "",
      media: "",
      selectedMedias: [],
      links: "",
      selectedLinks: [],
      selectedOrigin: "",
      url: "",
      selectedURL: "",
      fileSize: 0,
      selectedGroups: [],
      selectedArticleGroups: [],
      selectedMediaGroups: [],
      articleKeywords: "",
      mediaKeywords: "",
      themes: null,
      postTypeCode: "",
      tabName: tabName,
    });
  };

  submitPost = (e, code, groupPost, familyPost) => {
    e.preventDefault();
    let url;

    this.setState({ isSubmitting: true, showSpinner: true }, () => {
      if (this.state.tabName === "media") {
        if (this.state.heading === "" || this.state.textContent === "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Heading and Content cannot be empty",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          this.setState({ showSpinner: false, isSubmitting: false });
          return false;
        }
        if (this.state.selectedURL !== "" || this.state.files.length > 0) {
          let postKeywords = [];
          let postThemes = [
            {
              themeId: this.state.themes,
            },
          ];
          let groups = [];
          if (code === postType.articleCode) {
            let keywords = this.state.articleKeywords.split(", ");
            keywords.forEach((el) => {
              postKeywords.push({
                keyword: el,
              });
            });
          }
          if (code === postType.mediaCode) {
            let keywords = this.state.mediaKeywords.split(", ");
            keywords.forEach((el) => {
              postKeywords.push({
                keyword: el,
              });
            });
          }
          this.state.selectedGroups.forEach((el) => {
            groups.push({ id: el.value });
          });
          var data = {
            post: {
              postType: code,
              heading: this.state.heading,
              textContent: this.state.textContent,
              isAcademicRepo: this.state.isAcademicRepo,
              contents:
                code === postType.mediaCode
                  ? this.state.url.length > 0
                    ? this.getMediaContentJSON(this.state.url)
                    : []
                  : this.getContentJSON(this.state.selectedLinks),
              groups: groups,
              postThemes:
                code !== postType.noticeCode
                  ? postThemes[0].themeId === null
                    ? []
                    : postThemes
                  : [],
              postKeywords: postKeywords[0].keyword === "" ? [] : postKeywords,
              postQuestions:
                code !== postType.noticeCode
                  ? code === postType.articleCode
                    ? this.state.questions[0].question === ""
                      ? []
                      : this.state.questions
                    : this.state.questionsForMedia[0].question === ""
                    ? []
                    : this.state.questionsForMedia
                  : [],
            },
            moduleCode: modules["Post Section"],
          };
          this.props.groupPost
            ? (data.groupId = this.props.groupData.id)
            : this.props.familyPost
            ? (data.familyId = this.props.familyId)
            : code === postType.noticeCode
            ? (data = data.post)
            : (data.profilePeopleId = this.props.profilePeopleId);

          url = groupPost
            ? URL.insertGroupPost
            : familyPost
            ? URL.insertFamilyProfile
            : code === postType.noticeCode
            ? URL.insertPost
            : URL.insertPersonProfilePost;
          if (this.state.files.length > 0) {
            axiosPost(
              url,
              data,
              (response) => {
                if (response.status === 200) {
                  if (response.data.data.post.moderatedStatus === "PENDING") {
                    swal(
                      MESSAGEHEADER.success,
                      "Succesfully sent for moderation",
                      ""
                    );
                    if (this.props.groupPost) {
                      this.props.getGroupPosts(
                        this.props.groupData.id,
                        "initCall"
                      );
                    } else if (familyPost) {
                      this.props.getFamilyPosts(
                        this.props.familyId,
                        "initCall"
                      );
                    } else {
                      this.props.getPersonProfilePosts(
                        this.props.profilePeopleId,
                        "initCall"
                      );
                    }
                    this.props.getPersonPostSectionAcademicRepoPosts(
                      true,
                      "initCall"
                    );
                  } else {
                    swal(MESSAGEHEADER.success, "Succesfully posted", "");
                  }
                  // if (this.props.groupPost) {
                  //   this.props.getGroupPosts(
                  //     this.props.groupData.id,
                  //     "initCall"
                  //   );
                  // } else if (familyPost) {
                  //   this.props.getFamilyPosts(this.props.familyId, "initCall");
                  // } else {
                  //   //this.props.getPosts();
                  //   this.props.getPersonProfilePosts(
                  //     this.props.profilePeopleId,
                  //     "initCall"
                  //   );
                  // }
                  // this.props.getPersonPostSectionAcademicRepoPosts(
                  //   true,
                  //   "initCall"
                  // );

                  !this.props.noticeType
                    ? this.props.toggleModal()
                    : this.props.toggleNoticeModal();
                  if (
                    code === postType.noticeCode ||
                    this.state.files.length > 0
                  ) {
                    let formData = new FormData();
                    for (const key of Object.keys(this.state.files)) {
                      formData.append("content", this.state.files[key]);
                    }
                    formData.append(
                      "jsonData",
                      JSON.stringify({
                        origin: "myPC",
                        postId:
                          this.props.groupPost || this.props.familyPost
                            ? response.data.data.postId
                            : code === postType.noticeCode
                            ? response.data.data.id
                            : response.data.data.postId,
                      })
                    );
                    axiosPost(
                      URL.insertPostContent,
                      formData,
                      (response) => {
                        if (response.status === 200) {
                          this.setState({ showSpinner: false });
                          // this.callNoticeFunctions();
                          if (this.props.groupPost) {
                            this.props.getGroupPosts(
                              this.props.groupData.id,
                              "initCall"
                            );
                          } else if (familyPost) {
                            this.props.getFamilyPosts(
                              this.props.familyId,
                              "initCall"
                            );
                          } else {
                            this.props.getPersonProfilePosts(
                              this.props.profilePeopleId,
                              "initCall"
                            );
                          }
                        }
                      },
                      (error) => {
                        this.setState({ showSpinner: false });
                      }
                    );
                  } else {
                    this.setState({ showSpinner: false });
                  }
                  // this.callNoticeFunctions();
                }
              },
              (error) => {
                displayErrorAlert(error);
                this.setState({ showSpinner: false });
              }
            );
          } else {
            axiosPost(
              url,
              data,
              (response) => {
                if (response.status === 200) {
                  if (response.data.data.post.moderatedStatus === "PENDING") {
                    swal(
                      MESSAGEHEADER.success,
                      "Succesfully sent for moderation",
                      ""
                    );
                  } else {
                    swal(MESSAGEHEADER.success, "Succesfully posted", "");
                  }
                  this.setState({ showSpinner: false });
                  // this.callNoticeFunctions();
                  !this.props.noticeType
                    ? this.props.toggleModal()
                    : this.props.toggleNoticeModal();
                  if (this.props.groupPost) {
                    this.props.getGroupPosts(
                      this.props.groupData.id,
                      "initCall"
                    );
                  } else if (familyPost) {
                    this.props.getFamilyPosts(this.props.familyId, "initCall");
                  } else {
                    this.props.getPersonProfilePosts(
                      this.props.profilePeopleId,
                      "initCall"
                    );
                  }
                  this.props.getPersonPostSectionAcademicRepoPosts(
                    true,
                    "initCall"
                  );
                }
              },
              (error) => {
                this.setState({ showSpinner: false });
              }
            );
          }
        } else if (
          this.state.selectedURL === "" &&
          this.state.files.length === 0
        ) {
          swal({
            title: MESSAGEHEADER.error,
            text: "Atleast attach an image or media link",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          this.setState({ showSpinner: false, isSubmitting: false });
          draggable();
        }
      } else if (this.state.tabName === "discussion") {
        if (this.state.heading === "" && this.state.textContent === "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Heading and Content cannot be empty",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          this.setState({ showSpinner: false, isSubmitting: false });
          return false;
        } else {
          var data = {
            post: {
              postType: code,
              heading: this.state.heading,
              textContent: this.state.textContent,
            },
          };
          if (this.props.groupPost) {
            data.groupId = this.props.groupData.id;
          }
          if (this.props.familyPost) {
            data.familyId = this.props.familyId;
          }

          // url = groupPost
          //   ?
          //   : familyPost
          //   ? URL.insertFamilyProfile
          //   : code === postType.noticeCode
          //   ? URL.insertPost
          //   : URL.insertPersonProfilePost;

          url = groupPost
            ? URL.insertGroupPost
            : familyPost
            ? URL.insertFamilyProfile
            : "";

          axiosPost(
            url,
            data,
            (response) => {
              if (response.status === 200) {
                if (response.data.data.post.moderatedStatus === "PENDING") {
                  swal(
                    MESSAGEHEADER.success,
                    "Succesfully sent for moderation",
                    ""
                  );
                } else {
                  swal(MESSAGEHEADER.success, "Succesfully posted", "");
                }
                this.setState({ showSpinner: false });
                // this.callNoticeFunctions();
                !this.props.noticeType
                  ? this.props.toggleModal()
                  : this.props.toggleNoticeModal();
                if (this.props.groupPost) {
                  this.props.getGroupPosts(this.props.groupData.id, "initCall");
                  this.props.getGroupDiscussions(
                    this.props.groupData.id,
                    "initCall"
                  );
                } else if (familyPost) {
                  this.props.getFamilyPosts(this.props.familyId, "initCall");
                  this.props.getDiscussionPosts(
                    this.props.familyId,
                    "initCall"
                  );
                } else {
                  this.props.getPersonProfilePosts(
                    this.props.profilePeopleId,
                    "initCall"
                  );
                }
                this.props.getPersonPostSectionAcademicRepoPosts(
                  true,
                  "initCall"
                );
              }
            },
            (error) => {
              this.setState({ showSpinner: false });
            }
          );
        }
      } else {
        if (this.state.heading !== "" && this.state.textContent !== "") {
          let postKeywords = [];
          let url;
          let postThemes = [
            {
              themeId: this.state.themes,
            },
          ];
          let groups = [];
          if (code === postType.articleCode) {
            let keywords = this.state.articleKeywords.split(", ");
            keywords.forEach((el) => {
              postKeywords.push({
                keyword: el,
              });
            });
          }
          if (code === postType.mediaCode) {
            let keywords = this.state.mediaKeywords.split(", ");
            keywords.forEach((el) => {
              postKeywords.push({
                keyword: el,
              });
            });
          }
          if (
            this.state.selectedGroups &&
            this.state.selectedGroups.length > 0
          ) {
            this.state.selectedGroups.forEach((el) => {
              groups.push({ id: el.value });
            });
          }
          let peopleIds = [];
          if (this.state.selectedIndividual.length > 0) {
            this.state.selectedIndividual.forEach((el) => {
              peopleIds.push(el.value);
            });
          }
          if (this.state.tabName !== "article") {
            if (
              this.state.selectedIndividual.length === 0 &&
              this.state.selectedGroups.length === 0
            ) {
              swal({
                title: "Error",
                text: "Atleast Group or People Should be selected.",
                closeOnClickOutside: false,
                allowOutsideClick: false,
              });
              draggable();
              this.setState({ isSubmitting: false, showSpinner: false });
              return false;
            }
          }
          var data = {
            post: {
              postType: code,
              heading: this.state.heading,
              textContent: this.state.textContent,
              isAcademicRepo: this.state.isAcademicRepo,
              contents:
                code === postType.mediaCode
                  ? this.state.url.length > 0
                    ? this.getMediaContentJSON(this.state.url)
                    : []
                  : this.getContentJSON(this.state.selectedLinks),
              groups: groups,
              postThemes:
                code !== postType.noticeCode
                  ? postThemes[0].themeId === null
                    ? []
                    : postThemes
                  : [],
              postKeywords:
                code !== postType.noticeCode
                  ? postKeywords[0].keyword === ""
                    ? []
                    : postKeywords
                  : [],
              postQuestions:
                code !== postType.noticeCode
                  ? code === postType.articleCode
                    ? this.state.questions[0].question === ""
                      ? []
                      : this.state.questions
                    : this.state.questionsForMedia[0].question === ""
                    ? []
                    : this.state.questionsForMedia
                  : [],
              peopleIds: code === postType.noticeCode ? peopleIds : null,
            },
            moduleCode: modules["Post Section"],
          };

          this.props.groupPost
            ? (data.groupId = this.props.groupData.id)
            : this.props.familyPost
            ? (data.familyId = this.props.familyId)
            : code === postType.noticeCode
            ? (data = data.post)
            : (data.profilePeopleId = this.props.profilePeopleId);
          //data = data;
          url = groupPost
            ? URL.insertGroupPost
            : familyPost
            ? URL.insertFamilyProfile
            : code === postType.noticeCode
            ? URL.insertPost
            : URL.insertPersonProfilePost;
          axiosPost(
            url,
            data,
            (response) => {
              if (response.status === 200) {
                if (
                  response.data.data.post &&
                  response.data.data.post.moderatedStatus === "PENDING"
                ) {
                  swal(
                    MESSAGEHEADER.success,
                    "Succesfully sent for moderation",
                    ""
                  );
                } else {
                  swal(MESSAGEHEADER.success, "Succesfully posted", "");
                }
                if (this.props.groupPost) {
                  this.props.getGroupPosts(this.props.groupData.id, "initCall");
                  this.props.getGroupDiscussions(
                    this.props.groupData.id,
                    "initCall"
                  );
                } else if (familyPost) {
                  this.props.getFamilyPosts(this.props.familyId, "initCall");
                } else if (this.props.noticeType) {
                } else {
                  this.props.getPersonProfilePosts(
                    this.props.profilePeopleId,
                    "initCall"
                  );
                }
                this.props.getPersonPostSectionAcademicRepoPosts(
                  true,
                  "initCall"
                );
                this.callNoticeFunctions();

                if (this.state.files.length > 0) {
                  let formData = new FormData();

                  for (const key of Object.keys(this.state.files)) {
                    formData.append("content", this.state.files[key]);
                  }
                  formData.append(
                    "jsonData",
                    JSON.stringify({
                      origin: "myPC",
                      postId:
                        this.props.groupPost || this.props.familyPost
                          ? response.data.data.postId
                          : code === postType.noticeCode
                          ? response.data.data.id
                          : response.data.data.postId,
                    })
                  );
                  axiosPost(
                    URL.insertPostContent,
                    formData,
                    (response) => {
                      if (response.status === 200) {
                        !this.props.noticeType
                          ? this.props.toggleModal()
                          : this.props.toggleNoticeModal();
                        // this.callNoticeFunctions();
                        this.setState({
                          isSubmitting: false,
                          showSpinner: false,
                        });
                      }
                    },
                    () => {
                      this.setState({
                        isSubmitting: false,
                        showSpinner: false,
                      });
                    }
                  );
                } else {
                  this.setState({ showSpinner: false });
                  // this.callNoticeFunctions();
                  !this.props.noticeType
                    ? this.props.toggleModal()
                    : this.props.toggleNoticeModal();
                }
                if (
                  userRole === userRoleCode.roleStudent ||
                  userRole === userRoleCode.roleStudentContact
                ) {
                  if (this.props.peopleId === this.props.profilePeopleId) {
                  } else {
                    swal({
                      title: MESSAGEHEADER.success,
                      text: "Your post is sent for moderation.",
                      allowOutsideClick: false,
                      closeOnClickOutside: false,
                    });
                    draggable();
                  }
                } else {
                  swal({
                    title: MESSAGEHEADER.success,
                    text: "Successfully Posted",
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                  });
                  draggable();
                }
              }
            },
            () => {
              this.setState({ showSpinner: false });
            }
          );
        } else if (this.state.heading === "" && this.state.textContent !== "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Heading cannot be null",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
        } else if (this.state.textContent === "" && this.state.heading !== "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Content cannot be null",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
        } else if (this.state.textContent === "" && this.state.heading === "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Content and Heading cannot be null",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
        }
      }
    });
  };

  //For Notice Reload
  callNoticeFunctions = () => {
    if (this.props.groupPage) {
      this.groupNoticeConfig();
    } else if (this.props.familyPage) {
      this.familyNoticeConfig();
    } else {
      this.personalNoticeConfig();
    }
  };

  // groupNoticeConfig = () => {
  //   let initialData = this.props.groupName;
  //   let groupName = initialData.replace(/_/g, " ");
  //   let param = {
  //     groupName: groupName,
  //     postType: postType.noticeCode,
  //     createdAt: new Date(),
  //   };
  //   this.props.getGroupNotices(param);
  // };

  // familyNoticeConfig = () => {
  //   let userRoles = userRole;
  //   let param = {
  //     postType: postType.noticeCode,
  //     createdAt: new Date(),
  //   };
  //   let familyId = this.props.familyId;
  //   if (
  //     userRoles === userRoleCode.roleAdmin ||
  //     userRoles === userRoleCode.roleStaff
  //   ) {
  //     param.familyId = familyId;
  //     this.props.getFamilyNotices(param);
  //   } else {
  //     this.getFamilyDetails(param, familyId);
  //   }
  // };

  // getFamilyDetails = (param, familyId) => {
  //   let data = {
  //     familyId: familyId,
  //   };
  //   let count = 0;
  //   axiosPost(URL.selectFamilyMembers, data, (response) => {
  //     if (response.status === 200) {
  //       let peopleId = localStorage.getItem("peopleId");
  //       let datas = response.data.data;
  //       if (datas.length > 0) {
  //         datas.forEach((el) => {
  //           if (el.peopleId === parseInt(peopleId)) {
  //             count++;
  //           }
  //         });
  //       }
  //       if (count === 0) {
  //         param.peopleId = localStorage.getItem("peopleId");
  //         this.props.getNotices(param);
  //       } else {
  //         param.familyId = familyId;
  //         this.props.getFamilyNotices(param);
  //       }
  //     }
  //   });
  // };

  personalNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      if (!this.props.username) {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      } else {
        let data = {
          username: this.props.username,
        };
        axiosPost(URL.getLoggedInUser, data, (response) => {
          if (response.status === 200) {
            param.peopleId = response.data.data.peopleId;
            this.props.getNotices(param);
          }
        });
      }
    } else {
      if (userRole === userRoleCode.roleStudentContact) {
        axiosPost(
          URL.selectFamilyMembers,
          { familyId: localStorage.getItem("familyId") },
          (response) => {
            if (response.status === 200) {
              let profilePeopleId = this.porps.profilePeopleId;
              let count = 0;
              if (response.data.data.length > 0) {
                response.data.data.forEach((el) => {
                  if (el.peopleId === parseInt(profilePeopleId)) {
                    count++;
                  }
                });
              }
              if (count > 0) {
                param.peopleId = profilePeopleId;
                this.props.getNotices(param);
              }
            }
          }
        );
      } else {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      }
    }
  };

  getContentJSON = (links) => {
    let contentArray = [];
    links.forEach((link) => {
      contentArray.push({
        contentTypeCode: "LNK",
        content: link,
        origin: this.getOrigin(link),
      });
    });
    return contentArray;
  };

  getMediaContentJSON = (link) => {
    let contentArray = [
      {
        contentTypeCode: "LNK",
        content: link,
        origin: this.getOrigin(link),
      },
    ];
    return contentArray;
  };

  getOrigin = (link) => {
    if (link.includes("soundcloud")) return "soundCloud";
    if (link.includes("youtube") || link.includes("youtu.be")) return "youtube";
    if (link.includes("vimeo")) return "vimeo";
    if (link.includes("dailymotion") || link.includes("dai.ly"))
      return "dailymotion";
    if (link.includes(reactServer)) return "insitePost";
    if (link.includes("slideshare")) return "slideshare";
    return "others";
  };

  handleAddThemeClick = (e) => {
    this.setState((prevState) => ({
      addThemeModal: !prevState.addThemeModal,
    }));
  };

  handleAddQuestionClick = (e, code) => {
    this.setState((prevState) => ({
      addQuestionModal: !prevState.addQuestionModal,
    }));
    this.setState({ postTypeCode: code });
  };

  handleQuestionSubmit = (e) => {
    let questions = [...this.state.questions];
    let newQuestions = [];
    if (questions.length > 0 && questions[0].question !== "") {
      questions.forEach((el) => {
        if (el.question !== "") {
          newQuestions.push({
            question: el.question,
          });
        }
      });
    } else {
      newQuestions.push({ question: "" });
    }
    this.setState({
      questions: newQuestions,
      addQuestionModal: !this.state.addQuestionModal,
    });
  };

  handleMediaQuestionSubmit = (e) => {
    let questions = [...this.state.questionsForMedia];
    let newQuestions = [];
    if (questions.length > 0 && questions[0].question !== "") {
      questions.forEach((el) => {
        if (el.question !== "") {
          newQuestions.push({
            question: el.question,
          });
        }
      });
    } else {
      newQuestions.push({ question: "" });
    }
    this.setState({
      questionsForMedia: newQuestions,
      addQuestionModal: !this.state.addQuestionModal,
    });
  };

  handleAddThemeSubmit = (e) => {
    if (this.state.theme !== "") {
      e.preventDefault();
      let param = {
        theme: this.state.theme,
        description: this.state.description,
      };
      axiosPost(URL.insertTheme, param, (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, "Theme is successfully added");
          this.handleAddThemeClick();
          this.getThemeList();
          this.setState({ theme: "", description: "" });
        } else {
          swal(MESSAGEHEADER.error, response.message);
        }
      });
    } else {
      swal({ title: "Error", text: "Theme is empty" });
    }
  };

  handleAddMoreQuestion = (e) => {
    this.setState((prevState) => ({
      questions: [
        ...prevState.questions,
        {
          question: "",
        },
      ],
    }));
  };

  handleAddMoreQuestionsForMedia = (e) => {
    this.setState((prevState) => ({
      questionsForMedia: [
        ...prevState.questionsForMedia,
        {
          question: "",
        },
      ],
    }));
  };

  handleRemoveQuestion = (e, index) => {
    e.preventDefault();
    displayConfirmDeleteAlert(index, this.handleDeleteQuestion);
  };

  handleRemoveQuestionsForMedia = (e, index) => {
    e.preventDefault();
    displayConfirmDeleteAlert(index, this.handleDeleteQuestionForMedia);
  };

  handleDeleteQuestion = (id) => {
    let questions = [...this.state.questions];
    questions.splice(id, 1);
    this.setState({ questions });
  };

  handleDeleteQuestionForMedia = (id) => {
    let questionsForMedia = [...this.state.questionsForMedia];
    questionsForMedia.splice(id, 1);
    this.setState({ questionsForMedia });
  };

  handleQuestionChange = (e, index) => {
    let questions = [...this.state.questions];
    let name = e.target.name;
    let value = e.target.value;
    questions[index][name] = value;
    this.setState({ questions });
  };

  handleQuestionChangeForMedia = (e, index) => {
    let questionsForMedia = [...this.state.questionsForMedia];
    let name = e.target.name;
    let value = e.target.value;
    questionsForMedia[index][name] = value;
    this.setState({ questionsForMedia });
  };

  deleteQuestionsForMedia = (e, idx) => {
    let questionsForMedia = [...this.state.questionsForMedia];
    questionsForMedia.splice(idx, 1);
    if (questionsForMedia.length === 0) {
      questionsForMedia.push({ question: "" });
    }
    this.setState({ questionsForMedia });
  };

  render() {
    return (
      <>
        <div className={"tt-postTabs"}>
          <Tabs onChange={(tabId) => this.clearStates(tabId)}>
            {!this.props.noticeType ? (
              <TabList>
                <Tab tabFor="article">Article</Tab>
                <Tab tabFor="media">Media</Tab>
                {(this.props.familyPost || this.props.groupPost) && (
                  <Tab tabFor="discussion">Discussion</Tab>
                )}
              </TabList>
            ) : (
              <TabList>
                <Tab tabFor="notice">Notice</Tab>
              </TabList>
            )}
            {!this.props.noticeType ? (
              <>
                <TabPanel tabId="article">
                  <Article
                    insertedInsitePost={this.state.insertedInsitePost}
                    links={this.state.links}
                    selectedLinks={this.state.selectedLinks}
                    deleteLinkContent={this.deleteLinkContent}
                    deleteQuestions={this.deleteQuestions}
                    handleLinkChanges={this.handleLinkChanges}
                    handleChange={this.handleChange}
                    heading={this.state.heading}
                    textContent={this.state.textContent}
                    submitPost={this.submitPost}
                    //   handleArticleChangeSelect={this.handleArticleChangeSelect}
                    handleTextEditor={this.handleTextEditor}
                    selectedOrigin={this.state.selectedOrigin}
                    handleChangeSelect={this.handleChangeSelect}
                    articleKeywords={this.state.articleKeywords}
                    insertArticleKeyword={this.insertArticleKeyword}
                    themes={this.state.themes}
                    groupPost={
                      this.props.groupPost ? this.props.groupPost : false
                    }
                    familyPost={
                      this.props.familyPost ? this.props.familyPost : false
                    }
                    handleAddThemeClick={this.handleAddThemeClick}
                    themeList={this.state.themeList}
                    handleAddQuestionClick={this.handleAddQuestionClick}
                    questions={this.state.questions}
                    isAcademicRepo={this.state.isAcademicRepo}
                    error={this.state.error}
                  ></Article>
                </TabPanel>
                <TabPanel tabId="media">
                  <Media
                    handleURLChanges={this.handleURLChanges}
                    url={this.state.url}
                    selectedURL={this.state.selectedURL}
                    handleChange={this.handleChange}
                    heading={this.state.heading}
                    textContent={this.state.textContent}
                    submitPost={this.submitPost}
                    handleTextEditor={this.handleTextEditor}
                    selectedOrigin={this.state.selectedOrigin}
                    mediaKeywords={this.state.mediaKeywords}
                    insertMediaKeyword={this.insertMediaKeyword}
                    //   handleMediaChangeSelect={this.handleMediaChangeSelect}
                    removeSelectedURL={this.removeSelectedURL}
                    files={this.state.files}
                    handleFileSelect={this.handleFileSelect}
                    themes={this.state.themes}
                    handleChangeSelect={this.handleChangeSelect}
                    groupPost={
                      this.props.groupPost ? this.props.groupPost : false
                    }
                    familyPost={
                      this.props.familyPost ? this.props.familyPost : false
                    }
                    handleAddThemeClick={this.handleAddThemeClick}
                    themeList={this.state.themeList}
                    handleAddQuestionClick={this.handleAddQuestionClick}
                    questionsForMedia={this.state.questionsForMedia}
                    deleteQuestionsForMedia={this.deleteQuestionsForMedia}
                    deleteFileContent={this.deleteFileContent}
                    isAcademicRepo={this.state.isAcademicRepo}
                  ></Media>
                </TabPanel>
                {(this.props.familyPost || this.props.groupPost) && (
                  <TabPanel tabId="discussion">
                    <Discussion
                      heading={this.state.heading}
                      textContent={this.state.textContent}
                      submitPost={this.submitPost}
                      handleTextEditor={this.handleTextEditor}
                      isAcademicRepo={this.state.isAcademicRepo}
                      error={this.state.error}
                      handleChange={this.handleChange}
                      groupPost={
                        this.props.groupPost ? this.props.groupPost : false
                      }
                      familyPost={
                        this.props.familyPost ? this.props.familyPost : false
                      }
                    />
                  </TabPanel>
                )}
              </>
            ) : (
              <TabPanel tabId="notice">
                <Notice
                  heading={this.state.heading}
                  handleChange={this.handleChange}
                  handleChangeSelect={this.handleChangeSelect}
                  handleFileSelect={this.handleFileSelect}
                  textContent={this.state.textContent}
                  handleTextEditor={this.handleTextEditor}
                  submitPost={this.submitPost}
                  deleteFileContent={this.deleteFileContent}
                  files={this.state.files}
                  fileSize={this.state.fileSize}
                  handleMediaChanges={this.handleLinkChanges}
                  media={this.state.links}
                  selectedMedias={this.state.selectedLinks}
                  deleteMediaContent={this.deleteMediaContent}
                  deleteLinkContent={this.deleteLinkContent}
                  selectedOrigin={this.state.selectedOrigin}
                  groupPost={
                    this.props.groupPost ? this.props.groupPost : false
                  }
                  selectedGroups={this.state.selectedGroups}
                  selectedIndividual={this.state.selectedIndividual}
                  handleIndividualSelect={this.handleIndividualSelect}
                ></Notice>
              </TabPanel>
            )}
          </Tabs>
          <ModalWindow
            modal={this.state.addThemeModal}
            toggleModal={this.handleAddThemeClick}
            modalHeader="Add New Theme"
            modalBody={
              <AddTheme
                handleChange={this.handleChange}
                theme={this.state.theme}
                description={this.state.description}
                handleAddThemeSubmit={this.handleAddThemeSubmit}
              />
            }
          />

          <ModalWindow
            modal={this.state.addQuestionModal}
            size="lg"
            toggleModal={this.handleAddQuestionClick}
            modalHeader="Add New Questions"
            modalBody={
              <PostQuestion
                handleAddMoreQuestion={
                  this.state.postTypeCode === postType.articleCode
                    ? this.handleAddMoreQuestion
                    : this.handleAddMoreQuestionsForMedia
                }
                questions={
                  this.state.postTypeCode === postType.articleCode
                    ? this.state.questions
                    : this.state.questionsForMedia
                }
                handleRemoveQuestion={
                  this.state.postTypeCode === postType.articleCode
                    ? this.handleRemoveQuestion
                    : this.handleRemoveQuestionsForMedia
                }
                handleQuestionChange={
                  this.state.postTypeCode === postType.articleCode
                    ? this.handleQuestionChange
                    : this.handleQuestionChangeForMedia
                }
                handleQuestionSubmit={
                  this.state.postTypeCode === postType.articleCode
                    ? this.handleQuestionSubmit
                    : this.handleMediaQuestionSubmit
                }
              />
            }
          />
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Posting ...
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = () => ({});
const mapActionToProps = {
  getNotices,
  getPosts,
  getGroupPosts,
  getPersonProfilePosts,
  getFamilyPosts,
  getPersonPostSectionAcademicRepoPosts,
  getGroupNotices,
  getFamilyNotices,
  getDiscussionPosts,
  getGroupDiscussions,
};
export default connect(mapStateToProps, mapActionToProps)(Posts);

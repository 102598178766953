import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import ViewLMSFile from "./ViewLMSFile";

export class ListSchoolLMS extends Component {
  state = {
    lmsList: [],
    activeLMSFile: [],
    fileViewModal: false,
  };

  componentDidMount() {
    this.getSchoolLMS();
  }

  getSchoolLMS = () => {
    let schoolLmsTypeId =
      this.props.lms === "cirriculam"
        ? 1
        : this.props.lms === "textbooks"
        ? 2
        : this.props.lms === "question-bank"
        ? 3
        : this.props.lms === "av-resource"
        ? 4
        : "";

    axiosPost(
      URL.getSchoolLMS,
      { schoolLmsTypeId: schoolLmsTypeId },
      (response) => {
        if (response.status === 200) {
          this.setState({
            lmsList: response.data.data,
          });
        }
      }
    );
  };

  handleViewResource = (file) => {
    this.setState({
      activeLMSFile: file,
      fileViewModal: true,
    });
  };

  toggleFileViewer = () => {
    this.setState({
      fileViewModal: !this.state.fileViewModal,
    });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            School Learning Management System
          </div>

          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>Title</th>
                <th>Year Level</th>
                <th>Subject</th>
                <th>Description</th>
                <th>Resources</th>
              </tr>
            </thead>
            <tbody>
              {this.state.lmsList.length > 0 ? (
                this.state.lmsList.map((list) => (
                  <tr>
                    <td>{list.title}</td>
                    <td>{list.yearLevelName}</td>
                    <td>{list.subjectName || "--"}</td>
                    <td>{list.description}</td>
                    <td>
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() => this.handleViewResource(list)}
                      >
                        View Resources
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={4}>No records available!!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <ModalWindow
          modal={this.state.fileViewModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Resource Files"
          modalBody={<ViewLMSFile activeLMSFile={this.state.activeLMSFile} />}
        ></ModalWindow>
      </>
    );
  }
}

export default ListSchoolLMS;

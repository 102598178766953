import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import ReactToPrint from "react-to-print";

class ViewPlanner extends React.Component {
  render() {
    return (
      <>
        <div className="text-right mb-2">
          <ReactToPrint
            trigger={() => (
              <button className="tt-button tt-button-primary">
                Print <FontAwesomeIcon icon={faPrint} />
              </button>
            )}
            content={() => this.componentRef}
          />
        </div>
        <div style={{ display: "none" }}>
          <div
            className="row printPlanner"
            ref={(el) => (this.componentRef = el)}
          >
            <div className="col">
              <table className="table table-bordered table-striped mb-0 text-center">
                <thead className="tt-group-header">
                  <tr>
                    <th>Day / Date</th>
                    <th>Topic</th>
                    <th>Objectives</th>
                    <th>Materials / Methodology</th>
                    <th>Class Assignment</th>
                    <th>Home Assignment</th>
                    <th>Evalution / Marks</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.selectedPlannerList.length > 0 ? (
                    this.props.selectedPlannerList.map((list, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            {moment(list.date).format("dddd, Do MMMM YYYY")}
                          </td>
                          <td>{list.topic}</td>
                          <td>{list.objectives}</td>
                          <td>{list.methodology}</td>
                          <td>{list.classAssignment}</td>
                          <td>{list.homeAssignment}</td>
                          <td>{list.remark}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>No Planner List</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <table className="table table-bordered table-striped mb-0 text-center">
              <thead className="tt-group-header">
                <tr>
                  <th>Day / Date</th>
                  <th>Topic</th>
                  <th>Objectives</th>
                  <th>Materials / Methodology</th>
                  <th>Class Assignment</th>
                  <th>Home Assignment</th>
                  <th>Evalution / Marks</th>
                  <th>Resources</th>
                </tr>
              </thead>
              <tbody>
                {this.props.selectedPlannerList.length > 0 ? (
                  this.props.selectedPlannerList.map((list, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {moment(list.date).format("dddd, Do MMMM YYYY")}
                        </td>
                        <td>{list.topic}</td>
                        <td>{list.objectives}</td>
                        <td>{list.methodology}</td>
                        <td>{list.classAssignment}</td>
                        <td>{list.homeAssignment}</td>
                        <td>{list.remark}</td>
                        <td>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() => this.props.handleViewFiles(list)}
                          >
                            View Files
                          </button>{" "}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>No Planner List</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default ViewPlanner;

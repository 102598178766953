import React, { Component } from "react";
import "../../assets/scss/mcq.scss";
import swal from "sweetalert";
import { URL, draggable, userRoleCode } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import { connect } from "react-redux";
import { getMcqList } from "../../ducks/MCQDucks";
import SystemExamHeader from "./SystemExamHeader";
import SystemExamQuestions from "./SystemExamQuestions";

class SystemExamConfiguration extends Component {
  state = {
    topic: "",
    subjectId: "",
    creatorPeopleId: "",
    educationLevels: [],
    educationLevelCode: "",
    educationLevelId: "",
    assessmentSubjects: [],
    groupsArray: [],
    selectedGroups: [],
    testType: [],
    selectedTestType: "",
    timer: "",
    peopleSpinner: false,
    selectedIndividual: [],
    peopleArray: [],
    theoryQuestions: [
      {
        Question: "",
        explanation: "",
        isOpen: false,
        type: 0, //subjective
        hierarchyOrder: 1,
        isPublished: false,
        isTheory: true,
        markWeight: 0,
        subjectQuestionOptions: [
          {
            optionValue: "",
            isCorrect: false,
          },
        ],
      },
    ],
    practicalQuestions: [
      {
        Question: "",
        explanation: "",
        isOpen: false,
        type: 0, //subjective
        hierarchyOrder: 1,
        isPractical: true,
        markWeight: 0,
        isPublished: false,
        subjectQuestionOptions: [
          {
            optionValue: "",
            isCorrect: false,
          },
        ],
      },
    ],
    marks: "full",
    yearLevels: [],
    yearLevelId: "",
    classRooms: [],
    classRoomId: "",
    assessments: [],
    assessmentId: "",
    assessmentModuleCode: null,
    educationLevelDivisions: null,
    educationLevelDivisionId: "",
    marksCalculation: "0",
    hasPractical: false,
    hasTheory: false,
    theoryMaxMark: 0,
    practicalMaxMark: 0,
  };

  clearData = () => {
    this.setState({
      topic: "",
      subjectId: "",
      creatorPeopleId: "",
      educationLevelCode: "",
      selectedGroups: [],
      selectedIndividual: [],
      selectedTestType: "",
      timer: "",
      questions: [
        {
          Question: "",
          explanation: "",
          isOpen: false,
          type: 0, //subjective
          subjectQuestionOptions: [
            {
              optionValue: "",
              isCorrect: false,
            },
          ],
        },
      ],
    });
  };

  clearState = () => {
    swal({
      title: "Are you sure?",
      text: "Once cleared, you will not be able to recover this record!",
      // icon: "warning",
      closeOnClickOutside: false,
      allowOutsideClick: false,
      buttons: true,
      dangerMode: true,
    }).then((deleteConfirm) => {
      if (deleteConfirm) {
        this.clearData();
      } else {
      }
    });
    draggable();
  };

  componentDidMount() {
    this.getEducationLevel();
    // this.getAllGroups();
    // this.getTestType();
    // this.getGroupAssociatedPeople();
    // if (this.props.update) {
    //   this.setUpdateData();
    // }
  }

  // setUpdateData = () => {
  //   let updateData = this.props.updateData;
  //   if (updateData.subjectId) {
  //     let data = { id: updateData.subjectId };
  //     axiosPost(URL.selectByKeySubjectList, data, (res) => {
  //       if (res.status === 200) {
  //         this.setState(
  //           {
  //             educationLevelCode: res.data.data.assessmentModuleCode,
  //           },
  //           function () {
  //             this.getSubjects();
  //             // this.setState({
  //             //   subjectId: parseInt(updateData.subjectId),
  //             // });
  //           }
  //         );
  //       }
  //     });
  //   }

  //   //For Group
  //   let updatedGroups = updateData.groups;
  //   let selectedGroups = [];
  //   if (updatedGroups && updatedGroups.length > 0) {
  //     updatedGroups.forEach((el) => {
  //       selectedGroups.push({ label: el.name, value: el.id });
  //     });
  //   }

  //   //For People
  //   let updatedPeople = updateData.people;
  //   let selectedIndividual = [];
  //   if (updatedPeople && updatedPeople.length > 0) {
  //     updatedPeople.forEach((up) => {
  //       selectedIndividual.push({
  //         label: up.name,
  //         value: up.id,
  //       });
  //     });
  //   }
  //   //For questions
  //   let updatedquestions = updateData.questions;
  //   let questions = [];
  //   if (updatedquestions && updatedquestions.length > 0) {
  //     updatedquestions.forEach((mq) => {
  //       let correctExplanation = "";
  //       let subjectQuestionOptions = [];
  //       if (mq.subjectQuestionOptions.length > 0) {
  //         mq.subjectQuestionOptions.forEach((mqO) => {
  //           subjectQuestionOptions.push({
  //             optionValue: mqO.optionValue,
  //             isCorrect: mqO.isCorrect,
  //           });
  //           if (mqO.isCorrect) {
  //             correctExplanation = mqO.explanation;
  //           }
  //         });
  //       }
  //       questions.push({
  //         Question: mq.question,
  //         explanation: correctExplanation,
  //         isOpen: false,
  //         subjectQuestionOptions: subjectQuestionOptions,
  //       });
  //     });
  //   }
  //   this.setState({
  //     topic: updateData.topic,
  //     selectedGroups,
  //     selectedTestType: updateData.testTypeId,
  //     timer: updateData.timer,
  //     selectedIndividual,
  //     questions,
  //   });
  // };

  // getGroupAssociatedPeople = () => {
  //   let data = {
  //     peopleId: localStorage.getItem("peopleId"),
  //   };
  //   this.setState({ peopleSpinner: true }, function () {
  //     axiosPost(
  //       URL.getGroupAssociatedPeople,
  //       data,
  //       (response) => {
  //         if (response.status === 200) {
  //           let people = response.data.data;
  //           let peopleArray = [];
  //           let yearLevel = localStorage.getItem("yearLevel");
  //           let classroom = localStorage.getItem("classroom");
  //           if (people.length > 0) {
  //             people.forEach((el) => {
  //               let displayName = "";
  //               if (el.userRoleCode === userRoleCode.roleStudent) {
  //                 displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
  //               } else {
  //                 displayName = `${el.name} [${el.userRole}]`;
  //               }
  //               peopleArray.push({ label: displayName, value: el.id });
  //             });
  //           }

  //           this.setState({ peopleArray, peopleSpinner: false });
  //         }
  //       },
  //       (failur) => {
  //         this.setState({ peopleSpinner: false });
  //       }
  //     );
  //   });
  // };

  // getTestType = () => {
  //   axiosPost(URL.getTestType, {}, (response) => {
  //     if (response.status === 200) {
  //       this.setState({ testType: response.data.data });
  //     }
  //   });
  // };

  // getAllGroups = () => {
  //   axiosPost(URL.getAllGroups, {}, (response) => {
  //     if (response.status === 200) {
  //       let groups = response.data.data;
  //       let groupsArray = [];
  //       groups.forEach((element) => {
  //         groupsArray.push({ label: element.name, value: element.id });
  //       });
  //       this.setState({
  //         groupsArray,
  //       });
  //     }
  //   });
  // };

  getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ educationLevels: response.data.data });
      }
    });
  };

  getSubjects = () => {
    let data = {
      assessmentModuleCode: this.state.educationLevelCode,
    };
    axiosPost(URL.subjectList, data, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
        if (this.props.update) {
          this.setState({ subjectId: this.props.updateData.subjectId });
        }
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (
        this.state.yearLevelId != "" &&
        this.state.educationLevelId !== "" &&
        this.state.classRoomId !== "" &&
        this.state.educationLevelDivisionId !== "" &&
        this.state.assessmentId !== ""
      ) {
        this.getAssessmentSubjects();
      }
      if (name === "educationLevelId") {
        this.getYearLevelByEducationLevel(value);
        const educationLevel = this.state.educationLevels?.find(
          (e) => e.id === parseInt(value)
        );
        this.setState({
          assessmentModuleCode: educationLevel
            ? educationLevel.assessmentModuleCode
            : null,
          educationLevelDivisions: educationLevel
            ? educationLevel.educationLevelDivisions
            : null,
        });
        educationLevel &&
          this.getAssessments(educationLevel.assessmentModuleCode);
      }
      if (name === "yearLevelId") {
        const yearLevel = this.state.yearLevels.find(
          (y) => y.id === parseInt(value)
        );
        this.setState({ classRooms: yearLevel ? yearLevel.classrooms : [] });
      }
      if (name === "assessmentId") {
        this.setState({ assessmentSubjects: [], subjectId: "" });
      }
      if (name === "subjectId") {
        const subject = this.state.assessmentSubjects.find(
          (s) => s.subjectId === parseInt(value)
        );
        if (subject && subject.assessmentScale) {
          this.setState({
            hasTheory: subject.hasTheory,
            hasPractical: subject.hasPractical,
          });
          const assessmentScaleMark =
            subject.assessmentScale.assessmentScaleMark;
          let theoryQuestions = assessmentScaleMark
            ? this.state.theoryQuestions.map((tq) => {
                tq.markWeight = assessmentScaleMark.theoryMax;
                return tq;
              })
            : this.state.theoryQuestions;
          let practicalQuestions = assessmentScaleMark
            ? this.state.practicalQuestions.map((pq) => {
                pq.markWeight = assessmentScaleMark.practicalMax;
                return pq;
              })
            : this.state.practicalQuestions;
          this.setState({
            theoryMaxMark: assessmentScaleMark
              ? assessmentScaleMark.theoryMax
              : 80,
            practicalMaxMark: assessmentScaleMark
              ? assessmentScaleMark.practicalMax
              : 20,
            theoryQuestions,
            practicalQuestions,
          });
        }
      }
      // if (name === "marksCalculation" && value) {
      //   let theoryQuestions = this.state.theoryQuestions.map(tq=>);
      //   let practicalQuestions = this.state.practicalQuestions.map();
      // }
    });
  };

  getYearLevelByEducationLevel = (value) => {
    axiosPost(
      URL.getYearLevelByEducationLevel,
      { educationLevelId: value },
      (response) => {
        if (response.status === 200) {
          this.setState({ yearLevels: response.data.data });
        }
      },
      (error) => {}
    );
  };

  getAssessments = (code) => {
    const data = {
      assessmentModuleCode: code,
    };
    axiosPost(URL.selectAssessments, data, (resp) => {
      if (resp.status === 200) {
        let assessments = resp.data.data;
        this.setState({ assessments });
      }
    });
  };

  getAssessmentSubjects = () => {
    const data = {
      yearLevelId: this.state.yearLevelId,
      educationLevelId: this.state.educationLevelId,
      classroomId: this.state.classRoomId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      assessmentId: this.state.assessmentId,
    };

    axiosPost(
      URL.selectSystemExamSubjects,
      data,
      (response) => {
        if (response.status === 200) {
          let assessmentSubjects = response.data.data;
          this.setState({ assessmentSubjects });
        }
      },
      (err) => {}
    );
  };

  addOption = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions[idx].subjectQuestionOptions.push({
      optionValue: "",
      isCorrect: false,
    });
    this.setState({ [stateName]: questions });
  };

  removeOption = (e, idx, oIdx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    if (questions[idx].subjectQuestionOptions.length < 2) {
      swal({ title: "Error", text: "Atleast one option should be given" });
      return;
    } else {
      questions[idx].subjectQuestionOptions.splice(oIdx, 1);
      this.setState({ [stateName]: questions });
    }
  };

  handleChangeSelect = (selectedItem) => {
    this.setState({
      selectedGroups: selectedItem,
    });
  };

  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  handleMcqOptionChange = (e, oIdx, idx, type) => {
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let questions = [...this.state[stateName]];
    if (name === "isCorrect") {
      if (questions[idx].subjectQuestionOptions.length > 0) {
        questions[idx].subjectQuestionOptions.forEach((el) => {
          el.isCorrect = false;
        });
      }
    }
    questions[idx].subjectQuestionOptions[oIdx][name] = value;
    this.setState({ [stateName]: questions });
  };

  handleMcqQuestionChange = (e, idx, type) => {
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let questions = [...this.state[stateName]];
    if (name === "markWeight") {
      const markState = type === "t" ? "theoryMaxMark" : "practicalMaxMark";
      const str = type === "t" ? "theory questions." : "practical questions.";
      const remainingMarks = this.state[markState];
      if (remainingMarks - parseInt(value) < 0) {
        swal("Warning", this.state[markState] + " is full marks for " + str);
        questions[idx][name] = 0;
      } else {
        questions[idx][name] = value;
      }
    } else {
      questions[idx][name] = value;
    }
    this.setState({ [stateName]: questions });
  };

  handleExplanation = (e, idx, type) => {
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions[idx]["explanation"] = e;
    this.setState({ [stateName]: questions });
  };

  addQuestion = (e, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];

    const hierarchyOrder = questions.length + 1;
    let obj = {
      Question: "",
      explanation: "",
      isOpen: false,
      type: 0, //subjective
      hierarchyOrder: hierarchyOrder,
      markWeight: 0,
      subjectQuestionOptions: [
        {
          optionValue: "",
          isCorrect: false,
        },
      ],
    };
    if (type === "t") {
      obj.isTheory = true;
    }
    if (type === "p") {
      obj.isPractical = true;
    }
    questions.push(obj);
    if (this.state.marksCalculation === "0") {
      const markState = type === "t" ? "theoryMaxMark" : "practicalMaxMark";
      const l = questions.length;
      questions.forEach((q) => {
        q.markWeight = parseFloat((this.state[markState] / l).toFixed(2));
      });
    }
    this.setState({ [stateName]: questions });
  };

  removeQuestion = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions.splice(idx, 1);
    if (questions.length < 1) {
      swal({ title: "Error", text: "Atleast one question is necessary" });
    } else {
      this.setState({ [stateName]: questions });
    }
  };

  openExplanation = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions.forEach((el) => {
      el.isOpen = false;
    });
    questions[idx].isOpen = true;
    this.setState({ [stateName]: questions });
  };

  closeExplanation = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];

    if (idx !== undefined) {
      questions[idx].explanation = "";
    }
    questions.forEach((el) => {
      el.isOpen = false;
    });
    this.setState({ [stateName]: questions });
  };

  saveExplanation = (e, idx) => {
    e.preventDefault();
    let questions = [...this.state.questions];
    questions.forEach((el) => {
      el.isOpen = false;
    });
    this.setState({ questions });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let theoryQuestions = [...this.state.theoryQuestions];
    let practicalQuestions = [...this.state.practicalQuestions];

    if (this.state.marksCalculation === "1") {
      const totalTheoryMarks = theoryQuestions?.reduce(function (acc, obj) {
        return acc + parseInt(obj.markWeight);
      }, 0);
      const totalPracticalMarks = practicalQuestions?.reduce(function (
        acc,
        obj
      ) {
        return acc + parseInt(obj.markWeight);
      },
      0);

      if (this.state.hasTheory && totalTheoryMarks < this.state.theoryMaxMark) {
        swal("Warning", "Total theory marks not reached.");
        return;
      }
      if (
        this.state.hasPractical &&
        totalPracticalMarks < this.state.practicalMaxMark
      ) {
        swal("Warning", "Total practical marks not reached.");
        return;
      }
    } else if (this.state.marksCalculation === "0") {
      theoryQuestions = theoryQuestions;
      practicalQuestions = practicalQuestions;
    }

    let questions = [...theoryQuestions, ...practicalQuestions];

    let err = 0;
    let nquestions = [];
    //Validating Data
    if (questions.length > 0) {
      questions.forEach((el) => {
        if (el.Question === "") {
          err++;
        }
        let correct = 0;
        if (el.type == "1") {
          el.subjectQuestionOptions.forEach((opt) => {
            if (opt.optionValue === "") {
              err++;
            }
            if (opt.isCorrect === true) {
              correct++;
            }
          });
        }
      });

      nquestions = questions.filter((d) => d.Question !== "");

      const q = nquestions.find(
        (que) =>
          que.Question === "" ||
          (que.type == "1" &&
            que.subjectQuestionOptions.some((opt) => opt.optionValue === ""))
      );
      if (q) {
        swal({
          title: "Error",
          text: "Fill all the Questions and Options first",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        return;
      }
    }
    // if (this.state.topic === "") {
    //   swal({
    //     title: "Error",
    //     text: "MCQ title or test type is missing.",
    //     allowOutsideClick: false,
    //     closeOnClickOutside: false,
    //   });
    //   draggable();
    //   return false;
    // }

    // if (this.state.selectedTestType == 1) {
    //   if (this.state.timer === "") {
    //     swal({
    //       title: "Error",
    //       text: "Timer is missing",
    //       allowOutsideClick: false,
    //       closeOnClickOutside: false,
    //     });
    //     draggable();
    //     return;
    //   }
    // }

    //End of Validation

    //   {
    //     "question": "What is this?",
    //     "questionType": "SUBJECTIVE",
    //     "educationLevelId": 2,
    //     "educationLevelDivisionId": 4,
    //     "yearLevelId": 1,
    //     "classroomId": 1,
    //     "subjectId": 1,
    //     "assessmentId": 1,
    //     "markWeight": 10,
    //     "isTheory": true,
    //     "isPublished": false,
    //     "hierarchyOrder": 2
    // },

    let data = [];
    if (nquestions.length > 0) {
      nquestions.forEach((el) => {
        let obj = {};
        let options = [];

        obj.question = el.Question;
        obj.questionType =
          el.type == "0"
            ? "SUBJECTIVE"
            : el.type == "1"
            ? "OBJECTIVE"
            : "NONANSWERABLE";
        obj.educationLevelId = this.state.educationLevelId;
        obj.educationLevelDivisionId = this.state.educationLevelDivisionId;
        obj.yearLevelId = this.state.yearLevelId;
        obj.classroomId = this.state.classRoomId;
        obj.assessmentId = this.state.assessmentId;
        obj.subjectId = this.state.subjectId;
        obj.hierarchyOrder = el.hierarchyOrder;
        obj.explanation = el.explanation;
        obj.markWeight = el.markWeight;
        obj.isPublished = el.isPublished;

        if (el.isTheory) {
          obj.isTheory = el.isTheory;
          obj.isPractical = false;
        }
        if (el.isPractical) {
          obj.isTheory = false;
          obj.isPractical = el.isPractical;
        }

        if (el.type === "1") {
          options = el.subjectQuestionOptions.map((opt) => {
            return {
              optionValue: opt.optionValue,
              isCorrect: opt.isCorrect,
              optionType: "text",
            };
          });
          obj.subjectQuestionOptions = options;
        }
        data.push(obj);
      });
    }

    axiosPost(URL.insertSystemExamSubjectQuestions, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "System exam added successfully.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.clearData();
        if (this.props.update) {
          this.props.toggleUpdateMOdal();
        }
      }
    });
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={this.props.update ? { height: "87.5vh" } : { height: "79.5vh" }}
      >
        <div className="tt-group-header">System Exam Configuration</div>
        <div
          className="tt-newListBox mb-1"
          style={
            this.props.update
              ? { height: "69.4vh", overflow: "hidden auto" }
              : { height: "60.5vh", overflowX: "hidden", overflowY: "auto" }
          }
        >
          <SystemExamHeader
            educationLevels={this.state.educationLevels}
            yearLevels={this.state.yearLevels}
            yearLevelId={this.state.yearLevelId}
            classRooms={this.state.classRooms}
            classRoomId={this.state.classRoomId}
            educationLevelId={this.state.educationLevelId}
            topic={this.state.topic}
            handleChange={this.handleChange}
            handleChangeSelect={this.handleChangeSelect}
            subjectList={this.state.subjectList}
            subjectId={this.state.subjectId}
            groupsArray={this.state.groupsArray}
            selectedGroups={this.state.selectedGroups}
            peopleSpinner={this.state.peopleSpinner}
            peopleArray={this.state.peopleArray}
            selectedIndividual={this.state.selectedIndividual}
            handleIndividualSelect={this.handleIndividualSelect}
            marks={this.state.marks}
            assessments={this.state.assessments}
            assessmentId={this.state.assessmentId}
            educationLevelDivisions={this.state.educationLevelDivisions}
            educationLevelDivisionId={this.state.educationLevelDivisionId}
            assessmentSubjects={this.state.assessmentSubjects}
            marksCalculation={this.state.marksCalculation}
            theoryMaxMark={this.state.theoryMaxMark}
            practicalMaxMark={this.state.practicalMaxMark}
          ></SystemExamHeader>
          {this.state.subjectId && this.state.hasTheory && (
            <>
              <div className="row p-2">
                <div className="col border border-info">
                  <strong>Theory</strong>
                  <SystemExamQuestions
                    questions={this.state.theoryQuestions}
                    addOption={this.addOption}
                    addQuestion={this.addQuestion}
                    openExplanation={this.openExplanation}
                    closeExplanation={this.closeExplanation}
                    saveExplanation={this.saveExplanation}
                    handleMcqOptionChange={this.handleMcqOptionChange}
                    handleMcqQuestionChange={this.handleMcqQuestionChange}
                    removeOption={this.removeOption}
                    removeQuestion={this.removeQuestion}
                    handleExplanation={this.handleExplanation}
                    marksCalculation={this.state.marksCalculation}
                    type="t"
                  ></SystemExamQuestions>
                  <div className="row">
                    <div className="col text-right mt-2">
                      <div>
                        <button
                          className="tt-button tt-button-primary"
                          onClick={(e) => this.addQuestion(e, "t")}
                          id="addQuestion"
                        >
                          Add Question
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.subjectId && this.state.hasPractical && (
            <>
              <div className="row p-2">
                <div className="col border border-info">
                  <strong>Practical</strong>
                  <SystemExamQuestions
                    questions={this.state.practicalQuestions}
                    addOption={this.addOption}
                    addQuestion={this.addQuestion}
                    openExplanation={this.openExplanation}
                    closeExplanation={this.closeExplanation}
                    saveExplanation={this.saveExplanation}
                    handleMcqOptionChange={this.handleMcqOptionChange}
                    handleMcqQuestionChange={this.handleMcqQuestionChange}
                    removeOption={this.removeOption}
                    removeQuestion={this.removeQuestion}
                    handleExplanation={this.handleExplanation}
                    marksCalculation={this.state.marksCalculation}
                    type="p"
                  ></SystemExamQuestions>
                  <div className="row">
                    <div className="col text-right mt-2">
                      <div>
                        <button
                          className="tt-button tt-button-primary"
                          onClick={(e) => this.addQuestion(e, "p")}
                          id="addQuestion"
                        >
                          Add Question
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* {this.state.subjectId && ( */}

          {/* )} */}
        </div>
        {this.state.subjectId && (
          <div className="container-fluid">
            <div className="row button-bg-color pr-4">
              {/* <div className="col-md-2 text-right mt-1">
                <strong>System Exam Type: </strong>
              </div>
              <div className="col">
                <select
                  className="form-control"
                  name="selectedTestType"
                  value={this.state.selectedTestType}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    System Exam Type:
                  </option>
                  {this.state.testType.map((type, idx) => {
                    return <option value={type.id}>{type.type}</option>;
                  })}
                </select>
              </div>
              {this.state.selectedTestType == 1 ? (
                <>
                  <div className="col-md-1 text-right mt-1">
                    <strong>Time: </strong>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="timer"
                      value={this.state.timer}
                      onChange={this.handleChange}
                      placeholder="in minutes"
                    ></input>
                  </div>
                </>
              ) : null} */}
              <div className="col-md-9"></div>
              <div className="col-md-3 text-right">
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={(e) => this.handleSubmit(e, false)}
                >
                  Publish
                </button>
                {this.props.update ? (
                  ""
                ) : (
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={(e) => this.handleSubmit(e, true)}
                  >
                    Save Draft
                  </button>
                )}
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.clearState}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionsToProps = {
  getMcqList,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SystemExamConfiguration);

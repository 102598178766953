import React, { Component } from "react";
import {
  userRole,
  userRoleCode,
  checkRolePermissions,
} from "../../utils/Constants";
import { Spinner } from "react-mdl";
import moment from "moment";

import Chip from "@material-ui/core/Chip";

export default class SubmissionDetails extends Component {
  state = {
    showAssignmentDetails: true,
    loggedInUser: localStorage.getItem("peopleId"),
    permissionSubmitSubjectAssignment: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionSubmitSubjectAssignment: checkRolePermissions(
        "submit-subject-assignment",
        "activity"
      ),
    });
  };

  handleSwitchChange = () => {
    this.setState({ showAssignmentDetails: !this.state.showAssignmentDetails });
    //call list api
  };

  render() {
    return (
      <>
        <div>
          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th width="210px">Student Name</th>
                <th width="165px">Status</th>
                {!this.props.propFromStudentInformation ? (
                  <th width="165px">Option</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {this.props.assignmentStudent.length > 0 ? (
                this.props.assignmentStudent.map((student, idx) => {
                  return userRole != userRoleCode.roleStudent ? (
                    // && userRole != userRoleCode.roleStudentContact
                    <tr key={idx}>
                      <td>
                        {" "}
                        <strong>{student.people.name}</strong>
                      </td>
                      <td>
                        <strong>{student.status}</strong>
                        <br />
                        {student.submittedAt != null ? (
                          student.isAssignmentDelayed ? (
                            <>
                              Submitted On :{" "}
                              {moment(student.submittedAt).format("LL")} ({" "}
                              <i>
                                Delayed :{" "}
                                {moment(student.submittedAt).from(
                                  student.assignmentDeadline
                                )}{" "}
                              </i>
                              )
                            </>
                          ) : (
                            "Submitted On : " +
                            moment(student.createdAt).format("LL")
                          )
                        ) : null}
                        {student.approvedAt != null ? (
                          <>
                            <br />
                            Approved On :{" "}
                            {moment(student.approvedAt).format("LL")}
                          </>
                        ) : null}

                        <br />
                        {student.isSeen ? (
                          <Chip
                            color="primary"
                            className="override-chip"
                            label="Seen"
                          />
                        ) : (
                          <Chip
                            color="primary"
                            className="override-chip"
                            label="Not Seen"
                          />
                        )}

                        {}
                      </td>

                      <td className="text-center">
                        <>
                          {this.state.permissionSubmitSubjectAssignment ? (
                            student.status == "Pending" ? (
                              student.people.id == this.state.loggedInUser ? (
                                <button
                                  className="tt-button tt-button-primary"
                                  onClick={() =>
                                    this.props.handleSubmissionModal(
                                      student.assignmentId
                                    )
                                  }
                                >
                                  Submit Assignment
                                </button>
                              ) : (
                                <Chip
                                  color="colorSecondary"
                                  className="override-chip"
                                  label="Not Submitted"
                                />
                              )
                            ) : (
                              <button
                                className="tt-button tt-button-primary"
                                onClick={() =>
                                  this.props.handleViewSubmittedResources(
                                    student.assignmentId,
                                    student.admissionId,
                                    student.status,
                                    student.point
                                  )
                                }
                              >
                                View Submitted Resources
                              </button>
                            )
                          ) : student.status == "Pending" ? (
                            <Chip
                              color="colorSecondary"
                              className="override-chip"
                              label="Not Submitted"
                            />
                          ) : (
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() =>
                                this.props.handleViewSubmittedResources(
                                  student.assignmentId,
                                  student.admissionId,
                                  null,
                                  student.point
                                )
                              }
                            >
                              View Submitted Resources
                            </button>
                          )}
                        </>
                      </td>
                    </tr>
                  ) : student.people.id == this.state.loggedInUser ? (
                    <tr key={idx}>
                      <td>
                        {" "}
                        <strong>{student.people.name}</strong>
                      </td>
                      <td>
                        {student.status}
                        <br />
                        {student.isSeen ? (
                          <Chip
                            color="primary"
                            className="override-chip"
                            label="Seen"
                          />
                        ) : (
                          <Chip
                            color="primary"
                            className="override-chip"
                            label="Not Seen"
                          />
                        )}

                        {}
                      </td>

                      {!this.props.propFromStudentInformation ? (
                        <td className="text-center">
                          <>
                            {this.state.permissionSubmitSubjectAssignment ? (
                              student.isRequired ? (
                                student.canSubmit ? (
                                  student.status == "Pending" ? (
                                    <button
                                      className="tt-button tt-button-primary"
                                      onClick={() =>
                                        this.props.handleSubmissionModal(
                                          student.assignmentId
                                        )
                                      }
                                    >
                                      Submit Assignment
                                    </button>
                                  ) : student.status === "Correction" ? (
                                    <>
                                      <button
                                        className="tt-button tt-button-primary"
                                        onClick={() =>
                                          this.props.handleSubmissionModal(
                                            student.assignmentId
                                          )
                                        }
                                      >
                                        Re-submit Assignment
                                      </button>
                                      <button
                                        className="tt-button tt-button-primary ml-3"
                                        onClick={() =>
                                          this.props.handleViewSubmittedResources(
                                            student.assignmentId,
                                            student.admissionId,
                                            null,
                                            student.point
                                          )
                                        }
                                      >
                                        View Submitted Resources
                                      </button>
                                    </>
                                  ) : (
                                    <button
                                      className="tt-button tt-button-primary"
                                      onClick={() =>
                                        this.props.handleViewSubmittedResources(
                                          student.assignmentId,
                                          student.admissionId,
                                          null,
                                          student.point
                                        )
                                      }
                                    >
                                      View Submitted Resources
                                    </button>
                                  )
                                ) : (
                                  <button
                                    className="tt-button tt-button-primary"
                                    disabled
                                  >
                                    Submission Date Expire
                                  </button>
                                )
                              ) : (
                                <button
                                  className="tt-button tt-button-primary"
                                  disabled
                                >
                                  Submission Not Required
                                </button>
                              )
                            ) : student.status == "Pending" ? (
                              <Chip
                                color="colorSecondary"
                                className="override-chip"
                                label="Not Submitted"
                              />
                            ) : (
                              <button
                                className="tt-button tt-button-primary"
                                onClick={() =>
                                  this.props.handleViewSubmittedResources(
                                    student.assignmentId,
                                    student.admissionId,
                                    null,
                                    student.point
                                  )
                                }
                              >
                                View Submitted Resources
                              </button>
                            )}
                          </>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ) : null;
                })
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    {this.state.spinner ? (
                      <Spinner color="white"></Spinner>
                    ) : (
                      "No assignments"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

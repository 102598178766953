import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import { axiosPost } from "../../utils/AxiosApi";
import {
  URL,
  profilePeopleId,
  checkWidgetAdminOrNot,
  userRole,
  userRoleCode,
} from "../../utils/Constants";
import swal from "sweetalert";
import "../../assets/scss/attendance.scss";
import moment from "moment";
import ModalWindow from "./../UI/ModalWindow";
import GranularPermissionModal from "./../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";
import AttendanceSubjectList from "./AttendanceSubjectList";
import AttendanceHeader from "./AttendanceHeader";
import AttendanceList from "./AttendanceList";

class Attendance extends Component {
  state = {
    academicYearEnd: null,
    academicYearStart: null,
    attendanceLoading: false,
    calendarDate: moment().format("YYYY-MM-DD"),
    checkedAll: false,
    cid: null,
    classroom: "",
    classroomAlias:
      localStorage.getItem("classroom") === null
        ? "Homeroom"
        : localStorage.getItem("classroom"), //get from localStorage
    classRoomName: "",
    classrooms: [],
    date: moment().format("YYYY-MM-DD"),
    day: moment().format("DD"),
    educationLevelId: null,
    isHoliday: false,
    month: moment().format("MMMM"),
    recruitmentId: null,
    selectedAttendance: [],
    selectedSubject: null,
    studentAttendances: [],
    students: [],
    subjects: [],
    weekDays: [],
    yearLevel: "",
    yearLevelAlias:
      localStorage.getItem("yearLevel") === null
        ? "Class"
        : localStorage.getItem("yearLevel"), //get from localStorage
    yearLevels: null,
    yid: null,
    month: "",
  };

  checkAll = (e) => {
    this.setState({ checkedAll: e.target.checked });
    let newSelectedAttendance = [...this.state.selectedAttendance];
    newSelectedAttendance.forEach((attendent) => {
      attendent.isPresent = e.target.checked;
    });
    this.setState({ selectedAttendance: newSelectedAttendance });
  };

  /**
   * @author Saurav Sitaula
   * @description gets events of education level and return true if selected date lies between
   * start date or end date of holiday event
   */
  checkForCalendarEvents = async () => {
    const calendarEventsPromise = new Promise((res, rej) => {
      axiosPost(
        URL.getEventsByEducationLevel,
        {
          educationLevelId: this.state.educationLevelId,
        },
        (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        }
      );
    });
    const calendarEvents = await calendarEventsPromise;
    if (calendarEvents) {
      //return true if selected day is holiday event
      for (let i = 0; i < calendarEvents.length; i++) {
        if (calendarEvents[i].isHoliday) {
          let range = moment(this.state.calendarDate).isBetween(
            calendarEvents[i].start,
            calendarEvents[i].end,
            "days",
            "[]"
          );
          if (range) return true;
        }
      }
    }
    return false;
  };

  clearData = (cid, yid) => {
    this.setState({
      studentAttendances: [],
      students: [],
    });
    this.getStudents(cid, yid);
  };

  componentDidMount() {
    if (userRole === userRoleCode.roleAdmin) {
      this.getClassrooms();
    } else if (userRole === userRoleCode.roleStaff) {
      this.getRecruitment();
    }
    this.evaluateCurrentAcademicYear();
  }

  attendanceConfirmation = () => {
    if (this.state.classRoomName === "") {
      swal("Warning", "First select the classroom for attendance.");
      return;
    } else {
      swal({
        title: "Are you sure?",
        text: "Do you really want to continue the attendance!",
        closeOnClickOutside: false,
        allowOutsideClick: false,
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (confirm) {
          this.doAttendance();
        }
      });
    }
  };

  doAttendance = () => {
    this.setState({
      submitSpinner: true,
    });
    const diff = moment(this.state.calendarDate).diff(
      moment().format("YYYY-MM-DD"),
      "days"
    );

    if (diff < 1) {
      let studentAttendances = this.state.studentAttendances;
      let newAttendances = {
        yearLevelId: studentAttendances.yearLevelId,
        classroomId: studentAttendances.classroomId,
        date: studentAttendances.date,
        studentAttendanceWrappers: [],
      };
      if (studentAttendances.subjectAttendances.length > 0) {
        studentAttendances.subjectAttendances.forEach((el) => {
          if (el.subjectSelected) {
            let tempData = {
              subjectId: el.subjectId,
              studentAttendanceStudentDataWrappers: [],
            };
            if (el.attendanceDatas.length > 0) {
              el.attendanceDatas.forEach((ad) => {
                let data = {
                  admissionId: ad.admissionId,
                  isPresent: ad.isPresent,
                  remarks: ad.remarks,
                };
                tempData.studentAttendanceStudentDataWrappers.push(data);
              });
            }
            newAttendances.studentAttendanceWrappers.push(tempData);
          }
        });
      }
      axiosPost(
        URL.insertStudentAttendance,
        newAttendances,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Attendance Recorded");
            this.setState({
              submitSpinner: false,
            });
          }
        },
        (err) => {
          swal("Error", "Some error occured!!");
          this.setState({
            submitSpinner: false,
          });
        }
      );
    } else {
      if (this.state.classRoomName !== "")
        swal("Warning", "You are trying to insert attendance in the future.");
      this.setState({
        submitSpinner: false,
      });
    }
  };

  evaluateCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState({
            academicYearStart: response?.data?.data?.startDate?.substring(
              0,
              10
            ),
            academicYearEnd: response?.data?.data?.endDate?.substring(0, 10),
          });
        } else {
          axiosPost(URL.selectCurrentAcademicYear, {}, (response) => {
            if (response.status === 200) {
              this.setState({
                academicYearStart: response?.data?.data?.startDate?.substring(
                  0,
                  10
                ),
                academicYearEnd: response?.data?.data?.endDate?.substring(
                  0,
                  10
                ),
              });
            }
          });
        }
      }
    });
  };

  getClassrooms = () => {
    let data = {
      yearLevelId: this.state.yearLevel,
    };
    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.status === 200) {
        let classrooms = response.data.data;
        this.setState({
          classrooms,
        });
      }
    });
  };

  getClassroomSubjects = (classroomId = this.state.cid) => {
    var data = {
      classroomId: classroomId,
      day: moment(this.state.calendarDate).format("dddd"),
    };
    axiosPost(URL.getSubjectByClassroomForAttendance, data, (response) => {
      if (response.data.status === 200) {
        let dataRes = response.data.data;
        const data =
          dataRes &&
          dataRes.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.id === value.id && t.name === value.name)
          );

        let selectedSubject = 0,
          studentAttendances = {
            yearLevelId: "",
            classroomId: "",
            date: this.state.calendarDate,
            subjectAttendances: [],
          };
        if (data.length > 0) {
          selectedSubject = data[0].id;
          studentAttendances.yearLevelId = this.state.yid;
          studentAttendances.classroomId = this.state.cid;
          data.forEach((el, idx) => {
            if (idx === 0) {
              el.isActive = true;
            } else {
              el.isActive = false;
            }
            studentAttendances.subjectAttendances.push({
              subjectId: el.id,
              subjectSelected: el.isClassRoutine,
              attendanceDatas: [],
              // subject: el,
            });
          });

          this.setState(
            {
              subjects: data,
              selectedSubject,
              studentAttendances,
            },
            function () {
              if (this.state.subjects.length > 0) {
                this.getOldStudentsData(this.state.calendarDate);
              }
            }
          );
        }
      }
    });
  };

  getOldStudentsData = (date) => {
    const params = {
      classroomId: this.state.cid,
      subjectId: this.state.selectedSubject,
      date,
    };
    this.setState({ attendanceLoading: true }, () => {
      if (this.state.cid != null) {
        axiosPost(
          URL.getAssignedStudents,
          params,
          (response) => {
            if (response.status === 200) {
              let students = response.data.data,
                selectedAttendance = [];
              let studentAttendances = { ...this.state.studentAttendances };
              if (students.length > 0) {
                students.forEach((stu) => {
                  if (studentAttendances.subjectAttendances.length > 0) {
                    selectedAttendance =
                      studentAttendances.subjectAttendances[0].attendanceDatas;
                    studentAttendances.subjectAttendances.forEach((el) => {
                      el.attendanceDatas.push({
                        admissionId: stu.admissionId,
                        isPresent: stu.isPresent || false,
                        remarks: "",
                        studentName: stu.people.name,
                      });
                    });
                  }
                });
              }
              this.setState(
                {
                  students,
                  studentAttendances,
                  selectedAttendance,
                  attendanceLoading: false,
                },
                () => {
                  // this.getStudentAttendance();
                }
              );
            }
          },
          (err) => {
            this.setState({ attendanceLoading: false });
          }
        );
      } else {
        swal("Select both yearLevel and Classroom");
      }
    });
  };

  getRecruitment = () => {
    axiosPost(
      URL.getStaffList,
      { peopleId: localStorage.getItem("peopleId") },
      (response) => {
        if (response.status === 200) {
          const data = response.data.data;
          this.setState(
            { recruitmentId: data.length > 0 && data[0].id },
            () => {
              axiosPost(
                URL.selectClassroomForAttendance,
                { recruitmentId: this.state.recruitmentId },
                (response) => {
                  if (response.status === 200) {
                    this.setState({ classrooms: response.data.data });
                  }
                }
              );
            }
          );
        }
      }
    );
  };

  getStudentAttendance = () => {
    const params = {
      classroomId: this.state.cid,
      subjectId: this.state.selectedSubject,
      date: this.state.calendarDate,
    };
    axiosPost(
      URL.getStudentsAttendance,
      params,
      (response) => {
        if (response.status === 200) {
          let studentAttendents = [...response.data.data],
            selectedAttendance = [...this.state.selectedAttendance];
          let studentAttendances = {
            ...this.state.studentAttendances,
          };

          if (studentAttendents.length > 0) {
            this.state.subjects.map((sub) => {
              studentAttendents.map((sa) => {
                if (sa.subjectId === sub.id) {
                  sub.attendanceStatus = true;
                } else {
                  sub.attendanceStatus = false;
                }
              });
            });
          }

          if (studentAttendents.length > 0) {
            studentAttendents.forEach((attendent) => {
              if (studentAttendances.subjectAttendances.length > 0) {
                studentAttendances.subjectAttendances.forEach((el) => {
                  if (el.subjectId === this.state.selectedSubject) {
                    const index = el.attendanceDatas.findIndex(
                      (student) => student.admissionId === attendent.admissionId
                    );
                    if (index !== -1) {
                      el.attendanceDatas[index].isPresent =
                        attendent.isPresent || false;
                      el.attendanceDatas[index].remarks =
                        attendent.remarks || "";
                    }
                    selectedAttendance = el.attendanceDatas;
                  }
                });
              }
            });
          } else {
            studentAttendances.subjectAttendances.forEach((el) => {
              if (el.subjectId === this.state.selectedSubject) {
                selectedAttendance = el.attendanceDatas;
              }
            });
          }

          studentAttendances.subjectAttendances.map((el) => {
            if (el.subjectId === this.state.selectedSubject) {
              if (el.attendanceDatas.some((ad) => ad.isPresent === false)) {
                this.setState({
                  checkedAll: false,
                });
              } else {
                this.setState({
                  checkedAll: true,
                });
              }
            }
          });

          this.setState({
            studentAttendances,
            selectedAttendance,
            attendanceLoading: false,
          });
        }
      },
      (err) => {
        this.setState({ attendanceLoading: false });
      }
    );
  };

  getStudents = (cid, yid) => {
    this.setState({ attendanceLoading: true }, () => {});
    if (this.state.classRoomName != null) {
      let params = {
        classroomId: cid,
      };
      axiosPost(
        URL.getAssignedStudents,
        params,
        (response) => {
          if (response.status === 200) {
            let students = response.data.data;
            let studentAttendances = [...this.state.studentAttendances];
            for (let index = 0; index < students.length; index++) {
              studentAttendances.push({
                yearLevelId: yid,
                classroomId: cid,
                admissionId: students[index].admissionId,
                isPresent: students[index].isPresent || false,
                remarks: "",
                studentName: students[index].people.name,
              });
            }
            this.setState({
              students,
              studentAttendances,
              attendanceLoading: false,
            });
          }
        },
        (err) => {
          this.setState({ attendanceLoading: false });
        }
      );
    } else {
      swal("Select both yearLevel and Classroom");
    }
  };

  getWeekDays = async () => {
    const weekDayPromise = new Promise((res, rej) => {
      axiosPost(
        URL.getWeekDaysByEduDiv,
        {
          educationLevelId: this.state.educationLevelId,
        },
        (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        }
      );
    });
    const weekDays = await weekDayPromise;
    weekDays &&
      this.setState({
        weekDays: weekDays.map(({ id }) => id),
      });
    return weekDays.map(({ id }) => id);
  };

  handleActiveStatus = (idx) => {
    let subjects = [...this.state.subjects];
    subjects.forEach((el) => {
      el.isActive = false;
    });
    if (!subjects[idx].isActive) {
      subjects[idx].isActive = true;
      this.setState({ selectedSubject: subjects[idx].id }, function () {
        this.getStudentAttendance();
      });
    }
    this.setState({ subjects });
  };

  handleCalandarDateChange = (e) => {
    if (this.state.classRoomName != "") {
      const target = e.target;
      const value = target.value;
      const date = value;
      this.setState(
        {
          calendarDate: value,
          students: [],
          studentAttendances: [],
        },
        async () => {
          this.getClassroomSubjects(this.state.cid);
          //set holiday true if not a weekday of education level
          const weekDays = await this.getWeekDays();
          const weekDay = new Date(this.state.calendarDate).getUTCDay();
          const isHoliday = weekDays.findIndex((day) => day === weekDay);
          this.setState({ isHoliday: isHoliday === -1 ? true : false });
          if (isHoliday !== -1) {
            const eventHoliday = await this.checkForCalendarEvents();
            this.setState({ isHoliday: eventHoliday ? true : false });
          }
          // this.getOldStudentsData(date);
        }
      );
    } else {
      swal("warning", "Please select classroom first.");
    }
  };

  handleChange = (e, id) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleClassroomSelect = (e) => {
    const target = e.target;
    const value = target.value.split(",");
    const name = target.name;
    const cid = value[0];
    const yid = value[1];

    //get selected classroom for its education level
    const classroom = this.state.classrooms.find((c) => c.id === parseInt(cid));
    this.setState(
      {
        [name]: value,
        studentAttendances: [],
        students: [],
        selectedSubject: null,
        subjects: [],
        cid,
        yid,
        educationLevelId: classroom && classroom.educationLevelId,
        checkedAll: false,
      },
      async () => {
        this.getClassroomSubjects(cid);
        if (this.state.calendarDate) {
          // this.getOldStudentsData(this.state.calendarDate);
          const weekDays = await this.getWeekDays();
          const weekDay = new Date(this.state.calendarDate).getUTCDay();
          const isHoliday = weekDays.findIndex((day) => day === weekDay);
          this.setState({ isHoliday: isHoliday === -1 ? true : false });
          if (isHoliday === -1) return;
          //check for holidays in calendar event as well
          const eventHoliday = await this.checkForCalendarEvents();
          this.setState({ isHoliday: eventHoliday ? true : false });
        } else {
          this.getStudents(cid, yid);
        }
      }
    );
  };

  handleSubjectSelect = (e, idx) => {
    // this.setState({ selectedSubject: e.target.value });
    let { name, checked } = e.target;
    let subjects = [...this.state.subjects];
    subjects[idx][name] = checked;
    let studentAttendances = this.state.studentAttendances;
    if (studentAttendances.subjectAttendances.length > 0) {
      studentAttendances.subjectAttendances.forEach((el) => {
        if (el.subjectId === subjects[idx].id) {
          el.subjectSelected = checked;
        }
      });

      studentAttendances.subjectAttendances.map((el) => {
        if (el.attendanceDatas.filter((ad) => ad.isPresent === false)) {
          this.setState({
            checkedAll: false,
          });
        } else {
          this.setState({
            checkedAll: true,
          });
        }
      });
    }
    this.setState({ subjects, studentAttendances });
  };

  handleYearLevelChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
    this.setState({ classroom: "", classrooms: null }, () => {
      this.getClassrooms();
    });
  };

  studentAttendanceChange = (e, idx) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let selectedAttendance = [...this.state.selectedAttendance];
    selectedAttendance[idx][name] = value;
    this.setState({ selectedAttendance });
  };

  render() {
    return (
      <>
        {this.state.submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Attendance record is being stored
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Attendance
            {checkWidgetAdminOrNot("Attendance") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
            {/* {this.state.isHoliday && (
            <span className="float-right mr-3" style={{ color: "#ff8f8f" }}>
              Holiday Selected
            </span>
          )} */}
          </div>
          <div className="tt-newListBox container-fluid">
            <AttendanceHeader
              academicYearEnd={this.state.academicYearEnd}
              academicYearStart={this.state.academicYearStart}
              calendarDate={this.state.calendarDate}
              classRoomName={this.state.classRoomName}
              classrooms={this.state.classrooms}
              handleCalandarDateChange={this.handleCalandarDateChange}
              handleClassroomSelect={this.handleClassroomSelect}
              isHoliday={this.state.isHoliday}
            />

            <div className="row mt-2">
              <div
                className="col-md-4"
                style={{ borderRight: "1px solid #ccc" }}
              >
                <AttendanceSubjectList
                  subjects={this.state.subjects}
                  handleActiveStatus={this.handleActiveStatus}
                  handleSubjectSelect={this.handleSubjectSelect}
                />
              </div>
              <div
                className="col"
                style={{
                  height: "52.5vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                <AttendanceList
                  checkAll={this.checkAll}
                  checkedAll={this.state.checkedAll}
                  studentAttendanceChange={this.studentAttendanceChange}
                  studentAttendances={this.state.studentAttendances}
                  students={this.state.students}
                  selectedAttendance={this.state.selectedAttendance}
                />
                {this.state.attendanceLoading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-8"></div>
              <div className="col-md-4 text-right">
                <button
                  className="tt-button tt-button-primary attendance-button"
                  onClick={this.attendanceConfirmation}
                >
                  Do Attendance
                </button>
              </div>
            </div>
          </div>
          <ModalWindow
            modal={this.state.permissionModal}
            size="lg"
            id="tt-permissionModal"
            toggleModal={() => {
              this.setState({
                permissionModal: !this.state.permissionModal,
              });
            }}
            modalHeader={"Assign permission to user"}
            modalBody={
              <GranularPermissionModal
                widgetName="Attendance"
                moduleName="Student Attendance"
                header="Student Attendance"
                activityName="insert-attendance"
              />
            }
          ></ModalWindow>
        </div>
      </>
    );
  }
}
export default Attendance;

import React, { Component } from "react";

export default class CreateFlashCardSet extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1 text-right mt-1">
            <strong>Title: </strong>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              placeholder="Enter Title"
              name="title"
              value={this.props.title}
              onChange={this.props.handleChange}
              className="form-control"
            ></input>
          </div>
          <div className="col-md-1 text-right pr-0 pl-0 mt-1">
            <strong>Description: </strong>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              placeholder="Enter Description"
              name="description"
              value={this.props.description}
              onChange={this.props.handleChange}
              className="form-control"
            ></input>
          </div>
          <div className="col-md-1 text-right mt-1">
            <strong>Access: </strong>
          </div>
          <div className="col-md-3">
            <select
              className="form-control"
              name="access"
              value={this.props.access}
              onChange={this.props.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

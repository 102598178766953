import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL, draggable, checkWidgetAdminOrNot } from "../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import ModalWindow from "../UI/ModalWindow";
import swal from "sweetalert";
import CourseSyllabusDetailModal from "./CourseSyllabusDetailModal";
import CourseSyllabusUpdateModal from "./CourseSyllabusUpdateModal";
import CourseSyllabusResource from "./CourseSyllabusResource";
import FileModal from "../ViewFile/FileModal";
import CourseClassResources from "../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseResourceTemplate";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

const animatedComponents = makeAnimated();
export default class ListCourseSyllabus extends Component {
  state = {
    courseSyllabus: [],

    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: "",

    classrooms: [],
    selectedClassrooms: "",

    subjects: [],
    selectedSubjects: "",

    syllabusDetailModal: false,
    topicList: "",

    updateSyllabusDetailModal: false,
    updateTopicName: "",
    updateTopicLectureHour: "",
    updateTopicPracticalHour: "",

    syllabusResourcesModal: false,
    fileModal: false,
    selectedSubjectResources: "",

    coursePlanId: "",
    coursePlanSyllabusId: "",
    permissionModal: false,
  };

  componentDidMount = () => {
    this.getEducationLevels();
    this.getCourseSyllabus();
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getCourseSyllabus = () => {
    this.setState({ courseSyllabus: [] });
    let filter = {
      educationLevelId: this.state.selectedEducationLevelId,
      yearlevelId: this.state.selectedYearLevel,
      classroomId: this.state.selectedClassrooms,
      subjectId: this.state.selectedSubjects,
    };

    axiosPost(URL.getCoursePlan, filter, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            courseSyllabus: data,
          });
      }
    });
  };

  handleSyllabusPlanUpdate = (id) => {};

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
            selectedEducationLevelId: data[0].id,
          });
      }
    });
  };

  handleEducationLevelChange = (e) => {
    this.setState({ selectedEducationLevelId: e.target.value }, () => {
      this.getCourseSyllabus();
    });
    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          selectedYearLevel: "",

          classrooms: [],
          selectedClassrooms: "",

          subjects: [],
          selectedSubjects: "",
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState({ selectedYearLevel: e.target.value }, () => {
      this.getCourseSyllabus();
    });
    var data = {
      yearLevelId: e.target.value,
    };

    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.data.status === 200) {
        const datas = response.data.data;
        if (datas.length > 0)
          this.setState({
            classrooms: datas,
            // selectedClassrooms: datas[0].id,
            subjects: [],
            selectedSubjects: "",
          });
      }
    });
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value }, () => {
      this.getCourseSyllabus();
    });

    var data = {
      classroomId: e.target.value,
    };

    axiosPost(URL.getSubjectByClassroom, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState(
            {
              subjects: data,
              // selectedSubjects: data[0].id,
            },
            () => {
              this.getCourseSyllabus();
            }
          );
      }
    });
  };

  handleSubjects = (e) => {
    this.setState(
      {
        selectedSubjects: e.target.value,
      },
      () => {
        this.getCourseSyllabus();
      }
    );
  };

  handleSyllabusDetailModal = (sub) => {
    this.setState({
      topicList: sub,
      syllabusDetailModal: !this.state.syllabusDetailModal,
    });
  };

  toggleSyllabusDetailModal = () => {
    this.setState({ syllabusDetailModal: !this.state.syllabusDetailModal });
  };

  handleUpdateSyllabusDetail = (data) => {
    this.setState({
      updateTopicName: data.name,
      updateTopicLectureHour: data.lectureHour,
      updateTopicPracticalHour: data.practicalHour,
      id: data.id,
      updateSyllabusDetailModal: !this.state.updateSyllabusDetailModal,
    });
  };

  // delete whole course plan syllabus
  handleSyllabusPlanDelete = (id) => {
    displayConfirmDeleteAlert(id, this.deleteSyllabusPlan);
  };

  deleteSyllabusPlan = (id) => {
    const data = {
      id: id,
    };
    axiosPost(URL.deleteCoursePlan, data, (response) => {
      if (response.data.status === 200) {
        swal({
          title: "Success",
          text: "Successfully Deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getCourseSyllabus();
      }
    });
  };

  // delete single syllabus detail
  handleSyllabusDetailDelete = (id) => {
    displayConfirmDeleteAlert(id, this.deleteSyllabusDetail);
  };

  deleteSyllabusDetail = (id) => {
    const data = {
      id: id,
    };
    axiosPost(URL.deleteCoursePlanSyllabusDetail, data, (response) => {
      if (response.data.status === 200) {
        swal({
          title: "Success",
          text: "Successfully Deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getCourseSyllabus();
        this.setState({
          syllabusDetailModal: !this.state.syllabusDetailModal,
        });
      }
    });
  };

  // tigger subject resource modal
  subjectResourceModal = (param) => {
    this.setState({ coursePlanId: param }, this.toggleSyllabusResourcesModal());
  };

  // View subject resource modal
  viewSubjectResourceModal = (id) => {
    const data = {
      coursePlanId: id,
    };
    axiosPost(URL.getCoursePlanResources, data, (response) => {
      if (response.data.status === 200) {
        this.setState(
          {
            selectedSubjectResources: response.data.data,
          },
          () => {
            this.toggleFileModal();
          }
        );
      }
    });
  };

  //tigger syllabus wise resource modal
  syllabusResourceModal = (param) => {
    this.setState(
      { coursePlanSyllabusId: param.id, coursePlanId: param.coursePlanId },
      this.toggleSyllabusResourcesModal()
    );
  };

  //toggle resources modal
  toggleSyllabusResourcesModal = () => {
    this.setState({
      syllabusResourcesModal: !this.state.syllabusResourcesModal,
    });
  };

  toggleSyllabusResourcesModalView = (param) => {
    let data = {
      coursePlanSyllabusId: param.id,
      coursePlanId: param.coursePlanId,
    };
    axiosPost(URL.getCoursePlanResources, data, (res) => {
      if (res.status === 200) {
        this.setState({ selectedSubjectResources: res.data.data }, () => {
          this.toggleFileModal();
        });
      }
    });
  };

  toggleFileModal = () => {
    this.setState({
      fileModal: !this.state.fileModal,
    });
  };

  // update single syllabus
  handleSyllabusDetailUpdate = () => {
    let data = {
      id: this.state.id,
      name: this.state.updateTopicName,
      lectureHour: this.state.updateTopicLectureHour,
      practicalHour: this.state.updateTopicPracticalHour,
    };
    axiosPost(URL.updateCoursePlanSyllabusDetail, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Topic successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.toggleUpdateSyllabusDetail();
        this.getCourseSyllabus();
        this.setState({
          syllabusDetailModal: !this.state.syllabusDetailModal,
        });
      }
    });
  };

  toggleUpdateSyllabusDetail = () => {
    this.setState({
      id: "",
      updateTopicName: "",
      updateTopicLectureHour: "",
      updateTopicPracticalHour: "",
      updateSyllabusDetailModal: !this.state.updateSyllabusDetailModal,
    });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            List Course Plan
            {checkWidgetAdminOrNot("Course Plan") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-4 col-md">
                  <strong>Education Level</strong>
                  <select
                    className="form-control"
                    name="educationLevel"
                    onChange={this.handleEducationLevelChange}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.educationLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-4 col-md">
                  <strong>{localStorage.getItem("yearLevel")}</strong>
                  <select
                    className="form-control"
                    name="yearLevel"
                    onChange={this.handleYearLevel}
                    disabled={false}
                  >
                    <option
                      value=""
                      selected={
                        this.state.selectedYearLevel == "" ? true : false
                      }
                      disabled={
                        this.state.selectedYearLevel == "" ? false : true
                      }
                    >
                      Select
                    </option>
                    {this.state.yearLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-4 col-md">
                  <strong>Section</strong>
                  <select
                    className="form-control"
                    name="sectionId"
                    onChange={this.handleSectionChange}
                  >
                    <option value="" selected isabled>
                      Select
                    </option>
                    {this.state.classrooms.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-4 col-md">
                  <strong>Subject: </strong>
                  <select
                    className="form-control"
                    name="subjectId"
                    onChange={this.handleSubjects}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select Subjects
                    </option>
                    {this.state.subjects.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 border">
                  <table className="table table-bordered table-striped">
                    <thead className="tt-group-header">
                      <tr>
                        <th>Education Level</th>
                        <th width="120px">Year Level</th>
                        <th width="120px">Classroom</th>
                        <th width="130px">Subject</th>
                        <th width="240px">Resources</th>
                        <th width="260px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.courseSyllabus.length > 0 ? (
                        this.state.courseSyllabus.map((list, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{list.educationLevelName}</td>
                              <td>{list.yearlevelName}</td>
                              <td>{list.classroomName}</td>
                              <td>{list.subjectName}</td>
                              <td className="text-center">
                                <>
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.subjectResourceModal(list.id)
                                    }
                                  >
                                    Add
                                  </button>
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.viewSubjectResourceModal(list.id)
                                    }
                                  >
                                    View
                                  </button>
                                  {/* <button className="tt-button tt-button-primary">
                                    Update
                                  </button> */}
                                </>
                              </td>
                              <td className="text-center">
                                <>
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleSyllabusDetailModal(
                                        list.coursePlanSyllabus
                                      )
                                    }
                                  >
                                    View
                                  </button>

                                  {/* <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleSyllabusPlanUpdate(list.id)
                                    }
                                  >
                                    Update
                                  </button> */}

                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleSyllabusPlanDelete(list.id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center" colspan={6}>
                            No records found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state.syllabusDetailModal}
          toggleModal={this.handleSyllabusDetailModal}
          modalHeader={"Course Syllabus"}
          size="xl"
          modalBody={
            <CourseSyllabusDetailModal
              topicList={this.state.topicList ? this.state.topicList : []}
              handleUpdateSyllabusDetail={this.handleUpdateSyllabusDetail}
              handleSyllabusDetailDelete={this.handleSyllabusDetailDelete}
              handleSyllabusPlanDelete={this.handleSyllabusPlanDelete}
              toggleSyllabusResourcesModal={this.toggleSyllabusResourcesModal}
              toggleSyllabusResourcesModalView={
                this.toggleSyllabusResourcesModalView
              }
              syllabusResourceModal={this.syllabusResourceModal}
            ></CourseSyllabusDetailModal>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.updateSyllabusDetailModal}
          toggleModal={this.toggleUpdateSyllabusDetail}
          modalHeader="Edit Course"
          modalBody={
            <CourseSyllabusUpdateModal
              updateTopicName={this.state.updateTopicName}
              updateTopicLectureHour={this.state.updateTopicLectureHour}
              updateTopicPracticalHour={this.state.updateTopicPracticalHour}
              handleChange={this.handleChange}
            ></CourseSyllabusUpdateModal>
          }
          modalFooter={
            <div className="container-fluid">
              <div className="row">
                <div className="col text-right">
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.handleSyllabusDetailUpdate}
                  >
                    Save
                  </button>
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.toggleUpdateSyllabusDetail}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.syllabusResourcesModal}
          toggleModal={this.toggleSyllabusResourcesModal}
          modalHeader="Add Resource"
          size="xl"
          modalBody={
            <CourseSyllabusResource
              assignment={true}
              updateAssignment={false}
              toggleAddResourceModal={this.toggleSyllabusResourcesModal}
              saveResources={this.saveResources}
              assignmentContents={this.state.contents}
              assignmentLinks={this.state.links}
              coursePlanId={this.state.coursePlanId}
              coursePlanSyllabusId={this.state.coursePlanSyllabusId}
            ></CourseSyllabusResource>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.fileModal}
          toggleModal={this.toggleFileModal}
          modalHeader="Resource"
          size="xl"
          modalBody={
            <CourseClassResources
              selectedResources={this.state.selectedSubjectResources}
            ></CourseClassResources>

            // <FileModal
            //   isNotice={false}
            //   isSyllabys={true}

            //   handleChangeFile={this.handleChangeFile}
            //   toggleModal={this.toggleFileModal}
            //   fileExist={true}
            //   activeIndex={this.state.activeIndex}
            //   fileType={this.state.fileType}
            //   filePath={this.state.filePath}
            //   files={this.state.files}
            //   images={this.state.images}
            //   links={this.state.links}
            //   type={this.state.type}
            // ></FileModal>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Course Plan"
              moduleName="Course Syllabus"
              header="List Courses syallabus configuration"
              activityName="list-course-syllabus"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

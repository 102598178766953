import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { URL } from "../../../utils/Constants";

class McqTestTemplate extends Component {
  state = {};

  componentDidMount() {
    this.props.startTimerTest();
  }

  // playAudio = (path) => {
  //   const fileURL = `${URL.imageSelectURL}${path}`;
  //   const a = new Audio(fileURL);
  //   a.play();
  // };

  render() {
    let mcqTest = this.props.mcqTest;
    return (
      <div>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "75.7vh" }}
        >
          <div>
            <div className="row">
              <div className="col text-center tt-mcqTestHead">
                <h4>{mcqTest.subjectName}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col text-center tt-mcqTestHead">
                <h3>{mcqTest.topic}</h3>
              </div>
            </div>
          </div>
          {mcqTest.testTypeId === 1 ? (
            <div className="row tt-timerSticky">
              <div className="col text-right">
                <div className="timer" id="time">
                  {this.props.minute} : {this.props.seconds < 10 ? 0 : ""}
                  {this.props.seconds}
                </div>
              </div>
            </div>
          ) : null}

          {mcqTest.mcqQuestions.map((ques, idx) => {
            return (
              <div key={idx} className="tt-mcqQuestionHolder">
                <div className="question1">
                  {idx + 1}) {ques.question}
                </div>
                <ul className="answerOptions">
                  {ques.mcqQuestionOptions.map((opt, oIdx) => {
                    return (
                      <li
                        className="answerOption"
                        key={oIdx}
                        onClick={
                          this.props.isSubmitted
                            ? null
                            : (e) =>
                                this.props.handleOptionClick(e, ques.id, oIdx)
                        }
                      >
                        <input
                          type="radio"
                          name={`mcq${ques.id}`}
                          checked={opt.isSelected}
                          disabled={this.props.isSubmitted}
                        ></input>
                        <span className="answerChoice">
                          {opt.mediaPath !== "" &&
                          opt.mediaType !== "" &&
                          opt.optionValue === "" ? (
                            <>
                              {opt.mediaType === "AUD" ? (
                                <figure style={{ padding: "10px" }}>
                                  <figcaption>Listen:</figcaption>
                                  <audio
                                    controls
                                    src={`${URL.imageSelectURL}${opt.mediaPath}`}
                                  >
                                    <a
                                      href={`${URL.imageSelectURL}${opt.mediaPath}`}
                                    >
                                      Download audio
                                    </a>
                                  </audio>
                                </figure>
                              ) : (
                                <img
                                  src={`${URL.imageSelectURL}${opt.mediaPath}`}
                                  alt={opt.mediaPath}
                                />
                              )}
                            </>
                          ) : (
                            opt.optionValue
                          )}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.props.submitMcqTest}
              >
                Submit
              </button>
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.toggleMcqModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default McqTestTemplate;

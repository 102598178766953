import React, { Component } from "react";
import Navbar from "../Profile/Navbar";
import cover1 from "../../assets/images/profile-bg.png";
import CoverPicture from "../Profile/CoverPicture";
import NoticeDisplay from "../Profile/Notice/NoticeDisplay";
import PostArea from "../Profile/PostArea";
import CalendarDisplay from "../Profile/CalendarDisplay";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, userRoleCode, userRole } from "../../utils/Constants";
import FamilyMemberList from "./FamilyMemberList";
import GroupArea from "../Profile/GroupArea";
import WidgetArea from "../Profile/WidgetArea";
import MobileUserDetail from "../Profile/MobileUserDetail";
import ChangePassword from "../Login/ChangePassword";
import ModalWindow from "../UI/ModalWindow";
import { connect } from "react-redux";
import {
  getFamilyPosts,
  getDiscussionPosts,
  getPersonPostSectionAcademicRepoPosts,
} from "../../ducks/PostsDucks";

class FamilyPage extends Component {
  state = {
    dropdownOpen: false,
    pictures: [
      { id: "tt-cover1", src: cover1 },
      { id: "tt-cover2", src: cover1 },
      { id: "tt-cover3", src: cover1 },
      { id: "tt-cover4", src: cover1 },
    ],
    fullName: "",
    userRole: "",
    userData: "",
    minimize: false,
    setting: false,
    groupData: "",
    groupMembers: "",
    classroom: false,
    family: {},
    familyId: null,
    familyMembersList: [],
    isLegal: false,
    displayNotice: true,
    passwordModal: false,
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  toggleHover = (component, action) => {
    let systemDefault = document.getElementById("tt-systemDefault");
    let personalChange = document.getElementById("tt-personalChange");
    if (component === "cover") {
      if (action === "show") {
        if (document.getElementById("tt-editButton")) {
          document.getElementById("tt-editButton").style.opacity = 1;
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.opacity = 1;
          personalChange.style.opacity = 1;
        }
      } else {
        if (document.getElementById("tt-editButton")) {
          document.getElementById("tt-editButton").style.opacity = 0;
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.opacity = 0;
          personalChange.style.opacity = 0;
        }
      }
    } else {
      if (action === "show") {
        document.getElementById("tt-editProfileButton").style.opacity = 1;
      } else {
        document.getElementById("tt-editProfileButton").style.opacity = 0;
      }
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.minimizeCoverToggle();
    this.getFamilyDetails();
    this.checkIsLegal();
    this.checkFamilyPage();
    userRole === userRoleCode.roleStudentContact && this.checkFirstLogin();
    const familyId = this.props.match.params.familyId;
    this.props.getFamilyPosts(familyId, "initCall");
    this.props.getDiscussionPosts(familyId, "initCall");
    this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
  }

  checkFirstLogin = () => {
    axiosPost(URL.isFirstLogin, {}, (response) => {
      if (response.status === 200) {
        this.setState({ passwordModal: response.data.data.isFirstLogin });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getFamilyDetails();
      this.checkIsLegal();
      this.checkFamilyPage();
    }
  }

  checkFamilyPage = () => {
    if (
      userRole === userRoleCode.roleAdmin ||
      userRole === userRoleCode.roleStaff
    ) {
      this.setState({ displayNotice: true });
    } else if (userRole === userRoleCode.roleStudent) {
      this.setState({ displayNotice: false });
    } else {
      let myFamilyId = localStorage.getItem("familyId");
      let newFamilyId = this.props.match.params.familyId;
      if (parseInt(myFamilyId) === parseInt(newFamilyId)) {
        this.setState({ displayNotice: true });
      } else {
        this.setState({ displayNotice: false });
      }
    }
  };

  checkIsLegal = () => {
    let data = {
      familyId: this.props.match.params.familyId,
    };
    axiosPost(URL.checkIsLegal, data, (response) => {
      if (response.status === 200) {
        this.setState({ isLegal: response.data.data });
      }
    });
  };

  getFamilyDetails = (peopleId) => {
    let familyId = this.props.match.params.familyId;
    let data = {
      familyId: familyId,
    };
    this.setState({ familyId: familyId }, function () {
      axiosPost(URL.selectFamilyMembers, data, (response) => {
        if (response.status === 200) {
          let peoples = response.data.data;
          if (peoples.length > 0) {
            peoples.forEach((el) => {
              el.displayPhoto = URL.imageSelectURL + "136x136" + "/" + el.photo;
            });
          } else {
            peoples = [];
          }
          this.setState({ familyMembersList: response.data.data });
        }
      });
      axiosPost(URL.getFamilyName, data, (response) => {
        if (response.status === 200) {
          this.setState({ family: response.data.data });
        }
      });
    });
  };

  minimizeCoverToggle = () => {
    var coverImage = document.querySelectorAll(".tt-slider-content");
    var coverImageHolder = document.getElementById("tt-coverImage");
    var editButton = document.getElementById("tt-editButton");
    let systemDefault = document.getElementById("tt-systemDefault");
    let personalChange = document.getElementById("tt-personalChange");

    this.setState({ minimize: !this.state.minimize }, function () {
      if (this.state.minimize) {
        coverImageHolder.classList.add("tt-cover-minimize");
        if (editButton) {
          editButton.style.display = "none";
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.display = "none";
          personalChange.style.display = "none";
        }
        for (let i = 0; i < coverImage.length; i++) {
          coverImage[i].classList.add("tt-slider-content-minimize");
        }
      } else {
        coverImageHolder.classList.remove("tt-cover-minimize");
        if (editButton) {
          editButton.style.display = "block";
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.display = "block";
          personalChange.style.display = "block";
        }
        for (let i = 0; i < coverImage.length; i++) {
          coverImage[i].classList.remove("tt-slider-content-minimize");
        }
      }
    });
  };
  render() {
    return (
      <React.Fragment>
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
          setting={this.state.setting}
          familyPage={true}
          familyId={this.props.match.params.familyId}
        ></Navbar>
        <CoverPicture
          notices={this.state.notices}
          toggleHover={this.toggleHover}
          pictures={this.state.pictures}
          minimizeCoverToggle={this.minimizeCoverToggle}
          userRole={this.state.userRole}
          minimize={this.state.minimize}
          groupPage={true}
          familyPage={true}
          family={this.state.family}
          // familyId={this.state.familyId}
          familyId={this.props.match.params.familyId}
          isLegal={this.state.isLegal}
        ></CoverPicture>
        <div className="tt-userInfoMobile">
          <MobileUserDetail
            familyPage={true}
            family={this.state.family}
          ></MobileUserDetail>
        </div>
        {this.state.displayNotice ? (
          <div className="container">
            <NoticeDisplay
              familyPage={true}
              familyId={this.props.match.params.familyId}
            ></NoticeDisplay>
          </div>
        ) : null}
        <div className="container">
          <div id="tt-contentSection" className="tt-content-section">
            <div className="row reverseContent">
              <div className="col-md-6">
                <PostArea
                  familyPost={true}
                  familyId={this.props.match.params.familyId}
                  familyPage={true}
                  profilePeopleId={localStorage.getItem("profilePeopleId")}
                ></PostArea>
              </div>
              <div className="col-md-6">
                <FamilyMemberList
                  familyMembersList={this.state.familyMembersList}
                />
                <GroupArea
                  groupId={
                    this.props.location.groupId
                      ? this.props.location.groupId
                      : null
                  }
                  familyPage={true}
                  familyId={this.props.match.params.familyId}
                ></GroupArea>
                <div className="mt-4"></div>
                <CalendarDisplay></CalendarDisplay>
                <div className="mt-4"></div>
                <span className="mobileWidgetArea">
                  <WidgetArea />
                </span>
              </div>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.passwordModal}
          modalHeader="Change Password"
          size="lg"
          // fullWidth={true}
          toggleModal={this.togglePasswordModal}
          modalBody={<ChangePassword />}
        ></ModalWindow>
      </React.Fragment>
    );
  }
}

const mapActionToProps = {
  getFamilyPosts,
  getDiscussionPosts,
  getPersonPostSectionAcademicRepoPosts,
};
export default connect(null, mapActionToProps)(FamilyPage);

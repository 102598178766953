import {
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import "../../assets/scss/workSheet.scss";

class CreateWorksheetQuestions extends Component {
  render() {
    let props = this.props;
    return (
      <div className="container-fluid questionAnswersHolder">
        <div className="row">
          <div className="col">
            <div className="row">
              {props.questionAnswers.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div className="col-md-11 mt-3">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-1 text-right mt-1">
                            <span>
                              <strong>{idx + 1}. </strong>
                            </span>
                          </div>
                          <div className="col pl-0">
                            <input
                              type="text"
                              className="form-control"
                              name="question"
                              value={item.question}
                              onChange={(e) => props.handleDataChange(e, idx)}
                              placeholder="Enter Question"
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="container mt-2">
                        {item.answers.map((ans, aIdx) => {
                          return (
                            <div className="row mt-3" key={aIdx}>
                              <div className="col-md-1"></div>
                              <div className="col-md-3">
                                {ans.src && ans.src !== "" ? (
                                  <div className="workSheetImgHolder">
                                    <img src={ans.src} />
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      onClick={() =>
                                        props.removeImage(idx, aIdx)
                                      }
                                      style={{
                                        position: "absolute",
                                        right: "3px",
                                        top: "3px",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) =>
                                      props.handleImageChange(e, idx, aIdx)
                                    }
                                  />
                                )}
                              </div>
                              {ans.src && ans.src !== "" ? (
                                <div className="col">
                                  <div className="row">
                                    <div className="col mt-1">
                                      <strong>Image Width </strong>
                                    </div>
                                    <div className="col">
                                      <div className="input-group">
                                        <input
                                          type="number"
                                          className="form-control"
                                          min={0}
                                          name="imageWidth"
                                          value={ans.imageWidth}
                                          onChange={(e) =>
                                            props.handleAnswerDataChange(
                                              e,
                                              idx,
                                              aIdx
                                            )
                                          }
                                        />
                                        <div className="input-group-append">
                                          <span
                                            className="input-group-text"
                                            style={{ padding: "5px" }}
                                          >
                                            px
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col mt-1">
                                      <strong>Image Height </strong>
                                    </div>
                                    <div className="col">
                                      <div className="input-group">
                                        <input
                                          type="number"
                                          className="form-control"
                                          min={0}
                                          name="imageHeight"
                                          value={ans.imageHeight}
                                          onChange={(e) =>
                                            props.handleAnswerDataChange(
                                              e,
                                              idx,
                                              aIdx
                                            )
                                          }
                                        />
                                        <div className="input-group-append">
                                          <span
                                            className="input-group-text"
                                            style={{ padding: "5px" }}
                                          >
                                            px
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="text"
                                  value={ans.text}
                                  onChange={(e) =>
                                    props.handleAnswerDataChange(e, idx, aIdx)
                                  }
                                />
                              </div>
                              <div className="col-md-1 text-right mt-1">
                                <strong>No.of Lines</strong>
                              </div>
                              <div className="col-md-1">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  name="numberOfLine"
                                  value={ans.numberOfLine}
                                  onChange={(e) =>
                                    props.handleAnswerDataChange(e, idx, aIdx)
                                  }
                                />
                              </div>
                              <div className="col-md-1">
                                {aIdx !== 0 ? (
                                  <FontAwesomeIcon
                                    icon={faMinusCircle}
                                    className="removeMcqOption"
                                    onClick={() =>
                                      props.removeAnswers(idx, aIdx)
                                    }
                                  />
                                ) : null}
                                {aIdx === item.answers.length - 1 ? (
                                  <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="addMcqOption ml-3"
                                    onClick={() => props.addAnswers(idx)}
                                  ></FontAwesomeIcon>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="col-md-1 mt-3"
                      style={{ borderLeft: "1px solid #ccc" }}
                    >
                      {idx !== 0 ? (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="removeMcqOption"
                          onClick={() => props.removeQuestionAnswers(idx)}
                        />
                      ) : null}
                      {idx === props.questionAnswers.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="addMcqOption ml-3"
                          onClick={props.addQuestionAnswers}
                        ></FontAwesomeIcon>
                      ) : null}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateWorksheetQuestions;

/**
 * @author Sandeep Shakya
 * @version 1.0
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import ModalWindow from "../../UI/ModalWindow";
import GroupMembersConfig from "./GroupMembersConfig";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  draggable,
  profilePeopleId,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import swal from "sweetalert";
import { getGroupsAA } from "../../../ducks/AcademicAdministrationDucks";
import GroupEditMember from "./GroupEditMember";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
} from "../../../utils/Utils";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import Pagination from "../../UI/Pagination";

class GroupLists extends Component {
  state = {
    modal: false,
    editModal: false,
    membersTitle: "",
    groupLists: [],
    staffs: [],
    staffTypeId: "",
    staffType: [],
    yearLevels: "",
    selectYearLevel: "",
    selectClassroom: "",
    classroomList: [],
    assignedStudentsList: "",
    selectedStaff: [],
    selectedStudent: [],
    groupRoles: [],
    selectedGroupRole: "",
    groupId: "",
    allStaffChecked: false,
    allStudentChecked: false,
    groupDetail: [],
    groupData: "",
    groupSpinner: "",
    editMemberGroupSpinner: false,
    totalRecordsCount: 0,
    offset: 0,
    rowCountLimit: 20,
    showSpinner: false,
    currentPage: 1,
    groupCurrentPage: 1,
    groupTotalRecordsCount: 0,
    groupOffset: 0,
    groupRowCountLimit: 10,
    permissionEditGroupList: false,
    permissionInsertGroupMembers: false,
    permissionEditGroupMembers: false,
  };

  componentDidMount() {
    this.props.getGroupsAA(
      profilePeopleId,
      this.state.groupOffset,
      this.state.groupRowCountLimit
    );
    this.getYearLevels();
    this.getStaffType();
    this.getGroupRoles();
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditGroupList: checkRolePermissions(
        "edit-group-list",
        "activity"
      ),
      permissionInsertGroupMembers: checkRolePermissions(
        "insert-group-members",
        "activity"
      ),
      permissionEditGroupMembers: checkRolePermissions(
        "edit-group-members",
        "activity"
      ),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.groupLists !== prevProps.groupLists) {
      this.setState({
        groupLists: this.props.groupLists,
        groupSpinner: this.props.groupSpinner,
        groupTotalRecordsCount: this.props.groupTotalRecordsCount,
      });
    }
  }

  setGroupId = (e, id, action) => {
    e.preventDefault();
    this.setState({ groupId: id }, function () {
      if (action === "open") {
        this.getGroupMemberList("open");
      }
    });
  };

  //Get Functions to get data from the database

  getGroupRoles = () => {
    axiosPost(URL.getGroupRoles, {}, (response) => {
      if (response.status === 200) {
        this.setState({ groupRoles: response.data.data });
      }
    });
  };

  getStaffType = () => {
    axiosPost(URL.getStaffType, {}, (response) => {
      if (response.status === 200) {
        let staffType = response.data.data;
        this.setState({
          staffType,
        });
      }
    });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  getStaffList = () => {
    let data = {
      groupId: this.state.groupId,
      staffTypeId: this.state.staffTypeId,
    };
    axiosPost(URL.getUnassignedStaffInGroup, data, (response) => {
      if (response.status === 200) {
        const staffs = response.data.data;
        let groupStaffs = [];
        if (this.state.selectedStaff.length > 0) {
          let selected = [...this.state.selectedStaff];
          let trimmedStaffs = [];
          staffs.forEach((el) => {
            let count = 0;
            if (el.people !== null) {
              for (let i = 0; i < selected.length; i++) {
                if (selected[i].peopleId === el.peopleId) {
                  count++;
                }
              }
              if (count === 0) {
                trimmedStaffs.push(el);
              }
            }
          });
          trimmedStaffs.forEach((el) => {
            el.checked = false;
          });
          this.setState({ staffs: trimmedStaffs });
        } else {
          staffs.forEach((el) => {
            if (el.people !== null) {
              el.checked = false;
              groupStaffs.push(el);
            }
          });
          this.setState({ staffs: groupStaffs });
        }
      }
    });
  };

  getAssignedStudents = () => {
    let data = {
      groupId: this.state.groupId,
      yearLevelId: this.state.selectYearLevel,
      classroomId: this.state.selectClassroom,
    };
    axiosPost(URL.getUnassignedstudentInGroup, data, (response) => {
      if (response.status === 200) {
        let assignedStudentsList = response.data.data;
        let groupStudents = [];
        if (this.state.selectedStudent.length > 0) {
          let selected = [...this.state.selectedStudent];
          let trimmedStudents = [];
          assignedStudentsList.forEach((el) => {
            let count = 0;
            if (el.people !== null) {
              for (let i = 0; i < selected.length; i++) {
                if (selected[i].people.id === el.people.id) {
                  count++;
                }
              }
              if (count === 0) {
                trimmedStudents.push(el);
              }
            }
          });
          trimmedStudents.forEach((el) => {
            el.checked = false;
          });
          this.setState({ assignedStudentsList: trimmedStudents });
        } else {
          assignedStudentsList.forEach((el) => {
            if (el.people !== null) {
              el.checked = false;
              groupStudents.push(el);
            }
          });
          this.setState({ assignedStudentsList: groupStudents });
        }
      }
    });
  };

  getGroupMemberList = (action) => {
    let data = {
      id: this.state.groupId,
      offset: this.state.offset,
      rowCountLimit: this.state.rowCountLimit,
    };
    this.setState(
      { editMemberGroupSpinner: true, groupDetail: [] },
      function () {
        this.getGroupMembers(data);
      }
    );
  };

  getGroupMembers = (data) => {
    axiosPost(
      URL.getGroupSelectByKey,
      data,
      (response) => {
        if (response.status === 200) {
          let totalRecordsCount = response.data.totalRecordsCount;
          let groupDetail = response.data.data;
          if (groupDetail) {
            groupDetail.groupPeople.forEach((el) => {
              el.editable = false;
            });
          }
          this.setState({
            groupDetail,
            editMemberGroupSpinner: false,
            totalRecordsCount,
          });
        }
      },
      (error) => {
        displayErrorAlert(error);
        this.setState({ editMemberGroupSpinner: false });
      }
    );
  };

  handlePageChange = (page) => {
    this.reRenderGroupMemberList(page);
    this.setState({ currentPage: page });
  };

  reRenderGroupMemberList = (page) => {
    let offset =
      page * parseInt(this.state.rowCountLimit) -
      parseInt(this.state.rowCountLimit);
    this.setState(
      { editMemberGroupSpinner: true, groupDetail: [], offset: offset },
      function () {
        let data = {
          id: this.state.groupId,
          offset: this.state.offset,
          rowCountLimit: this.state.rowCountLimit,
        };
        this.getGroupMembers(data);
      }
    );
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1)
      this.reRenderGroupMemberList(this.state.currentPage - 1);
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.totalRecordsCount / this.state.rowCountLimit)
    )
      return;
    else {
      this.reRenderGroupMemberList(nextPage);
      this.setState({ currentPage: nextPage });
    }
  };

  getClassroomList = () => {
    axiosPost(
      URL.selectClassroom,
      {
        yearLevelId: this.state.selectYearLevel,
      },
      (response) => {
        if (response.status === 200) {
          this.setState({ classroomList: response.data.data });
        }
      }
    );
  };

  //Assign Member function for students

  handleYearLevelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState(
      { [name]: value, selectClassroom: "", assignedStudentsList: "" },
      function () {
        this.getClassroomList();
      }
    );
  };

  handleClassroomChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      this.getAssignedStudents();
    });
  };

  //Group List Pagination
  groupHandlePageChange = (page) => {
    this.reRenderGroupList(page);
    this.setState({ groupCurrentPage: page });
  };

  reRenderGroupList = (page) => {
    let offset =
      page * parseInt(this.state.groupRowCountLimit) -
      parseInt(this.state.groupRowCountLimit);
    this.setState({ groupOffset: offset }, function () {
      this.props.getGroupsAA(
        profilePeopleId,
        this.state.groupOffset,
        this.state.groupRowCountLimit
      );
    });
  };

  groupHandlePreviousPage = () => {
    if (this.state.groupCurrentPage !== 1)
      this.reRenderGroupList(this.state.groupCurrentPage - 1);
    this.setState((prevState) => ({
      groupCurrentPage: prevState.groupCurrentPage - 1,
    }));
  };

  groupHandleNextPage = () => {
    let nextPage = this.state.groupCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(
        this.state.groupTotalRecordsCount / this.state.groupTotalRecordsCount
      )
    )
      return;
    else {
      this.reRenderGroupList(nextPage);
      this.setState({ groupCurrentPage: nextPage });
    }
  };

  //Assign member function for Staff

  handleStaffChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      this.getStaffList();
    });
  };

  //Assign member checkbox function
  handleStaffCheckbox = (e, idx) => {
    let staffs = [...this.state.staffs];
    let selected = [...this.state.selectedStaff];
    if (idx === "all") {
      staffs.forEach((el) => {
        el.checked = e.target.checked;
      });
      this.setState({ allStaffChecked: false });
    } else {
      staffs[idx].checked = e.target.checked;
    }
    staffs.forEach((el) => {
      if (el.checked === true) {
        selected.push(el);
      }
    });
    if (idx === "all") {
      staffs = [];
    } else {
      staffs.splice(idx, 1);
    }
    selected.forEach((el) => {
      el.assignedRole = "";
      el.groupRoleName = "";
      el.checked = false;
    });
    this.setState({ staffs: staffs, selectedStaff: selected });
  };

  handleStudentCheckbox = (e, idx) => {
    let assignedStudentsList = [...this.state.assignedStudentsList];
    let selected = [...this.state.selectedStudent];
    if (idx === "all") {
      assignedStudentsList.forEach((el) => {
        el.checked = e.target.checked;
      });
      this.setState({ allStudentChecked: false });
    } else {
      assignedStudentsList[idx].checked = e.target.checked;
    }
    assignedStudentsList.forEach((el) => {
      if (el.checked === true) {
        selected.push(el);
      }
    });
    if (idx === "all") {
      assignedStudentsList = [];
    } else {
      assignedStudentsList.splice(idx, 1);
    }
    selected.forEach((el) => {
      el.assignedRole = "";
      el.groupRoleName = "";
      el.checked = false;
    });
    this.setState({
      assignedStudentsList: assignedStudentsList,
      selectedStudent: selected,
    });
  };

  handleSelectedMemberCheckbox = (e, idx, member) => {
    if (member === "Staff") {
      let selectedStaff = [...this.state.selectedStaff];
      if (idx === "all") {
        selectedStaff.forEach((el) => {
          el.checked = e.target.checked;
        });
      } else {
        selectedStaff[idx].checked = e.target.checked;
      }
      this.setState({
        selectedStaff: selectedStaff,
      });
    } else {
      let selectedStudent = [...this.state.selectedStudent];
      if (idx === "all") {
        selectedStudent.forEach((el) => {
          el.checked = e.target.checked;
        });
      } else {
        selectedStudent[idx].checked = e.target.checked;
      }
      this.setState({
        selectedStudent: selectedStudent,
      });
    }
  };

  //Assign Member removal function
  removeSelectedStaff = (e, idx) => {
    e.preventDefault();
    let selectedStaff = [...this.state.selectedStaff];
    selectedStaff.splice(idx, 1);
    this.setState({ selectedStaff: selectedStaff }, function () {
      this.getStaffList();
    });
  };

  removeSelectedStudent = (e, idx) => {
    e.preventDefault();
    let selectedStudent = [...this.state.selectedStudent];
    selectedStudent.splice(idx, 1);
    this.setState({ selectedStudent: selectedStudent }, function () {
      this.getAssignedStudents();
    });
  };

  //handleChange function

  handleSelectChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  //Assign Member Functions
  assignRole = (e) => {
    e.preventDefault();
    if (this.state.selectedGroupRole === "") {
      swal({
        title: "Warning",
        text: "Choose role first",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let groupRoles = this.state.selectedGroupRole.split(",");
      let selectedStaff = [...this.state.selectedStaff];
      let selectedStudent = [...this.state.selectedStudent];
      let checkCount = 0;
      if (selectedStudent.length > 0 || selectedStaff.length > 0) {
        selectedStudent.forEach((el) => {
          if (el.checked === true) {
            checkCount++;
          }
        });
        selectedStaff.forEach((el) => {
          if (el.checked === true) {
            checkCount++;
          }
        });
        if (checkCount === 0) {
          swal({
            title: "Warning",
            text: "No members checked",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          return false;
        }
      } else {
        if (selectedStaff.length === 0 && selectedStudent.length === 0) {
          swal({
            title: "Warning",
            text: "No members selected. Select members first",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          return false;
        }
      }
      selectedStaff.forEach((el) => {
        if (el.checked === true) {
          el.assignedRole = groupRoles[0];
          el.groupRoleName = groupRoles[1];
          el.checked = false;
        }
      });
      selectedStudent.forEach((el) => {
        if (el.checked === true) {
          el.assignedRole = groupRoles[0];
          el.groupRoleName = groupRoles[1];
          el.checked = false;
        }
      });
      this.setState({
        selectedStaff: selectedStaff,
        selectedStudent: selectedStudent,
        selectedGroupRole: "",
      });
    }
  };

  assignMembertoGroup = (e) => {
    e.preventDefault();

    let data = [];
    let selectedStaff = [...this.state.selectedStaff];
    let selectedStudent = [...this.state.selectedStudent];
    if (selectedStaff.length === 0 && selectedStudent.length === 0) {
      swal({
        title: "Warning",
        text: "No members selected to assign",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let studentRoleCount = 0;
      let staffRoleCount = 0;
      if (selectedStudent.length > 0) {
        selectedStudent.forEach((el) => {
          if (el.assignedRole !== "") {
            studentRoleCount++;
          }
        });
      }
      if (selectedStaff.length > 0) {
        selectedStaff.forEach((el) => {
          if (el.assignedRole !== "") {
            staffRoleCount++;
          }
        });
      }
      if (
        studentRoleCount !== selectedStudent.length ||
        staffRoleCount !== selectedStaff.length
      ) {
        swal({
          title: "Warning",
          text: "Assign role for all the members first",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        selectedStaff.forEach((el) => {
          data.push({
            groupId: this.state.groupId,
            groupRoleCode: el.assignedRole,
            peopleId: el.people.id,
          });
        });
        selectedStudent.forEach((el) => {
          data.push({
            groupId: this.state.groupId,
            groupRoleCode: el.assignedRole,
            peopleId: el.people.id,
          });
        });
        this.setState({ showSpinner: true }, function () {
          axiosPost(
            URL.assignMembertoGroup,
            data,
            (response) => {
              if (response.status === 200) {
                swal({
                  title: "Success",
                  text: "Members assigned to the group",
                  allowOutsideClick: false,
                  closeOnClickOutside: false,
                });
                draggable();
                this.setState({
                  selectedStaff: [],
                  selectedStudent: [],
                  membersTitle: "",
                  selectedGroupRole: "",
                  selectedYearLevel: "",
                  selectYearLevel: "",
                  selectClassroom: "",
                  staffTypeId: "",
                  showSpinner: false,
                });
                this.toggleModal();
              }
            },
            (error) => {
              this.setState({ showSpinner: false });
              let errorResponse = error.response ? error.response.data : error;
              if (errorResponse.status === 400) {
                //if condition to check spring boot validation errors
                let errorMessage = "";
                if (errorResponse.errors) {
                  errorResponse.errors.forEach((error) => {
                    errorMessage += `${error.field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })} ${error.defaultMessage} \n`; //ishan
                  });
                  swal(errorResponse.error, errorMessage, "");
                } else {
                  swal(errorResponse.error, errorResponse.message, "");
                }
              } else {
                swal(
                  errorResponse.error || "Network Error",
                  errorResponse.message
                    ? errorResponse.message
                    : "Could Not Connect To The Server.",
                  ""
                );
              }
            }
          );
        });
      }
    }
  };

  //Edit member function

  editGroupRoleField = (e, idx) => {
    let groupDetail = { ...this.state.groupDetail };
    groupDetail.groupPeople.forEach((el) => {
      el.editable = false;
    });
    groupDetail.groupPeople[idx].editable = true;
    this.setState({ groupDetail });
  };

  cancelEditGroupRoleField = () => {
    let groupDetail = { ...this.state.groupDetail };
    groupDetail.groupPeople.forEach((el) => {
      el.editable = false;
    });
    this.setState({ groupDetail });
  };

  updateGroupRole = (e, id) => {
    e.preventDefault();
    let groupRoles = this.state.selectedGroupRole.split(",");
    let data = {
      groupId: this.state.groupId,
      peopleId: id,
      groupRoleCode: groupRoles[0],
    };
    axiosPost(URL.updateGroupRole, data, (response) => {
      if (response.status === 200) {
        this.getGroupMemberList();
      }
    });
  };

  deleteGroupMember = (e, id) => {
    e.preventDefault();
    let data = {
      groupId: this.state.groupId,
      peopleId: id,
    };
    displayConfirmDeleteAlert(data, this.deleteMember);
  };

  deleteMember = (data) => {
    axiosPost(URL.deleteGroupMember, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully Deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getGroupMemberList();
      }
    });
  };

  //Modal Function
  closeModal = () => {
    this.setState({
      modal: false,
      selectedStaff: [],
      selectedStudent: [],
      membersTitle: "",
      selectedGroupRole: "",
      selectedYearLevel: "",
      selectYearLevel: "",
      selectClassroom: "",
      staffTypeId: "",
    });
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
      selectedStaff: [],
      selectedStudent: [],
      membersTitle: "",
      selectedGroupRole: "",
      selectedYearLevel: "",
      selectYearLevel: "",
      selectClassroom: "",
      staffTypeId: "",
    });
  };

  toggleEditModal = (e) => {
    e.preventDefault();
    this.setState({ editModal: !this.state.editModal, selectedGroupRole: "" });
  };

  //Modal Bodies

  groupMembersConfig = () => {
    return (
      <GroupMembersConfig
        membersTitle={this.state.membersTitle}
        handleStaffCheckbox={this.handleStaffCheckbox}
        handleStudentCheckbox={this.handleStudentCheckbox}
        handleSelectChange={this.handleSelectChange}
        staffs={this.state.staffs}
        staffType={this.state.staffType}
        staffTypeId={this.state.staffTypeId}
        handleStaffChange={this.handleStaffChange}
        yearLevels={this.state.yearLevels}
        handleYearLevelChange={this.handleYearLevelChange}
        selectYearLevel={this.state.selectYearLevel}
        selectClassroom={this.state.selectClassroom}
        classroomList={this.state.classroomList}
        handleClassroomChange={this.handleClassroomChange}
        assignedStudentsList={this.state.assignedStudentsList}
        selectedStaff={this.state.selectedStaff}
        selectedStudent={this.state.selectedStudent}
        removeSelectedStaff={this.removeSelectedStaff}
        removeSelectedStudent={this.removeSelectedStudent}
        handleSelectedMemberCheckbox={this.handleSelectedMemberCheckbox}
        assignRole={this.assignRole}
        groupRoles={this.state.groupRoles}
        allStaffChecked={this.state.allStaffChecked}
        allStudentChecked={this.state.allStudentChecked}
        selectedGroupRole={this.state.selectedGroupRole}
        showSpinner={this.state.showSpinner}
      />
    );
  };

  groupMembersFooter = () => {
    return (
      <div>
        <button
          className="tt-button tt-button-primary mr-3"
          onClick={this.assignMembertoGroup}
        >
          Assign
        </button>
        <button
          className="tt-button tt-button-primary"
          onClick={this.closeModal}
        >
          Cancel
        </button>
      </div>
    );
  };

  groupEditMemberConfig = () => {
    return (
      <GroupEditMember
        groupDetail={this.state.groupDetail}
        selectedGroupRole={this.state.selectedGroupRole}
        handleSelectChange={this.handleSelectChange}
        groupRoles={this.state.groupRoles}
        editGroupRoleField={this.editGroupRoleField}
        cancelEditGroupRoleField={this.cancelEditGroupRoleField}
        updateGroupRole={this.updateGroupRole}
        deleteGroupMember={this.deleteGroupMember}
        editMemberGroupSpinner={this.state.editMemberGroupSpinner}
        totalRecordsCount={this.state.totalRecordsCount}
        currentPage={this.state.currentPage}
        rowCountLimit={this.state.rowCountLimit}
        handlePageChange={this.handlePageChange}
        handlePreviousPage={this.handlePreviousPage}
        handleNextPage={this.handleNextPage}
      ></GroupEditMember>
    );
  };

  groupEditMemberFooter = () => {
    return (
      <button
        className="tt-button tt-button-primary"
        onClick={this.toggleEditModal}
      >
        Close
      </button>
    );
  };

  render() {
    const editErrors = this.props.editErrors;
    return (
      <div
        className={
          this.props.members ? "tt-widgetContent-tab-holder conHeight" : ""
        }
      >
        <div className="tt-group-header">
          Add Group Members
          {checkWidgetAdminOrNot("Academic Administration") === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div
          className={this.props.members ? "tt-newListBox" : "tt-smallListBox"}
        >
          <table className="table table-bordered text-center table-striped">
            <thead className="tt-group-header">
              <tr>
                <th width="20%">Group Name</th>
                <th width="35%">Description</th>
                <th>Sharing Option</th>
                {(this.state.permissionEditGroupList && !this.props.members) ||
                (this.props.members &&
                  (this.state.permissionInsertGroupMembers ||
                    this.state.permissionEditGroupMembers)) ? (
                  <th>Options</th>
                ) : null}
              </tr>
            </thead>
            {this.props.members ? (
              <tbody>
                {this.state.groupLists.length > 0 ? (
                  this.state.groupLists.map((groups, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <Link
                            to={{
                              pathname: `/groupPage/${groups.name.replace(
                                / /g,
                                "_"
                              )}`,
                              state: {
                                groupData: groups,
                              },
                            }}
                          >
                            {groups.name}
                          </Link>
                        </td>
                        <td className="text-left">{groups.description}</td>
                        <td>{groups.isPublic ? "Public" : "Private"}</td>
                        {this.state.permissionInsertGroupMembers ||
                        this.state.permissionEditGroupMembers ? (
                          <td>
                            {this.state.permissionInsertGroupMembers ? (
                              <button
                                className="tt-button tt-button-primary mr-3"
                                onClick={(e) => {
                                  this.toggleModal();
                                  this.setGroupId(e, groups.id);
                                }}
                              >
                                Assign members
                              </button>
                            ) : null}
                            {this.state.permissionEditGroupMembers ? (
                              <button
                                className="tt-button tt-button-primary"
                                onClick={(e) => {
                                  this.toggleEditModal(e);
                                  this.setGroupId(e, groups.id, "open");
                                }}
                              >
                                Edit members
                              </button>
                            ) : null}
                          </td>
                        ) : null}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} className="mt-3 mb-3">
                      {this.state.groupSpinner ? (
                        <>
                          <Spinner color="primary" />
                          <br />
                          Loading Groups ...
                        </>
                      ) : (
                        "No Groups"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                {this.props.groupListsDisplay ? (
                  this.props.groupListsDisplay.map((groups, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {groups.editable ? (
                            <input
                              type="text"
                              name="editGroupName"
                              className={
                                editErrors.editGroupName
                                  ? "form-control indicate-error"
                                  : "form-control"
                              }
                              value={this.props.editGroupName}
                              onChange={this.props.handleSelectChange}
                            ></input>
                          ) : (
                            <Link
                              to={{
                                pathname: `/groupPage/${groups.name.replace(
                                  / /g,
                                  "_"
                                )}`,
                              }}
                            >
                              {groups.name}
                            </Link>
                          )}
                        </td>
                        <td className="text-left">
                          {groups.editable ? (
                            <textarea
                              placeholder="Write Something"
                              className={
                                editErrors.editDescription
                                  ? "form-control indicate-error"
                                  : "form-control"
                              }
                              name="editDescription"
                              value={this.props.editDescription}
                              onChange={this.props.handleSelectChange}
                            />
                          ) : (
                            groups.description
                          )}
                        </td>
                        <td>
                          {groups.editable ? (
                            <div
                              className={
                                editErrors.editIsPublic ? "indicate-error" : ""
                              }
                            >
                              <select
                                name="editIsPublic"
                                value={this.props.editIsPublic}
                                className="form-control"
                                onChange={this.props.handleSelectChange}
                              >
                                <option value="" disabled>
                                  Chosoe public or private
                                </option>
                                <option value={1}>Public</option>
                                <option value={0}>Private</option>
                              </select>
                            </div>
                          ) : groups.isPublic ? (
                            "Public"
                          ) : (
                            "Private"
                          )}
                        </td>
                        {this.state.permissionEditGroupList ? (
                          <td>
                            {groups.editable ? (
                              <>
                                <button
                                  className="tt-button tt-button-primary"
                                  onClick={(e) =>
                                    this.props.updateGroup(e, groups.id)
                                  }
                                >
                                  Save
                                </button>
                                <button
                                  className="tt-button tt-button-primary"
                                  onClick={this.props.cancelEditGroup}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="tt-button tt-button-primary mr-3"
                                  onClick={(e) =>
                                    this.props.editGroup(e, idx, groups)
                                  }
                                >
                                  Edit
                                </button>

                                <button
                                  className="tt-button tt-button-primary"
                                  onClick={(e) =>
                                    this.props.deleteGroup(e, idx, groups)
                                  }
                                >
                                  Delete
                                </button>
                              </>
                            )}
                          </td>
                        ) : null}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} className="mt-3 mb-3">
                      {this.state.groupSpinner ? (
                        <>
                          <Spinner color="primary" />
                          <br />
                          Loading Groups ...
                        </>
                      ) : (
                        "No Groups"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
          {this.props.createGroup ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                itemsCount={this.props.groupTotalRecordsCount}
                pageSize={this.props.groupRowCountLimit}
                currentPage={this.props.groupCurrentPage}
                onPageChange={this.props.groupHandlePageChange}
                nextPage={this.props.groupHandleNextPage}
                previousPage={this.props.groupHandlePreviousPage}
              ></Pagination>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                itemsCount={this.state.groupTotalRecordsCount}
                pageSize={this.state.groupRowCountLimit}
                currentPage={this.state.groupCurrentPage}
                onPageChange={this.groupHandlePageChange}
                nextPage={this.groupHandleNextPage}
                previousPage={this.groupHandlePreviousPage}
              ></Pagination>
            </div>
          )}
        </div>
        <ModalWindow
          fullWidth={true}
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Assign Members to this group"
          modalBody={this.groupMembersConfig()}
          modalFooter={this.groupMembersFooter()}
        ></ModalWindow>
        <ModalWindow
          fullWidth={true}
          modal={this.state.editModal}
          toggleModal={this.toggleEditModal}
          modalHeader="Group Members"
          modalBody={this.groupEditMemberConfig()}
          modalFooter={this.groupEditMemberFooter()}
        ></ModalWindow>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School Groupings"
              header="Add Group Members"
              activityName="insert-group-members"
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  groupLists: state.academicAdministration.aaGroupLists,
  groupSpinner: state.academicAdministration.aaGroupSpinner,
  groupTotalRecordsCount: state.academicAdministration.aaGroupTotal,
});

const mapActionToProps = {
  getGroupsAA,
};

export default connect(mapStateToProps, mapActionToProps)(GroupLists);

import React, { useEffect, useState } from "react";

const AuthorizedPage = () => {
  const [test, setTest] = useState();

  useEffect(() => {
      // window.addEventListener("message", ev => {
      //   console.log(ev);
      // });
      function receiveMessage (event) {
        console.log(event);
      
    }
      window.addEventListener("message",receiveMessage, true);
    
  }, []);

  return (
    <>
      <h1>Login SuccessFully</h1>
    </>
  );
};
export default AuthorizedPage;

import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import "../../../assets/scss/calendarWidget.scss";
import ModalWindow from "../../UI/ModalWindow";
import CalendarForm from "./CalendarForm";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  URL,
  draggable,
  userRole,
  userRoleCode,
} from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  displayMessage,
} from "../../../utils/Utils";
import swal from "sweetalert";
import CalendarWidgetHeader from "./CalendarWidgetHeader";
import { connect } from "react-redux";
import { getCalendarEvents } from "../../../ducks/AcademicAdministrationDucks";
import {
  checkRolePermissions,
  checkForSubRoles,
} from "../../../utils/Constants";

class CalendarWidget extends Component {
  calendarComponentRef = React.createRef();
  state = {
    modal: false,
    calendarTitle: "",
    startDate: "",
    endDate: "",
    description: "",
    action: "add",
    events: [],
    eventInfo: "",
    updateId: "",
    checked: false,
    error: "",
    //educationLevelId: "0",
    educationLevels: [],
    calendarDates: [],
    dates: [],
    educationLevelIdMultiple: "0",
    terms: [],
    termId: "0",
    eduLevelTitle: "All",
    termTitle: `All ${
      localStorage.getItem("educationLevelDivision")
        ? localStorage.getItem("educationLevelDivision")
        : "Terms"
    }`,
    selectedItems: null,
    widgetEvents: [],
    currentAcYrId: "",
    currentAcYr: "",
    currentAcYrName: "",
    assessmentModules: [],
    eventNameError: false,
    holidaysOption: [],
    selectHolidayOption: [],
    colorCode: "",
    isHoliday: "",
    selectedEvent: "",
    eventType: "",
  };

  componentDidMount() {
    this.getEducationLevel();
    // this.getAllDates();
    this.getWidgetEvents();
    axiosPost(
      URL.getAssesmentModuleAndEducationLevelForCurrentUser,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({
            assessmentModuleandEducationLevel: response.data.data,
          });
        }
      }
    );
    this.getUserCurrentAcademicYear();
    this.getAssessmentModules();
    this.getHolidays();
  }

  //calender Events
  getHolidays = () => {
    axiosPost(URL.getHolidayOptions, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            holidaysOption: data,
          });
        const selectOptions = this.state.holidaysOption.map((el) => {
          return {
            value: el.id,
            label: el.eventTypeName,
            color: el.colorCode,
          };
        });
        this.setState({ selectHolidayOption: selectOptions });
      }
    });
  };

  getAssessmentModules = () => {
    axiosPost(URL.getAssesmentModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ assessmentModules: response.data.data });
      }
    });
  };

  getUserCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState(
            {
              currentAcYr: response.data.data,
              currentAcYrId: response.data.data.academicYearId,
              currentAcYrName: response.data.data.academicYearName,
            },
            function () {
              this.getAcYrDate();
            }
          );
        } else {
          this.getCurrentAcademicYear();
        }
      }
    });
  };

  getCurrentAcademicYear = () => {
    let data = {
      isCurrent: "true",
    };

    axiosPost(URL.getAcademicYears, data, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            currentAcYr: response.data.data[0],
            currentAcYrId: response.data.data[0].id,
            currentAcYrName: response.data.data[0].name,
          },
          function () {
            // this.getAcYrDate();
          }
        );
      }
    });
  };

  getWidgetEvents = () => {
    axiosPost(URL.getEvents, {}, async (responseWidgets) => {
      if (responseWidgets.status === 200) {
        axiosPost(URL.getWeekDaysByEduDiv, {}, (response) => {
          if (response.status === 200) {
            const weekDays = [0, 1, 2, 3, 4, 5, 6];
            const days = response.data.data.map(({ id }) => id);
            let filteredDays = weekDays.filter((el) => !days.includes(el));
            let anEvent = [];
            anEvent.push({
              daysOfWeek: filteredDays,
              rendering: "background",
              color: `#668ef5`,
              overLap: false,
              allDay: true,
            });

            let newEvents = [...responseWidgets.data.data];
            newEvents.forEach((event) => {
              event.end = new Date(event.end).toISOString();
            });
            this.setState(
              {
                widgetEvents: [...anEvent, ...newEvents],
              },
              () => {
                const events = [...this.state.widgetEvents];
                const unique = [];
                events.map((x) =>
                  unique.filter(
                    (a) =>
                      a.color == x.color &&
                      a.title == x.title &&
                      a.start == x.start &&
                      a.end == x.end
                  ).length > 0
                    ? null
                    : unique.push(x)
                );
                this.setState({ uniqueCalendarEvents: unique });
              }
            );
          }
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild();
  }

  //Axios Get request to get values from the database
  // getAllDates = () => {
  //   let newDates = [...this.state.dates];
  //   for (let i = 1; i <= 12; i++) {
  //     if (i < 10) {
  //       newDates.push("0" + i.toString());
  //     } else {
  //       newDates.push(i.toString());
  //     }
  //   }
  //   this.setState({ dates: newDates });
  // };

  getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ educationLevels: response.data.data });
        let selectedItems = [...response.data.data];
        selectedItems.forEach((level) => {
          level.label = level.name;
          level.value = level.id;
        });
        this.setState({ selectedItems });
      }
    });
  };

  getData = (color, isHoliday, eventTypeName, educationLevelId) => {
    let param = [];
    if (this.state.action === "add") {
      this.state.selectedItems.forEach((item) => {
        param.push({
          title: this.state.calendarTitle,
          start: moment(this.state.startDate).format("YYYY-MM-DD"),
          end: moment(this.state.endDate).format("YYYY-MM-DD"),
          educationLevelId:
            parseInt(this.state.educationLevelIdMultiple) === 0
              ? item.id
              : parseInt(this.state.educationLevelIdMultiple),
          description: this.state.description
            ? this.state.description
            : "no description",
          color: color,
          isHoliday: isHoliday,
          eventTypeName: eventTypeName,
        });
      });
    } else {
      param = {
        title: this.state.calendarTitle,
        start: moment(this.state.startDate).format("YYYY-MM-DD"),
        end: moment(this.state.endDate).format("YYYY-MM-DD"),
        educationLevelId: parseInt(this.state.educationLevelId),
        description: this.state.description
          ? this.state.description
          : "no description",
        color: color,
        isHoliday: isHoliday,
        eventTypeName: eventTypeName,
      };
    }
    return param;
  };

  //handle Change Functions
  handleDateClick = (arg) => {
    this.setState({
      action: "add",
      modal: !this.state.modal,
      startDate: arg.dateStr,
      endDate: arg.dateStr,
    });
  };

  handleChangeEvent = (e) => {
    const holidaysOptionCopy = [...this.state.holidaysOption];
    let reactSelectEvent = e;
    if (e !== null) {
      this.setState({ selectedEvent: reactSelectEvent });
      var selectedVal = [{ ...e }];
      var selectedColor = selectedVal.map((opt) => opt.color);

      holidaysOptionCopy.forEach((el, id) => {
        if (el.colorCode === selectedColor[0]) {
          this.setState({
            colorCode: el.colorCode,
            isHoliday: el.isHoliday,
            eventType: el.eventTypeName,
          });
        }
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "calenderTitle" && value.length > 0) {
      this.setState({ [name]: value });
    }
    name === "calendarTitle" &&
      value.length > 0 &&
      this.setState({ eventNameError: false });

    this.setState({ [name]: value });
  };

  handleCalendarChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
    // this.setState({ [name]: value }, this.handleCalendarDate());
  };

  handleEducationLevelChange = (items) => {
    this.setState({ selectedItems: items });
  };

  handleEventChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "educationLevelIdMultiple") {
      if (value === "0") {
        let selectedItems = [...this.state.educationLevels];
        selectedItems.forEach((level) => {
          level.label = level.name;
          level.value = level.id;
        });
        this.setState({ selectedItems });
      } else {
        const index = this.state.educationLevels.findIndex(
          (level) => level.id === parseInt(value)
        );
        if (index !== -1) {
          const selectedItems = [];
          selectedItems.push({
            label: this.state.educationLevels[index].name,
            value: this.state.educationLevels[index].id,
          });
          this.setState({ selectedItems });
        }
      }
    }
    this.setState(
      {
        [name]: value,
        termId: "0",
        termTitle: `All ${
          localStorage.getItem("educationLevelDivision")
            ? localStorage.getItem("educationLevelDivision")
            : "Terms"
        }`,
      },
      function () {
        this.handleCalendarEventChange();
      }
    );
  };

  //Function Called when all the events are positisioned on their particular dates

  handleEventPositioned = (arg) => {
    arg.el.setAttribute("data-tip", arg.event.extendedProps.description);
    ReactTooltip.rebuild();
  };

  closeModal = (e) => {
    this.setState({
      modal: !this.state.modal,
      calendarTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      checked: false,
      selectedEvent: "",
    });
  };

  calendarFooter = () => {
    return (
      <>
        <button
          className="tt-button tt-button-primary"
          type="submit"
          onClick={
            this.state.action === "add"
              ? this.submitCalendar
              : this.updateCalendar
          }
          disabled={this.state.educationLevels.length > 0 ? false : true}
        >
          {this.state.action === "add" ? "Save Event" : "Update Event"}
        </button>
        <button
          type="reset"
          className="tt-button tt-button-primary"
          onClick={
            this.state.action === "add"
              ? this.closeModal
              : () => {
                  displayConfirmDeleteAlert({}, this.deleteEvent);
                }
          }
        >
          {this.state.action === "add" ? "Cancel Event" : "Delete Event"}
        </button>
      </>
    );
  };

  //Submitting Calendar Event Functions

  handleEventForm = () => {
    return (
      <CalendarForm
        selectHolidayOption={this.state.selectHolidayOption}
        create={true}
        calendarTitle={this.state.calendarTitle}
        handleChangeEvent={this.handleChangeEvent}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        description={this.state.description}
        closeModal={this.closeModal}
        checked={this.state.checked}
        educationLevelId={this.state.educationLevelId}
        educationLevelIdMultiple={this.state.educationLevelIdMultiple}
        educationLevels={this.state.educationLevels}
        handleEducationLevelChange={this.handleEducationLevelChange}
        action={this.state.action}
        showSpinner={this.state.showSpinner}
        eventNameError={this.state.eventNameError}
        handleChange={this.handleChange}
        selectedEvent={this.state.selectedEvent}
        isHoliday={this.state.isHoliday}
        colorCode={this.state.colorCode}
      ></CalendarForm>
    );
  };

  submitCalendar = (e) => {
    if (
      this.state.calendarTitle === "" ||
      this.state.startDate === "" ||
      this.state.endDate === "" ||
      this.state.isHoliday === "" ||
      this.state.colorCode === ""
    ) {
      swal({
        title: "Error",
        text: "Fill all the necessary fields: Event Name, Start Date and End Date ,Event Type.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      return false;
    }
    e.preventDefault();
    let datas = [];
    let color = this.state.colorCode;
    let isHoliday = this.state.isHoliday;
    let eventTypeName = this.state.eventType;
    datas = this.getData(
      color,
      isHoliday,
      eventTypeName,
      this.state.educationLevelId
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.insertEvent,
        datas,
        (response) => {
          if (response.status === 200) {
            displayMessage("Success", "Events added successfully.", () => {
              this.getWidgetEvents();
              this.props.getCalendarEvents(
                this.props.assessmentModuleandEducationLevel
              );
            });
            this.setState({
              modal: !this.state.modal,
              calendarTitle: "",
              startDate: "",
              endDate: "",
              description: "",
              holidayChecked: false,
              educationLevelId: 0,
              showSpinner: false,
              eventNameError: false,
              selectedEvent: "",
            });
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
        }
      );
    });
  };

  //Updating Calendar Functions
  handleEventClick = (arg) => {
    let holidayOptionCopy = [...this.state.holidaysOption];
    let colorCode;
    holidayOptionCopy.forEach((el) => {
      if (el.eventTypeName === arg.event.extendedProps.eventTypeName) {
        colorCode = el.colorCode;
      }
    });

    let selectedOptionAfterUpdate = {};
    Object.assign(selectedOptionAfterUpdate, {
      color: colorCode,
      label: arg.event.extendedProps.eventTypeName,
      value: colorCode,
    });
    let title = arg.event.title;
    let start = moment(arg.event.start).format("YYYY-MM-DD");
    //Uncommented
    let end = moment(arg.event.end).format("YYYY-MM-DD");

    if (end === "Invalid date") {
      end = start;
    }
    //
    let checked;
    if (arg.event.backgroundColor === "#a70707") {
      checked = true;
    } else {
      checked = false;
    }

    let description = arg.event.extendedProps.description;
    let educationLevelId = arg.event.extendedProps.educationLevelId;

    let id = arg.event.id;
    this.setState(
      {
        action: "edit",
        calendarTitle: title,
        startDate: start,
        endDate: end !== "Invalid date" ? end : start, //Uncommented
        description: description,
        updateId: id,
        eventInfo: arg,
        checked: checked,
        educationLevelId: educationLevelId,
        selectedEvent: selectedOptionAfterUpdate,
      },
      function () {
        this.setState({ modal: !this.state.modal });
      }
    );
  };

  updateEventForm = () => {
    return (
      <CalendarForm
        create={false}
        submit={this.updateCalendar}
        closeModal={this.closeModal}
        checked={this.state.checked}
        calendarTitle={this.state.calendarTitle}
        handleChangeEvent={this.handleChangeEvent}
        handleChange={this.handleChange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        description={this.state.description}
        educationLevels={this.state.educationLevels}
        handleEducationLevelChange={this.handleEducationLevelChange}
        action={this.state.action}
        showSpinner={this.state.showSpinner}
        selectHolidayOption={this.state.selectHolidayOption}
        selectedEvent={this.state.selectedEvent}
        colorCode={this.state.colorCode}
        isHoliday={this.state.isHoliday}
      ></CalendarForm>
    );
  };

  updateCalendar = (e) => {
    e.preventDefault();
    let id = parseInt(this.state.updateId);
    let color = this.state.colorCode;
    let isHoliday = this.state.isHoliday;
    let eventTypeName = this.state.eventType;
    let datas = this.getData(
      color,
      isHoliday,
      eventTypeName,
      this.state.educationLevelId
    );
    datas.id = id;
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.updateEvent,
        datas,
        (response) => {
          if (response.status === 200) {
            swal({
              title: "Success",
              text: "Events updated successfully",
              allowOutsideClick: false,
              closeOnClickOutside: false,
            });
            draggable();
            this.props.getCalendarEvents(
              this.props.assessmentModuleandEducationLevel
            );
            this.getWidgetEvents();
            this.setState(
              {
                modal: !this.state.modal,
                calendarTitle: "",
                startDate: "",
                endDate: "",
                description: "",
                updateId: "",
                checked: false,
                educationLevelId: 0,
                showSpinner: false,
                isHoliday: "",
                color: "",
                selectedEvent: "",
              },
              function () {
                ReactTooltip.rebuild();
              }
            );
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          let errorResponse = error.response ? error.response.data : error;
          if (errorResponse.status === 400) {
            //if condition to check spring boot validation errors
            let errorMessage = "";
            if (errorResponse.errors) {
              errorResponse.errors.forEach((error) => {
                errorMessage += `${error.field
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })} ${error.defaultMessage} \n`; //ishan
              });
              swal(errorResponse.error, errorMessage, "");
            } else {
              swal(errorResponse.error, errorResponse.message, "");
            }
          } else {
            swal(
              errorResponse.error || "Network Error",
              errorResponse.message
                ? errorResponse.message
                : "Could Not Connect To The Server.",
              ""
            );
          }
        }
      );
    });
  };

  //Deleting Calendar Events
  deleteEvent = () => {
    let id = parseInt(this.state.updateId);
    axiosPost(URL.deleteEvent, { id }, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Records deleted successfully",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.setState(
          {
            modal: !this.state.modal,
            educationLevelId: "0",
            events: [],
            calendarTitle: "",
            startDate: "",
            endDate: "",
            description: "",
            holidayChecked: false,
          },
          () => {
            this.getWidgetEvents();
            this.props.getCalendarEvents(
              this.props.assessmentModuleandEducationLevel
            );
          }
        );
      }
    });
  };

  //Render Past Calendar
  gotoPast = () => {
    if (this.state.year.length === 4) {
      this.setState({ error: "" });
      let date = this.state.year + "-01-01";
      let calendarApi = this.calendarComponentRef.current.getApi();
      calendarApi.gotoDate(date); // call a method on the Calendar object
    } else {
      this.setState({ error: "Enter a valid year" });
    }
  };

  //Multiple Calendar
  handleCalendarDate = () => {
    this.setState({ dates: [] }, function () {
      if (this.state.termId === "0") {
        // this.getAllDates();
        // this.getAcYrDate();
        this.setState({
          termTitle: `All ${
            localStorage.getItem("educationLevelDivision")
              ? localStorage.getItem("educationLevelDivision")
              : "Terms"
          }`,
        });
      } else {
        let data = {
          id: this.state.termId,
        };
        axiosPost(URL.getEducationLevelDivisionByKey, data, (response) => {
          if (response.status === 200) {
            this.setState({ calendarDates: response.data.data }, function () {
              let startDates = moment(
                this.state.calendarDates.startDate
              ).format("YYYY-MM-DD");
              let x = startDates.split("-");
              let endDates = moment(this.state.calendarDates.endDate).format(
                "YYYY-MM-DD"
              );
              let y = endDates.split("-");
              let newDates = [...this.state.dates];
              if (
                parseInt(x[0]) < parseInt(y[0]) ||
                parseInt(x[0]) === parseInt(y[0])
              ) {
                if (parseInt(x[0]) === parseInt(y[0])) {
                  if (
                    parseInt(x[1]) < parseInt(y[1]) ||
                    parseInt(x[1]) === parseInt(y[1])
                  ) {
                    for (let i = parseInt(x[1]); i <= parseInt(y[1]); i++) {
                      if (i < 10) {
                        newDates.push(x[0] + "-0" + i.toString() + "-01");
                      } else {
                        newDates.push(x[0] + "-" + i.toString() + "-01");
                      }
                    }
                  }
                } else {
                  for (let i = parseInt(x[0]); i <= parseInt(y[0]); i++) {
                    let mx, my;
                    if (i === parseInt(x[0])) {
                      mx = parseInt(x[1]);
                      my = 12;
                    } else if (i === parseInt(y[0])) {
                      mx = 1;
                      my = parseInt(y[1]);
                    } else {
                      mx = 1;
                      my = 12;
                    }
                    for (let j = mx; j <= my; j++) {
                      if (j < 10) {
                        newDates.push(
                          i.toString() + "-0" + j.toString() + "-01"
                        );
                      } else {
                        newDates.push(
                          i.toString() + "-" + j.toString() + "-01"
                        );
                      }
                    }
                  }
                }
              }
              this.setState({
                dates: newDates,
                termTitle: this.state.calendarDates.name,
              });
              ReactTooltip.rebuild();
            });
          }
        });
      }
    });
  };

  // getAcYrDate = () => {
  //   let startDates = moment(this.state.currentAcYr.startDate).format(
  //     "YYYY-MM-DD"
  //   );
  //   let x = startDates.split("-");
  //   let endDates = moment(this.state.currentAcYr.endDate).format("YYYY-MM-DD");
  //   let y = endDates.split("-");
  //   let newDates = [...this.state.dates];
  //   if (parseInt(x[0]) < parseInt(y[0]) || parseInt(x[0]) === parseInt(y[0])) {
  //     if (parseInt(x[0]) === parseInt(y[0])) {
  //       if (
  //         parseInt(x[1]) < parseInt(y[1]) ||
  //         parseInt(x[1]) === parseInt(y[1])
  //       ) {
  //         for (let i = parseInt(x[1]); i <= parseInt(y[1]); i++) {
  //           if (i < 10) {
  //             newDates.push(x[0] + "-0" + i.toString() + "-01");
  //           } else {
  //             newDates.push(x[0] + "-" + i.toString() + "-01");
  //           }
  //         }
  //       }
  //     } else {
  //       for (let i = parseInt(x[0]); i <= parseInt(y[0]); i++) {
  //         let mx, my;
  //         if (i === parseInt(x[0])) {
  //           mx = parseInt(x[1]);
  //           my = 12;
  //         } else if (i === parseInt(y[0])) {
  //           mx = 1;
  //           my = parseInt(y[1]);
  //         } else {
  //           mx = 1;
  //           my = 12;
  //         }
  //         for (let j = mx; j <= my; j++) {
  //           if (j < 10) {
  //             newDates.push(i.toString() + "-0" + j.toString() + "-01");
  //           } else {
  //             newDates.push(i.toString() + "-" + j.toString() + "-01");
  //           }
  //         }
  //       }
  //     }
  //   }
  //   this.setState({
  //     dates: newDates,
  //   });
  //   ReactTooltip.rebuild();
  // };

  handleCalendarEventChange = () => {
    // this.getEvents(this.state.educationLevelIdMultiple);
    if (this.state.educationLevelIdMultiple === "0") {
      this.setState({ eduLevelTitle: "All", terms: [] });
      this.getWidgetEvents();
    } else {
      axiosPost(
        URL.getEducationLevelByKey,
        {
          id: this.state.educationLevelIdMultiple,
        },
        (response) => {
          if (response.status === 200) {
            this.setState({ eduLevelTitle: response.data.data.name });
          }
        }
      );
      this.setState({ widgetEvents: [] }, () => {
        axiosPost(
          URL.getEventsByEducationLevel,
          { educationLevelId: this.state.educationLevelIdMultiple },
          (responseWidgets) => {
            if (responseWidgets.status === 200) {
              axiosPost(
                URL.getWeekDaysByEduDiv,
                { educationLevelId: this.state.educationLevelIdMultiple },
                (response) => {
                  if (response.status === 200) {
                    const weekDays = [0, 1, 2, 3, 4, 5, 6];
                    const days = response.data.data.map(({ id }) => id);
                    let filteredDays = weekDays.filter(
                      (el) => !days.includes(el)
                    );
                    let anEvent = [];
                    anEvent.push({
                      daysOfWeek: filteredDays,
                      rendering: "background",
                      color: `#668ef5`,
                      overLap: false,
                      allDay: true,
                    });
                    let newEvents = [...responseWidgets.data.data];
                    newEvents.forEach((event) => {
                      event.end = new Date(event.end).toISOString();
                    });
                    this.setState({
                      widgetEvents: [...anEvent, ...newEvents],
                    });
                  }
                }
              );
              //   this.setState({ widgetEvents: response.data.data });
            }
          }
        );
      });
      // let data = {
      //   educationLevelId: parseInt(this.state.educationLevelIdMultiple),
      // };
      // axiosPost(
      //   URL.getEducationLevelDivisionsByEducationLevelId,
      //   data,
      //   (response) => {
      //     if (response.status === 200) {
      //       this.setState({ terms: response.data.data });
      //     }
      //   }
      // );
    }
  };

  calendarGetDate = (idx) => {
    let date;
    let year = new Date().getFullYear();

    if (this.state.termId === "0") {
      date = year + "-" + this.state.dates[idx] + "-01";
    } else {
      date = this.state.dates[idx];
    }
    return date;
  };

  // openDate = (e, idx) => {
  //   e.preventDefault();
  //   let calendarApi = this.calendarComponentRef.current.getApi();
  //   let date;
  //   date = this.state.dates[idx];
  //   calendarApi.gotoDate(date);
  //   ReactTooltip.rebuild();
  // };

  checkForAdministratorRole = () => {
    let canInsert = false;
    if (userRole === userRoleCode.roleStaff) {
      if (this.state.educationLevelIdMultiple === "0") {
        canInsert = false;
      } else {
        const educationLevelIndex = this.state.educationLevels.findIndex(
          (level) => level.id === parseInt(this.state.educationLevelIdMultiple)
        );
        if (educationLevelIndex !== -1) {
          const assessmentModuleCode =
            this.state.educationLevels[educationLevelIndex]
              .assessmentModuleCode;
          this.state.assessmentModules.forEach((assessmentModule) => {
            if (
              assessmentModuleCode === assessmentModule.code &&
              checkForSubRoles(`${assessmentModule.code}_ADMTR`)
            )
              canInsert = true;
          });
        }
      }
    }
    return canInsert;
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <CalendarWidgetHeader
          educationLevelIdMultiple={this.state.educationLevelIdMultiple}
          handleCalendarChange={this.handleCalendarChange}
          educationLevels={this.state.educationLevels}
          handleEventChange={this.handleEventChange}
          terms={this.state.terms}
          termId={this.state.termId}
          eduLevelTitle={this.state.eduLevelTitle}
          termTitle={this.state.termTitle}
          assessmentModules={this.state.assessmentModules}
        ></CalendarWidgetHeader>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1"></div>
            <div
              className="col tt-adjustPadding tt-widgetContent-tab-holder"
              style={{ height: "auto" }}
            >
              <FullCalendar
                dateClick={
                  checkRolePermissions("insert-calendar-event", "activity") ||
                  this.checkForAdministratorRole()
                    ? this.handleDateClick
                    : null
                }
                ref={this.calendarComponentRef}
                eventLimit={1}
                header={{
                  left: "prev",
                  center: "title",
                  right: "next",
                }}
                defaultView="dayGridMonth"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                weekends={true}
                fullDay={true}
                displayEventTime={false}
                events={this.state.uniqueCalendarEvents || []}
                eventPositioned={this.handleEventPositioned}
                eventClick={this.handleEventClick}
                eventRender={this.renderEvents}
                validRange={{
                  start: this.state.currentAcYr.startDate,
                  end: this.state.currentAcYr.endDate,
                }}
              />
            </div>
            <div className="col-md-1"></div>

            {/* <div className="col-md-6 tt-adjustPadding">
              <div className="tt-displayDate">
                {this.state.dates.map((date, idx) => {
                  return (
                    <div
                      className="tt-calendarHolder tt-widgetContent-tab-holder"
                      onClick={(e) => this.openDate(e, idx)}
                      key={idx}
                    >
                      <FullCalendar
                        defaultDate={date}
                        header={{ left: "", center: "title", right: "" }}
                        defaultView="dayGridMonth"
                        plugins={[dayGridPlugin]}
                        weekends={true}
                        columnHeader={false}
                      />
                    </div>
                  );
                })}
              </div>
            
            </div> */}
          </div>
        </div>

        <ModalWindow
          fullWidth={false}
          modal={this.state.modal}
          toggleModal={this.closeModal}
          modalHeader={
            this.state.action === "add"
              ? "Set Calendar Events"
              : "Update Calendar Events"
          }
          modalBody={
            this.state.action === "add"
              ? this.handleEventForm()
              : this.updateEventForm()
          }
          modalFooter={this.calendarFooter()}
        ></ModalWindow>
        <ReactTooltip globalEventOff="click"></ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  calendarEvents: state.academicAdministration.calendarEvents,
});
const mapActionToProps = {
  getCalendarEvents,
};
export default connect(mapStateToProps, mapActionToProps)(CalendarWidget);

import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../utils/Constants";
import QuestionAnswersDetails from "./QuestionAnswersDetails";
import ModalWindow from "../UI/ModalWindow";
import WorksheetHeader from "./WorksheetHeader";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import Pagination from "../UI/Pagination";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

class WorksheetTemplate extends Component {
  state = {
    toogleModal: false,
    selectedQA: null,
    questionAnswers: [],
    isLoading: true,
    pageSize: 5,
    startIndex: 0,
    endIndex: 20,
    offset: 0,
    currentPage: 1,
    totalRecords: 0,
    workSheetSearch: false,
    disablePagination: false,
  };

  componentDidMount() {
    this.workSheetHeaderTemplate = React.createRef();
    axiosPost(
      URL.getWorksheet,
      { offset: this.state.offset, rowCountLimit: this.state.pageSize },
      (response) => {
        if (response.status === 200) {
          this.setState({
            questionAnswers: response.data.data,
            isLoading: false,
            totalRecords: response.data.totalRecordsCount,
          });
        }
      }
    );
  }

  toogleModal = (qA) => {
    this.setState({ toogleModal: !this.state.toogleModal, selectedQA: qA });
  };
  handleWorkSheetSearch = () => {
    //pass the payload to the API  to filter the list
    const workSheetHeaderData = this.workSheetHeaderTemplate.current;
    console.log(workSheetHeaderData.state);
    if (workSheetHeaderData === null) {
      swal({
        title: "Error",
        text: "Field is required to perfom search operation ",
      });
      return;
    }
    if (
      workSheetHeaderData.state.date === "" &&
      workSheetHeaderData.state.subject === "" &&
      workSheetHeaderData.state.title === "" &&
      workSheetHeaderData.state.yearLevel === ""
    ) {
      swal({
        title: "Error",
        text: "Field is required to perform  search operation ",
      });
      return;
    } else {
      this.setState({ workSheetSearch: true });
    }

    let param = {
      submitDate: workSheetHeaderData.state.date,
      subjectId: workSheetHeaderData.state.subject,
      title: workSheetHeaderData.state.title,
      yearLevelId: workSheetHeaderData.state.yearLevel,
    };

    axiosPost(URL.getWorksheet, param, (response) => {
      if (response.status === 200) {
        if (response.data.totalRecordsCount > 5) {
          this.setState({ disablePagination: false });
          this.reRenderAdmissionList(this.state.currentPage);
        } else {
          this.setState({
            questionAnswers: response.data.data,
            disablePagination: true,
          });
        }
      }
    });
  };
  loadSpinner = () => {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="text-center" style={{ marginTop: "50px" }}>
            <Spinner color="primary" />
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  handlePageChange = (page) => {
    this.reRenderAdmissionList(page);
    this.setState({ currentPage: page }, function () {
      this.setDisplayNumber();
    });
  };

  reRenderAdmissionList = (page) => {
    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);
    this.setState({ displaySpinner: true, offset: offset }, function () {
      //if user's click the search button to filter the worksheet list
      const workSheetHeaderData = this.workSheetHeaderTemplate.current;
      if (
        workSheetHeaderData.state.date !== "" ||
        workSheetHeaderData.state.subject !== "" ||
        workSheetHeaderData.state.title !== "" ||
        workSheetHeaderData.state.yearLevel !== ""
      ) {
        let data = {
          submitDate: workSheetHeaderData.state.date,
          subjectId: workSheetHeaderData.state.subject,
          title: workSheetHeaderData.state.title,
          yearLevelId: workSheetHeaderData.state.yearLevel,
        };

        data.offset = this.state.offset;
        data.rowCountLimit = this.state.pageSize;
        this.searchAPIPagination(data);
      } else {
        //call the API making changes in offset and rowCOuntLimit value
        this.worksheetListUpdated();
      }
    });
  };
  searchAPIPagination = (data) => {
    axiosPost(URL.getWorksheet, data, (response) => {
      if (response.status === 200) {
        let totalRecords = response.data.totalRecordsCount;
        this.setState(
          { questionAnswers: response.data.data, totalRecords },
          () => {
            this.setState(
              {
                displaySpinner: false,
              },
              function () {
                this.setDisplayNumber();
              }
            );
          }
        );
      }
    });
  };
  worksheetListUpdated = () => {
    this.setState({ questionAnswers: "" });
    axiosPost(
      URL.getWorksheet,
      { offset: this.state.offset, rowCountLimit: this.state.pageSize },
      (response) => {
        if (response.status === 200) {
          this.setState({
            questionAnswers: response.data.data,
            isLoading: false,
            totalRecords: response.data.totalRecordsCount,
          });
        }
      }
    );
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1)
      this.reRenderAdmissionList(this.state.currentPage - 1);
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage - 1,
      }),
      function () {
        this.setDisplayNumber();
      }
    );
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
      return;
    else {
      this.reRenderAdmissionList(nextPage);
      this.setState({ currentPage: nextPage }, function () {
        this.setDisplayNumber();
      });
    }
  };

  setDisplayNumber = () => {
    var pageNumber = this.state.currentPage,
      pageSize = this.state.pageSize;
    var startIndex = (pageNumber - 1) * pageSize + 1;
    var offValue = pageSize - 1;
    var endIndex = parseInt(startIndex) + parseInt(offValue);
    if (endIndex > this.state.totalRecords) {
      endIndex = this.state.totalRecords;
    }
    this.setState({
      startIndex: startIndex,
      endIndex: endIndex,
    });
  };
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">
          List Worksheets
          {checkWidgetAdminOrNot("WorkSheets") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <WorksheetHeader
          ref={this.workSheetHeaderTemplate}
          value={true}
          handleWorkSheetSearch={this.handleWorkSheetSearch}
        />

        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            marginTop: "4px",
          }}
        >
          <div className="row">
            <div className="col">
              {this.loadSpinner()}
              {this.state.questionAnswers.length > 0 ? (
                this.state.questionAnswers.map((qA, idx) => {
                  return (
                    <>
                      <div key={idx} className={idx === 0 ? "" : " mt-3"}>
                        <div
                          className="row tt-group-header"
                          style={{ borderRadius: 0, textShadow: "none" }}
                        >
                          <div className="col">
                            {idx + 1}. {qA.title}
                          </div>
                          <div className="col text-right">
                            <button
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: 0,
                              }}
                              className="tt-button tt-button-primary"
                              onClick={(e) => this.toogleModal(qA)}
                            >
                              {" "}
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div
                  style={{ justifyContent: "center", justifyItems: "center" }}
                  className="text-center"
                >
                  No Data to Display
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
          }}
        >
          {this.state.disablePagination ? null : (
            <Pagination
              itemsCount={this.state.totalRecords}
              pageSize={this.state.pageSize}
              currentPage={this.state.currentPage}
              onPageChange={this.handlePageChange}
              previousPage={this.handlePreviousPage}
              nextPage={this.handleNextPage}
            />
          )}
        </div>

        <ModalWindow
          modal={this.state?.toogleModal}
          toggleModal={this.toogleModal}
          modalHeader={"WorkSheets"}
          fullWidth={true}
          modalBody={
            <QuestionAnswersDetails
              selectedQA={this.state?.selectedQA}
              details={true}
            />
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="WorkSheets"
              moduleName="WorkSheets"
              header="List WorkSheets"
              activityName="list_work_sheets"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default WorksheetTemplate;

// questionAnswers: [
//   // {
//   //   question: 'Answer the following question with "this or that".',
//   //   answers: [
//   //     {
//   //       src: "https://picsum.photos/600/300",
//   //       file: {
//   //         name: "goldstar_black.jpg",
//   //         lastModified: 1641460389042,
//   //         lastModifiedDate:
//   //           "Thu Jan 06 2022 14:58:09 GMT+0545 (Nepal Time)",
//   //         webkitRelativePath: "",
//   //         size: 98853,
//   //         type: "image/jpeg",
//   //         arrayBuffer: "ƒ arrayBuffer() {}",
//   //         slice: "ƒ slice() {}",
//   //         stream: "ƒ stream() {}",
//   //         text: "ƒ text() {}",
//   //       },
//   //       numberOfLines: "1",
//   //       imgWidth: 300,
//   //       imgHeight: 200,
//   //     },
//   //     {
//   //       src: "https://picsum.photos/400/400",
//   //       file: {
//   //         name: "d9a7bd9c55ddfe60c2fd7346480e0281.jpg",
//   //         lastModified: 1641460418249,
//   //         lastModifiedDate:
//   //           "Thu Jan 06 2022 14:58:38 GMT+0545 (Nepal Time)",
//   //         webkitRelativePath: "",
//   //         size: 499245,
//   //         type: "image/jpeg",
//   //         arrayBuffer: "ƒ arrayBuffer() {}",
//   //         slice: "ƒ slice() {}",
//   //         stream: "ƒ stream() {}",
//   //         text: "ƒ text() {}",
//   //       },
//   //       numberOfLines: "2",
//   //       imgWidth: 200,
//   //       imgHeight: 200,
//   //     },
//   //     {
//   //       src: "https://picsum.photos/200/250",
//   //       file: {
//   //         name: "images.jpg",
//   //         lastModified: 1641460443158,
//   //         lastModifiedDate:
//   //           "Thu Jan 06 2022 14:59:03 GMT+0545 (Nepal Time)",
//   //         webkitRelativePath: "",
//   //         size: 5892,
//   //         type: "image/jpeg",
//   //         arrayBuffer: "ƒ arrayBuffer() {}",
//   //         slice: "ƒ slice() {}",
//   //         stream: "ƒ stream() {}",
//   //         text: "ƒ text() {}",
//   //       },
//   //       numberOfLines: "14",
//   //       imgWidth: 300,
//   //       imgHeight: 160,
//   //     },
//   //   ],
//   // },
//   // {
//   //   question: 'Answer the following question with "this or that".',
//   //   answers: [
//   //     {
//   //       src: "https://picsum.photos/600/300",
//   //       file: {
//   //         name: "goldstar_black.jpg",
//   //         lastModified: 1641460389042,
//   //         lastModifiedDate:
//   //           "Thu Jan 06 2022 14:58:09 GMT+0545 (Nepal Time)",
//   //         webkitRelativePath: "",
//   //         size: 98853,
//   //         type: "image/jpeg",
//   //         arrayBuffer: "ƒ arrayBuffer() {}",
//   //         slice: "ƒ slice() {}",
//   //         stream: "ƒ stream() {}",
//   //         text: "ƒ text() {}",
//   //       },
//   //       numberOfLines: "1",
//   //       imgWidth: 300,
//   //       imgHeight: 200,
//   //     },
//   //     {
//   //       src: "https://picsum.photos/400/400",
//   //       file: {
//   //         name: "d9a7bd9c55ddfe60c2fd7346480e0281.jpg",
//   //         lastModified: 1641460418249,
//   //         lastModifiedDate:
//   //           "Thu Jan 06 2022 14:58:38 GMT+0545 (Nepal Time)",
//   //         webkitRelativePath: "",
//   //         size: 499245,
//   //         type: "image/jpeg",
//   //         arrayBuffer: "ƒ arrayBuffer() {}",
//   //         slice: "ƒ slice() {}",
//   //         stream: "ƒ stream() {}",
//   //         text: "ƒ text() {}",
//   //       },
//   //       numberOfLines: "2",
//   //       imgWidth: 200,
//   //       imgHeight: 200,
//   //     },
//   //     {
//   //       src: "https://picsum.photos/200/250",
//   //       file: {
//   //         name: "images.jpg",
//   //         lastModified: 1641460443158,
//   //         lastModifiedDate:
//   //           "Thu Jan 06 2022 14:59:03 GMT+0545 (Nepal Time)",
//   //         webkitRelativePath: "",
//   //         size: 5892,
//   //         type: "image/jpeg",
//   //         arrayBuffer: "ƒ arrayBuffer() {}",
//   //         slice: "ƒ slice() {}",
//   //         stream: "ƒ stream() {}",
//   //         text: "ƒ text() {}",
//   //       },
//   //       numberOfLines: "6",
//   //       imgWidth: 300,
//   //       imgHeight: 160,
//   //     },
//   //   ],
//   // },
// ],

import React, { Component } from "react";

class DropDownList extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>
                    Select {localStorage.getItem("educationLevelDivision")}
                  </strong>
                  <span className="tt-assessment-module-mandatory">*</span>
                </label>
              </div>
              <div className="col-md-5">
                <select
                  className="form-control"
                  onChange={this.props.universalHandleChange}
                  name="educationLevelDivision"
                  // disabled={this.props.educationLevel ? false : true}
                  value={
                    this.props.educationLevelDivision
                      ? this.props.educationLevelDivision
                      : ""
                  }
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select {localStorage.getItem("educationLevelDivision")}
                  </option>
                  {this.props.educationLevelDivisions
                    ? this.props.educationLevelDivisions.map(
                        (educationLevelDivision, idx) => (
                          <option key={idx} value={educationLevelDivision.id}>
                            {educationLevelDivision.name}
                          </option>
                        )
                      )
                    : null}
                </select>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>Select {localStorage.getItem("yearLevel")}</strong>
                  <span className="tt-assessment-module-mandatory">*</span>
                </label>
              </div>
              <div className="col-md-5">
                <select
                  className="form-control"
                  name="yearLevel"
                  value={this.props.yearLevel ? this.props.yearLevel : ""}
                  onChange={this.props.universalHandleChange}
                  disabled={this.props.educationLevelDivision ? false : true}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select {localStorage.getItem("yearLevel")}
                  </option>
                  {this.props.yearLevels
                    ? this.props.yearLevels.map((yearLevel, idx) => (
                        <option key={idx} value={yearLevel.id}>
                          {yearLevel.name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="col-md-3">
                {this.props.educationLevelDivision ? (
                  ""
                ) : (
                  <p
                    className="tt-notify"
                    style={{ marginLeft: "2%", fontSize: "11px" }}
                  >
                    (Select {localStorage.getItem("educationLevelDivision")}{" "}
                    first)
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>Select {localStorage.getItem("classroom")}</strong>
                  <span className="tt-assessment-module-mandatory">*</span>
                </label>
              </div>
              <div className="col-md-5">
                <select
                  className="form-control"
                  name="classroom"
                  value={this.props.classroom}
                  onChange={this.props.universalHandleChange}
                  disabled={this.props.yearLevel ? false : true}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select {localStorage.getItem("classroom")}
                  </option>
                  {this.props.classroomList
                    ? this.props.classroomList.map((classroom, idx) => (
                        <option key={idx} value={classroom.id}>
                          {classroom.name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="col-md-3">
                {this.props.yearLevel ? (
                  ""
                ) : (
                  <p
                    className="tt-notify"
                    style={{ marginLeft: "2%", fontSize: "11px" }}
                  >
                    (Select {localStorage.getItem("yearLevel")} first)
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>Select subject</strong>
                  <span className="tt-assessment-module-mandatory">*</span>
                </label>
              </div>
              <div className="col-md-5">
                <select
                  className="form-control"
                  multiple
                  name="subject"
                  value={this.props.subject}
                  onChange={this.props.multipleSubjectSelectHandler}
                  disabled={this.props.yearLevel ? false : true}
                  defaultValue={[""]}
                >
                  <option value="" disabled>
                    Select subject
                  </option>
                  {this.props.subjectList &&
                  this.props.subjectList.length > 0 ? (
                    this.props.subjectList.map((subject, idx) => (
                      <option key={idx} value={subject.subjectId}>
                        {subject.subjectName}
                      </option>
                    ))
                  ) : this.props.yearLevel ? (
                    <option disabled value="">
                      No Optional Subjects
                    </option>
                  ) : (
                    ""
                  )}
                </select>
              </div>
              <div className="col-md-3">
                {this.props.yearLevel ? (
                  ""
                ) : (
                  <p
                    className="tt-notify"
                    style={{ marginLeft: "2%", fontSize: "11px" }}
                  >
                    (Select {localStorage.getItem("yearLevel")} first)
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DropDownList;

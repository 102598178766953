import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL as URLs } from "../../utils/Constants";
import CreateWorksheetQuestions from "./CreateWorksheetQuestions";
import WorksheetHeader from "./WorksheetHeader";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import ModalWindow from "../UI/ModalWindow";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

class Worksheet extends Component {
  state = {
    err: false,
    questionAnswers: [
      {
        question: "",
        answers: [
          {
            src: "",
            file: "",
            numberOfLine: 0,
            imageWidth: 0,
            imageHeight: 0,
            text: "",
            index: "",
          },
        ],
      },
    ],
    clearInputField: false,
    submitSpinner: false,
    permissionModal: false,
  };

  addAnswers = (qIdx) => {
    let questionAnswers = [...this.state.questionAnswers];
    //check the length of answers array
    if (questionAnswers.length > 1) {
      for (let i = 0; i < questionAnswers.length; i++) {
        if (questionAnswers[i].question === "") {
          this.setState({ err: true });
          swal({
            title: "Error",
            text: "Question  musnot be Empty",
          });
          return;
        } else {
          this.setState({ err: false });
        }

        if (
          questionAnswers[i].answers
            .map((ans) => ans.numberOfLine >= 1)
            .includes(false)
        ) {
          this.setState({ err: true });
          swal({
            title: "Error",
            text: "Number of Lines must be greater than zero",
          });
          return;
        } else {
          this.setState({ err: false });
        }
      }
    }
    if (questionAnswers[0].answers.length > 1) {
      //for multiple answer array -check the noOfLine param
      if (
        questionAnswers[0].answers
          .map((ans) => ans.numberOfLine >= 1)
          .includes(false)
      ) {
        this.setState({ err: true });
        swal({
          title: "Error",
          text: "Number of Lines must be greater than zero",
        });
        return;
      }
    } else {
      this.setState({ err: false });
      if (
        questionAnswers[0].answers
          .map((ans) => ans.numberOfLine >= 1)
          .toLocaleString() === "false"
      ) {
        this.setState({ err: true });

        swal({
          title: "Error",
          text: "Number of Lines must be greater than zero",
        });
        return;
      } else {
        this.setState({ err: false });
      }

      if (questionAnswers[0].question === "") {
        this.setState({ err: true });
        swal({
          title: "Error",
          text: "Question  musnot be Empty",
        });
        return;
      } else {
        this.setState({ err: false });
      }
    }

    questionAnswers[qIdx].answers.push({
      src: "",
      file: "",
      text: "",
      numberOfLine: 0,
      imageHeight: 0,
      imageWidth: 0,
    });
    this.setState({ questionAnswers });
  };

  addQuestionAnswers = () => {
    let questionAnswers = [...this.state.questionAnswers];
    //check the length of questionAnswers array & validate accordingly
    for (let i = 0; i < questionAnswers.length; i++) {
      if (questionAnswers[i].question === "") {
        this.setState({ err: true });
        swal({
          title: "Error",
          text: "Question  musnot be Empty",
        });
        return;
      } else {
        this.setState({ err: false });
      }
      if (
        questionAnswers[i].answers
          .map((ans) => ans.numberOfLine >= 1)
          .includes(false)
      ) {
        this.setState({ err: true });
        swal({
          title: "Error",
          text: "Number of Lines must be greater than zero",
        });
        return;
      } else {
        this.setState({ err: false });
      }
    }

    questionAnswers.push({
      question: "",
      answers: [{ src: "", file: "", numberOfLine: 0 }],
    });
    this.setState({ questionAnswers });
  };

  componentDidMount() {
    this.workSheetHeader = React.createRef();
  }

  handleAnswerDataChange = (e, qIdx, aIdx) => {
    let questionAnswers = [...this.state.questionAnswers];
    let name = e.target.name;
    questionAnswers[qIdx].answers[aIdx][name] = e.target.value;
    this.setState({ questionAnswers });
  };

  handleCancel = () => {
    this.setState({
      date: "",
      questionAnswers: [
        { question: "", answers: [{ src: "", file: "", numberOfLine: 0 }] },
      ],
      subject: "",
      title: "",
      yearLevel: "",
    });
  };

  handleDataChange = (e, idx) => {
    let questionAnswers = [...this.state.questionAnswers];
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    questionAnswers[idx][name] = value;
    this.setState({ questionAnswers });
  };

  handleImageChange = (e, questionIndex, answerIndex) => {
    let questionAnswers = [...this.state.questionAnswers];
    if (e.target.files.length > 0) {
      questionAnswers[questionIndex].answers[answerIndex].file =
        e.target.files[0];
      questionAnswers[questionIndex].answers[answerIndex].src =
        URL.createObjectURL(e.target.files[0]);
      this.setState({ questionAnswers });
    }
  };

  removeAnswers = (qIdx, aIdx) => {
    let questionAnswers = [...this.state.questionAnswers];
    questionAnswers[qIdx].answers.splice(aIdx, 1);
    this.setState({ questionAnswers });
  };

  removeImage = (qIdx, aIdx) => {
    let questionAnswers = [...this.state.questionAnswers];
    questionAnswers[qIdx].answers[aIdx].file = "";
    questionAnswers[qIdx].answers[aIdx].src = "";
    this.setState({ questionAnswers });
  };

  removeQuestionAnswers = (idx) => {
    let questionAnswers = [...this.state.questionAnswers];
    questionAnswers.splice(idx, 1);
    this.setState({ questionAnswers });
  };

  save = () => {
    let error = false;
    const workSheetHeader = this.workSheetHeader.current;
    console.log(workSheetHeader);

    if (workSheetHeader === null) {
      swal({ title: "Error", text: "Please Fill up the required field check" });
      return;
    }

    if (workSheetHeader !== null) {
      if (
        workSheetHeader.state.date === "" ||
        workSheetHeader.state.subject === "" ||
        workSheetHeader.state.title === "" ||
        workSheetHeader.state.yearLevel === ""
      ) {
        swal({ title: "Error", text: "Please Fill up the required field" });
        return;
      }
    }

    let param = {
      submitDate: workSheetHeader.state.date,
      workSheetQuestions: [],
      subjectId: workSheetHeader.state.subject,
      title: workSheetHeader.state.title,
      yearLevelId: workSheetHeader.state.yearLevel,
    };
    // let questions = JSON.parse(JSON.stringify(this.state.questionAnswers)),
    let questions = [...this.state.questionAnswers];
    // //validate WorksheetQuestions before submitting

    if (questions.length === 1) {
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].question === "") {
          swal({ title: "Error", text: "Question musnot be empty" });
          return;
        }
        if (
          questions[i].answers
            .map((ans) => ans.numberOfLine >= 1)
            .includes(false)
        ) {
          swal({
            title: "Error",
            text: "Number of Lines must be greater than zero",
          });
          return;
        }
      }
    } else {
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].question === "") {
          swal({ title: "Error", text: "Question musnot be empty" });
          return;
        }
        if (
          questions[i].answers
            .map((ans) => ans.numberOfLine >= 1)
            .includes(false)
        ) {
          swal({
            title: "Error",
            text: "Number of Lines must be greater than zero",
          });
          return;
        }
      }
    }

    let demofiles = [];
    let formData = new FormData();

    questions.forEach((q, qIdx) => {
      q.answers.forEach((a, aIdx) => {
        let index = a.index;
        if (a.src !== "") {
          formData.append("resources", a.file);

          demofiles.push(a.file);
          a.index = demofiles.length - 1;
        }
        delete a.file;
        delete a.src;
      });
    });

    param.workSheetQuestions = questions;

    // ;
    // formData.append("resources", demofiles);
    formData.append("workSheetData", JSON.stringify(param));

    //NOt requires as error prompted onFly
    // if (this.state?.err) {
    //   swal({
    //     title: "Error",
    //     text: "Field is Misssing",
    //   });
    //   return;
    // }

    this.setState(
      {
        submitSpinner: true,
      },
      () => {
        axiosPost(
          URLs.insertWorksheet,
          formData,
          (response) => {
            if (response.status === 200) {
              swal({ title: "Success", text: response.data.message });
              this.clearState();
              this.setState({
                clearInputField: !this.state.clearInputField,
                submitSpinner: false,
              });
            }
          },
          (err) => {
            // ;
            swal("Warning", "Some error occurred!!");
            this.setState({
              submitSpinner: false,
            });
          }
        );
      }
    );
  };
  clearState = () => {
    this.setState({
      questionAnswers: [
        {
          question: "",
          answers: [
            {
              src: "",
              file: "",
              numberOfLine: 0,
              imageWidth: 0,
              imageHeight: 0,
              text: "",
              index: "",
            },
          ],
        },
      ],
    });
  };
  render() {
    return (
      <>
        {this.state.submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Your request is being processed!!
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "81.5vh" }}
        >
          <div className="tt-group-header">
            Insert Worksheet
            {checkWidgetAdminOrNot("WorkSheets") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <WorksheetHeader
            ref={this.workSheetHeader}
            clearInputField={this.state.clearInputField}
          />
          <div className="container-fluid mt-3">
            <div className="row mt-3">
              <CreateWorksheetQuestions
                addAnswers={this.addAnswers}
                addQuestionAnswers={this.addQuestionAnswers}
                handleAnswerDataChange={this.handleAnswerDataChange}
                handleDataChange={this.handleDataChange}
                handleImageChange={this.handleImageChange}
                questionAnswers={this.state.questionAnswers}
                removeAnswers={this.removeAnswers}
                removeImage={this.removeImage}
                removeQuestionAnswers={this.removeQuestionAnswers}
              ></CreateWorksheetQuestions>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.save}
                // disabled={this.state?.err}
              >
                Save
              </button>
              <button
                className="tt-button tt-button-danger ml-3"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="WorkSheets"
              moduleName="WorkSheets"
              header="Insert WorkSheets"
              activityName="insert_work_sheets"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

export default Worksheet;
